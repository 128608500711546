import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { useState } from "react";

import ChangeProfileModalByAdmin from "../Modals/ChangeProfileModalByAdmin";
import PhotoInput from "../../UI/PhotoInput/PhotoInput";
import Scrollbar from "../../UI/Scroolbar/Scrollbar";
import GeneralInfoEdit from "./GeneralInfoEdit";
import AccountingInfo from "./AccountingInfo";
import CardZachetInfo from "./CardZachetInfo";
import Button from "../../UI/Button/Button";
import GeneralInfo from "./GeneralInfo";
import SocialInfo from "./SocialInfo";
import EventInfo from "./EventInfo";
import Tabs from "../Tabs/Tabs";

import { useDispathProfile } from "../../hooks/useDispathProfile";
import { REQUEST_URL, routerPath } from "../../interface/config";
import { UserActions } from "../../interface/userActions";
import { Functions } from "../../interface/functions";
import { Table } from "../../interface/Table";
import { HTTP } from "../../interface/HTTP";

import "./CardProfile.css";

export default function CardProfile({
    detailUser,
    isLoadingDetailUser,
    modalStatus,
    pageType,
    setModalStatus,
    selectedDetailUse,
    activeState = 0,
    setTransferDetailUser,
    selectedDetailUser,
    changeFetchStatus,
    setButtonStatus,
    changeButtonStatus,
    typeOfLearning = "budget",
}) {
    const userData = useSelector(({ profile }) => profile);

    const currentPage = useLocation();

    const [activeTab, setActiveTab] = useState(activeState);
    const [editMode, setEditMode] = useState(false);

    const tabsList = ["Основная информация", "Zachet", "Бухгалтерия", "Соц. программы", "Мероприятия"];

    const tabsContent = [
        { children: <GeneralInfo detailUser={detailUser} pageType={pageType} activeTableTab={modalStatus} /> },
        { children: <AccountingInfo detailUser={detailUser} /> },
        { children: <SocialInfo /> },
        { children: <EventInfo detailUserEvents={detailUser}/> },
    ];

    function getActiveTab(index) {
        setActiveTab(index);
    }

    const setTypeOfLerning = type_of_learning => {
        if (type_of_learning === "DAY_BD" || type_of_learning === "EVE_BD") {
            return "Бюджет";
        } else {
            return "Контракт";
        }
    };

    const setTypeOfSex = sex => {
        if (sex === "MEN") {
            return "М";
        } else {
            return "Ж";
        }
    };
    const setTabContent = () => {
        switch (activeTab) {
            case 0: {
                return !editMode ? (
                    <GeneralInfo detailUser={detailUser} />
                ) : (
                    <GeneralInfoEdit detailUser={detailUser} setModalStatus={e => setModalStatus("none")} />
                );
            }
            case 1:
                return <CardZachetInfo detailUser={detailUser} />;
            case 2:
                return <AccountingInfo detailUser={detailUser} />;
            case 3:
                return <SocialInfo detailUserSocials={detailUser.social}/>;
            case 4:
                return <EventInfo detailUserEvents={detailUser.event}/>;
            default:
                return "0";
        }
    };

    const getUserAplicationForm = async () => {
        let selectedArr = [
            {
                id: selectedDetailUser.id,
                status: Table.setUserStatus(selectedDetailUser.status),
                card: {
                    id: selectedDetailUser.card ? selectedDetailUser.card.id : false,
                },
            },
        ];
        const userApplicationForm = await HTTP.PostFormForRequest(
            REQUEST_URL.getApplicationFormForAdmin,
            JSON.stringify({ ids: UserActions.getUsersIds(selectedArr) })
        );
    };

    const updateStatusUser = async (text, buttonStatus, secondStatus) => {
        let selectedArr = [
            {
                id:
                    (userData.role === "Администратор" || userData.role === "Председатель профкома") &&
                    currentPage.pathname === routerPath.table
                        ? selectedDetailUser[14]
                        : selectedDetailUser.id,
                status: Table.setUserStatus(selectedDetailUser.status),

                card: {
                    id: selectedDetailUser.card
                        ? selectedDetailUser.card.id
                        : detailUser.card === null
                        ? false
                        : detailUser.card.id,
                },
            },
        ];

        if (buttonStatus === "DOWNLOAD_PHOTO") {
            const userApplicationForm = await HTTP.PostFormForRequest(
                REQUEST_URL.zipImageUsers,
                JSON.stringify({ ids: UserActions.getUsersIds(selectedArr) })
            );
        } else if (
            buttonStatus === "NO" ||
            buttonStatus === "MAKE" ||
            buttonStatus === "UNLOAD" ||
            buttonStatus === "MADE" ||
            buttonStatus === "ISSUED"
        ) {
            const resp = UserActions.updateCard(selectedArr, buttonStatus);
            setModalStatus("none");
            if (resp && setTransferDetailUser) {
                setButtonStatus(buttonStatus);
                setTransferDetailUser(selectedArr);
            }
        } else if (userData.role === "Администратор" || userData.role === "Председатель профкома") {
            const resp = UserActions.update(selectedArr, buttonStatus, (secondStatus = false), changeFetchStatus);
            setModalStatus("none");
            if (resp && setTransferDetailUser) {
                setButtonStatus(buttonStatus);
                setTransferDetailUser(selectedArr);
            }
        } else {
            UserActions.update(selectedArr, buttonStatus, secondStatus);
            setModalStatus("none");
        }
    };

    return (
        <div className="card-profile">
            <div className="card-profile__wrapper DAS-scrollbar" style={{ height: "75vh", overflowX: "hidden" }}>
                {isLoadingDetailUser ? (
                    <div className="modal-loader">Загрузка...</div>
                ) : (
                    <>
                        {!editMode && (
                            <div className="card-profile__header">
                                <div className="card-profile__header-wrapper">
                                    <div className="card-profile__header-image">
                                        <PhotoInput
                                            mode="disable"
                                            user_photo={
                                                detailUser
                                                    ? Functions.setUserPhoto(detailUser.image)
                                                    : Functions.setUserPhoto(userData.image)
                                            }
                                        />
                                    </div>
                                    <div className="card-profile__header-content">
                                        <div className="card-profile__header-tab">
                                            <p className="card-profile__header-tab__text">
                                                {setTypeOfLerning(detailUser.type_of_learning)}
                                            </p>
                                        </div>
                                        <div className="card-profile__header-block card-profile__header-block-name">
                                            <p className="card-profile__header__title">
                                                {detailUser.last_name} {detailUser.first_name} {detailUser.middle_name}
                                            </p>
                                            <p className="card-profile__header__sub">
                                                {detailUser.last_name_translated} {detailUser.first_name_translated}{" "}
                                                {detailUser.middle_name_translated}
                                            </p>
                                        </div>

                                        <div className="card-profile__header-block card-profile__header-block-sex">
                                            <div className="card-profile__header-sex card-profile__text">
                                                {setTypeOfSex(detailUser.sex)}
                                            </div>
                                            <span className="card-profile__text">
                                                {Functions.formatDate(detailUser.date_of_birth)}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {!editMode && (
                            <div className="card-profile__tabs">
                                <div className="card-profile__tabs-list DAS-scrollbar">
                                    <Tabs
                                        tabsLabelList={tabsList}
                                        callback={getActiveTab}
                                        active={activeTab}
                                        type="white"
                                        border={"all"}
                                    />
                                </div>
                            </div>
                        )}

                        <div className="card-profile__tabs-content">{setTabContent()}</div>
                    </>
                )}
            </div>
            <div className="card-profile__btn">
                {!editMode && pageType === "table" && (
                    <>
                        {modalStatus === "user_all" &&
                            activeTab === 0 &&
                            !editMode &&
                            Functions.getAccess(userData.access, "table", "USER_EDIT") && (
                                <>
                                    <Button type="button" text="РЕДАКТИРОВАТЬ" callback={() => setEditMode(true)} />
                                </>
                            )}
                        {modalStatus === "new_users" && activeTab === 0 && (
                            <>
                                {Functions.getAccess(userData.access, "table", "APPL_1") && (
                                    <Button
                                        type="button"
                                        text="ВЕРИФИЦИРОВАТЬ"
                                        color="green"
                                        callback={e => updateStatusUser(e.target.innerText, "APPL")}
                                    />
                                )}
                                {Functions.getAccess(userData.access, "table", "EXCLUDE_1") && (
                                    <Button
                                        type="button"
                                        text="ОТКЛОНИТЬ"
                                        callback={e => updateStatusUser(e.target.innerText, "EXCLUDE")}
                                        color="red"
                                    />
                                )}
                                {!editMode && Functions.getAccess(userData.access, "table", "USER_EDIT") && (
                                    <Button type="button" text="РЕДАКТИРОВАТЬ" callback={() => setEditMode(true)} />
                                )}
                            </>
                        )}
                        {modalStatus === "user_application" && activeTab === 0 && (
                            <>
                                {Functions.getAccess(userData.access, "table", "MEMBER") && (
                                    <Button
                                        type="button"
                                        text="ПРИНЯТЬ"
                                        color="green"
                                        callback={e => updateStatusUser(e.target.innerText, "MEMBER", "MEMBER")}
                                    />
                                )}
                                {Functions.getAccess(userData.access, "table", "EXCLUDE_1") && (
                                    <Button
                                        type="button"
                                        text="ОТКЛОНИТЬ"
                                        callback={e => updateStatusUser(e.target.innerText, "EXCLUDE")}
                                        color="red"
                                    />
                                )}
                                {!editMode && Functions.getAccess(userData.access, "table", "USER_EDIT") && (
                                    <Button type="button" text="РЕДАКТИРОВАТЬ" callback={() => setEditMode(true)} />
                                )}
                                {Functions.getAccess(userData.access, "table", "STATMENT") && (
                                    <Button
                                        text="ЗАЯВЛЕНИЕ"
                                        type="button"
                                        color="green"
                                        callback={() => getUserAplicationForm()}
                                    />
                                )}
                            </>
                        )}
                        {modalStatus === "kick" && activeTab === 0 && (
                            <>
                                {Functions.getAccess(userData.access, "table", "APPL_2") && (
                                    <Button
                                        type="button"
                                        text="ПРИНЯТЬ ОБРАТНО"
                                        color="green"
                                        callback={e => updateStatusUser(e.target.innerText, "APPL", "APPL")}
                                    />
                                )}
                                {Functions.getAccess(userData.access, "table", "ARCH") && (
                                    <Button
                                        type="button"
                                        text="АРХИВИРОВАТЬ"
                                        callback={e => updateStatusUser(e.target.innerText, "ARCH")}
                                        color="green"
                                    />
                                )}
                                {!editMode && Functions.getAccess(userData.access, "table", "USER_EDIT") && (
                                    <Button type="button" text="РЕДАКТИРОВАТЬ" callback={() => setEditMode(true)} />
                                )}
                            </>
                        )}
                        {modalStatus === "user_prof" && activeTab === 0 && (
                            <>
                                {!editMode && Functions.getAccess(userData.access, "table", "USER_EDIT") && (
                                    <Button type="button" text="РЕДАКТИРОВАТЬ" callback={() => setEditMode(true)} />
                                )}
                                {Functions.getAccess(userData.access, "table", "NO") && (
                                    <Button
                                        type="button"
                                        text="ПЕРЕВЫПУСТИТЬ КАРТУ"
                                        color="green"
                                        callback={e => updateStatusUser(e.target.innerText, "NO")}
                                    />
                                )}
                                {Functions.getAccess(userData.access, "table", "EXCLUDE_2") && (
                                    <Button
                                        type="button"
                                        text="ИСКЛЮЧИТЬ"
                                        color="red"
                                        callback={e => updateStatusUser(e.target.innerText, "EXCLUDE")}
                                    />
                                )}
                            </>
                        )}
                    </>
                )}
                {!editMode && pageType === "card" && (
                    <>
                        {modalStatus === "user_prof" && (
                            <>
                                {Functions.getAccess(userData.access, "card_admin", "USER_EDIT") && (
                                    <Button
                                        type="button"
                                        text="РЕДАКТИРОВАТЬ"
                                        callback={() => setModalStatus("change_profilebyadmin")}
                                    />
                                )}
                                {Functions.getAccess(userData.access, "card_admin", "NO") && (
                                    <Button
                                        type="button"
                                        text="ПЕРЕВЫПУСТИТЬ КАРТУ"
                                        color="green"
                                        callback={e => updateStatusUser(e.target.innerText, "NO")}
                                    />
                                )}
                                {Functions.getAccess(userData.access, "card_admin", "EXCLUDE_2") && (
                                    <Button
                                        type="button"
                                        text="ИСКЛЮЧИТЬ"
                                        color="red"
                                        callback={e => updateStatusUser(e.target.innerText, "EXCLUDE")}
                                    />
                                )}
                            </>
                        )}
                        {modalStatus === "no_map" && userData.role === "Администратор" && (
                            <>
                                {Functions.getAccess(userData.access, "card_admin", "MAKE_1") && (
                                    <Button
                                        type="button"
                                        text="НАЧАТЬ ФОРМИРОВАНИЕ КАРТЫ"
                                        color="blue"
                                        callback={e => {
                                            updateStatusUser(e.target.innerText, "MAKE");
                                        }}
                                    />
                                )}
                            </>
                        )}
                        {modalStatus === "make" && userData.role === "Администратор" && (
                            <>
                                {Functions.getAccess(userData.access, "card_admin", "UNLOAD_1") && (
                                    <Button
                                        type="button"
                                        text="ИЗГОТОВИТЬ КАРТУ"
                                        color="blue"
                                        callback={e => updateStatusUser(e.target.innerText, "UNLOAD")}
                                    />
                                )}
                                {Functions.getAccess(userData.access, "card_admin", "NO") && (
                                    <Button
                                        type="button"
                                        text="Вернуть 'Нет карты'"
                                        color="green"
                                        callback={e => updateStatusUser(e.target.innerText, "NO")}
                                    />
                                )}
                                {Functions.getAccess(userData.access, "card_admin", "DOWNLOAD_PHOTO") && (
                                    <Button
                                        type="button"
                                        text="Скачать фото"
                                        color="green"
                                        callback={e => updateStatusUser(e.target.innerText, "DOWNLOAD_PHOTO")}
                                    />
                                )}
                            </>
                        )}
                        {modalStatus === "unload" && userData.role === "Администратор" && (
                            <>
                                {Functions.getAccess(userData.access, "card_admin", "MADE_2") && (
                                    <Button
                                        type="button"
                                        text="ВЫДАТЬ КАРТУ"
                                        color="blue"
                                        callback={e => updateStatusUser(e.target.innerText, "MADE")}
                                    />
                                )}
                                {Functions.getAccess(userData.access, "card_admin", "MAKE_3") && (
                                    <Button
                                        type="button"
                                        text="Вернуть 'На изготовлении'"
                                        color="green"
                                        callback={e => updateStatusUser(e.target.innerText, "MAKE")}
                                    />
                                )}
                            </>
                        )}
                        {modalStatus === "made" && userData.role === "Администратор" && (
                            <>
                                {Functions.getAccess(userData.access, "card_admin", "ISSUED") && (
                                    <Button
                                        type="button"
                                        text="ЗАБРАТЬ КАРТУ"
                                        color="blue"
                                        callback={e => updateStatusUser(e.target.innerText, "ISSUED")}
                                    />
                                )}
                                {Functions.getAccess(userData.access, "card_admin", "UNLOAD_2") && (
                                    <Button
                                        type="button"
                                        text="Вернуть 'Выгруженные'"
                                        color="green"
                                        callback={e => updateStatusUser(e.target.innerText, "UNLOAD")}
                                    />
                                )}
                            </>
                        )}
                        {modalStatus === "issued" && userData.role === "Администратор" && (
                            <>
                                {Functions.getAccess(userData.access, "card_admin", "MADE_1") && (
                                    <Button
                                        type="button"
                                        text="ВЕРНУТЬ 'ИЗГОТОВЛЕНЫ'"
                                        color="green"
                                        callback={e => updateStatusUser(e.target.innerText, "MADE")}
                                    />
                                )}
                            </>
                        )}
                        {Functions.getAccess(userData.access, "card_admin", "USER_EDIT") && (
                            <Button type="button" text="РЕДАКТИРОВАТЬ" callback={() => setEditMode(true)} />
                        )}
                    </>
                )}
                {!editMode && pageType === "bookkeping" && (
                    <>
                        {(modalStatus === "ENTRY" || modalStatus === "PROL") && typeOfLearning === "budget" && (
                            <>
                                <Button
                                    type="button"
                                    text="ПЕРЕДАТЬ В ЦБ"
                                    color="green"
                                    callback={e => updateStatusUser(e.target.innerText, "TRANS")}
                                />
                            </>
                        )}
                        {modalStatus === "TRANS" && typeOfLearning === "budget" && (
                            <>
                                <Button
                                    type="button"
                                    text="ПОДТВЕРДИТЬ ОПЛАТУ"
                                    color="green"
                                    callback={e => updateStatusUser(e.target.innerText, "CONF")}
                                />
                            </>
                        )}
                    </>
                )}
                {!editMode && pageType === "rzdTable" && (
                    <>
                        {modalStatus === "rzd_status_FILLED" && (
                            <>
                                {Functions.getAccess(userData.access, "rzd_bonus", "PASSED") && (
                                    <Button
                                        type="button"
                                        text="ПЕРЕДАТЬ В РЖД"
                                        color="green"
                                        // callback={e => updateRzdStatusUser("PASSED")}
                                    />
                                )}
                                {userData.role === "Администратор" &&
                                    Functions.getAccess(userData.access, "rzd_bonus", "USER_EDIT") && (
                                        <Button
                                            type="button"
                                            text="РЕДАКТИРОВАТЬ"
                                            color="blue"
                                            callback={() => setModalStatus("change_profilebyadmin")}
                                        />
                                    )}
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}
