import { useState } from "react";
import { useDebounce } from "./useDebounce";

export function useFilter(items, filterProp) {
    const [enteredSearchValue, setEnteredSearchValue] = useState("");
    const activeSearchValue = useDebounce(enteredSearchValue, 500);

    const availablesItems =
        activeSearchValue && filterProp !== ""
            ? items.filter(item => RegExp(activeSearchValue, "i").test(item[filterProp]))
            : activeSearchValue && filterProp === ""
            ? items.filter(item =>
                  Object.values(item).join(" ").toLowerCase().includes(activeSearchValue.toLowerCase())
              )
            : items;

    return {
        enteredSearchValue,
        setEnteredSearchValue,
        availablesItems,
    };
}

/*
    const [enteredSearchValue, setEnteredSearchValue] = useState("");
    const activeSearchValue = useDebounce(enteredSearchValue, 500);
    const [availablesItems, setAvailablesItems] = useState(items);

    useEffect(() => {
        if (enteredSearchValue !== ""){
            const itemsList = 
                (activeSearchValue && filterProp !== "")
                    ? items.filter(item => item[filterProp].toLowerCase().includes(activeSearchValue.toLowerCase()))
                    : activeSearchValue && filterProp === ""
                    ? items.filter(item =>
                          Object.values(item).join(" ").toLowerCase().includes(activeSearchValue.toLowerCase())
                      )
                    : items
            
            console.log(itemsList)
            setAvailablesItems(itemsList);
        }
        else{
            console.log(123)
            setAvailablesItems(items);
        }
    }, [enteredSearchValue]);

    return {
        enteredSearchValue,
        setEnteredSearchValue,
        availablesItems,
    };
    */
