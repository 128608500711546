import { useNavigate, useLocation } from "react-router-dom";
import { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import TextArea from "../../components/Textarea/Textarea";
import PhotoInput from "../../UI/PhotoInput/PhotoInput";
import Button from "../../UI/Button/Button";
import Input from "../../UI/Input/Input";

import { useDispathProfile } from "../../hooks/useDispathProfile";
import { UserActions } from "../../interface/userActions";
import { Functions } from "../../interface/functions";
import ConvertData from "../../interface/ConvertData";
import { Table } from "../../interface/Table";
import { HTTP } from "../../interface/HTTP";

import { REQUEST_URL, routerPath } from "../../interface/config";

import close_window from "../../images/other/close_window.png";

export default function OtherModals(props) {
    const {
        modalStatus,
        setModalStatus,
        selectedDetailUser,
        detailUser,
        isLoadingDetailUser,
        setDetailUser,
        setTransferDetailUser,
        setButtonStatus,
        activeTab,
        changeFetchStatus,
        updateRzdNumber,
        selectedUserId,
    } = props;

    const [textAreaValue, setTextAreaValue] = useState("");
    const [errors, setErrors] = useState("");

    const userData = useSelector(({ profile }) => profile);
    const [profileData, setDispatch] = useDispathProfile({});

    const currentPage = useLocation();
    const navigate = useNavigate();

    const faculties = useSelector(({ facultiesList }) => [
        { id: "", title: "Выберите Регион" },
        ...facultiesList.facultiesList,
    ]);

    const modalTitle = useRef();
    const modalDescription = useRef();

    const setTitleAndDescription = () => {
        switch (modalStatus) {
            case "change_faculty":
                modalTitle.current.innerHTML = "Смена региона";
                modalDescription.current.innerHTML =
                    "Для смены региона вы также можете обратиться к председателю. Обратите внимание, что рассмотрение заявки может занять от 2 до 7 дней.";
                break;
            case "join_faculty":
                modalTitle.current.innerHTML = "Вступление в АМП";
                modalDescription.current.innerHTML =
                    "Для вступления в АМП и подачи заявки, пожалуйста, заполните все поля. Для получения карты Зачёт нужно дождаться подтверждения профиля и подписать заявление на вступление в профсоюз";
                break;
            case "material_support":
                modalTitle.current.innerHTML = "Документы";
                modalDescription.current.innerHTML =
                    "После формирования файла вы должны его подписать и отнести в АМП.";
                break;
            case "user_application":
                modalTitle.current.innerHTML = "Подал заявку";
                modalDescription.current.innerHTML = "";
                break;
            case "user_prof":
                modalTitle.current.innerHTML = "Член АМП";
                modalDescription.current.innerHTML = "";
                break;
            case "kick":
                modalTitle.current.innerHTML = "Исключен из АМП";
                modalDescription.current.innerHTML = "";
                break;
            case "new_users":
                modalTitle.current.innerHTML = "Новый пользователь";
                modalDescription.current.innerHTML = "";
                break;
            case "user_all":
                modalTitle.current.innerHTML = "";
                modalDescription.current.innerHTML = "";
                break;
            case "no_map":
                modalTitle.current.innerHTML = "Нет карты";
                modalDescription.current.innerHTML = "";
                break;
            case "make":
                modalTitle.current.innerHTML = "На изготовлении";
                modalDescription.current.innerHTML = "";
                break;
            case "unload":
                modalTitle.current.innerHTML = "Выгружена";
                modalDescription.current.innerHTML = "";
                break;
            case "made":
                modalTitle.current.innerHTML = "Изготовлена";
                modalDescription.current.innerHTML = "";
                break;
            case "issued":
                modalTitle.current.innerHTML = "Выдана";
                modalDescription.current.innerHTML = "";
                break;
            case "rzd_status_FILLED":
                modalTitle.current.innerHTML = "Номер в РЖД";
                modalDescription.current.innerHTML = "";
                break;
            case "rzd_status_PASSED":
                modalTitle.current.innerHTML = "Передан в РЖД";
                modalDescription.current.innerHTML = "";
                break;
            case "restore_membership":
                modalTitle.current.innerHTML = "Восстановить членство";
                modalDescription.current.innerHTML = "";
                break;
            case "finished_msu":
                modalTitle.current.innerHTML = "Восстановить членство";
                modalDescription.current.innerHTML = "";
                break;
            default:
                modalTitle.current.innerHTML = "default";
                modalDescription.current.innerHTML = "default";
                break;
        }
    };

    const modalsRequests = async () => {
        if (modalStatus === "change_faculty") {
            let requestList = await HTTP.resetUser(REQUEST_URL.resetCurrentUser);
            if (requestList === 204) {
                window.location.reload();
                // dispatch(role("Подал заявку"));
            }
        }
        if (modalStatus === "restore_membership") {
            let requestList = await HTTP.resetUser(REQUEST_URL.resetCurrentUser);
            if (requestList === 204) {
                window.location.reload();
                // dispatch(role("Подал заявку"));
            }
        }
        if (modalStatus === "finished_msu") {
            let requestList = await HTTP.Update("PATCH", true, REQUEST_URL.updateUser(userData.id), {status: "EXCLUDE"});
            if (requestList === 204) {
                window.location.reload();
            }
        }
        if (modalStatus === "material_support") {
            if (textAreaValue !== "") {
                const responce = await HTTP.getDownloadFiles(
                    REQUEST_URL.userMaterialSupport,
                    { text: textAreaValue },
                    "filename=",
                    "POST"
                );
                setTextAreaValue("");
                setModalStatus("none");
                if (responce === 200) {
                    navigate("/");
                } else {
                }
            }
        }
        if (modalStatus === "join_faculty") {
            const validate = Functions.validateFakeForm(userData);

            if (validate.length === 0) {
                setErrors("");
                setModalStatus("none");
                const userApplicationForm = await HTTP.GetFile(REQUEST_URL.userApplicationForm);
                if (userApplicationForm === 200) {
                    setDispatch({ ...userData, status: "Подал заявку" });
                }
            } else {
                setErrors(`Для отправки заявки заполните недостающие поля в личном кабинете: ${validate.join(", ")}`);
            }
        }
    };

    const updateRzdStatusUser = async buttonStatus => {
        let selectedArrUsers = [
            {
                id: detailUser.id,
                rzd_status: buttonStatus,
            },
        ];

        const resp = UserActions.updateStatus(selectedArrUsers, buttonStatus, null, detailUser.rzd_number);
        if (resp && setTransferDetailUser) {
            setButtonStatus(buttonStatus);
            setTransferDetailUser(selectedArrUsers);
            setModalStatus("none");
        }
    };

    const updateStatusUser = async (text, buttonStatus, secondStatus) => {
        let selectedArr = [
            {
                id:
                    (userData.role === "Администратор" || userData.role === "Председатель профкома") &&
                    currentPage.pathname === routerPath.table
                        ? selectedDetailUser[14]
                        : selectedDetailUser.id,
                status: Table.setUserStatus(selectedDetailUser.status),
                // card: {
                //     id: selectedDetailUser.card ? selectedDetailUser.card.id : detailUser.card.id !==null ? detailUser.card.id : false
                // },
                card: {
                    id: selectedDetailUser.card
                        ? selectedDetailUser.card.id
                        : detailUser.card === null
                        ? false
                        : detailUser.card.id,
                },
            },
        ];

        if (buttonStatus === "DOWNLOAD_PHOTO") {
            const userApplicationForm = await HTTP.PostFormForRequest(
                REQUEST_URL.zipImageUsers,
                JSON.stringify({ ids: UserActions.getUsersIds(selectedArr) })
            );
        } else if (
            buttonStatus === "NO" ||
            buttonStatus === "MAKE" ||
            buttonStatus === "UNLOAD" ||
            buttonStatus === "MADE" ||
            buttonStatus === "ISSUED"
        ) {
            const resp = UserActions.updateCard(selectedArr, buttonStatus);
            setModalStatus("none");
            if (resp && setTransferDetailUser) {
                setButtonStatus(buttonStatus);
                setTransferDetailUser(selectedArr);
            }
        } else if (userData.role === "Администратор" || userData.role === "Председатель профкома") {
            const resp = UserActions.update(selectedArr, buttonStatus, (secondStatus = false), changeFetchStatus);
            setModalStatus("none");
            if (resp && setTransferDetailUser) {
                setButtonStatus(buttonStatus);
                setTransferDetailUser(selectedArr);
            }
        } else {
            UserActions.update(selectedArr, buttonStatus, secondStatus);
            setModalStatus("none");
        }
    };

    const ClearDateHandler = () => {
        if (detailUser) {
            setDetailUser(false);
        }
    };

    const getUserAplicationForm = async () => {
        // const userApplicationForm = await HTTP.Get(REQUEST_URL.userApplicationForm);
        let selectedArr = [
            {
                id: selectedDetailUser.id,
                status: Table.setUserStatus(selectedDetailUser.status),
                card: {
                    id: selectedDetailUser.card ? selectedDetailUser.card.id : false,
                },
            },
        ];
        const userApplicationForm = await HTTP.PostFormForRequest(
            REQUEST_URL.getApplicationFormForAdmin,
            JSON.stringify({ ids: UserActions.getUsersIds(selectedArr) })
        );
    };

    useEffect(() => {
        if (detailUser.image) {
            Functions.setUserPhoto(detailUser.image);
        }
    }, [detailUser.image]);

    useEffect(() => {
        if (modalTitle.current && modalDescription.current) {
            setTitleAndDescription();
        }
    }, [modalTitle.current, modalDescription.current, modalStatus]);

    return (
        <>
            <div className="modal-content">
                <div
                    className={
                        modalStatus !== "none"
                            ? modalStatus !== "material_support" && modalStatus !== "change_faculty" && modalStatus !== "restore_membership" && modalStatus !== "finished_msu"
                                ? "modalForms modalForms_active change-modal"
                                : "modalForms modalForms_active change-modal"
                            : "modalForms"
                    }
                >
                    <div
                        className="modal-close"
                        onClick={() => {
                            setModalStatus("none");
                            ClearDateHandler();
                        }}
                    >
                        <img src={close_window} alt="Close" />
                    </div>
                    {isLoadingDetailUser ? (
                        <div className="modal-loader">Загрузка...</div>
                    ) : (
                        <form
                            className={
                                modalStatus !== "material_support" && modalStatus !== "change_faculty" && modalStatus !== "restore_membership" && modalStatus !== "finished_msu"
                                    ? "modalForms-mainEdit change-modal"
                                    : "modalForms-mainEdit change-modal"
                            }
                            onSubmit={e => {
                                e.preventDefault();
                                modalsRequests();
                            }}
                        >
                            <div className="modalForms-header">
                                <div
                                    className={
                                        modalStatus !== "change_faculty" && modalStatus !== "material_support" && modalStatus !== "restore_membership" && modalStatus !== "finished_msu"
                                            ? "modalForms-header-image modalForms-header-image-wrapper"
                                            : "modalForms-header-image"
                                    }
                                >
                                    {modalStatus !== "change_faculty" && modalStatus !== "material_support" && modalStatus !== "restore_membership" && modalStatus !== "finished_msu" ? (
                                        <>
                                            {(userData.role === "Администратор" ||
                                                userData.role === "Председатель профкома") &&
                                            detailUser ? (
                                                <div
                                                    className={
                                                        detailUser.type_of_learning === "DAY_BD" ||
                                                        detailUser.type_of_learning === "EVE_BD"
                                                            ? "modal-squar-budget"
                                                            : "modal-square-contract"
                                                    }
                                                ></div>
                                            ) : (
                                                ""
                                            )}

                                            <PhotoInput
                                                mode="disable"
                                                user_photo={
                                                    detailUser
                                                        ? Functions.setUserPhoto(detailUser.image)
                                                        : Functions.setUserPhoto(userData.image)
                                                }
                                            />
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </div>

                                {(userData.role === "Администратор" || userData.role === "Председатель профкома") && (
                                    <div className="modalForms-header-info">
                                        <div ref={modalTitle} className="modalForms-header-title"></div>
                                        <div ref={modalDescription} className="modalForms-header-description"></div>
                                        {detailUser.card &&
                                            detailUser.card !== undefined &&
                                            detailUser.card !== null &&
                                            (userData.role === "Администратор" ||
                                                userData.role === "Председатель профкома") &&
                                            activeTab === "users_prof" && (
                                                <>
                                                    {modalStatus === "user_prof" ? (
                                                        <div className="modalForms-header-description">
                                                            Статус карты: {detailUser.card.status}
                                                        </div>
                                                    ) : null}
                                                </>
                                            )}
                                    </div>
                                )}
                            </div>

                            {modalStatus !== "material_support" && modalStatus !== "change_faculty" && modalStatus !== "restore_membership" && modalStatus !== "finished_msu" ? (
                                <>
                                    <Input
                                        mode="fake_translit"
                                        text="Фамилия"
                                        placeholderTranslit={
                                            detailUser ? detailUser.last_name_translated : userData.last_name_translated
                                        }
                                        textTranslit="Транслит поля Фамилия"
                                        placeholder={detailUser ? detailUser.last_name : userData.last_name}
                                    />
                                    <Input
                                        mode="fake_translit"
                                        text="Имя"
                                        placeholderTranslit={
                                            detailUser
                                                ? detailUser.first_name_translated
                                                : userData.first_name_translated
                                        }
                                        textTranslit="Транслит поля Имя"
                                        placeholder={detailUser ? detailUser.first_name : userData.first_name}
                                    />
                                    <Input
                                        mode="fake_translit"
                                        text="Отчество"
                                        placeholderTranslit={
                                            detailUser
                                                ? detailUser.middle_name_translated
                                                : userData.middle_name_translated
                                        }
                                        textTranslit="Транслит поля Отчество"
                                        placeholder={detailUser ? detailUser.middle_name : userData.middle_name}
                                    />

                                    {modalStatus !== "rzd_status_FILLED" && modalStatus !== "rzd_status_PASSED" ? (
                                        <>
                                            <Input
                                                mode="fake"
                                                text="День рождения"
                                                placeholder={
                                                    detailUser ? detailUser.date_of_birth : userData.date_of_birth
                                                }
                                            />
                                            <Input
                                                mode="fake"
                                                text="Пол"
                                                placeholder={Functions.setUserSex(detailUser ? detailUser : userData)}
                                            />
                                            <Input
                                                mode="fake"
                                                text="Адрес электронной почты"
                                                placeholder={detailUser ? detailUser.email : userData.email}
                                            />
                                            <Input
                                                mode="fake"
                                                text="Номер телефона"
                                                placeholder={
                                                    detailUser ? detailUser.phone_number : userData.phone_number
                                                }
                                            />
                                            <Input
                                                mode="fake"
                                                text="Регион"
                                                placeholder={detailUser ? detailUser.faculty : userData.faculty}
                                            />
                                            <Input
                                                mode="fake"
                                                text="Уровень обучения"
                                                placeholder={
                                                    detailUser
                                                        ? ConvertData.getData(
                                                              "academic_level",
                                                              detailUser.academic_level,
                                                              faculties.filter(
                                                                  item =>
                                                                      Number(item.id) === Number(detailUser.faculty_id)
                                                              )[0]?.academic_level
                                                          )
                                                        : ConvertData.getData(
                                                              "academic_level",
                                                              userData.academic_level,
                                                              faculties.filter(
                                                                  item =>
                                                                      Number(item.id) === Number(userData.faculty_id)
                                                              )[0]?.academic_level
                                                          )
                                                }
                                            />
                                            <Input
                                                mode="fake"
                                                text="Тип обучения"
                                                placeholder={
                                                    detailUser
                                                        ? ConvertData.getData(
                                                              "type_of_learning",
                                                              detailUser.type_of_learning,
                                                              faculties.filter(
                                                                  item =>
                                                                      Number(item.id) === Number(detailUser.faculty_id)
                                                              )[0]?.type_of_learning
                                                          )
                                                        : ConvertData.getData(
                                                              "type_of_learning",
                                                              userData.type_of_learning,
                                                              faculties.filter(
                                                                  item =>
                                                                      Number(item.id) === Number(userData.faculty_id)
                                                              )[0]?.type_of_learning
                                                          )
                                                }
                                            />
                                            <Input
                                                mode="fake"
                                                text="Текущий Курс"
                                                placeholder={detailUser ? detailUser.grade_level : userData.grade_level}
                                            />
                                            <Input
                                                mode="fake"
                                                text="Дата начала обучения"
                                                placeholder={detailUser ? detailUser.start_date : userData.start_date}
                                            />
                                            <Input
                                                mode="fake"
                                                text="Плановый срок окончания обучения"
                                                placeholder={detailUser ? detailUser.end_date : userData.end_date}
                                            />
                                            {/* <Input
                                                mode="fake"
                                                text="Студенческий билет"
                                                placeholder={detailUser ? detailUser.student_id : userData.student_id}
                                            /> */}
                                            <Input
                                                mode="fake"
                                                text="Номер Профбилета ОПО"
                                                placeholder={
                                                    detailUser ? detailUser.number_general : userData.number_general
                                                }
                                            />
                                            {/* <div className="modalForms-space"></div> */}
                                        </>
                                    ) : (
                                        <></>
                                    )}

                                    {modalStatus === "rzd_status_FILLED" || modalStatus === "rzd_status_PASSED" ? (
                                        <>
                                            <Input
                                                mode="fake"
                                                text="Регион"
                                                placeholder={detailUser ? detailUser.faculty : userData.faculty}
                                            />
                                            <Input
                                                mode="fake"
                                                text="Дата подачи в РЖД"
                                                placeholder={
                                                    detailUser && detailUser.rzd_datetime
                                                        ? detailUser.rzd_datetime.slice(0, 10)
                                                        : ""
                                                }
                                            />
                                            <Input
                                                mode="fake"
                                                text="Статус РЖД"
                                                placeholder={detailUser ? detailUser.rzd_status : ""}
                                            />
                                            <Input
                                                mode="fake"
                                                placeholder={detailUser ? detailUser.rzd_number : ""}
                                                text="Номер РЖД"
                                            />
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    <div
                                        style={
                                            errors === ""
                                                ? { display: "none" }
                                                : { color: "#FF4E43", fontSize: "12px", marginTop: "3px" }
                                        }
                                    >
                                        {errors}
                                    </div>
                                </>
                            ) : modalStatus === "change_faculty" ? (
                                <>
                                    <div className="modalForms-header-confirm" style={{ paddingTop: "25px" }}>
                                        Вы уверены, что хотите сменить Регион?
                                    </div>
                                </>
                            ) : modalStatus === "restore_membership" ? (
                                <>
                                <div className="modalForms-header-confirm" style={{ paddingTop: "25px" }}>
                                    Вы подаете заявку на восстановление членства в профсоюзной организации МГУ. Для завершения процесса восстановления необходимо будет подписать бумажное заявление и сдать его в профком Региона. Подтвердите действие.
                                </div>
                            </>
                            ) : modalStatus === "finished_msu" ? (
                                <>
                                <div className="modalForms-header-confirm" style={{ paddingTop: "25px" }}>
                                    Вы будете исключены из членов профсоюза МГУ. Подтвердите действие.
                                </div>
                            </>
                            ) : (
                                <TextArea setTextAreaValue={setTextAreaValue} />
                            )}
                            {modalStatus === "join_faculty" && errors === "" && (
                                <div className="modalForms-space"></div>
                            )}
                            {modalStatus === "join_faculty" && (
                                <Button type="submit" text="ОТПРАВИТЬ ЗАЯВКУ" color="green" />
                            )}
                            {modalStatus === "material_support" && (
                                <Button
                                    type="submit"
                                    text="ОТПРАВИТЬ ЗАЯВКУ"
                                    disabled={textAreaValue !== "" ? true : false}
                                    color="green"
                                />
                            )}
                            {modalStatus === "change_faculty" && (
                                <div className="modalForms-faculty-button">
                                    <Button type="submit" text="СМЕНИТЬ Регион" color="green" />
                                </div>
                            )}

                            {modalStatus === "restore_membership" && (
                                <div className="modalForms-faculty-button">
                                    <Button type="submit" text="ВОССТАНОВИТЬ ЧЛЕНСТВО" color="green" />
                                </div>
                            )}
                            
                            {modalStatus === "finished_msu" && (
                                <div className="modalForms-faculty-button">
                                    <Button type="submit" text="ДА" color="green" />
                                </div>
                            )}

                            {modalStatus === "user_all" && (
                                <>
                                    <Button
                                        type="button"
                                        text="РЕДАКТИРОВАТЬ"
                                        callback={() => setModalStatus("change_profilebyadmin")}
                                    />
                                </>
                            )}
                            {modalStatus === "new_users" && (
                                <>
                                    {Functions.getAccess(userData.access, "table", "APPL_1") && (
                                        <Button
                                            type="button"
                                            text="ВЕРИФИЦИРОВАТЬ"
                                            color="green"
                                            callback={e => updateStatusUser(e.target.innerText, "APPL")}
                                        />
                                    )}
                                    {Functions.getAccess(userData.access, "table", "EXCLUDE_1") && (
                                        <Button
                                            type="button"
                                            text="ОТКЛОНИТЬ"
                                            callback={e => updateStatusUser(e.target.innerText, "EXCLUDE")}
                                            color="red"
                                        />
                                    )}
                                    <Button
                                        type="button"
                                        text="РЕДАКТИРОВАТЬ"
                                        callback={() => setModalStatus("change_profilebyadmin")}
                                    />
                                </>
                            )}
                            {modalStatus === "user_application" && (
                                <>
                                    {Functions.getAccess(userData.access, "table", "MEMBER") && (
                                        <Button
                                            type="button"
                                            text="ПРИНЯТЬ"
                                            color="green"
                                            callback={e => updateStatusUser(e.target.innerText, "MEMBER", "MEMBER")}
                                        />
                                    )}
                                    {Functions.getAccess(userData.access, "table", "EXCLUDE_1") && (
                                        <Button
                                            type="button"
                                            text="ОТКЛОНИТЬ"
                                            callback={e => updateStatusUser(e.target.innerText, "EXCLUDE")}
                                            color="red"
                                        />
                                    )}
                                    <Button
                                        type="button"
                                        text="РЕДАКТИРОВАТЬ"
                                        callback={() => setModalStatus("change_profilebyadmin")}
                                    />
                                    {Functions.getAccess(userData.access, "table", "STATMENT") && (
                                        <Button
                                            text="ЗАЯВЛЕНИЕ"
                                            type="button"
                                            color="green"
                                            callback={() => getUserAplicationForm()}
                                        />
                                    )}
                                </>
                            )}
                            {modalStatus === "kick" && (
                                <>
                                    {Functions.getAccess(userData.access, "table", "APPL_2") && (
                                        <Button
                                            type="button"
                                            text="ПРИНЯТЬ ОБРАТНО"
                                            color="green"
                                            callback={e => updateStatusUser(e.target.innerText, "APPL", "APPL")}
                                        />
                                    )}
                                    {Functions.getAccess(userData.access, "table", "ARCH") && (
                                        <Button
                                            type="button"
                                            text="АРХИВИРОВАТЬ"
                                            callback={e => updateStatusUser(e.target.innerText, "ARCH")}
                                            color="green"
                                        />
                                    )}
                                    <Button
                                        type="button"
                                        text="РЕДАКТИРОВАТЬ"
                                        callback={() => setModalStatus("change_profilebyadmin")}
                                    />
                                </>
                            )}
                            {modalStatus === "user_prof" && (
                                <>
                                    <Button
                                        type="button"
                                        text="РЕДАКТИРОВАТЬ"
                                        callback={() => setModalStatus("change_profilebyadmin")}
                                    />
                                    {Functions.getAccess(userData.access, "table", "NO") && (
                                        <Button
                                            type="button"
                                            text="ПЕРЕВЫПУСТИТЬ КАРТУ"
                                            color="green"
                                            callback={e => updateStatusUser(e.target.innerText, "NO")}
                                        />
                                    )}
                                    {Functions.getAccess(userData.access, "table", "EXCLUDE_2") && (
                                        <Button
                                            type="button"
                                            text="ИСКЛЮЧИТЬ"
                                            color="red"
                                            callback={e => updateStatusUser(e.target.innerText, "EXCLUDE")}
                                        />
                                    )}
                                </>
                            )}
                            {modalStatus === "no_map" && userData.role === "Администратор" && (
                                <>
                                    {Functions.getAccess(userData.access, "card_admin", "MAKE_1") && (
                                        <Button
                                            type="button"
                                            text="НАЧАТЬ ФОРМИРОВАНИЕ КАРТЫ"
                                            color="blue"
                                            callback={e => {
                                                updateStatusUser(e.target.innerText, "MAKE");
                                            }}
                                        />
                                    )}
                                </>
                            )}
                            {modalStatus === "make" && userData.role === "Администратор" && (
                                <>
                                    {Functions.getAccess(userData.access, "card_admin", "UNLOAD_1") && (
                                        <Button
                                            type="button"
                                            text="ИЗГОТОВИТЬ КАРТУ"
                                            color="blue"
                                            callback={e => updateStatusUser(e.target.innerText, "UNLOAD")}
                                        />
                                    )}
                                    {Functions.getAccess(userData.access, "card_admin", "NO") && (
                                        <Button
                                            type="button"
                                            text="Вернуть 'Нет карты'"
                                            color="green"
                                            callback={e => updateStatusUser(e.target.innerText, "NO")}
                                        />
                                    )}
                                    {Functions.getAccess(userData.access, "card_admin", "DOWNLOAD_PHOTO") && (
                                        <Button
                                            type="button"
                                            text="Скачать фото"
                                            color="green"
                                            callback={e => updateStatusUser(e.target.innerText, "DOWNLOAD_PHOTO")}
                                        />
                                    )}
                                </>
                            )}
                            {modalStatus === "unload" && userData.role === "Администратор" && (
                                <>
                                    {Functions.getAccess(userData.access, "card_admin", "MADE_2") && (
                                        <Button
                                            type="button"
                                            text="ВЫДАТЬ КАРТУ"
                                            color="blue"
                                            callback={e => updateStatusUser(e.target.innerText, "MADE")}
                                        />
                                    )}
                                    {Functions.getAccess(userData.access, "card_admin", "MAKE_3") && (
                                        <Button
                                            type="button"
                                            text="Вернуть 'На изготовлении'"
                                            color="green"
                                            callback={e => updateStatusUser(e.target.innerText, "MAKE")}
                                        />
                                    )}
                                </>
                            )}
                            {modalStatus === "made" && userData.role === "Администратор" && (
                                <>
                                    {Functions.getAccess(userData.access, "card_admin", "ISSUED") && (
                                        <Button
                                            type="button"
                                            text="ЗАБРАТЬ КАРТУ"
                                            color="blue"
                                            callback={e => updateStatusUser(e.target.innerText, "ISSUED")}
                                        />
                                    )}
                                    {Functions.getAccess(userData.access, "card_admin", "UNLOAD_2") && (
                                        <Button
                                            type="button"
                                            text="Вернуть 'Выгруженные'"
                                            color="green"
                                            callback={e => updateStatusUser(e.target.innerText, "UNLOAD")}
                                        />
                                    )}
                                </>
                            )}
                            {modalStatus === "issued" && userData.role === "Администратор" && (
                                <>
                                    {Functions.getAccess(userData.access, "card_admin", "MADE_1") && (
                                        <Button
                                            type="button"
                                            text="ВЕРНУТЬ 'ИЗГОТОВЛЕНЫ'"
                                            color="green"
                                            callback={e => updateStatusUser(e.target.innerText, "MADE")}
                                        />
                                    )}
                                </>
                            )}

                            {modalStatus === "rzd_status_FILLED" && (
                                <>
                                {Functions.getAccess(userData.access, "rzd_bonus", "PASSED") && (
                                    <Button
                                        type="button"
                                        text="ПЕРЕДАТЬ В РЖД"
                                        color="green"
                                        callback={e => updateRzdStatusUser("PASSED")}
                                    />
                                    )}
                                    {userData.role === "Администратор" && (
                                        <Button
                                            type="button"
                                            text="РЕДАКТИРОВАТЬ"
                                            color="blue"
                                            callback={() => setModalStatus("change_profilebyadmin")}
                                        />
                                    )}
                                </>
                            )}
                        </form>
                    )}
                </div>
            </div>
        </>
    );
}
