import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as yup from "yup";
import InputTranslate from "../../../UI/InputTranslate/InputTranslate";
import MaskedCustomInput from "../../../UI/Input/MaskedCustomInput";
import { ImageLoader } from "../../../interface/ImageLoader";
import PhotoInput from "../../../UI/PhotoInput/PhotoInput";
import CropPhoto from "../../CropPhoto/CropPhoto";
import openNotification from "../MessagePopup";
import Button from "../../../UI/Button/Button";
import Select from "../../../UI/Select/Select";
import Radio from "../../../UI/Radio/Radio";
import Input from "../../../UI/Input/Input";
import DocumentsStudent from "../../DocumentsStudent/DocumentsStudent";
import { showRegistrationErrors, getIncludesValues } from "../../../interface/information-module";
import * as regExpList from "../../../interface/RegExpDictionary";
import CustomValidationUser from "../../../interface/Validation";
import { Functions } from "../../../interface/functions";
import { REQUEST_URL } from "../../../interface/config";
import { HTTP } from "../../../interface/HTTP";
import DownloadBoxWrapper from "../../DownloadBox/DownloadBoxWrapper";
import FileInput from "../../FileInput/FileInput";
import FileInputList from "../../FileInput/FileInputList";
import { academic_level, COURCES_LIST } from "../../../assets/academic_level";
import { academicLevelSlice } from "../../../assets/academic_level_slice";
import { type_of_learning } from "../../../assets/type_of_learning";

import close_window from "../../../images/other/close_window.png";

export default function AddEducationModal(props) {
    const { modalStatus, setModalStatus } = props;

    const [registrationErrors, setRegistrationErrors] = useState([]);
    const [disableGradeLevel, setDisableGradeLevel] = useState(true);
    const [disableFields, setDisableFields] = useState(true);
    const [croppedData, setCroppedData] = useState("");
    const [isError, setIsError] = useState(false); // стейт для ошибок загружаемых файлов
    const [error, setError] = useState([]); // ошибки загрузки
    const [isVisibleProgressBar, setisVisibleProgressBar] = useState(false);
    const [progressInfos, setProgressInfos] = useState(23);
    const [croppedFile, setCroppedFile] = useState();
    const [inputImage, setInputImage] = useState();
    const inputRef = useRef(null);
    const [filesNameList, setFilesNameList] = useState(["Диплом"]);
    const [canCrop, setCanCrop] = useState(false);
    const [fileList, setFileList] = useState([]);
    const faculties = useSelector(({ facultiesList }) => [
        { id: "", title: "Выберите Регион" },
        ...facultiesList.facultiesList,
    ])
    const cropperRef = useRef(null);
    const studentIdRef = useRef(null);
    const courceRef = useRef(null);
    const type_of_learning_ref = useRef(null);

    const formikAddUser = useFormik({
        initialValues: {
            start_date: "",
            end_date: "",
            institution_name: "",
            specialization: "",
        },
        validationSchema: Yup.object({
            start_date: Yup.string().required("Введите дату начала обучения"),
            end_date: Yup.string().required("Введите дату окончания обучения"),
            institution_name: Yup.string().required("Введите название образовательного обучения"),
            specialization: Yup.string().required("Введите название специализации"),
        }),
        onSubmit: async values => {
            console.log("TEST")
            // values.date_of_birth = values.date_of_birth.split("-").reverse().join("-");
            await setSubmitInfo(values);
            // const responce = await HTTP.Post(REQUEST_URL.useEducation, values);
            // console.log(responce.text)
        },
    });

    async function setSubmitInfo(values) {
        const formData = new FormData();
        for (let item of Object.entries(values)) {
            console.log(item[0])
            if (item[0] === "date_of_birth" || item[0] === "start_date" || item[0] === "end_date") {
                const reverse = item[1].split(".").reverse().join("-");
                formData.append(item[0], reverse);
                console.log("sdsdsd")
            } 
             else {
                formData.append(item[0], item[1]);
            }
        }
        const json = {};
        Array.from(formData.entries()).forEach(([key, value]) => {
        json[key] = value;
        })

        const responce = await HTTP.PostBody(REQUEST_URL.useEducation,JSON.stringify(json));

        if (responce.id) {
            openNotification({
                title: "Добавление юзера",
                text: "Задача успешно выполнена",
                type: "success",
            });
            setModalStatus("none");
        } else {
            openNotification({
                title: "Добавление юзера",
                text: "Что-то пошло не так",
                type: "error",
            });
            setRegistrationErrors(responce);
        }
    }

    useEffect(() => {
        if (croppedData !== "") {
            formikAddUser.values.image = croppedData;
            setCroppedFile(Functions.dataURLtoFile(croppedData, "user_photo.png"));
        }
    }, [croppedData, formikAddUser.values]);

    // useEffect(() => {
    //     if (formikAddUser.values.has_student_id) {
    //         formikAddUser.values.student_id = "";
    //         studentIdRef.current.style.pointerEvents = "none";
    //     } else {
    //         studentIdRef.current.style.pointerEvents = "";
    //     }
    // }, [formikAddUser.values.student_id, formikAddUser.values.has_student_id]);


    

    // useEffect(() => {
    //     setModalStatus("add_profile");
    // }, []);

    useEffect(() => {
        if (formikAddUser.values.faculty !== "") {
            setDisableFields(false);
        } else {
            setDisableFields(true);
        }
    }, [formikAddUser.values.faculty]);

    useEffect(() => {
        if (formikAddUser.values.academic_level !== "") {
            setDisableGradeLevel(false);
        } else {
            setDisableGradeLevel(true);
        }
    }, [formikAddUser.values.academic_level]);

    return (
        <>
            <div className={modalStatus !== "none" ? "modalForms modalForms_active change-modal" : "modalForms"}>
                <div
                    className="modal-close"
                    onClick={() => {
                        setModalStatus("none");
                    }}
                >
                    <img src={close_window} alt="Close" />
                </div>

                <form
                    className={
                        modalStatus !== "material_support" && modalStatus !== "change_faculty"
                            ? "modalForms-mainEdit change-modal"
                            : "modalForms-mainEdit"
                    }
                >
                    <div className={canCrop ? "modalForms-header modalForms-header__column" : "modalForms-header"}>
                        <div className="modalForms-header-image">
                        
                        </div>
                        {/* <div className="modalForms-header-info">
                            <div className="modalForms-header-title">Подтвеждающий документ</div>
                        </div> */}
                    </div>
                    <Input
                        text="Наименование образовательной организации"
                        required={true}
                        id="institution_name"
                        {...formikAddUser.getFieldProps("institution_name")}
                        formError={formikAddUser}
                        value={formikAddUser.values.institution_name}
                    />
                    <MaskedCustomInput
                        type="text"
                        text="Дата начала обучения / Start date"
                        required={true}
                        id="start_date"
                        {...formikAddUser.getFieldProps("start_date")}
                        formError={formikAddUser}
                        mask={"99.99.9999"}
                    />
                    <MaskedCustomInput
                        type="text"
                        text="Cрок окончания обучения / End date"
                        required={true}
                        id="end_date"
                        {...formikAddUser.getFieldProps("end_date")}
                        formError={formikAddUser}
                        mask={"99.99.9999"}
                    />
                    <Input
                        type="text"
                        text="Специальность"
                        required={true}
                        id="specialization"
                        {...formikAddUser.getFieldProps("specialization")}
                        formError={formikAddUser}
                    />
                    
                    <div>
                        
                    </div>
                    {/* <Button text="Добавить" type="button" color="blue" onClick={formikAddUser.handleSubmit} /> */}
                    <button type="button" className="button button_blue" color="blue" onClick={formikAddUser.handleSubmit}>Добавить значение</button>
                    <div className="reg__content-error">{showRegistrationErrors(registrationErrors)}</div>
                </form>
            </div>
            {/* 
        <Modals
          page="registration"
          setModalStatus={setModalStatus}
          modalStatus={modalStatus}
        /> */}
        </>
    );
}

// AddProfileModal
