import React, {  useState } from "react";

import "./DownloadBox.css";

export default function DownloadBoxWrapper({ children, filesList, setFilesList, setIsError, isError, title, titleBox, subTitleBox, arrayFormat, className }) {

  const [dragEnter, setDragEnter] = useState(false);

  const overrideEventDefaults = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDragEnter(true);
  };

  const handleDragAndDropFiles = (event) => {
    overrideEventDefaults(event);
    setDragEnter(false);
    if (!event.dataTransfer) {
      return;
    }
    handleFiles(event.dataTransfer.files);
  };

  const handleFiles = (fileList) => {
    if (fileList) {
      let files = Array.from(fileList);
      setFilesList(files);
    }
  };

  return (
    <>
      <div
        className={`${"download"} ${dragEnter ? "download_active" : ""} ${className ? className : ""}`}
        onDrop={handleDragAndDropFiles}
        onDragEnter={overrideEventDefaults}
        onDragLeave={overrideEventDefaults}
        onDragOver={overrideEventDefaults}
      >
        <div className={"download__content"}>
          <p  className={"download__button-file"}>
            {titleBox}
          </p>
          <p className={"download__text"}>{subTitleBox}</p>
          {children}
        </div>
      </div>
    </>
  );
}
