import { useState, useEffect } from "react";

import { academic_level } from "../../assets/staticticMembers";

import { HTTP } from "../../interface/HTTP";
import { REQUEST_URL } from "../../interface/config";

export default function StaticticMemberRow({ listOfRender }) {
    const delimeterNum = num => {
        num = Math.ceil(num);

        if (num.length <= 3) {
            return num;
        }
        num = String(num).trim();
        let returnValue = "";
        let counter = 0;
        for (let i = num.length - 1; i >= 0; i--) {
            returnValue += num.charAt(i);
            counter++;
            if (counter === 3) {
                returnValue += " ";
                counter = 0;
            }
        }

        return returnValue.split("").reverse().join("").trim();
    };

    const setItems = () => {
        let returnArr = [];
        const listEntries = Object.entries(listOfRender);
        listEntries.forEach((numList, index) =>
            returnArr.push(
                <div className="statictic-memeber-table-tr" key={index}>
                    {numList[1].map((num, index) => (
                        <div className="statictic-memeber-table-td" key={index}>
                            {delimeterNum(num)}
                        </div>
                    ))}
                </div>
            )
        );
        return returnArr;
    };

    return <>{setItems()}</>;
}
