import { useState, useEffect } from "react";

import CardsList from "../../components/CardsList/CardsList";
import Header from "../../components/Header/Header";
import GoBack from "../../UI/Button/GoBack";
import Title from "../../UI/Title/Title";

import { REQUEST_URL } from "../../interface/config";
import { HTTP } from "../../interface/HTTP";

//import './SocialProgramsUser.css'

export default function SocialProgramsUser() {
    const [socialListUser, setSocialListUser] = useState([]);
    const [socialListAll, setSocialListAll] = useState([]);

    const filtersList = ["Все программы", "Мои заявки"];

    const socialListRequest = async () => {
        let requesrList = await HTTP.Get(`${REQUEST_URL.getSocialProgramsList}`);
        if (requesrList) {
            setSocialListUser(requesrList);
            setSocialListAll(requesrList);
        }
    };

    useEffect(() => {
        socialListRequest();
        // setEventsList(listEvents);
        // setEventsListAdmin(listEventsAdmin);
    }, []);

    return (
        <>
            <Header />
            <div className="social-user">
                <div className="main">
                    <div className="container-content center">
                        <GoBack text={"Назад"} type="button" />
                        <Title text="Социальные программы" className="cards-list__title" />
                        {/* <p className="cards-list__document">
                            Для записи в некоторые социальные программы необходимо предоставление дополнительных
                            подтверждающих документов. <br /> Заполните свои данные на странице{" "}
                            <a href="/documents_program">
                                <span className="cards-list__document-link"> Документы</span>
                            </a>
                        </p> */}
                        <CardsList
                            type="social"
                            cardsList={socialListAll}
                            filters={filtersList}
                            socialListUser={socialListUser}
                            page="social"
                        />
                    </div>
                </div>
            </div>
        </>
    );
}
