import { useState, useEffect } from "react";

import Input from "../../UI/Input/Input";

import { HTTP } from "../../interface/HTTP";
import { REQUEST_URL } from "../../interface/config";

import { ReactComponent as CloseImg } from "../../images/other/close.svg";

import "./Availability.css";

export default function Availability({ form, emailList, setEmailList }) {
    const [inputValue, setInputValue] = useState("");
    const [fetchEmailsList, setFetchEmailsList] = useState([]);
    const [showHint, setShowHint] = useState(false);

    const addEmailItem = (e, inputValue) => {
        e.stopPropagation();
        if (e.keyCode === 13) {
            if (!emailList.includes(inputValue)) {
                setEmailList(prevState => {
                    return [...prevState, inputValue];
                });
            }
            setInputValue("");
        }
    };

    const addEmailItemHandler = inputValue => {
        if (!emailList.includes(inputValue)) {
            setEmailList(prevState => {
                return [...prevState, inputValue];
            });
        }
        setInputValue("");
    };

    const deleteEmailItem = index => {
        setEmailList(emailList.filter((_, i) => i !== index));
    };

    const getMailsList = async mail => {
        if (mail.length > 0) {
            const response = await HTTP.getUser(REQUEST_URL.getEmployeeMail(mail));
            if (response.length > 0) {
                setFetchEmailsList(response);
                if (response.length > 0){
                    setShowHint(true);
                }
                else{
                    setShowHint(false);
                }
            }
            else{
                setFetchEmailsList([]);
            }
        }
    };

    const setCards = () => {
        return emailList.map((item, index) => {
            return (
                <div className="availability__list-item" key={index}>
                    <div className="availability__list-title"> {item}</div>
                    <div
                        className="availability__list-btn"
                        onClick={e => {
                            e.stopPropagation();
                            deleteEmailItem(index);
                        }}
                    >
                        <CloseImg />
                    </div>
                </div>
            );
        });
    };

    return (
        <div className="availability">
            <Input
                text="Права доступа администратора"
                placeholder={"Введите почту для выдачи доступа"}
                id="email"
                {...form.getFieldProps("email")}
                onKeyDown={e => {
                    {
                        e.stopPropagation();
                        addEmailItem(e, e.target.value);
                    }
                }}
                onChange={e => {
                    setInputValue(e.target.value);
                    getMailsList(e.target.value);
                }}
                formError={form}
                value={inputValue}
                className="event-form__label-place"
            />
            <div className="availability__list-search">
                {showHint &&
                    inputValue.length > 0 &&
                    fetchEmailsList.map((item, index) => {
                        return (
                            <div
                                className="availability__list-search-item"
                                key={index}
                                onClick={e => {
                                    addEmailItemHandler(item.email);
                                    setShowHint(false);
                                }}
                            >
                                {item.email}
                            </div>
                        );
                    })}
            </div>
            <div className="availability__list">{emailList && emailList.length !== 0 && setCards()}</div>
        </div>
    );
}
