export const usersParams = {
    role: {
        admin: 'Администратор',
        user: 'Пользователь',
        member: 'Член АМП',
        chairman: 'Председатель профкома'
    },
    status: {
        new: 'Новый пользователь',
        apply: 'Подал заявку',
        verified: 'Верефицированный пользователь',
        member: 'Член АМП',
        archived: 'Архивированный пользователь',
        exclude: 'Членство приостановлено',
    },
    availableRoutes: {
        role:{
            admin: ['/table', '/card_admin', '/', '/statistics', '/registration', '/password_recovery', '/password_reset', '/bookkeeping', '/rzd_bonus', '/events', '/peoples_event', '/peoples_social', '/peoples_responses', '/social_programs_admin', '/statistics_event', '/appeals', '/about_event', '/about_social_program', '/create_event', '/documents_program'],
            member: ['/profile', '/documents', '/card', '/', '/registration', '/password_recovery', '/password_reset', '/events', '/social_programs_student','/sponsors', '/documents_program', '/rzd_bonus_profile'],
            chairman: ['/profile', '/', '/table', '/card_admin', '/new_users', '/bookkeeping', '/requests', '/members', '/excluded', '/registration', '/password_recovery', '/password_reset', '/statistics', '/rzd_bonus', '/events',]
        },
        status: {
            new: ['/profile', '/', '/registration', '/documents', '/password_recovery', '/password_reset'],
            apply: ['/profile', '/', '/registration', '/documents', '/password_recovery', '/password_reset'],
            verified: ['/profile', '/', '/registration', '/documents', '/password_recovery', '/password_reset'],
            member: ['/profile', '/', '/registration', '/card', '/documents', '/password_recovery', '/password_reset', '/events', '/social_programs_student', '/documents_program', '/sponsors', '/rzd_bonus_profile'],
            archived: ['/profile', '/', '/registration', '/password_recovery', '/password_reset'],
            exclude: ['/profile', '/', '/registration', '/password_recovery', '/password_reset'],
        },
    }
}