import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

import BookkeepingTable from "../../components/BookkeepingTable/BookkeepingTable";
import Header from "../../components/Header/Header";

import "react-tabs/style/react-tabs.css";
import "./Bookkeeping.css";

export default function Bookkeeping() {
    return (
        <>
            <Header />
            <div className="main">
                <div className="card-admin">
                    <Tabs>
                        <TabList>
                            <Tab>Бюджетник</Tab>
                            <Tab>Контрактник</Tab>
                        </TabList>
                        <TabPanel>
                            <BookkeepingTable typeOfLearning={"budget"} />
                        </TabPanel>
                        <TabPanel>
                            <BookkeepingTable typeOfLearning={"contract"} />
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </>
    );
}
