import success_icon from "../images/other/success_icon.png";
import error_icon from "../images/other/error_icon.png";

export class SetFieldsStatus {
  static setTouched(form, id) {
    for (let field in form.touched) {
      if (field === id) {
        return form.touched[field];
      }
    }
  }

  static setInputClassValid(status) {
    switch (status) {
      case "default":
        return "";
      case "valid":
        return "input-wrapper-success";
      case "not_valid":
        return "input-wrapper-error";
      default:
        return "";
    }
  }

  static setSelectClassValid(status) {
    switch (status) {
      case "default":
        return "";
      case "valid":
        return "select-success";
      case "not_valid":
        return "select-error";
      default:
        return "";
    }
  }

  static setImage = (status) => {
    switch (status) {
      case "default":
        return null;
      case "valid":
        return <img src={success_icon} alt="success" className="input__icon" />;
      case "not_valid":
        return <img src={error_icon} alt="error" className="input__icon" />;
      default:
        return null;
    }
  };

  static setStyle = (status) => {
 
    switch (status) {
      case "default":
        return null;
      case "valid":
        return null;
      case "not_valid":
        return "questionnaire__box-err";
      default:
        return null;
    }
  };

  static setEror(form, id) {
    for (let field in form.errors) {
      //  console.log('field', field)
      //  console.log('id', id)
      // console.log('form.errors', form.errors)
      if (field === id) {
        return form.errors[field];
      }
    }
  }

  static returnStatus(form, id, valueStudentId = false) {
 
    const error = this.setEror(form, id);
    const touched = this.setTouched(form, id);


    if (touched && error && valueStudentId === false) {
      return "not_valid";
    }
    if (id === "student_id" && valueStudentId === false && !error && touched && form.values.id) {
      return "valid";
    }
    if (touched && !error && valueStudentId === false && id !== "student_id") {
      return "valid";
    }
    if (!touched && valueStudentId === false) {
      return "default";
    }
    if (valueStudentId === true && touched) {
      return "valid";
    }
    if (valueStudentId === true && !touched) {
      return "valid";
    }
  }
}
