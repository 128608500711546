import React from "react";

import "./StatusTab.css";

export default function StatusTab({ text, type }) {
    const setColorTabStatus = () => {
        switch (text) {
            case "Заявка подана":
                return "violet";
            case "Ожидание ответа":
                return "violet";
            case "Заявка одобрена":
                return "green";
            case "Обращение закрыто":
                return "green";
            case "Заявка отклонена":
                return "red";
            case "Требуется помощь":
                return "red";
            default:
                break;
        }
    };

    const setColorTabLearning = () => {
        const type = text.toLowerCase();
        switch (type) {
            case "бюджет":
                return "blue";
            case "контрактник":
                return "yellow";
            default:
                break;
        }
    };

    return (
        <div
            className={`status-tab
      status-tab__color-${type !== "type_of_learning" ? setColorTabStatus() : setColorTabLearning()}`}
        >
            {type !== "type_of_learning" ? <span className="status-tab__icon"></span> : <></>}

            <div className="status-tab__text">{text}</div>
        </div>
    );
}
