export const vocabularyStaticticMembers = [
    // {
    //     id: 1,
    //     course: "",
    // },
    {
        id: 2,
        course: "1 курс",
    },
    {
        id: 3,
        course: "2 курс",
    },
    {
        id: 4,
        course: "3 курс",
    },

    {
        id: 5,
        course: "4 курс",
    },
    {
        id: 6,
        course: "5 курс",
    },
    {
        id: 7,
        course: "6 курс",
    },
];


export const academic_level = [
    {
        id: "UNGR",
        title: "Бакалавриат (4 года)"
    },
    {
        id: "SP", 
        title: "Специалитет (6 лет)"
    },
    {
        id: "MG", 
        title: "Магистратура (2 года)"
    },
    {
        id: "GR3", 
        title: "Аспирантура (3 года)"
    },
    {
        id: "GR4", 
        title: "Аспирантура (4 года)"
    },
    {
        id: "RS", 
        title: "Ординатура (4 года)"
    }
];
