import React, { useState } from "react";

import "./Tabs.css";

export default function Tabs({ tabsLabelList, active = undefined, callback, type, border = "default" }) {
    const [activeTabInner, setActiveTabInner] = useState(active ? active : 0);

    const setActiveInner = activeIndex => {
        setActiveTabInner(activeIndex);
        callback(activeIndex);
    };

    const setTabsLabelList = () => {
        return tabsLabelList.map((item, index) => {
            return (
                <div
                    className={`
                  DAS-tabs__tab
                  ${index === activeTabInner ? "DAS-tabs_active" : ""}
                  ${type === "white_box" ? "DAS-tabs__tab-white" : ""}
                  ${border && `DAS-tabs__tab-${border}`}
                  `}
                    onClick={() => {
                        setActiveInner(index);
                    }}
                    key={index}
                >
                    {item}
                </div>
            );
        });
    };

    return (
        <div className={"DAS-tabs"}>
            <div className={type === "white_box" ? "DAS-tabs__header DAS-tabs__header-white" : "DAS-tabs__header"}>
                {setTabsLabelList()}
            </div>
        </div>
    );
}
