import Input from "../../../UI/Input/Input";
import MaskedCustomInput from "../../../UI/Input/MaskedCustomInput";
import Answer from "../Answer/Answer";

export default function AnswerField({
    activeAnswer,
    handleChangeAnswerInput,
    handleSortAnswer,
    options,
    removeAnswer,
    item,
    name,
    mode,
}) {
    return (
        <>
            {activeAnswer === "text" && (
                <div className="create-ev_item create-ev_item-options">
                    <Input
                        name={name}
                        id={item.id}
                        //onChange={(e) => handleChangeInput(e, item.id)}
                        type="text"
                        placeholder={"Ответ"}
                        className={`create-ev__input create-ev__input-answer ${mode === "create" && "events-none"}`}
                        //  value={item.answer}
                    />
                    <div className="create-ev__div"></div>
                </div>
            )}
            {activeAnswer === "radio" && (
                <div className="create-ev_item create-ev_item-options">
                    <Answer
                        type="radio"
                        options={options}
                        removeAnswer={removeAnswer}
                        name={"answerlist"}
                        item={item}
                        handleChangeAnswerInput={handleChangeAnswerInput}
                        handleSortAnswer={handleSortAnswer}
                    />
                </div>
            )}
            {activeAnswer === "checkbox" && (
                <div className="create-ev_item create-ev_item-options">
                    <Answer
                        type="checkbox"
                        options={options}
                        removeAnswer={removeAnswer}
                        name={"answerlist"}
                        item={item}
                        handleChangeAnswerInput={handleChangeAnswerInput}
                        handleSortAnswer={handleSortAnswer}
                    />
                </div>
            )}

            {activeAnswer === "date" && (
                <div className="create-ev_item create-ev_item-options">
                    <MaskedCustomInput
                        type="text"
                        //value={item.answer}
                        placeholder={"Дата, месяц, год"}
                        answerId={item.id}
                        //  onChange={(e) => handleChangeInput(e, item.id)}
                        mask={"99.99.9999"}
                        className={`create-ev__input create-ev__input-answer ${mode === "create" && "events-none"}`}
                        name="answer"
                    />
                </div>
            )}
            {activeAnswer === "time" && (
                <div className="create-ev_item create-ev_item-options">
                    <MaskedCustomInput
                        type="text"
                        placeholder={"Время"}
                        answerId={item.id}
                        // onChange={(e) => handleChangeInput(e, item.id)}
                        mask={"99:99"}
                        //  value={item.answer}
                        className={`create-ev__input create-ev__input-answer ${mode === "create" && "events-none"}`}
                        name="answer"
                    />
                </div>
            )}
        </>
    );
}
