import React from "react";
import { useSelector } from "react-redux";

import Dropdown from "../Dropdown/Dropdown";
import { CheckboxGroup } from "../Checkbox/CheckboxGroup/CheckboxGroup";

import "./Select.css";

export default function SelectByGroup({ className, setAccessValues, accessValues }) {
    const faculties = useSelector(({ facultiesList }) => [...facultiesList.facultiesList]);

    const checkboxGroupOptions = [
        {
            option: { optionNameRu: "Тип обучения", optionNameOnBackend: "type_of_learning" },
            entities: ["Бюджетники", "Контрактники"],
        },
        {
            option: { optionNameRu: "Уровень обучения", optionNameOnBackend: "academic_level" },
            entities: ["1 курс", "2 курс", "3 курс", "4 курс", "5 курс"],
        },
        {
            option: { optionNameRu: "Пол", optionNameOnBackend: "sex" },
            entities: ["Мужской", "Женский"],
        },
        {
            option: { optionNameRu: "Регион", optionNameOnBackend: "faculty" },
            entities: [
                ...faculties.map(item => {
                    return item.title;
                }),
            ],
        },
    ];

    const handleChangeCheckedBoxGroup = ({ target: { checked, value } }, nameGroup) => {
        if (checked) {
            if (accessValues[nameGroup].includes(value)) {
                setAccessValues(prevState => ({
                    ...prevState,
                }));
            } else {
                setAccessValues(prevState => ({
                    ...prevState,
                    [nameGroup]: [...prevState[nameGroup], value],
                }));
            }
        } else {
            setAccessValues(prevState => ({
                ...prevState,
                [nameGroup]: [...prevState[nameGroup].filter(x => x !== value)],
            }));
        }
    };

    const setAllChecks = (checked, nameGroup) => {
        if (!checked) {
            setAccessValues(prevState => ({
                ...prevState,
                [nameGroup]: checkboxGroupOptions.filter((item) => item.option.optionNameOnBackend === nameGroup)[0].entities,
            }));
        } else {
            setAccessValues(prevState => ({
                ...prevState,
                [nameGroup]: [],
            }));
        }
    };

    return (
        <div className={`DAS-select__wrapper ${className && className}`}>
            <Dropdown
                value={"Выберите из списка"}
                type={"dropdown"}
                label={"Права доступа пользователей"}
                className={"group"}
            >
                <ul className={`${"DAS-select__container"} DAS-select__container-${"group"}`}>
                    <div className="DAS-select__group">
                        {checkboxGroupOptions.map((item, index) => (
                            <div className="DAS-select__group-item" key={index}>
                                <p className="DAS-select__group-title">{item.option.optionNameRu}</p>
                                <CheckboxGroup
                                    id={item.option.optionNameOnBackend.toString() + "_all"}
                                    label={"Все"}
                                    checkedBoxByGroup={accessValues}
                                    key={10000}
                                    type="all"
                                    allValues={item.entities}
                                    nameGroup={item.option.optionNameOnBackend}
                                    onChange={setAllChecks}
                                />
                                {item.entities.map((label, index) => (
                                    <CheckboxGroup
                                        id={index.toString() + label}
                                        label={label}
                                        checkedBoxByGroup={accessValues}
                                        key={index}
                                        nameGroup={item.option.optionNameOnBackend}
                                        onChange={handleChangeCheckedBoxGroup}
                                    />
                                ))}
                            </div>
                        ))}
                    </div>
                </ul>
            </Dropdown>
        </div>
    );
}
