import { useRef, useEffect } from "react";

import { Functions } from "../../interface/functions";
import no_user_img from "../../images/other/add_photo_back.png";
import select_photo from "../../images/other/select_photo.png";

import "./PhotoInput.css";

export default function PhotoInput({
    id,
    mode = "default",
    user_photo,
    multiple = false,
    accept,
    form,
    name,
    setModalStatus,
    takeFile = () => {},
}) {
    const photo = useRef();
    const inputRef = useRef();

    const validateImage = e => {
        form.values = Functions.filesValidation(inputRef.current.files, accept, multiple);
        form.validateForm();
        if (form.values !== undefined) {
            takeFile(e);
        }
    };

    useEffect(() => {
        if (user_photo !== "") {
            photo.current.src = user_photo;
        }
    }, [user_photo]);

    useEffect(() => {
        if (mode === "default") {
            photo.current.src = select_photo;
        }
    }, []);

    return (
        <>
            {(mode === "default" || mode === "create") && (
                <div className="photo-input-wrapper">
                    {mode !== "create" ? (
                        <label htmlFor={id}>
                            <img ref={photo} alt="Фотография пользователя" className={user_photo !== "" ? "loaded-img" : null} />
                            <div className="photo-input-btn">ЗАГРУЗИТЬ ФОТОГРАФИЮ</div>
                        </label>
                    ) : (
                        <label htmlFor={id}>
                            <div className={user_photo !== "" ? null : "photo-input-image"}>
                                <img ref={photo} alt="Фотография пользователя" className={user_photo !== "" ? "loaded-img-create" : null} />
                            </div>

                            <div className="download__button-file">
                                <p>
                                    Перетащите фото или&nbsp;<span className="download__button-span">загрузите</span>
                                </p>
                            </div>
                        </label>
                    )}

                    <input
                        onChange={e => validateImage(e)}
                        type="file"
                        datatype={"png, jpeg, jpg, jfif"}
                        accept={accept}
                        multiple={multiple}
                        name={name}
                        id={id}
                        ref={inputRef}
                    />
                </div>
            )}
            {mode === "change_profile" && (
                <div className="photo-input-wrapper photo-input-wrapper_change">
                    <label htmlFor={id}>
                        <img src={user_photo ? user_photo : no_user_img} ref={photo} alt="Фотография пользователя" />
                    </label>
                    <input
                        onChange={e => validateImage(e)}
                        datatype={"png, jpeg, jpg, jfif"}
                        type="file"
                        accept={accept}
                        multiple={multiple}
                        name={name}
                        ref={inputRef}
                        id={id}
                    />
                </div>
            )}
            {mode === "disable" && (
                <div className="photo-input-wrapper photo-input-wrapper_change">
                    <label style={{ pointerEvents: "none" }}>
                        <img ref={photo} src={user_photo} alt="Фотография пользователя" />
                    </label>
                </div>
            )}
        </>
    );
}
