const initialState = {
  countApplicationUsers: 0,
  countNewUsers: 0,
};

const countUsers = (state = initialState, action) => {
  switch (action.type) {
    case "getCountApplicationUsers":
      return {
        ...state,
        countApplicationUsers: action.payload,
      };
    case "getCountNewUsers":
      return {
        ...state,
        countNewUsers: action.payload,
      };
    default:
      return state;
  }
};

export default countUsers;
