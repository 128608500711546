import { useState, useEffect, useContext, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import MUIDataTable, { TableBody } from "mui-datatables";

import ControlButtons from "../../components/ControlButtons/ControlButtons";
import ButtonsToolBar from "../../components/ButtonsToolBar/ButtonsToolBar";
import Loader from "../../UI/Loader/Loader";
import Modals from "../../components/Modals/Modals/Modals";
import ShowRequest from "../../components/Modals/ShowRequest/ShowRequest";

import Header from "../../components/Header/Header";
import Tab from "../../UI/Tab/Tab";

import { Table } from "../../interface/Table";
import { HTTP } from "../../interface/HTTP";
import { useDisableBodyScroll } from "../../hooks/useDisableBodyScroll";

import { REQUEST_URL, routerPath, userContext } from "../../interface/config";
import { tablesConfig, tableLangConfig } from "../../interface/tablesConfig";

import detailInfo from "../../images/other/eye_detail.svg";

import "./RzdBonus.css";

export default function RzdBonus() {
  const { studentData, setStudentData } = useContext(userContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDetailUser, setIsLoadingDetailUser] = useState(false);
  const lastRequest = useRef();

  const [activeTab, setActiveTab] = useState("FILLED");
  const [modalStatus, setModalStatus] = useState("none");
  const [selectedUserId, setSelectedUserId] = useState("");
  const [detailUser, setDetailUser] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(" ");
  const [secondStatus, setSecondStatus] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [usersListArray, setUsersListArray] = useState([]);
  const [filteredUsersList, setFilteredUsersList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [selectedIDArray, setSelectedIDArray] = useState([]);
  const [isVisibleShowRequest, setIsVisibleShowRequest] = useState("none");
  const [buttonText, setButtonText] = useState("");
  const [selectedCountId, setSelectedCountId] = useState([]);
  const [transferUsers, setTransferUsers] = useState([]);
  const [transferDetailUser, setTransferDetailUser] = useState();
  const [selectedDetailUser, setSelectedDetailUser] = useState({});
  const [rzdNumber, setRzdNumber] = useState("");

  const dispatch = useDispatch();

  const userRole = useSelector(({ profile }) => profile.role);

  useDisableBodyScroll(modalStatus);

  const BodyComponent = (props) => {
    if (isLoading) {
      return <Loader />;
    } else {
      return <TableBody {...props} />;
    }
  };

  const tableButtonConfig = {
    FILLED: [
      {
        text: "ПЕРЕДАТЬ В РЖД",
        color: "green",
        access_type: "PASSED",
        callback: (e) => showModalRequest(e.target.innerText),
      },
    ],
  };

  const updateRzdNumber = (date) => {
    setRzdNumber(date);
  };

  async function getDetailUserFetch(selectedUserId) {
    setIsLoadingDetailUser(true);
    const userInfo = await HTTP.Get(REQUEST_URL.getUser(selectedUserId));

    if (userInfo) {
      setIsLoadingDetailUser(false);
      setDetailUser(userInfo);
    }
  }

  const displayDetailInfo = (dataIndex) => {
    filteredUsersList.filter((item) => {
      if (item[7] === dataIndex) {
        setSelectedDetailUser(item);
        setSelectedUserId(item[7]);
        return item;
      }
    });

    getDetailUserFetch(dataIndex);

    setModalStatus(() => {
      switch (activeTab) {
        case "PASSED":
          return "rzd_status_PASSED";
        case "FILLED":
          return "rzd_status_FILLED";
        default:
          return "";
      }
    });
  };

  async function usersListRequest() {
    let requestString = activeTab;
    const requestOptions = tablesConfig.rzd_bonus.requests;
    setIsLoading(true);
    let requestList = await HTTP.Get(`${REQUEST_URL.getUsersList}?rzd-card=${activeTab}`);
    if (requestList && activeTab === lastRequest.current) {
      setUsersList(requestList);
    }
    setIsLoading(false);
  }

  useEffect(() => {
    if (usersList.length > 0) {
      if (!tablesConfig.noRemoveFromTable.includes(buttonStatus)) {
        if (transferDetailUser.length > 0) {
          Table.remove(usersList, setUsersList, transferDetailUser, buttonStatus);
          setSelected([]);
          setTransferDetailUser([]);
        }
      }
    }
  }, [transferDetailUser]);

  useEffect(() => {
    if (usersList.length > 0) {
      if (buttonStatus === "SAVE") {
        Table.edit(usersList, setUsersList, studentData.id, studentData);
      }
      setStudentData(false);
    }
  }, [buttonStatus, studentData]);

  useEffect(() => {
    if (usersList.length > 0) {
      if (!tablesConfig.noRemoveFromTable.includes(buttonStatus)) {
        if (transferUsers.length > 0) {
          Table.remove(usersList, setUsersList, transferUsers, buttonStatus);
          setSelected([]);
          setTransferUsers([]);
        }
      }
    }
  }, [transferUsers.length, usersList]);

  useEffect(() => {
    Table.setOnlyId(selected, setSelectedIDArray);
  }, [selected]);

  useEffect(() => {
    setUsersListArray(Table.setTableArray(usersList, routerPath.rzd_bonus));
  }, [usersList]);

  useEffect(() => {
    setSelected([]);
    setTransferDetailUser([]);
    setTransferUsers([]);
    lastRequest.current = activeTab;
    usersListRequest();
  }, [activeTab]);

  const columns = [
    {
      name: "",
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div
              className="detail-img"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                displayDetailInfo(tableMeta.rowData[7]);
              }}
            >
              <img src={detailInfo} width="30" height="30" align="right" alt="" />
            </div>
          );
        },
        filter: false,
      },
    },
    { name: "Регион", options: { filter: true } },
    { name: "Фамилия Имя", options: { filter: false } },
    // { name: "Имя", options: { filter: false } },
    { name: "Отчество", options: { filter: false } },
    { name: "Номер РЖД бонус", options: { filter: false } },
    { name: "Дата подачи в РЖД", options: { filter: false } },
    { name: "Статус РЖД", options: { filter: false } },
    { name: "ID студента", options: { filter: false } },
  ];

  const options = {
    ...tablesConfig.options,
    textLabels: tableLangConfig,
    onRowSelectionChange: (rowsSelected) => {
      Table.setSelectedRows(rowsSelected, selected, setSelected, usersList, buttonStatus);
    },
    onTableChange: (event, tableState) => {
      if (usersListArray.length !== usersList.length) {
        tableState.selectedRows.lookup = {};
        tableState.selectedRows.data = [];
      }
    },
    MUIDataTableBodyCell: { responsiveStacked: { whiteSpace: "pre" } },

    setRowProps: (row, dataIndex) => ({
      onDoubleClick: (event) => {
        event.preventDefault();
      },
    }),
  };

  const showModalRequest = (text) => {
    if (selected.length !== 0) {
      const statusList = tablesConfig.rzd_bonus.buttonsStatus;
      for (let status in statusList) {
        if (Array.isArray(statusList[status])) {
          statusList[status].forEach((item, index) => {
            if (text.toUpperCase() === item) {
              setButtonStatus(status);
              setButtonText(item.toLowerCase());
            }
          });
        } else {
          if (text.toUpperCase() === statusList[status]) {
            setButtonStatus(status);
            setButtonText(statusList[status].toLowerCase());
          }
        }
      }
      setSelectedCountId(selected.length);
      setIsVisibleShowRequest(true);
    }
  };

  const changeButtonStatus = (status) => {
    setButtonStatus(status);
  };

  const setTabs = () => {
    const tabArr = tablesConfig.rzd_bonus.tabs;
    let returnArr = [];
    for (let tab in tabArr) {
      returnArr.push(
        <Tab
          activeTab={activeTab}
          tabName={tabArr[tab]}
          currentTab={tab}
          setTab={setActiveTab}
          userRole={userRole}
          key={tabArr[tab]}
        />
      );
    }
    return returnArr;
  };

  return (
    <>
      <Header selected={selected} activeTab={activeTab} />
      <div className="main">
        <div className="admin-table">
          <div className="tables-switch">{setTabs()}</div>

          <MUIDataTable
            title=""
            data={usersListArray}
            columns={columns}
            options={options}
            components={{
              TableBody: BodyComponent,
            }}
          />

          {userRole === "Администратор" && (
            <div className="admin-table-container">
              <div className="main__control__buttons">
                <div className="main-content-accept">
                  <ButtonsToolBar config={tableButtonConfig} activeTab={activeTab} type={"rzd_bonus"}/>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modals
        page="rzdTable"
        activeTab={activeTab}
        detailUser={detailUser}
        setDetailUser={setDetailUser}
        isLoadingDetailUser={isLoadingDetailUser}
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
        selected={selected}
        activeTable={activeTab}
        selectedDetailUser={selectedDetailUser}
        setTransferDetailUser={setTransferDetailUser}
        setButtonStatus={setButtonStatus}
        changeButtonStatus={changeButtonStatus}
        selectedUserId={selectedUserId}
        updateRzdNumber={updateRzdNumber}
      />
      <ShowRequest
        setSelected={setSelected}
        modalStatus={isVisibleShowRequest}
        setModalStatus={setIsVisibleShowRequest}
        buttonText={buttonText}
        selectedCountId={selectedCountId}
        selected={selected}
        buttonStatus={buttonStatus}
        secondStatus={secondStatus}
        setTransferUsers={setTransferUsers}
        rzdNumber={rzdNumber}
      ></ShowRequest>
    </>
  );
}
