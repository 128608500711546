import useOnclickOutside from "react-cool-onclickoutside";
import { NavLink } from "react-router-dom";
import { useState } from "react";

import { ReactComponent as SubMenuImg } from "../../images/other/sub-menu.svg";

export default function HeaderItem({ path, title, mode = "default", subMenuList, className }) {
  const [isActiveSubmenu, setIsActiveSubmenu] = useState(false);
  const closeSubmenu = () => setIsActiveSubmenu(false);
  const ref = useOnclickOutside(() => closeSubmenu());

  const toggleSubmenu = (e) => {
    if (isActiveSubmenu) {
      closeSubmenu();
    } else {
      e.stopPropagation();
      setIsActiveSubmenu(true);
    }
  };

  return (
    <>
      {mode === "default" && (
        <NavLink
          to={path}
          className={({ isActive }) =>
            `${isActive ? "header__menu-item header__menu-item-active" : "header__menu-item"}
            ${className ? className : ""}
            `
          }
        >
          {title}
        </NavLink>
      )}

      {mode === "select" && (
        <div className="header__menu-item header__menu-box" onClick={(e) => toggleSubmenu(e)}>
          <span
          // className={`header__menu-link
          // ${pathname === `/${item.path}`
          // 		&& header__menu-link-active}`}
          >
            {title}
          </span>
          <SubMenuImg className={`${isActiveSubmenu ? "header__menu__icon_active" : "header__menu__icon"}`} />
          <ul ref={ref} className={`header__menu-list ${isActiveSubmenu ? "header__menu-list_active" : null}`}>
            {subMenuList &&
              subMenuList.map((item, index) => (
                <li className="header__menu-submenu" key={index}>
                  <NavLink
                    to={item.path}
                    className={({ isActive }) =>
                      isActive
                        ? "header__menu-submenu__item header__menu-submenu__item-active"
                        : "header__menu-submenu__item"
                    }
                  >
                    {item.title}
                  </NavLink>
                </li>
              ))}
          </ul>
        </div>
      )}
    </>
  );
}
