import Button from "../../UI/Button/Button";


export default function ControlButtonsItem ({title}){

    return(
        <div className="main-statistic-buttons-item">
            <div className="main-statistic-buttons-item__title">{title}</div>
            <div className="main-statistic-buttons-item__buttons">
                <Button type="button" text='CSV' color='blue'/>
                <Button type="button" text='WORD' color='blue'/>
            </div>
        </div>
    )
}