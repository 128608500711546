

export default function CardStatistics({value, title}){

    return(
        <div className="stats-cards__item" >
            <div className="stats-cards__number">{value ? value : 0}</div>
            <div className="stats-cards__description">{title ? title : 0}</div>
        </div>
    )
}