import { validateParams } from "./config";
import { SERVER_URL } from "./HTTP";

import no_user_img from "../images/other/no-image.png";
import MessageImg from "../images/create/message.svg";
import CheckboxCircleImg from "../images/create/checkbox_circle.svg";
import CheckboxSquareImg from "../images/create/checkbox_square.svg";
import TimeImg from "../images/create/time.svg";
import DownloadImg from "../images/create/download.svg";
import DateImg from "../images/create/date.svg";

export class Functions {
    static sortData({ tableData, sortKey, reverse, data }) {
        if (!sortKey) return tableData;

        const sortedData = data.sort((a, b) => {
            return a[sortKey] > b[sortKey] ? 1 : -1;
        });
        if (reverse) {
            return sortedData.reverse();
        }

        return sortedData;
    }

    static formatDate(date) {
        if (date) {
            const newFormat = date.split("-").reverse().join(".");
            return newFormat;
        }
    }

    static delimeterPrice(price) {
        if (price.length <= 3) {
            return price;
        }
        price = String(price).trim();
        let returnValue = "";
        let counter = 0;
        for (let i = price.length - 1; i >= 0; i--) {
            returnValue += price.charAt(i);
            counter++;
            if (counter === 3) {
                returnValue += " ";
                counter = 0;
            }
        }

        return returnValue.split("").reverse().join("").trim();
    }

    static hasClass(target, nameOfClass) {
        const rec = this.getClassRecursion(target, nameOfClass);
        if (nameOfClass == rec) {
            return true;
        } else {
            return false;
        }
    }

    static getCroppedText(text, numberWords) {
        const slicedText = text.slice(0, numberWords);
        return (slicedText.endsWith(" ") ? slicedText.slice(0, slicedText.length - 1) : slicedText) + "...";
    }

    static getFullMonth(monthNumber){
        switch(monthNumber){
            case 1: return "января";
            case 2: return "февраля";
            case 3: return "марта";
            case 4: return "апреля";
            case 5: return "мая";
            case 6: return "июня";
            case 7: return "июля";
            case 8: return "августа";
            case 9: return "сентября";
            case 10: return "октября";
            case 11: return "ноября";
            case 12: return "декабря";
            default: return "Января"
        }
    }

    static setWeekAgo() {
        const weekAgo = new Date(Date.now() - 86400000 * 7);
        weekAgo.setHours(0);
        weekAgo.setMinutes(0);
        weekAgo.setSeconds(0);
        weekAgo.setMilliseconds(0);
        return weekAgo;
    }

    static fileSizeConverter(size) {
        const convertSize = size / 1024; //kilobites

        return convertSize < 1024 ? `${convertSize.toFixed(0)} КБ` : `${Number(convertSize / 1024).toFixed(1)} МВ`;
    }

    static getClassRecursion(target, nameOfClass) {
        if (target.parentNode === null) {
            return false;
        }
        if (target.classList.contains(nameOfClass)) {
            return nameOfClass;
        } else {
            return this.getClassRecursion(target.parentNode, nameOfClass);
        }
    }

    static validateFakeForm(userData) {
        const returnArray = [];

        for (let item of Object.keys(validateParams)) {
            if (userData[item] === "") {
                returnArray.push(validateParams[item]);
            }
            if (item === "image") {
                if (userData[item] === null || userData[item] === `${SERVER_URL}/media/user/images/no-image.png`) {
                    returnArray.push(validateParams[item]);
                }

                return returnArray;
            }
        }
    }

    static sortArray = (a, b, type, property) => {
        if (a[property] > b[property]) {
            return type ? 1 : -1;
        }
        if (a[property] < b[property]) {
            return type ? -1 : 1;
        }
        return 0;
    };

    static getAccess(accessList, type, key) {
        let access = false;

        for (let item of accessList[type]) {
            if (item.includes(key)) {
                access = true;
            }
        }

        return access;
    }

    static getMonthWithPrefix(value) {
        return value < 10 ? `0${value}` : value;
    }

    static getCountYear(type) {
        switch (type) {
            case "MG":
                return 2;
            case "GR3":
                return 3;
            case "UNGR":
            case "GR4":
            case "RS":
                return 4;
            case "SP":
                return 6;
            default:
                console.error("Error");
        }
    }

    static filterArr = (arrAll, arrUser) => {
        const arrFilter = arrAll.filter(item => {
            return arrUser.includes(item.id);
        });
        return arrFilter;
    };

    static getErrorsList(list) {
        const listItems = Object.values(list);
        return listItems.map(item => {
            return item[0];
        });
    }

    static filesValidation(files, fileTypes, multiply, setFilesList, mode) {
        const fileTypesList = fileTypes.split(", ");

        if (multiply) {
            const returnArray = [];
            for (let item of files) {
                if (fileTypesList.includes(item.type)) {
                    returnArray.push(item);
                }
            }
            if (mode === "file_list") {
                setFilesList(returnArray);
            }

            return returnArray;
        } else {
            const returnArray = [];
            if (fileTypesList.includes(files[0].type)) {
                returnArray.push(files[0]);
                if (mode === "file_list") {
                    setFilesList(returnArray);
                }
                return returnArray;
            } else {
                return undefined;
            }
        }
    }

    static setUserPhoto(data) {
        if (data === undefined || data === null || data.includes("no-image")) {
            return no_user_img;
        } else {
            return data;
        }
    }

    static setUserSex = user => {
        switch (user.sex) {
            case "MEN":
                return "Мужской";
            case "WOMEN":
                return "Женский";
            default:
                return "";
        }
    };

    static setSvgItem = text => {
        switch (text) {
            case "Длинный текст":
                return MessageImg;
            case "Один из списка":
                return CheckboxCircleImg;
            case "Несколько из списка":
                return CheckboxSquareImg;
            case "Дата":
                return DateImg;
            case "Время":
                return TimeImg;
            case "Загрузка файлов":
                return DownloadImg;
            default:
                return "";
        }
    };

    static getUserEventStatus = (status) => {
		switch(status.toLowerCase()){
			case "registered": return "Заявка подана";
			case "member": return "Заявка одобрена";
			case "rejected": return "Заявка отклонена";
			default: return "";
		}
	}

    static getEventStatus = (status) => {
		switch(status.toLowerCase()){
			case "event_wait": return "Регистрация";
			case "event_going": return "Заявка отклонена";
			case "event_registration": return "Участвую";
			case "event_archive": return "Посетил";
			case "event_archive2": return "Событие завершилось";
			default: return "";
		}
	}
}
