import React, { useState } from "react";

import Dropdown from "../Dropdown/Dropdown";
import Checkbox from "../Checkbox/Checkbox";

import { Functions } from "../../interface/functions";

import "./Select.css";

export default function SelectFilter({
    items,
    id,
    name,
    label = "",
    error = true,
    form,
    except = false,
    selectedItemKey = undefined,
    type = "select",
    className = "",
    style = {},
    callback,
    text,
    svgItem = false,
    mode,
}) {
    const [currentValue, setCurrentValue] = useState(
        selectedItemKey !== undefined ? items[selectedItemKey] : "Выберите значение"
    );
    const [currentValueKey, setCurrentValueKey] = useState(selectedItemKey);
    const [itemSvg, setItemSvg] = useState("");

    const itemsList = Object.entries(items);

    const selectItem = (keyValue, value, name) => {
        setCurrentValue(value);
        setCurrentValueKey(keyValue);
        if (form) {
            form.values[name] = keyValue;
            form.validateForm();
        }
        if (callback && mode !== "create") {
            callback(keyValue);
        }
        if (callback && mode === "create") {
            callback(keyValue, id, name, value);
        }
    };

    const checkSelected = itemKey => {
        if (form !== undefined) {
            // console.log('form', form)
            // return form.values[name].includes(itemKey);
        }
    };

    const setSelectItem = (itemKey, value) => {
        if (type === "multi" && form) {
            return (
                <li className={"DAS-select-item"}>
                    <Checkbox
                        label={value}
                        name={name}
                        key={itemKey}
                        checked={checkSelected(itemKey)}
                        callback={() => {
                            let tempArray = form.values[name];
                            if (tempArray.includes(itemKey)) {
                                tempArray = tempArray.filter(item => {
                                    return item !== itemKey;
                                });
                            } else {
                                tempArray.push(itemKey);
                            }
                            form.values[name] = tempArray;
                            form.validateForm();
                        }}
                    />
                </li>
            );
        }

        return (
            <li
                className={`DAS-select-item DAS-select-item__${className}`}
                onClick={e => selectItem(itemKey, value, name)}
                key={`${id}-${itemKey}`}
            >
                {svgItem && <img src={Functions.setSvgItem(value)} alt={name} style={{ width: 24, height: 24 }} />}
                <input
                    type="radio"
                    checked={itemKey === currentValueKey}
                    id={`${id}-${itemKey}`}
                    name={name}
                    onChange={() => setCurrentValue(value)}
                />
                <label htmlFor={`${id}-${itemKey}`}>{value}</label>
            </li>
        );
    };

    const renderSelectItems = arrayList => {
        return arrayList.map(item => {
            if (except && item[0] === currentValueKey) {
                return;
            }
            return setSelectItem(item[0], item[1]);
        });
    };

    return (
        <div className={`DAS-select__wrapper DAS-select__wrapper-${className}`}>
            <span className="DAS-select__sort">{text}</span>
            <Dropdown
                value={currentValue}
                type={type}
                label={label}
                className={className}
                style={style}
                icon={false}
                svgItem={svgItem}
                name={name}
            >
                <ul className="DAS-select__container">{renderSelectItems(itemsList)}</ul>
            </Dropdown>
        </div>
    );
}
