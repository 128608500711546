
import { Functions } from "../../interface/functions";

import DownloadFile from "../DownloadFile/DownloadFile";

export default function FileInputList({  filesList, deleteFile, setIsError, titleFile, progressInfos, isVisibleProgressBar }) {
  let arrayError = [];


  const renderItems = (item) => {
    const sizeFile = Number((item.size / 1024 / 1024).toFixed(2));
    const type = item.type.replace("application/", ".");
    let errorText = "";
    if (sizeFile > 60) {
      errorText = "Вес превышает допустимое значение 60 мб";
    }
    arrayError.push(errorText ? true : false);
    setIsError(arrayError.some((i) => i === true) ? true : false);

    return (
      <DownloadFile
        name={item.name}
        size={Functions.fileSizeConverter(item.size)}
        deleteFile={deleteFile}
        errorText={errorText}
        type={type}
        progress={100}
        titleFile={titleFile}
        progressInfos={progressInfos}
        isVisibleProgressBar={isVisibleProgressBar}
      />
    );
  };

  return (
    <div className={`${"download__container"} `}>
      <div className={`${"download__box"} scroll scroll_light`}>
        <ul className={`${"download__list"} `}>
          {filesList.map((item) => (
            <li className={"download__item"} key={item.size}>
              {renderItems(item)}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
