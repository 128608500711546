import MUIDataTable, { TableBody } from "mui-datatables";
import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

import ControlButtons from "../../components/ControlButtons/ControlButtons";
import ButtonsToolBar from "../../components/ButtonsToolBar/ButtonsToolBar";
import ShowRequest from "../../components/Modals/ShowRequest/ShowRequest";
import { Overlay } from "../../components/Modals/Overlay/Overlay";
import LoyoutModal from "../../components/Modals/LoyoutModal/LoyoutModal";
import Modals from "../../components/Modals/Modals/Modals";
import CardProfile from "../../components/CardProfile/CardProfile";
import Header from "../../components/Header/Header";
import Loader from "../../UI/Loader/Loader";

import { useDisableBodyScroll } from "../../hooks/useDisableBodyScroll";
import { usersParams } from "../../interface/usersParams";
import { Table } from "../../interface/Table";
import { HTTP } from "../../interface/HTTP";

import { tablesConfig, tableLangConfig } from "../../interface/tablesConfig";
import { REQUEST_URL, routerPath } from "../../interface/config";

import detailInfo from "../../images/other/eye_detail.svg";

import "./CardAdmin.css";

export default function CardAdmin() {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDetailUser, setIsLoadingDetailUser] = useState(false);

    const [usersList, setUsersList] = useState([]);
    const [usersListArray, setUsersListArray] = useState([]);

    const [selected, setSelected] = useState([]);
    const [transferUsers, setTransferUsers] = useState([]);
    const [activeTab, setActiveTab] = useState("no_map");
    const [selectedDetailUser, setSelectedDetailUser] = useState({});
    const [modalStatus, setModalStatus] = useState("none");
    const [detailUser, setDetailUser] = useState(false);
    const [isVisibleShowRequest, setIsVisibleShowRequest] = useState("none");
    const [buttonText, setButtonText] = useState("");
    const [selectedCountId, setSelectedCountId] = useState([]); //длина массива выделенных пользователей
    const [buttonStatus, setButtonStatus] = useState(" ");
    const [transferDetailUser, setTransferDetailUser] = useState();

    const user_role = useSelector(({ profile }) => profile.role);

    const lastRequest = useRef();

    useDisableBodyScroll(modalStatus);

    const BodyComponent = {
        TableBody: function (props) {
            if (isLoading) return <Loader />;
            return <TableBody {...props} />;
        },
    };

    const tableButtonConfig = {
        no_map: [
            {
                text: "НАЧАТЬ ФОРМИРОВАНИЕ КАРТЫ",
                color: "blue",
                access_type: "MAKE_1",
                callback: e => showModalRequest(e.target.innerText),
            },
        ],
        make: [
            {
                text: "ИЗГОТОВИТЬ КАРТУ",
                color: "blue",
                access_type: "UNLOAD_1",
                callback: e => showModalRequest(e.target.innerText),
            },
            {
                text: "Вернуть 'Нет карты'",
                color: "green",
                access_type: "NO",
                callback: e => showModalRequest(e.target.innerText),
            },
            {
                text: "Скачать фото",
                color: "green",
                access_type: "DOWNLOAD_PHOTO",
                callback: e => showModalRequest(e.target.innerText),
            },
        ],
        unload: [
            {
                text: "ВЫДАТЬ КАРТУ",
                color: "blue",
                access_type: "MADE_2",
                callback: e => showModalRequest(e.target.innerText),
            },
            {
                text: "Вернуть 'На изготовлении'",
                color: "green",
                access_type: "MAKE_3",
                callback: e => showModalRequest(e.target.innerText),
            },
        ],
        made: [
            {
                text: "ЗАБРАТЬ КАРТУ",
                color: "blue",
                access_type: "ISSUED",
                callback: e => showModalRequest(e.target.innerText),
            },
            {
                text: "Вернуть 'Выгруженные'",
                color: "green",
                access_type: "UNLOAD_2",
                callback: e => showModalRequest(e.target.innerText),
            },
        ],
        issued: [
            {
                text: "ВЕРНУТЬ 'ИЗГОТОВЛЕНЫ'",
                color: "green",
                access_type: "MADE_1",
                callback: e => showModalRequest(e.target.innerText),
            },
        ],
    };

    const getDetailUserFetch = async selectedUserId => {
        setIsLoadingDetailUser(true);
        const userInfo = await HTTP.Get(REQUEST_URL.getUser(selectedUserId));
        if (userInfo) {
            setDetailUser(userInfo);
            setIsLoadingDetailUser(false);
        }
    };

    const getActiveTab = tab => {
        switch (tab) {
            case "no_map":
                return "no_map";
            case "make":
                return "make";
            case "unload":
                return "unload";
            case "made":
                return "made";
            case "issued":
                return "issued";
            default:
                return "no_map";
        }
    };

    const displayDetailInfo = dataIndex => {
        usersList.forEach(item => {
            if (item.id === dataIndex) {
                setSelectedDetailUser(item);
                return item;
            }
        });
        getDetailUserFetch(dataIndex);
        setModalStatus(getActiveTab(activeTab));
    };

    async function usersListRequest() {
        let requestString = activeTab;
        const requestOptions = tablesConfig.card_admin.requests;
        setIsLoading(true);
        let requestList = await HTTP.Get(`${REQUEST_URL.getUsersList}${requestOptions[requestString]}`);
        if (requestList && activeTab === lastRequest.current) {
            setUsersList(requestList);
        }
        setIsLoading(false);
    }

    const columns = [
        {
            name: "",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div
                            className="detail-img"
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                displayDetailInfo(tableMeta.rowData[9]);
                            }}
                        >
                            <img src={detailInfo} width="30" height="30" align="right" alt="" />
                        </div>
                    );
                },
                filter: false,
            },
        },
        { name: "Регион", options: { filter: true } },
        { name: "Department", options: { filter: false } },
        { name: "Фамилия Имя", options: { filter: false } },
        { name: "Surname Name", options: { filter: false } },
        // { name: "Имя", options: { filter: false } },
        // { name: "Name", options: { filter: false } },
        { name: "Дата рождения", options: { filter: false } },
        "Статус",
        { name: "№ Профбилета", options: { filter: false } },
        { name: "Дата заявки", options: { filter: false } },
        { name: "ID студента", options: { filter: false } },
    ];

    const options = {
        ...tablesConfig.options,
        textLabels: tableLangConfig,
        onRowSelectionChange: (rowsSelected, rowsSelectedAll, b) => {
            Table.setSelectedRows(rowsSelected, selected, setSelected, usersList, buttonStatus);
        },
        onTableChange: (event, tableState) => {
            if (usersListArray.length !== usersList.length) {
                tableState.selectedRows.lookup = {};
                tableState.selectedRows.data = [];
            }
        },
        onCellClick: (colData, cellMeta) => {
            cellMeta.event.isDefaultPrevented = e => {
                e.preventDefault();
            };
        },
        setRowProps: (row, dataIndex) => ({
            onDoubleClick: event => {
                event.preventDefault();
            },
        }),
    };

    const showModalRequest = text => {
        if (selected.length !== 0) {
            const statusList = tablesConfig.card_admin.buttonsStatus;
            for (let status in statusList) {
                if (Array.isArray(statusList[status])) {
                    statusList[status].forEach(item => {
                        if (text.toUpperCase() === item) {
                            setButtonStatus(status);
                            setButtonText(item.toLowerCase());
                        }
                    });
                } else {
                    if (text.toUpperCase() === statusList[status]) {
                        setButtonStatus(status);
                        setButtonText(statusList[status].toLowerCase());
                    }
                }
            }
            setSelectedCountId(selected.length);
            setIsVisibleShowRequest(true);
        }
    };

    useEffect(() => {
        setUsersListArray(Table.setTableArray(usersList, routerPath.card_admin));
    }, [usersList]);

    useEffect(() => {
        if (usersList.length > 0) {
            if (buttonStatus !== "DOWNLOAD_PHOTO") {
                if (transferDetailUser && transferDetailUser.length > 0) {
                    Table.remove(usersList, setUsersList, transferDetailUser, buttonStatus);
                    setSelected([]);
                    setTransferDetailUser([]);
                }
            }
        }
    }, [transferDetailUser]);

    useEffect(() => {
        if (usersList.length > 0) {
            if (transferUsers.length > 0) {
                Table.remove(usersList, setUsersList, transferUsers, buttonStatus);
                setSelected([]);
                setTransferUsers([]);
            }
        }
    }, [transferUsers]);

    useEffect(() => {
        setSelected([]);
        setTransferUsers([]);
        lastRequest.current = activeTab;
        usersListRequest();
    }, [activeTab]);

    return (
        <>
            <Header />
            <div className="main">
                <div className="card-admin">
                    <div className="tables-switch">
                        {Table.setTabs(activeTab, setActiveTab, tablesConfig.card_admin.tabs)}
                    </div>
                    <MUIDataTable
                        title=""
                        data={usersListArray}
                        columns={columns}
                        options={options}
                        components={BodyComponent}
                    />
                    <div className="card-admin-container">
                        {user_role === usersParams.role.admin ? (
                            <div className="main__control__buttons">
                                <div className="main-content-accept">
                                    <ButtonsToolBar
                                        config={tableButtonConfig}
                                        activeTab={activeTab}
                                        type={"card_admin"}
                                    />
                                </div>
                                <ControlButtons type="2_buttons" usersListId={selected} />
                            </div>
                        ) : (
                            <ControlButtons type="2_buttons" usersListId={selected} />
                        )}
                    </div>
                </div>
            </div>
            {/* <Modals
                page="cardAdmin"
                detailUser={detailUser}
                setDetailUser={setDetailUser}
                modalStatus={modalStatus}
                setModalStatus={setModalStatus}
                selected={selected}
                isLoadingDetailUser={isLoadingDetailUser}
                selectedDetailUser={selectedDetailUser}
                setTransferDetailUser={setTransferDetailUser}
                setButtonStatus={setButtonStatus}
            /> */}
            {modalStatus !== "none" && (
                <Overlay setModalStatus={setModalStatus} modalStatus={modalStatus}>
                    <LoyoutModal
                        modalStatus={modalStatus}
                        setModalStatus={setModalStatus}
                        sizeModal="loyout-modal__size-large"
                    >
                        <CardProfile
                            detailUser={detailUser}
                            pageType="card"
                            activeTableTab={activeTab}
                            isLoadingDetailUser={isLoadingDetailUser}
                            selected={selected}
                            modalStatus={modalStatus}
                            setModalStatus={setModalStatus}
                            activeState={0}
                            selectedDetailUser={detailUser}
                            setTransferDetailUser={setTransferDetailUser}
                            setButtonStatus={setButtonStatus}
                            // changeButtonStatus={changeButtonStatus}
                            // changeFetchStatus={changeFetchStatus}
                        />
                    </LoyoutModal>
                </Overlay>
            )}
            <ShowRequest
                setSelected={setSelected}
                modalStatus={isVisibleShowRequest}
                setModalStatus={setIsVisibleShowRequest}
                buttonText={buttonText}
                selectedCountId={selectedCountId}
                selected={selected}
                buttonStatus={buttonStatus}
                setTransferUsers={setTransferUsers}
            ></ShowRequest>
        </>
    );
}
