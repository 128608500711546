export class FileDownload{
    constructor(){
        this.filename = "file.docx";
    }

    saveFileName(res, headerName, partOfHeaderString){
        const fileName = res.headers.get(headerName).split(';').find(n => n.includes(partOfHeaderString)).replace(partOfHeaderString, '').trim();
        this.filename = fileName ?? "file.docx";
    }

    saveFile(data){
        const url = window.URL.createObjectURL(data);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", decodeURI(this.filename));
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    
}