import { useSelector } from "react-redux";
import { useState } from "react";

import Title from "../../UI/Title/Title";
import GoBack from "../../UI/Button/GoBack";
import Button from "../../UI/Button/Button";

import Header from "../../components/Header/Header";
import DocumentsAdmin from "../../components/DocumentsAdmin/DocumentsAdmin";
import DocumentsStudent from "../../components/DocumentsStudent/DocumentsStudent";

import "./DocumentsProgram.css";

export default function DocumentsProgram() {
  const userData = useSelector(({ profile }) => profile);
  
  const [filesNameList, setFilesNameList] = useState(["СНИЛС", "ОМС"]);

  return (
    <>
      <Header />
      <div className="main">
        <div className="container-content center">
          <div className="documets-program">
            <GoBack text={"Назад"} type="button" />
            <Title text="Документы" className="documets-program__title" />
            {userData.role === "Администратор" ? (
              <DocumentsAdmin 
              filesNameList={filesNameList} 
              setFilesNameList={setFilesNameList} />
            ) : (
              <DocumentsStudent 
              filesNameList={filesNameList} />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
