//import { useSelector } from "react-redux";
// import { useLocation, useNavigate } from "react-router-dom";

import { usersParams } from "./usersParams";
import { routesList } from "./config";

export class Routing{
    
    static routing(userData, page, navigate){
        // if (localStorage.getItem('token') === null){
        //     return navigate('/');
        // }
        if (localStorage.getItem('token') !== null){
            if (routesList.indexOf(page, 0) !== -1) {
                return this.checkPass(page, userData) ? true : navigate("/");
            }
            else{
                return true
            }
        }
    }

    static checkPass(page, userData){
        if (userData.role !== usersParams.role.admin) {
            if (userData.role !== usersParams.role.chairman) {
                switch (userData.status) {
                    // new apply verified member archived exclude
                    case usersParams.status.new:
                        return this.checkAvailableRoute(page, 'new')
                    case usersParams.status.apply:
                        return this.checkAvailableRoute(page, 'apply')
                    case usersParams.status.verified:
                        return this.checkAvailableRoute(page, 'verified')
                    case usersParams.status.member:
                        return this.checkAvailableRoute(page, 'member')
                    case usersParams.status.archived:
                        return this.checkAvailableRoute(page, 'archived')
                    case usersParams.status.exclude:
                        return this.checkAvailableRoute(page, 'exclude')
                    default:
                        console.error('router не смог определить статус пользователя, default {exclude}')
                        return this.checkAvailableRoute(page, 'exclude')
                }
            }
            else{
                return this.checkAvailableRoute(page, 'chairman', true);

            }
        }
        else{
            return this.checkAvailableRoute(page, 'admin', false, true);
            // return true
        }
    }

    static checkAvailableRoute(page, status, chairman=false, admin=false){
        
        if (!chairman && !admin ) {
            return usersParams.availableRoutes.status[status].includes(page);
        }
        if(admin){
            return usersParams.availableRoutes.role[status].includes(page);
        }
        else{
            return usersParams.availableRoutes.role[status].includes(page);
        }
    }
}


