import MguImage from "../../images/event/mgu.png";
import "./NotContent.css";

export default function NotContent({ text, type }) {
  const setText = () => {
    switch (type) {
      case "social":
        return "программ";
      case "events":
        return "мереприятий";
      case "sponsors":
        return "спонсоров";
      case "people":
        return "заявок";
      case "documents":
        return "документы";
      default:
        return "";
    }
  };

  return (
    <div className="not-content">
      <div className="not-content__content">
        <p className="not-content__text">
          {text} {setText()}
        </p>
        <img src={MguImage} alt="МГУ" />
      </div>
    </div>
  );
}
