import { useSelector } from "react-redux";

import { SERVER_URL } from "../../interface/HTTP";

import "./ProfkomInfo.css";

export default function ProfkomInfo({ user_type }) {
    const facultyData = useSelector(state => state.facultyContacts);

    const setStatusText = () => {
        switch (user_type) {
            case "Новый пользователь":
                return (
                    <>
                        <div className="profkom-info-status-red">Статус: ожидает верификации</div>
                        <div className="profkom-info-top-description">
                            Для вступления в профсоюз вам необходимо распечатать и подписать заявление (вкладка
                            Документы). Если у вас нет возможности напечатать заявление - обратитесь в профком
                            Региона (см. контакты вашего профкома ниже). Заполненное заявление необходимо также сдать
                            в профком Региона. Внимательно проверьте введенные данные! После формирования заявления
                            их будет нельзя изменить.
                        </div>
                    </>
                );
            case "Подал заявку":
                return (
                    <>
                        <div className="profkom-info-status-purple">Статус: ожидает оригинала заявления</div>
                        <div className="profkom-info-top-description">
                            Ранее вы или ваш профком уже сформировали заявление на вступление в профсоюз.
                        </div>
                        <div className="profkom-info-top-description">
                            Для вступления в профсоюз необходимо предоставить в профком Региона подписанное вами
                            заявление. Для повторного формирования заявления перейдите на вкладку Документы.
                        </div>
                        Eсли в указанных данных присутствует ошибка – используйте кнопку «Редактировать», внесите правки
                        в персональные данные и сформируйте заявление на вкладке Документы повторно.
                    </>
                );
            case "Член профсоюза":
                return (
                    <>
                        <div className="profkom-info-status-green">Статус: член профсоюза</div>
                        <div className="profkom-info-top-description">
                            Вам присвоен номер профсоюзного билета и вы можете участвовать во всех предлагаемых
                            программах профсоюза. Электронный профсоюзный билет доступен на вкладке “Карта Zachёt”. Вы
                            можете изменить фотографию, номер телефона, курс с сохранением номера профсоюзного билета,
                            нажав на кнопку “Изменить данные”.
                        </div>
                        <div className="profkom-info-top-description">
                            В случае изменения ФИО или Региона обратитесь в профком вашего Региона для обновления
                            данных.
                        </div>
                    </>
                );
            case "Членство приостановлено":
                return (
                    <>
                        <div className="profkom-info-status-red">Статус: членство приостановлено</div>
                        <div className="profkom-info-top-description">
                            Вы исключены из профсоюзной организации или закончили обучение по выбранной программе. Если
                            вы до сих пор являетесь обучающимся МГУ и хотите продолжить получать блага профсоюза -
                            обратитесь в профком Региона для восстановления вашего членства
                        </div>
                    </>
                );
            default:
                return (
                    <div className="profkom-info-top-description">
                        После подтверждения профиля приходите в профком своего Региона, чтобы подписать бумажное
                        заявление. Это нужно для получения карты Зачёт
                    </div>
                );
        }
    };

    return (
        <div className="profkom-info">
            {/* <div className="profkom-info-top">
                <div className="profkom-info-top-title">Профком МГУ</div>
                <div className="profkom-info-top-description">
                    <div>{setStatusText()}</div>
                </div>
            </div> */}
            <div className="profkom-info-bottom">
                <div className="profkom-info-bottom-leftBlock">
                    <div className="profkom-info-bottom-leftBlock-item">
                        <div className="leftBlock-item-title">Место приема</div>
                        <div className="leftBlock-item-description">{facultyData.place_of_reception}</div>
                    </div>
                    <div className="profkom-info-bottom-leftBlock-item">
                        <div className="leftBlock-item-title">Телефон, email</div>
                        <div className="leftBlock-item-description">
                            <a href={`tel:${facultyData.faculty_phone_number}`}>{facultyData.faculty_phone_number}</a>
                            <br />
                            <a href={`mailto:${facultyData.faculty_email}`}>{facultyData.faculty_email}</a>
                        </div>
                    </div>
                    <div className="profkom-info-bottom-leftBlock-item">
                        <div className="leftBlock-item-title">Соцсети</div>
                        <div
                            className="leftBlock-item-description"
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            {facultyData.socials &&
                                facultyData.socials.split(",").map((item, index) => {
                                    return (
                                        <a href={item} rel="noreferrer" target="_blank" key={index}>
                                            {item}
                                        </a>
                                    );
                                })}
                        </div>
                    </div>
                </div>
                {/* <div className="profkom-info-bottom-rightBlock">
                    <div className="profkom-info-bottom-leftBlock-item">
                        <div className="leftBlock-item-title">Время работы</div>
                        <div className="leftBlock-item-description">{facultyData.time_of_reception}</div>
                    </div>
                    <div className="profkom-info-bottom-leftBlock-item">
                        <div className="leftBlock-item-title">QR-Code оплаты</div>
                        <div className="leftBlock-item-description">QR-Code для членских взносов</div>
                        <div className="leftBlock-item-description">
                            <img src={SERVER_URL + facultyData.qr_code} alt="qr-code" />
                        </div>
                    </div>
                </div> */}
                <div className="profkom-info-bottom-rightBlock"></div>
            </div>
        </div>
    );
}
