import CardSponsor from "../CardSponsor/CardSponsor";

import NotContent from "../NotContent/NotContent";

import "./SponsorsList.css";

export default function SponsorsList({ cardsList, title }) {
  const setItems = () => {
    return cardsList.map((item, index) => {
      return <CardSponsor item={item} key={index} />;
    });
  };

  return (
    <section className="sponsors-list">
      <h1 className="sponsors-list__title">{title}</h1>
      {cardsList && cardsList.length > 0 ? (
        <div className="sponsors-list__items">{setItems()}</div>
      ) : (
        <NotContent text={"Здесь еще пока нет"} type ={"sponsors"}/>
      )}
    </section>
  );
}
