import { useFormik } from "formik";
import * as yup from "yup";
import { useState } from "react";

import Button from "../../UI/Button/Button";
import Input from "../../UI/Input/Input";

import NotContent from "../NotContent/NotContent";
import LoyoutModal from "../Modals/LoyoutModal/LoyoutModal";
import { Overlay } from "../Modals/Overlay/Overlay";
import DocumentsList from "../DocumentsList/DocumentsList";

import { HTTP } from "../../interface/HTTP";
import { REQUEST_URL } from "../../interface/config";

import "./DocumentsAdmin.css";

export default function DocumentsAdmin({ filesNameList, setFilesNameList }) {

  const [modalStatus, setModalStatus] = useState(false);

  async function sendFormData(name) {
    const nameOfFile = Object.values(name);
    setFilesNameList((prevState) => [...prevState, ...nameOfFile]);
    setModalStatus("none")
  }

  const formikAddFile = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: yup.object().shape({
      name: yup.string().required("Обязательно для заполнения"),
    }),
    onSubmit: (values, { resetForm }) => {
      sendFormData(values);
      resetForm();
    },
  });

  const deleteItem = (item) => {
    const newFilesNameList = filesNameList.filter((i) => {
      return i !== item;
    });

    setFilesNameList(newFilesNameList);
  };

  return (
    <>
      <Button
        type="button"
        text={"добавить документ для пользователя"}
        color="green"
        className="documets-admin__btn"
        callback={() => setModalStatus("loading")}
      />
      {filesNameList && filesNameList.length > 0 ? (
        <DocumentsList documents={filesNameList} mode={"admin_doc"} deleteItem={deleteItem} />
      ) : (
        <NotContent text={"Вы еще не добавили"} type="documents" />
      )}

      {modalStatus && (
        <Overlay setModalStatus={setModalStatus} modalStatus={modalStatus}>
          <LoyoutModal modalStatus={modalStatus} setModalStatus={setModalStatus} title="Загрузка документа">
            <>
              <p className="documets-admin__text">
                Указывайте юридическое название документа, во избежании недопонимания и для ускорения процесса проверки
                документов. Пример: СНИЛС, ОМС, Свидетельство о рождении итд.
              </p>

              <form onSubmit={formikAddFile.handleSubmit} className="documets-admin__form" id="check_foto">
                <Input
                  mode="file"
                  id="name"
                  {...formikAddFile.getFieldProps("name")}
                  formError={formikAddFile}
                  value={formikAddFile.values.name}
                  className="documets-admin__input"
                />
                <Button text="СОХРАНИТЬ" size="middle" color="green" type={"submit"} />
              </form>
            </>
          </LoyoutModal>
        </Overlay>
      )}
    </>
  );
}
