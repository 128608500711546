import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import openNotification from "../../components/Modals/MessagePopup"

import { useDispathProfile } from "../../hooks/useDispathProfile";

import Input from "../../UI/Input/Input.jsx";
import Button from "../../UI/Button/Button.jsx";
import Header from "../../components/Header/Header.jsx";

import { HTTP } from "../../interface/HTTP";
import { REQUEST_URL } from "../../interface/config";

import "./RzdBonusProfile.css";

export default function RzdBonusProfile() {
  const userData = useSelector(({ profile }) => profile);
  const [inputMode, setInputMode] = useState("fake");
  const [dispatchObject, setDispatchObject] = useState({});
  const [updateFetch, setUpdateFetch] = useState(false);

  const [dispathProfile, setDispatchProfile] = useDispathProfile(dispatchObject, updateFetch);

  const rzd_number_REG_EXP = /^\d+$/;

  const formikChangeRzdNumber = useFormik({
    initialValues: {
      rzd_number: userData.rzd_number ? userData.rzd_number : "",
    },
    validationSchema: Yup.object({
      rzd_number: Yup.string()
        // .required("Некорректный номер")
        .min(13, "Номер РЖД должен содержать не менее 13 цифр")
        .max(13, "Номер РЖД должен содержать не более 13 цифр")
        .matches(rzd_number_REG_EXP, "Номер РЖД должен содержать только цифры"),
    }),
    validateOnChange: true,
    onSubmit: async (values) => {
      if(formikChangeRzdNumber.dirty && Object.keys(formikChangeRzdNumber.errors).length === 0) {
        const updateStatus = await fetchUpdateProfile(values);
        if (updateStatus.id) {
          openNotification({
            title: "Редактирование РЖД",
            text: "Задача успешно выполнена",
            type: "success",
          });
          setInputMode("fake");
          setUpdateFetch(true);
          setDispatchObject(updateStatus);
          setDispatchProfile(updateStatus);
        }
      }
    },
  });

  async function fetchUpdateProfile(values) {
    let formData = new FormData();
    for (let item of Object.entries(values)) {
      formData.append(`${item[0]}`, item[1]);
    }
    return await HTTP.UpdateUser("PATCH", false, REQUEST_URL.updateCurrentUser, formData);
  }

  return (
    <>
      <Header />
      <div className="rzd">
        <div className="main container-content">
          <div
            className={
              userData.status === "Членство приостановлено" ? "main__account main__account-kick" : "main__account"
            }
          >
            <div className="main__account-user"></div>
            <form className="main__account-fakeForm" onSubmit={formikChangeRzdNumber.handleSubmit}>
              {inputMode === "editInput" ? (
                <Input
                  id="rzd_number"
                  {...formikChangeRzdNumber.getFieldProps("rzd_number")}
                  type="text"
                  text="Номер РЖД"
                  defaultValue={userData.rzd_number}
                  formError={formikChangeRzdNumber}
                />
              ) : (
                <Input mode={inputMode} text="Номер РЖД" placeholder={userData.rzd_number} />
              )}
              {(userData.status === "Новый пользователь" ||
                userData.status === "Подал заявку" ||
                userData.status === "Член профсоюза" ||
                userData.status === "Членство приостановлено") && (
                <div className="main__account-buttons">
                  <Button
                    type="button"
                    text={"Редактировать"}
                    callback={() => {
                      setInputMode("editInput");
                    }}
                  />
                  {inputMode === "editInput" && (
                  <Button
                    type="submit"
                    color={"green"}
                    text="СОХРАНИТЬ"
                    // callback={() => {
                    //   Object.keys(formikChangeRzdNumber.errors).length === 0
                    //     ? setInputMode("fake")
                    //     : setInputMode("editInput");
                    // }}
                  />
                  )}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
