import MUIDataTable, { TableBody } from "mui-datatables";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import ButtonsToolBar from "../../components/ButtonsToolBar/ButtonsToolBar";
import LoyoutModal from "../../components/Modals/LoyoutModal/LoyoutModal";
import CardProfile from "../../components/CardProfile/CardProfile";
import { Overlay } from "../../components/Modals/Overlay/Overlay";
import TextArea from "../../components/Textarea/Textarea";
import Header from "../../components/Header/Header";
import Button from "../../UI/Button/Button";
import GoBack from "../../UI/Button/GoBack";
import Loader from "../../UI/Loader/Loader";

import { tablesConfig, tableLangConfig } from "../../interface/tablesConfig";
import { useDisableBodyScroll } from "../../interface/useDisableBodyScroll";
import { REQUEST_URL, routerPath } from "../../interface/config";
import { Functions } from "../../interface/functions";
import { Table } from "../../interface/Table";
import { HTTP } from "../../interface/HTTP";

import { peoplesResponses } from "../../assets/appData/peoplesResponses";
import EyeImage from "../../images/event/eye.svg";

import "./PeoplesResponses.css";
import { id } from "date-fns/locale";

export default function PeoplesResponses() {
    const [isLoadingDetailUser, setIsLoadingDetailUser] = useState(false);
    const [modalStatusReject, setModalStatusReject] = useState(false);
    const [questionsListArray, setQuestionsListArray] = useState([]);
    const [answersListArray, setAnswersListArray] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [textAreaValue, setTextAreaValue] = useState("");
    const [modalStatus, setModalStatus] = useState(false);
    const [buttonStatus, setButtonStatus] = useState(" ");
    const [detailUser, setDetailUser] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [eventTitle, setEventTitle] = useState("");
    const [selected, setSelected] = useState([]);

    const page = useParams();

    const displayDetailInfo = dataIndex => {
        answersListArray.forEach((item) => {
          if (item[0] === dataIndex) {
            setSelectedUser(item);
            return item;
          }
        });

        getDetailUserFetch(dataIndex);
        setModalStatus("open");
    };

    const columns = [
        {
            name: "",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div
                            className="detail-img"
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                displayDetailInfo(tableMeta.rowData[0]);
                            }}
                        >
                            <img src={EyeImage} width="30" height="30" align="right" alt="" />
                        </div>
                    );
                },
                filter: false,
            },
        },
        { name: "ФИО" },
        { name: "Отметка времени" },
    ];

    const getUsersList = async () => {
        const response = await HTTP.Post(REQUEST_URL.getUsersAnswers, {
            id: page.id,
        });
        if (response.hasOwnProperty("questions")) {
            prepareData(response);
        }
    };

    const BodyComponent = props => {
        if (isLoading) {
            return <Loader />;
        } else {
            return <TableBody {...props} />;
        }
    };

    const openRejectModal = () => {
        if (selected && selected?.length > 0){
            setModalStatusReject(true);
        }
    }

    const tableButtonConfig = {
        activetab: [
            {
                text: "ОТКЛОНИТЬ",
                color: "transparent",
                access_type: "REJECT",
                callback: () => openRejectModal(),
            },
            {
                text: "ОДОБРИТЬ",
                color: "green",
                access_type: "APPROVE",
                // callback: (e) => showModalRequest(e.target.innerText),
            },
        ],
    };
    
    async function getDetailUserFetch(selectedUserId) {
        setIsLoadingDetailUser(true);
        const userInfo = await HTTP.Get(REQUEST_URL.getUser(selectedUserId));
        if (userInfo) {
            setIsLoadingDetailUser(false);
            setDetailUser(userInfo);
        }
    }

    const rejectHandler = async () => {
        // const resp = await HTTP.Post()
    }

    const getDateTime = (datetime) => {
        if (datetime){
            const newDate = new Date(datetime);
            return `${Functions.getMonthWithPrefix(newDate.getDate())}.${Functions.getMonthWithPrefix(newDate.getMonth() + 1)}.${newDate.getFullYear()} ${Functions.getMonthWithPrefix(newDate.getHours())}:${Functions.getMonthWithPrefix(newDate.getMinutes())}`;
        }
        return "";
    }

    const prepareData = dataObject => {
        if (dataObject.questions) {
            const parseData = JSON.parse(dataObject.questions);
            const parseQuestions = parseData.map(item => item.question);
            setQuestionsListArray(parseQuestions);
        }
        if (dataObject.answers) {
            const parserAnswers = dataObject.answers.map(item => {
                let parsedAnswer = JSON.parse(item.answer);
                parsedAnswer = Object.values(parsedAnswer).map(item => {
                    if (Array.isArray(item)){
                        return item.join(",");
                    }

                    return item;
                })
                return [item.id, item.fio, getDateTime(item.time_create), ...parsedAnswer];
            });
            setAnswersListArray(parserAnswers);
        }
        if (dataObject.title){
            setEventTitle(dataObject.title);
        }
    };

    const createColumns = questionsList => {
        const columnsCopy = [...columns];

        questionsList.forEach(item => {
            columnsCopy.push({ name: item });
        });

        return columnsCopy;
    };

    const options = {
        textLabels: {
            body: {
                noMatch: "Таблица пуста",
            },
        },
        caseSensitive: false,
        download: "disabled",
        selectableRows: "multiple",
        // customTableBodyFooterRender: (options) => {
        //     return (
        //       <tfoot style={{ display: "none"}}>

        //       </tfoot>
        //     );
        //   },
        selectableRowsOnClick: true,
        responsive: "standard",
        onRowSelectionChange: (rowsSelected, allRows, rowsSelectedTwo) => {
            Table.setSelectedRows(rowsSelected, selected, setSelected, answersListArray, buttonStatus);
            // if (rowsSelected.length !== 0) {
            //     let returnArr = [...selected];
            //     rowsSelected.forEach(target => {
            //         const id = answersListArray[target.dataIndex][0];

            //         if (selected.length > 0) {
            //             if (
            //                 selected.every(element => {
            //                     return element.id !== id;
            //                 })
            //             ) {
            //                 this.pushRow(returnArr, id, buttonStatus);
            //             } else {
            //                 returnArr = selected.filter(element => {
            //                     return element.id !== id;
            //                 });
            //             }
            //         } else {
            //             this.pushRow(returnArr, id, buttonStatus);
            //         }
            //     });
            //     setSelected([...returnArr]);
            // } else {
            //     setSelected([]);
            // }

        },
        // onTableChange: (event, tableState) => {
        //   if (usersListArray.length !== usersList.length) {
        //     tableState.selectedRows.lookup = {};
        //     tableState.selectedRows.data = [];
        //   }
        // },
    };

    useEffect(() => {
        getUsersList();
    }, []);

    useEffect(() => {
        if (modalStatus === "none"){
            setSelectedUser(null);
        }
    }, [modalStatus]);

    return (
        <>
            <Header />
            <div className="people-responses">
                <div className="main">
                    <div className="container-content center">
                        <GoBack text={"Назад"} type="button" />
                        <h1 className="people-responses__title">{eventTitle}</h1>
                        <div className="people-responses__table">
                            <MUIDataTable
                                title=""
                                data={answersListArray}
                                columns={createColumns(questionsListArray)}
                                options={options}
                                components={{
                                    TableBody: BodyComponent,
                                }}
                            />
                        </div>
                        <div className="people-responses__buttons">
                            <ButtonsToolBar
                                config={tableButtonConfig}
                                activeTab={"activetab"}
                                type={"people_responces"}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {modalStatusReject && (
                <Overlay setModalStatus={setModalStatusReject} modalStatus={modalStatusReject}>
                    <LoyoutModal
                        modalStatus={modalStatus}
                        setModalStatus={setModalStatusReject}
                        title="Напишите причину отказа"
                        classNameTitle="people-responses__modal"
                    >
                        <>
                            <TextArea setTextAreaValue={setTextAreaValue} placeholder="Введите текст..." />
                            <div className="button-modal">
                                <Button
                                    text={"ОТПРАВИТЬ"}
                                    type="button"
                                    color="blue"
                                    className="people-responses__btn"
                                    // callback={(e) => showModalRequest(e.target.innerText)}
                                />
                                <Button
                                    text={"ОТМЕНА"}
                                    type="button"
                                    color="blue"
                                    className="people-responses__btn"
                                    // callback={(e) => showModalRequest(e.target.innerText)}
                                />
                            </div>
                        </>
                    </LoyoutModal>
                </Overlay>
            )}
            {modalStatus && (
                <Overlay setModalStatus={setModalStatus} modalStatus={modalStatus}>
                    <LoyoutModal
                        modalStatus={modalStatus}
                        setModalStatus={setModalStatus}
                        sizeModal="loyout-modal__size-large"
                    >
                        <CardProfile
                            detailUser={detailUser}
                            pageType="table"
                            activeTableTab={"all"}
                            isLoadingDetailUser={isLoadingDetailUser}
                            modalStatus={modalStatus}
                            setModalStatus={setModalStatus}
                            selectedDetailUser={{}}
                        />
                    </LoyoutModal>
                </Overlay>
            )}
        </>
    );
}
