import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import Modals from "../../components/Modals/Modals/Modals";
import InputTranslate from "../../UI/InputTranslate/InputTranslate";
import MaskedCustomInput from "../../UI/Input/MaskedCustomInput";
import CropPhoto from "../../components/CropPhoto/CropPhoto";
import PhotoInput from "../../UI/PhotoInput/PhotoInput";
import Select from "../../UI/Select/Select";
import Button from "../../UI/Button/Button";
import Input from "../../UI/Input/Input";
import UserAdditionalInfo from "../../components/UserAdditionalInfo/UserAdditionalInfo";
import ChangeInfo from "./ChangeInfo.jsx";
import ChangeSections from "./ChangeSections.jsx";

import { useDispathProfile } from "../../hooks/useDispathProfile";
import * as regExpList from "../../interface/RegExpDictionary";
import CustomValidationUser from "../../interface/Validation";
import { ImageLoader } from "../../interface/ImageLoader";
import { Functions } from "../../interface/functions";
import { REQUEST_URL } from "../../interface/config";
import { HTTP } from "../../interface/HTTP";

import { academic_level, COURCES_LIST } from "../../assets/academic_level";
import { academicLevelSlice } from "../../assets/academic_level_slice";
import { type_of_learning } from "../../assets/type_of_learning";

import no_user_img from "../../images/other/no-photo.png";
import { ReactComponent as Birthday } from "../../images/profile/birthday.svg";
import { ReactComponent as Location } from "../../images/profile/location.svg";
import { ReactComponent as Mail } from "../../images/profile/mail.svg";
import { ReactComponent as Phone } from "../../images/profile/phone.svg";
import { ReactComponent as Vk_logo } from "../../images/other/vk_icon.svg";
import { ReactComponent as Tg_logo } from "../../images/other/tg_icon.svg";


import "./ChangeProfile.css";

export default function ChangeProfile({ setHideProfile }) {


    let fakeData = {
        id: null,
        // это сейчас faculty_id ?
        telegram: "",
        vk: "dwwedewddedew",
        education: [
            {
                startDate: "05.2021",
                endDate: "03.2023",
                organisationName: "Наименование организации",
                position: "Специальность",
            },

            {
                startDate: "05.2021",
                endDate: "03.2023",
                organisationName: "«НАЦИОНАЛЬНЫЙ ИССЛЕДОВАТЕЛЬСКИЙ УНИВЕРСИТЕТ «МЭИ» в г. Смоленске, Электропривод и автоматика промышленных установок и технологических комплексов.",
                position: "крутышка",
            },
        ],

        workExperience: [
            {
                startDate: "05.2021",
                endDate: "03.2023",
                organisationName: "Наименование организации",
                position: "Должность",
            },

            {
                startDate: "05.2021",
                endDate: "03.2023",
                organisationName: "ООО Роскрут",
                position: "Глава отдела",
            },
        ],

        msu: [
            {
                startDate: "05.2021",
                endDate: "03.2023",
                region: "Регион",
                organisationType: "",
                document: undefined,
                organisationName: "Наименование организации",
                position: "Должность",
                logo: undefined,
            },

        ],

        internships: [
            {
                startDate: "05.2021",
                endDate: "03.2023",
                workType: "Форма деятельности",
                organisationType: "Форма деятельности",
                document: undefined,
                organisationName: "Наименование организации",
                position: "Должность",
                isHead: false,
                logo: undefined,
            },

            {
                startDate: "05.2021",
                endDate: "03.2023",
                workType: "Стажировка",
                organisationType: "Полная занятость",
                document: undefined,
                organisationName: "Стажреык",
                position: "Стажер",
                isHead: false,
                logo: undefined,
            },
        ],

        skills: ["Копирайтинг", "IT"],
    };



    const faculties = useSelector(({ facultiesList }) => [
        { id: "", title: "Выберите Регион" },
        ...facultiesList.facultiesList,
    ]);
    console.log(faculties);
    const userData = useSelector(({ profile }) => profile);
    console.log(userData);
    const [education, setEducation] = useState([]);

    const [competencies, setCompetencies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [croppedFile, setCroppedFile] = useState(userData.image ? userData.image : null);
    const [disableGradeLevel, setDisableGradeLevel] = useState(true);
    const [dispatchObject, setDispatchObject] = useState({});
    const [disableFields, setDisableFields] = useState(true);
    const [updateFetch, setUpdateFetch] = useState(false);
    const [croppedData, setCroppedData] = useState("");
    const [inputImage, setInputImage] = useState();
    const [canCrop, setCanCrop] = useState(false);
    const [dispathProfile, setDispatchProfile] = useDispathProfile(dispatchObject, updateFetch);
    const [selectedCompetencies, setSelectedCompetencies] = useState([]);
    const inputTypeOfLearning = useRef(null);
    const inputAcademicevel = useRef(null);
    const inputStartDate = useRef(null);
    const inputEndDate = useRef(null);
    const inputFaculty = useRef(null);
    const inputEmail = useRef(null);
    const cropperRef = useRef(null);
    const courceRef = useRef(null);

    const date = new Date().getFullYear();

    const deleteFields = [
        "first_name",
        "last_name",
        "middle_name",
        "email",
        "faculty",
        "type_of_learning",
        "academic_level",
        "grade_level",
        "start_date",
    ];
    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        setSelectedCompetencies(prevSelected =>
          checked
            ? [...prevSelected, value]
            : prevSelected.filter(c => c !== value)
        );
      };

    useEffect(() => {
        // Функция для выполнения запроса к API
        const fetchCompetencies = async () => {
          try {
            const response = await HTTP.Get(REQUEST_URL.useCompetency);
            console.log(response)
            setCompetencies(response);
            console.log(competencies)
          } catch (error) {
            setError(error);
          } finally {
            setLoading(false);
          }
        };
    
        // Вызов функции
        fetchCompetencies();
      }, []);


    const customDateOfBirthValidation = value => {
        if (value.length === 10) {
            const newValue = value.split("-").reverse().join(".");
            return Number(date - 14) > Number(newValue.slice(6, 10));
        }
        return false;
    };

    const customDateOfBirthValidationDate = value => {
        const newValue = value.split("-").reverse().join(".");
        if (newValue !== undefined && String(newValue).slice(9, 10) !== "_") {
            const date = new Date(String(value).split(".").reverse().join("."));
            return (
                `${Functions.getMonthWithPrefix(date.getDate())}.${Functions.getMonthWithPrefix(
                    date.getMonth() + 1
                )}.${date.getFullYear()}` === String(newValue)
            );
        }
        return false;
    };

    const formikChangeProfile = useFormik({
        initialValues: {
            first_name: userData.first_name !== "" ? userData.first_name : "",
            last_name: userData.last_name !== "" ? userData.last_name : "",
            middle_name: userData.middle_name !== "" ? userData.middle_name : "",
            date_of_birth: userData.date_of_birth !== "" ? userData.date_of_birth : "",
            sex: userData.sex !== "" ? userData.sex : "",
            email: userData.email !== "" ? userData.email : "",
            faculty: userData.faculty !== "" ? userData.faculty : "",
            faculty_id: userData.faculty_id !== "" ? userData.faculty_id : "",
            type_of_learning: userData.type_of_learning !== "" ? userData.type_of_learning : "",
            academic_level: userData.academic_level !== "" ? userData.academic_level : "",
            phone_number: userData.phone_number !== "" ? userData.phone_number : "",
            image: userData.image !== "" ? userData.image : no_user_img,
        },
        validationSchema: Yup.object({
            // last_name: Yup.string()
            //     .test("name", `Введите корректную фамилию`, value => CustomValidationUser.customNameValidation(value))
            //     .matches(regExpList.CYRILLIC_REG_EXP, "Возможно использование только кириллицы")
            //     .required("Некорректная фамилия"),
            // first_name: Yup.string()
            //     .test("name", `Введите корректное имя`, value => CustomValidationUser.customNameValidation(value))
            //     .required("Некорректное имя")
            //     .matches(regExpList.CYRILLIC_REG_EXP, "Возможно использование только кириллицы"),
            // middle_name: Yup.string()
            //     .test("name", `Введите корректное отчество`, value => CustomValidationUser.customNameValidation(value))
            //     .matches(regExpList.CYRILLIC_REG_EXP, "Возможно использование только кириллицы"),
            // date_of_birth: Yup.string()
            //     .required("Укажите дату рождения")
            //     //.matches(regExpList.DATE_OF_BIRTH_REG_EXP, "Введите корректную дату")
            //     .test("name2", `Введите корректную дату`, value =>
            //         CustomValidationUser.customDateOfBirthValidationReg(value)
            //     )
            //     .test("name", `Введите корректную дату`, value => customDateOfBirthValidation(value))
            //     .test("name1", `Введите корректную дату`, value => customDateOfBirthValidationDate(value)),
            // sex: Yup.string().required("Некорректный пол"),
            // faculty: Yup.string().required("Укажите Регион").notOneOf(["Выберите Регион"], "Укажите Регион"),
            // type_of_learning: Yup.string()
            //     .required("Некорректный тип обучения")
            //     .notOneOf(["Выберите тип обучения"], "Выберите тип обучения"),
            // phone_number: Yup.string().matches(regExpList.PHONE_REG_EXP, "Некорректный номер телефона"),
            // email: Yup.string().email("Некорректный email").required("Некорректный email"),
        }),
        onSubmit: async values => {
            values.image = croppedFile;
            const updateStatus = await fetchUpdateProfile(values);
            if (updateStatus.id) {
                setUpdateFetch(true);
                setDispatchObject(updateStatus);
                setDispatchProfile(updateStatus);
                setHideProfile(false);
            }
        },
    });
    const removeItem = (index) => {
        console.log("ТЕСТ");
        
        delete userData.education[index];
        console.log(userData.education);
        // setEducation(prevItems => prevItems.filter((_, i) => i !== index));
    };
    
    // useEffect(() => {
    //     setEducation(userData?.education);
    //     console.log(education);
    // }, [userData]);

    useEffect(() => {
        if (userData.image) {
            Functions.setUserPhoto(userData.image);
        }
    }, [userData.image]);

    async function fetchUpdateProfile(values) {
        let formData = new FormData();
        for (let item of Object.entries(values)) {
            if (item[0] === "date_of_birth" || item[0] === "start_date" || item[0] === "end_date") {
                const reverse = item[1].split(".").reverse().join("-");
                formData.append(item[0], reverse);
            } else if (item[0] === "image") {
                if (item[1] === userData.image) {
                    continue;
                } else {
                    formData.set(`${item[0]}`, item[1]);
                }
            } else {
                formData.append(`${item[0]}`, item[1]);
            }
        }
        if (userData.status !== "Новый пользователь" && userData.status !== "Подал заявку") {
            for (let item of deleteFields) {
                formData.delete(item);
            }
        }
        return await HTTP.UpdateUser("PATCH", false, REQUEST_URL.updateCurrentUser, formData);
    }

    useEffect(() => {
        if (croppedData !== "") {
            setInputImage(croppedData);
            setCroppedFile(ImageLoader.dataURLtoFile(croppedData, "user_photo.png"));
        }
    }, [croppedData]);

    useEffect(() => {
        if (croppedFile) {
            formikChangeProfile.values.image = croppedFile;
        }
    }, [croppedFile]);

    useEffect(() => {
        const filterFaculty = faculties.filter((item) => item.title === formikChangeProfile.values.faculty);
        formikChangeProfile.values.faculty_id = filterFaculty[0].number;
    }, [formikChangeProfile.values.faculty]);

    // useEffect(() => {
    //     if (formikChangeProfile.values.academic_level !== "") {
    //         const countOfYears = Functions.getCountYear(formikChangeProfile.values.academic_level);
    //         if (formikChangeProfile.values.grade_level > countOfYears || formikChangeProfile.values.grade_level <= 0) {
    //             formikChangeProfile.values.grade_level = "";
    //         }
    //     } else {
    //         courceRef.current.selectedIndex = 0;
    //         formikChangeProfile.values.grade_level = "";
    //     }
    // }, [formikChangeProfile.values.academic_level, formikChangeProfile.values.grade_level]);

    useEffect(() => {
        if (
            userData.status === "Новый пользователь" ||
            userData.status === "Подал заявку" ||
            userData.status === "Членство приостановлено" ||
            userData.status === "Архивированный пользователь"
        ) {
            inputEmail.current.style.pointerEvents = "none";
            inputEmail.current.style.backgroundColor = "#FFF0F0";
        }
    }, [userData.status, inputEmail]);

    useEffect(() => {
        if (formikChangeProfile.values.faculty !== "") {
            setDisableFields(false);
        } else {
            setDisableFields(true);
        }
    }, [formikChangeProfile.values.faculty]);

    useEffect(() => {
        if (formikChangeProfile.values.academic_level !== "") {
            setDisableGradeLevel(false);
        } else {
            setDisableGradeLevel(true);
        }
    }, [formikChangeProfile.values.academic_level]);

    return (
        <>
            <div className="change-profile">
                <div className="change-profile__content">
                    <div className={`${"change-profile__header"} ${canCrop ? "change-profile__header-crop" : ""}`}>
                        <div className={canCrop ? "modalForms-header modalForms-header__column" : "modalForms-header"}>
                            <div className="main__account-user">
                                <Button
                                    className="account-user__settings"
                                    type="button"
                                    text="Вернуться назад"
                                    callback={() => {
                                        // setModalStatus("none");
                                        setHideProfile(false);
                                    }}
                                    color={"transparent"}
                                />
                                <div className="account-user-photo">
                                    <div className="modalForms-header-image">
                                        <PhotoInput
                                            id="photoInput"
                                            accept="image/jpeg, image/jpg, image/jfif, image/png"
                                            name="photoInput"
                                            multiple={false}
                                            user_photo={inputImage ? inputImage : Functions.setUserPhoto(userData.image)}
                                            mode="change_profile"
                                            form={formikChangeProfile}
                                            takeFile={e => ImageLoader.imageHandler(e, setInputImage, setCanCrop)}
                                        />
                                        {canCrop && (
                                            <CropPhoto
                                                photo={inputImage}
                                                cropRef={cropperRef}
                                                getCroppedData={setCroppedData}
                                                setCanCropped={setCanCrop}
                                                height={400}
                                                width={"100%"}
                                            />
                                        )}
                                    </div>
                                </div>
                                    <div className="account-user__container">
                                        <div className="account-user__name">
                                            <Input
                                                mode="fake"
                                                placeholder={`${userData.last_name}`}
                                            />
                                            <Input
                                                mode="fake"
                                                placeholder={`${userData.first_name}`}
                                            />
                                            <Input
                                                mode="fake"
                                                placeholder={
                                                    userData.middle_name || userData.middle_name_translated
                                                        ? `${userData.middle_name}`
                                                        : ""
                                                }
                                            />
                                        </div>
                                        <div className="account-user__wrapper account-user__wrapper-maininfo">
                                            <div className="account-user__group">
                                                <div className="account-user__icon">
                                                    ID
                                                </div>
                                                <div className="account-user__id">
                                                    <Input
                                                        mode="fake"
                                                        placeholder={`${userData.faculty_id}`}
                                                    />
                                                </div>
                                            </div>
                                            <div className="account-user__group">
                                                <div className="account-user__icon">
                                                    <Birthday />
                                                </div>
                                                <Input className="account-user__date" mode="fake" placeholder={userData.date_of_birth} />
                                            </div> <div className="account-user__group">
                                                <div className="account-user__icon">
                                                    <Location />
                                                </div>
                                                <Input className="account-user__place" id="faculty" mode="fake" placeholder={userData.faculty} />
                                            </div><div className="account-user__group">
                                                <div className="account-user__icon">
                                                    <Phone />
                                                </div>
                                                <Input className="account-user__number" id="phone_number" mode="fake" placeholder={userData.phone_number} />
                                            </div>
                                            <div className="account-user__group">
                                                <div className="account-user__icon">
                                                    <Mail />
                                                </div>
                                                <Input className="account-user__email" id="email" mode="fake" placeholder={userData.email} />
                                            </div>
                                            <div className="account-user__links">
                                                <a href={fakeData.vk} className="account-user__sns-link" id="vk">
                                                    <Vk_logo className="account-user__icon" />
                                                </a>
                                                <a href={fakeData.telegram} className="account-user__sns-link" id="telegram">
                                                    <Tg_logo className="account-user__icon" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                
                            </div>
                        </div>
                    </div>
                    <form className="change-profile__form" onSubmit={formikChangeProfile.handleSubmit}>
                    <>
                                <div className="user__mainInfo">
                                    <Select
                                        mode="profileFaculty"
                                        required={true}
                                        id="faculty"
                                        refInput={inputFaculty}
                                        {...formikChangeProfile.getFieldProps("faculty")}
                                        text="Регион"
                                        defaultValue={userData.faculty}
                                        list={faculties}
                                        formError={formikChangeProfile}
                                    />
                                    <Select
                                            list={
                                                formikChangeProfile.values.faculty === ""
                                                    ? type_of_learning
                                                    : type_of_learning.concat(
                                                        formikChangeProfile.values.faculty_id === ""
                                                            ? type_of_learning.flat()
                                                            : faculties.filter(
                                                                item =>
                                                                    Number(item.id) ===
                                                                    Number(formikChangeProfile.values.faculty_id)
                                                            )[0]?.type_of_learning
                                                    )
                                            }
                                            required={true}
                                            text="Орган МСУ"
                                            defaultValue={userData.type_of_learning}
                                            id="type_of_learning"
                                            disable={disableFields}
                                            {...formikChangeProfile.getFieldProps("type_of_learning")}
                                            refInput={inputTypeOfLearning}
                                            formError={formikChangeProfile}
                                    />
                                    <MaskedCustomInput
                                        text="Номер телефона"
                                        required={true}
                                        id="phone_number"
                                        {...formikChangeProfile.getFieldProps("phone_number")}
                                        formError={formikChangeProfile}
                                        mask={"+7(999) 999-99-99"}
                                    />
                                    <Input
                                        id="email"
                                        {...formikChangeProfile.getFieldProps("email")}
                                        type="text"
                                        text="Адрес электронной почты"
                                        defaultValue={userData.email}
                                        refInput={inputEmail}
                                        formError={formikChangeProfile}
                                    />

                                    <Input
                                        text="Ссылка в Вконтакте"
                                        value={userData.vk}
                                        required={true}
                                        id="vk"
                                        name="vk"
                                    />

                                    <Input
                                        text="Ссылка на Telegram"
                                        value={userData.telegram}
                                        required={true}
                                        id="telegram"
                                        name="telegram"
                                    />
                                </div>
                                <div className="user__additionaInfo">
                                    <ChangeSections educations={userData.education} mode={"education"} text_section={"Образование"}/>
                                    <ChangeSections educations={userData.workexperience} mode={"workExp"} text_section={"Опыт работы"}/>
                                    <ChangeSections educations={userData.msumembership} mode={"msu"} text_section={"Членство в МСУ"}/>
                                    <ChangeSections educations={userData.internship} mode={"internships"} text_section={"Стажировка / Практика / Трудоустройсво"}/>

                                    {/* <div className="user__additionaInfo-skills user__additionaInfo-education">
                                        <div className="user__additionaInfo-nameGroup">
                                            <div className="user__additionaInfo-title">Компетенции</div>
                                        </div>
                                        <div className="user__additionaInfo-content">
                                            {competencies?.map((item) => (
                                                <div className="user__additionaInfo-content__skillItem">
                                                    <input id={item.id} name="cb1-name" type="checkbox" value={competency.id}
              onChange={handleCheckboxChange}
              checked={selectedCompetencies.includes(item.id)} />
                                                    <label for={item.id}>{item.name}</label>
                                                </div>))}
                                        </div>
                                    </div> */}

                                </div>
                                <div className="input-wrapper_fake-div"></div>
                            </>

                        <div className="input-wrapper"></div>
                        <Button type="submit" text="СОХРАНИТЬ" color={"blue"} className={"change-profile__button"} Onclick={formikChangeProfile.handleSubmit}/>
                    </form>
                </div>
            </div>
            
                                            
        </>
    );
}
