import React, { useState } from "react";

import CloseImage from "../../images/documents/close.svg";

import "./DownloadFile.css";

export default function DownloadFile({
  name,
  size,
  deleteFile = () => {},
  errorText,
  type,
  progressInfos,
  isVisibleProgressBar,
}) {
 // console.log("isVisibleProgressBar", isVisibleProgressBar);

  function deleteItem(name) {
    deleteFile(name);
  }

  return (
    <>
      <div className={`${"download-file"} ${errorText ? "download-file__error" : ""}`}>
        {/* { (<div className="download-file-title">{titleFile}</div>)} */}

        <div className="download-file-content">
          <div className="download-file-elem left-elem">
            <div className="download-file-tab">{type}</div>
            {progressInfos === 100 && <div className="download-file-name download-file-text">{name}</div>}
            {progressInfos < 100 && (
              <>
                <div className="download-file-block">
                  <div className="download-file-name download-file-text">
                    {name}({size})
                  </div>

                  {isVisibleProgressBar === true && (
                    <div className="download-file-loading">
                      <div className="download-file-loading__bar" style={{ width: `${progressInfos}%` }}></div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="download-file-elem right-elem">
            {progressInfos === 100 && <div className="download-file-size download-file-text">{size}</div>}
            {progressInfos < 100 && isVisibleProgressBar === true && (
              <div className="download-file-size download-file-text" style={{ width: progressInfos + "%" }}>
                {progressInfos}%
              </div>
            )}
            <div className="download-file-close" onClick={() => deleteItem(name)}>
              <img src={CloseImage} alt="Удалить" />
            </div>
          </div>
        </div>
        {errorText ? <span className="download-file__error">{errorText}</span> : <></>}
      </div>
    </>
  );
}
