import { ReactComponent as CloseIcon } from "../../../images/other/close_window.svg";

import "./LoyoutModal.css";

export default function LoyoutModal({
    modalStatus,
    setModalStatus,
    children,
    title,
    setCanCrop,
    classNameTitle,
    sizeModal,
}) {
    const changeCrop = () => {
        if (setCanCrop) {
            setCanCrop(false);
        }
    };

    return (
        <div className="modals-wrapper">
            <div
                className={`
      ${modalStatus !== "none" ? "modalForms change-modal modalForms_active" : "modalForms"} 
      ${modalStatus === "loading" ? "change-modal-loading" : ""}
      ${sizeModal && sizeModal}
      `}
            >
                <div
                    className="loyout-modal__close"
                    onClick={() => {
                        setModalStatus("none");
                        changeCrop();
                    }}
                >
                    <CloseIcon className />
                </div>
                <div
                    className={`${"loyout-modal__container"} 
        ${modalStatus === "loading" ? "loyout-modal__container-loading" : ""}
        `}
                >
                    <div className="loyout__header">
                        <h3 className={`loyout-modal__title  ${classNameTitle ? classNameTitle : ""}`}>{title}</h3>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );
}
