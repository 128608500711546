import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import InputTranslate from "../../../UI/InputTranslate/InputTranslate";
import MaskedCustomInput from "../../../UI/Input/MaskedCustomInput";
import { ImageLoader } from "../../../interface/ImageLoader";
import PhotoInput from "../../../UI/PhotoInput/PhotoInput";
import CropPhoto from "../../CropPhoto/CropPhoto";
import openNotification from "../MessagePopup";
import Button from "../../../UI/Button/Button";
import Select from "../../../UI/Select/Select";
import Radio from "../../../UI/Radio/Radio";
import Input from "../../../UI/Input/Input";

import { showRegistrationErrors, getIncludesValues } from "../../../interface/information-module";
import * as regExpList from "../../../interface/RegExpDictionary";
import CustomValidationUser from "../../../interface/Validation";
import { Functions } from "../../../interface/functions";
import { REQUEST_URL } from "../../../interface/config";
import { HTTP } from "../../../interface/HTTP";

import { academic_level, COURCES_LIST } from "../../../assets/academic_level";
import { academicLevelSlice } from "../../../assets/academic_level_slice";
import { type_of_learning } from "../../../assets/type_of_learning";

import close_window from "../../../images/other/close_window.png";

export default function AddProfileModal(props) {
    const { modalStatus, setModalStatus } = props;

    const [registrationErrors, setRegistrationErrors] = useState([]);
    const [disableGradeLevel, setDisableGradeLevel] = useState(true);
    const [disableFields, setDisableFields] = useState(true);
    const [croppedData, setCroppedData] = useState("");
    const [croppedFile, setCroppedFile] = useState();
    const [inputImage, setInputImage] = useState();
    const [canCrop, setCanCrop] = useState(false);

    const faculties = useSelector(({ facultiesList }) => [
        { id: "", title: "Выберите Регион" },
        ...facultiesList.facultiesList,
    ]);

    const cropperRef = useRef(null);
    const studentIdRef = useRef(null);
    const courceRef = useRef(null);
    const type_of_learning_ref = useRef(null);

    const formikAddUser = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            middle_name: "",
            date_of_birth: "",
            sex: "",
            email: "",
            faculty: "",
            type_of_learning: "",
            student_id: "",
            has_student_id: false,
            phone_number: "",
            academic_level: "",
            grade_level: "",
            start_date: "",
            end_date: "",
            image: "",
            // info_zachet: "",
            // info_zachet_sms: "",
            first_name_translated: "",
            last_name_translated: "",
            middle_name_translated: "",
            password: "WDtJV9Gthc4z",
            re_password: "WDtJV9Gthc4z",
            notification_email: true,
            notification_sms: true,
        },
        validationSchema: Yup.object({
            last_name: Yup.string()
                .required("Введите фамилию")
                .test("name", `Введите корректную фамилию`, value => CustomValidationUser.customNameValidation(value))
                .matches(regExpList.CYRILLIC_REG_EXP, "Возможно использование только кириллицы"),
            first_name: Yup.string()
                .required("Введите имя")
                .test("name", `Введите корректное имя`, value => CustomValidationUser.customNameValidation(value))
                .matches(regExpList.CYRILLIC_REG_EXP, "Возможно использование только кириллицы"),
            middle_name: Yup.string()
                .test("name", `Введите корректное отчество`, value => CustomValidationUser.customNameValidation(value))
                .matches(regExpList.CYRILLIC_REG_EXP, "Возможно использование только кириллицы"),
            last_name_translated: Yup.string()
                .required("Укажите транслит фамилии")
                .matches(regExpList.LATIN_REG_EXP, "Введите корректный транслит фамилии"),
            first_name_translated: Yup.string()
                .matches(regExpList.LATIN_REG_EXP, "Введите корректный транслит имени")
                .required("Укажите транслит имени"),
            middle_name_translated: Yup.string().matches(
                regExpList.LATIN_REG_EXP,
                "Введите корректный транслит отчества"
            ),
            date_of_birth: Yup.string()
                .required("Укажите дату рождения")
                .matches(regExpList.DATE_OF_BIRTH_REG_EXP, "Введите корректную дату")
                .test("name", `Введите корректную дату`, value =>
                    CustomValidationUser.customDateOfBirthValidation(value)
                )
                .test("name1", `Введите корректную дату`, value =>
                    CustomValidationUser.customDateOfBirthValidationDate(value)
                ),
            sex: Yup.string().required("Укажите пол"),
            faculty: Yup.string().required("Укажите Регион").notOneOf(["Выберите Регион"], "Укажите Регион"),
            type_of_learning: Yup.string()
                .required("Укажите тип обучения")
                .notOneOf(["Выберите тип обучения"], "Выберите тип обучения"),
            start_date: Yup.string("Введите полную дату")
                .required("Введите дату начала обучения")
                .matches(regExpList.DATE_OF_BIRTH_REG_EXP, "Введите правильную дату"),
            end_date: Yup.string("Введите полную дату")
                .required("Введите дату окончания обучения")
                .matches(regExpList.DATE_REG_EXP, "Введите полную дату"),
            academic_level: Yup.string()
                .required("Укажите уровень обучения")
                .notOneOf(["Выберите уровень обучения"], "Выберите уровень обучения"),
            grade_level: Yup.string()
                .required("Укажите курс")
                .matches(regExpList.STUDENT_TICKET_REG_EXP, "Введите курс"),
            phone_number: Yup.string()
                .matches(regExpList.PHONE_REG_EXP, "Введите правильный номер")
                .required("Укажите номер телефона"),
            student_id: Yup.string()
                .min(8, "Студенческий билет должен содержать не менее 8 цифр")
                .max(8, "Студенческий билет должен содержать не более 8 цифр")
                .matches(regExpList.STUDENT_TICKET_REG_EXP, "Студенческий билет должен содержать только цифры"),
            email: Yup.string().email("Введите корректный email").required("Введите email"),
        }),
        onSubmit: async values => {
            // values.date_of_birth = values.date_of_birth.split("-").reverse().join("-");
            await setSubmitInfo(values);
        },
    });

    async function setSubmitInfo(values) {
        const formData = new FormData();
        for (let item of Object.entries(values)) {
            if (item[0] === "date_of_birth" || item[0] === "start_date" || item[0] === "end_date") {
                const reverse = item[1].split(".").reverse().join("-");
                formData.append(item[0], reverse);
            } else if (item[0] === "image") {
                if (croppedFile !== undefined) {
                    formData.set("image", croppedFile);
                }
            } else {
                formData.append(item[0], item[1]);
            }
        }
        if (croppedFile !== undefined) {
            formData.set("image", croppedFile);
        }
        formData.set("status", "MEMBER");

        const responce = await HTTP.createUserByAdmin(REQUEST_URL.createUser, formData);

        if (responce.id) {
            openNotification({
                title: "Добавление юзера",
                text: "Задача успешно выполнена",
                type: "success",
            });
            setModalStatus("none");
        } else {
            openNotification({
                title: "Добавление юзера",
                text: "Что-то пошло не так",
                type: "error",
            });
            setRegistrationErrors(responce);
        }
    }

    useEffect(() => {
        if (croppedData !== "") {
            formikAddUser.values.image = croppedData;
            setCroppedFile(Functions.dataURLtoFile(croppedData, "user_photo.png"));
        }
    }, [croppedData, formikAddUser.values]);

    useEffect(() => {
        if (formikAddUser.values.has_student_id) {
            formikAddUser.values.student_id = "";
            studentIdRef.current.style.pointerEvents = "none";
        } else {
            studentIdRef.current.style.pointerEvents = "";
        }
    }, [formikAddUser.values.student_id, formikAddUser.values.has_student_id]);

    useEffect(() => {
        if (formikAddUser.values.academic_level !== "") {
            const countOfYears = Functions.getCountYear(formikAddUser.values.academic_level);
            if (formikAddUser.values.grade_level > countOfYears || formikAddUser.values.grade_level <= 0) {
                formikAddUser.values.grade_level = "";
            }
        } else {
            courceRef.current.selectedIndex = 0;
            formikAddUser.values.grade_level = "";
        }
    }, [formikAddUser.values.academic_level, formikAddUser.values.grade_level]);

    useEffect(() => {
        if (getIncludesValues(type_of_learning_ref.current.selectedOptions[0].value, ["GR3", "GR4", "RS"])) {
            if (formikAddUser.values.start_date === "") {
                formikAddUser.values.start_date = "01.10";
            } else {
                const endString = formikAddUser.values.start_date.slice(5);
                formikAddUser.values.start_date = "01.10" + endString;
            }
            if (formikAddUser.values.end_date === "") {
                formikAddUser.values.end_date = "30.09";
            }
            if (formikAddUser.values.start_date !== "") {
                const endString = formikAddUser.values.start_date.slice(6);
                const countOfYears = Functions.getCountYear(formikAddUser.values.academic_level);
                if (endString === "") {
                    formikAddUser.values.end_date = "30.09";
                } else {
                    const endStringWithcountOfYears = Number(endString) + Number(countOfYears);
                    formikAddUser.values.end_date = "30.09." + endStringWithcountOfYears;
                }
            }
        }
        if (getIncludesValues(type_of_learning_ref.current.selectedOptions[0].value, ["UNGR", "SP", "MG"])) {
            const endString = formikAddUser.values.start_date.slice(6);
            const countOfYears = Functions.getCountYear(formikAddUser.values.academic_level);
            const endStringWithcountOfYears = Number(endString) + Number(countOfYears);
            if (formikAddUser.values.start_date === "") {
                formikAddUser.values.start_date = "01.09";
            } else if (formikAddUser.values.start_date !== "") {
                if (formikAddUser.values.start_date !== "01.09.") {
                    formikAddUser.values.start_date = "01.09." + formikAddUser.values.start_date.slice(6);
                }
            }
            if (endString === "") {
                formikAddUser.values.end_date = "31.08";
            } else {
                formikAddUser.values.end_date = "31.08." + endStringWithcountOfYears;
            }
        }
    }, [formikAddUser]);

    useEffect(() => {
        setModalStatus("add_profile");
    }, []);

    useEffect(() => {
        if (formikAddUser.values.faculty !== "") {
            setDisableFields(false);
        } else {
            setDisableFields(true);
        }
    }, [formikAddUser.values.faculty]);

    useEffect(() => {
        if (formikAddUser.values.academic_level !== "") {
            setDisableGradeLevel(false);
        } else {
            setDisableGradeLevel(true);
        }
    }, [formikAddUser.values.academic_level]);

    return (
        <>
            <div className={modalStatus !== "none" ? "modalForms modalForms_active change-modal" : "modalForms"}>
                <div
                    className="modal-close"
                    onClick={() => {
                        setModalStatus("none");
                    }}
                >
                    <img src={close_window} alt="Close" />
                </div>

                <form
                    className={
                        modalStatus !== "material_support" && modalStatus !== "change_faculty"
                            ? "modalForms-mainEdit change-modal"
                            : "modalForms-mainEdit"
                    }
                    onSubmit={formikAddUser.handleSubmit}
                >
                    <div className={canCrop ? "modalForms-header modalForms-header__column" : "modalForms-header"}>
                        <div className="modalForms-header-image">
                            <PhotoInput
                                id="photoInput"
                                user_photo={formikAddUser.values.image}
                                mode="change_profile"
                                name="photoInput"
                                multiple={false}
                                form={formikAddUser}
                                accept="image/jpeg, image/jpg, image/jfif, image/png"
                                takeFile={e => ImageLoader.imageHandler(e, setInputImage, setCanCrop)}
                            />
                            {canCrop && (
                                <CropPhoto
                                    photo={inputImage}
                                    cropRef={cropperRef}
                                    getCroppedData={setCroppedData}
                                    setCanCropped={setCanCrop}
                                    height={400}
                                    width={"100%"}
                                />
                            )}
                        </div>
                        <div className="modalForms-header-info">
                            <div className="modalForms-header-title">Добавление пользователя</div>
                        </div>
                    </div>
                    <InputTranslate
                        id="last_name"
                        required={true}
                        {...formikAddUser.getFieldProps("last_name")}
                        translitOptions={formikAddUser.getFieldProps("last_name_translated")}
                        type="text"
                        text="Фамилия / Last name"
                        textTranslit="Транслит поля Фамилия"
                        formError={formikAddUser}
                    />
                    <InputTranslate
                        id="first_name"
                        required={true}
                        {...formikAddUser.getFieldProps("first_name")}
                        translitOptions={formikAddUser.getFieldProps("first_name_translated")}
                        type="text"
                        text="Имя / First name"
                        textTranslit="Транслит поля Имя"
                        formError={formikAddUser}
                    />
                    <InputTranslate
                        id="middle_name"
                        required={false}
                        {...formikAddUser.getFieldProps("middle_name")}
                        translitOptions={formikAddUser.getFieldProps("middle_name_translated")}
                        type="text"
                        text="Отчество / Middle name"
                        textTranslit="Транслит поля Отчество"
                        formError={formikAddUser}
                    />
                    <MaskedCustomInput
                        type="text"
                        text="Дата рождения / Date of Birth"
                        required={true}
                        id="date_of_birth"
                        {...formikAddUser.getFieldProps("date_of_birth")}
                        formError={formikAddUser}
                        mask={"99.99.9999"}
                    />
                    <Select
                        mode="sex"
                        id="sex"
                        required={true}
                        text="Пол / Sex"
                        {...formikAddUser.getFieldProps("sex")}
                        formError={formikAddUser}
                    />
                    <Input
                        text="Адрес электронной почты / Email"
                        required={true}
                        id="email"
                        {...formikAddUser.getFieldProps("email")}
                        formError={formikAddUser}
                        value={formikAddUser.values.email}
                    />
                    <MaskedCustomInput
                        text="Номер телефона / Phone"
                        required={true}
                        id="phone_number"
                        {...formikAddUser.getFieldProps("phone_number")}
                        formError={formikAddUser}
                        mask={"+7(999) 999-99-99"}
                    />
                    <Select
                        list={faculties}
                        required={true}
                        text="Регион / Faculty"
                        defaultValue={"Выберите Регион"}
                        id="faculty"
                        {...formikAddUser.getFieldProps("faculty")}
                        formError={formikAddUser}
                    />
                    <Select
                        list={
                            formikAddUser.values.faculty === ""
                                ? academic_level
                                : academic_level.concat(
                                      formikAddUser.values.faculty === ""
                                          ? academic_level.flat()
                                          : faculties.filter(
                                                item => Number(item.id) === Number(formikAddUser.values.faculty)
                                            )[0]?.academic_level
                                  )
                        }
                        required={true}
                        text="Уровень обучения / Education level"
                        defaultValue={"Выберите уровень обучения"}
                        id="academic_level"
                        disable={disableFields}
                        {...formikAddUser.getFieldProps("academic_level")}
                        formError={formikAddUser}
                        refInput={type_of_learning_ref}
                    />
                    <Select
                        list={
                            formikAddUser.values.faculty === ""
                                ? type_of_learning
                                : type_of_learning.concat(
                                      formikAddUser.values.faculty === ""
                                          ? type_of_learning.flat()
                                          : faculties.filter(
                                                item => Number(item.id) === Number(formikAddUser.values.faculty)
                                            )[0]?.type_of_learning
                                  )
                        }
                        required={true}
                        text="Тип обучения / Education type"
                        defaultValue={"Выберите тип обучения"}
                        id="type_of_learning"
                        disable={disableFields}
                        {...formikAddUser.getFieldProps("type_of_learning")}
                        formError={formikAddUser}
                    />
                    <Select
                        list={
                            formikAddUser.values.academic_level === ""
                                ? COURCES_LIST
                                : COURCES_LIST.concat(
									formikAddUser.values.faculty === ""
                                          ? COURCES_LIST.flat()
                                          : academicLevelSlice.slice(
                                                0,
                                                Functions.getCountYear(formikAddUser.values.academic_level)
                                            )
                                  )
                        }
                        required={true}
                        text="Текущий Курс / Current course"
                        defaultValue={"Выберите курс"}
                        id="grade_level"
						disable={disableFields || disableGradeLevel ? true : false}
                        {...formikAddUser.getFieldProps("grade_level")}
                        formError={formikAddUser}
                        refInput={courceRef}
                    />
                    <MaskedCustomInput
                        type="text"
                        text="Дата начала обучения / Start date"
                        required={true}
                        id="start_date"
                        {...formikAddUser.getFieldProps("start_date")}
                        formError={formikAddUser}
                        mask={"99.99.9999"}
                    />
                    <MaskedCustomInput
                        type="text"
                        text="Cрок окончания обучения / End date"
                        required={true}
                        id="end_date"
                        {...formikAddUser.getFieldProps("end_date")}
                        formError={formikAddUser}
                        mask={"99.99.9999"}
                    />
                    <Input
                        type="text"
                        text="Студенческий билет / Student ID"
                        required={true}
                        refInput={studentIdRef}
                        id="student_id"
                        {...formikAddUser.getFieldProps("student_id")}
                        formError={formikAddUser}
                    />
                    <Radio
                        text="Студенческий билет не выдан / Student ID not issued"
                        required={false}
                        checked={formikAddUser.values.has_student_id}
                        id="has_student_id"
                        {...formikAddUser.getFieldProps("has_student_id")}
                    />
                    <Button text="ЗАРЕГИСТРИРОВАТЬ" type="submit" color="blue" />
                    <div className="reg__content-error">{showRegistrationErrors(registrationErrors)}</div>
                </form>
            </div>
            {/* 
        <Modals
          page="registration"
          setModalStatus={setModalStatus}
          modalStatus={modalStatus}
        /> */}
        </>
    );
}

// AddProfileModal
