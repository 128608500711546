import { HTTP } from "./HTTP";
import { REQUEST_URL } from "./config";

const showRegistrationErrors = errors => {
    let errorsArray = [];
    for (let error in errors) {
        errorsArray.push(errors[error]);
    }
    return errorsArray.map((item, index) => {
        return <div key={index}>{item}</div>;
    });
};

const getFacultiesList = async setStateHandler => {
    const responce = await HTTP.GetWithOutAuth(REQUEST_URL.getFaculties);
    setStateHandler([{ id: "", title: "Выберите Регион" }, ...responce]);
};

const getIncludesValues = (target, values) => {
    for (let item of values) {
        if (target.includes(item)) {
            return true;
        }
    }
    return false;
};

export { 
    showRegistrationErrors, 
    getFacultiesList, 
    getIncludesValues, 
};
