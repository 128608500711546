import { useSelector } from "react-redux";
import { useState } from "react";

import ChangeFaculty from "../../components/ChangeFaculty/ChangeFaculty";
import ProfkomInfo from "../../components/ProfkomInfo/ProfkomInfo";
import Modals from "../../components/Modals/Modals/Modals";
import Header from "../../components/Header/Header";

import { useDisableBodyScroll } from "../../hooks/useDisableBodyScroll";

import "./Documents.css";

export default function Documents() {
    const user_type = useSelector(({ profile }) => profile.status);
    const user_role = useSelector(({ profile }) => profile.role);

    const [modalStatus, setModalStatus] = useState("none");
    
    useDisableBodyScroll(modalStatus);

    return (
        <>
            <Header/>
            <div className="documents container-content">
                {(user_role!== 'Администратор' && user_role!=='Председатель') && (
                    <div className="main">
                        <div className="main__info">
                            <ChangeFaculty
                                setModalStatus={setModalStatus}
                                mode={user_type === "Член профсоюза" ? "material_support" : "join_faculty"}
                            />
                            <ProfkomInfo user_type={user_type} />
                        </div>
                    </div>
                )}
            </div>
            <Modals page="document" modalStatus={modalStatus} setModalStatus={setModalStatus} />
        </>
    );
}
