import React from "react";

import SearchIcon from "../../images/other/input-search.svg";

import "./Input.css";

function SearchInput({ placeholder, onChange, value = "" }) {

	const searchHandler = (text) => {
		onChange(text);
	}

    return (
        <div className="input-search">
            <img src={SearchIcon} alt="Поиск" className="input-search__image" />
            <input
                className={"input-search__input"}
                type="text"
                value={value}
                placeholder={placeholder}
                onChange={e => searchHandler(e.target.value)}
            />
        </div>
    );
}

export default SearchInput;
