import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import PeopleEvent from "../../components/PeoplesEventTable/PeopleEvent";
import PaginationBar from "../Pagination/PaginationBar";
import SelectFilter from "../../UI/Select/SelectFilter";
import InputSearch from "../../UI/Input/SearchInput";
import Scrollbar from "../../UI/Scroolbar/Scrollbar";
import NotContent from "../NotContent/NotContent";
import Button from "../../UI/Button/Button";
import Tabs from "../Tabs/Tabs";

import { type_of_application } from "../../assets/type_of_application";
import useDasPagination from "../../hooks/useDasPagination";
import { PAGINATION_COUNT, PAGINATIONBAR_COUNT } from "../../interface/config";
import { useFilter } from "../../hooks/useFilter";
import { REQUEST_URL } from "../../interface/config";

import "./PeoplesEventTable.css";

export default function PeoplesEventTable({ type, cardsList, filters, title, page, setSelectedUser, djangoPagination = false, setIsLoading = null }) {
    const renderSelectList = () => {
        let listSelect = [];

        if (page === "social_people") {
            listSelect = type_of_application;
        } else {
            listSelect = type_of_application;
        }
        return listSelect;
    };

    const [activeTab, setActiveTab] = useState(0);
    const [activeSelect, setActiveSelect] = useState(0);
    const [renderList, setRenderList] = useState([]);
    const [selectList, SetSelectList] = useState(renderSelectList());

    const navigate = useNavigate();

    const pageParams = useParams();

    const { itemsList, activePagination, setActivePagination, currentItems, paginationItemsCount } = useDasPagination(
        cardsList,
        PAGINATION_COUNT,
        djangoPagination,
        REQUEST_URL.usersRequests,
        setIsLoading
    );

    const { enteredSearchValue, setEnteredSearchValue, availablesItems } = useFilter(currentItems, "name");

    const getActiveTab = index => {
        setActiveTab(index);
    };

    const getActiveSelect = index => {
        setActiveSelect(index);
    };

    const getUserEventStatus = status => {
        switch (status.toLowerCase()) {
            case "registered":
                return "Поданные заявки";
            case "member":
                return "Одобренные заявки";
            case "rejected":
                return "Отклоненные заявки";
            case "ожидание ответа":
                return "открытые";
            case "обращение закрыто":
                return "закрытые";
            default:
                return "";
        }
    };

    const getRenderList = (list, type, sortList) => {
        const sort = sortList[activeSelect];
        if (activeTab === 0 && sort.toLowerCase() === "все") {
            setRenderList(list);
        } else {
            const filter = filters[activeTab].toLowerCase();
            console.log(filter.toLowerCase())
            const filterList = list.filter(
                item => getUserEventStatus(item.status).toLowerCase() === filter.toLowerCase()
            );
            setRenderList(filterList);
        }
    };

    const tableTitles = (type === "appeal") ? ["ФИО", "Регион", "Телефон", "№ Профибилета", "Тип обучения", "Статус", " "] : [" ", "ФИО", "Регион", "Телефон", "№ Профибилета", "Тип обучения", "Статус", " "];

    const setTitlesTable = arrayTitle => {
        return arrayTitle.map((item, index) => {
            return <th key={index}>{item}</th>;
        });
    };

    const setItems = arrayItems => {
        return arrayItems.map(item => {
            return <PeopleEvent item={item} type={type} key={item.id} activeTab={activeTab} setSelectedUser={setSelectedUser} />;
        });
    };

    useEffect(() => {
        getRenderList(availablesItems, type, selectList);
    }, [availablesItems, activeTab, activeSelect]);

    return (
        <section className="peoples-list">
            <h1 className="peoples-list__title">{title}</h1>
            <div className="peoples-list__filter">
                <Tabs tabsLabelList={filters} callback={getActiveTab} active={activeTab} type="white_box" />
                <div className="peoples-list__filter-sort">
                    <InputSearch
                        type="search"
                        placeholder="Поиск"
                        value={enteredSearchValue}
                        onChange={e => setEnteredSearchValue(e.target.value)}
                    />
                    {/* <SelectFilter
                        items={selectList}
                        id={"type_of_events"}
                        name={"type_of_events"}
                        except={true}
                        selectedItemKey={activeSelect}
                        error={true}
                        callback={getActiveSelect}
                        text={"Фильтровать по:"}
                    /> */}
                </div>
            </div>

            {renderList && renderList.length > 0 ? (
                <>
                    <div className="peoples-table">
                        <Scrollbar>
                            <table className="peoples-table__wrapper">
                                <thead className="peoples-table__header">
                                    <tr className={(type === "appeal") ? "peoples-table__title_non_id" : "peoples-table__title"}>{setTitlesTable(tableTitles)}</tr>
                                </thead>
                                <tbody>{setItems(renderList)}</tbody>
                            </table>
                        </Scrollbar>
                        {type === "appeal" && (paginationItemsCount > PAGINATION_COUNT || itemsList.length > PAGINATION_COUNT) && (
                            <PaginationBar
                                paginationState={[activePagination, setActivePagination]}
                                itemCount={(paginationItemsCount) ? paginationItemsCount : renderList.length}
                                maxItems={PAGINATIONBAR_COUNT}
                                type={"circle"}
                            />
                        )}
                    </div>
                    {type !== "appeal" && (
                        <Button
                            text={"смотреть ответы"}
                            type="button"
                            color="green"
                            className="people-events__button"
                            callback={() => navigate(`/peoples_responses/${pageParams.id}`)}
                        />
                    )}
                </>
            ) : (
                <NotContent text="Здесь еще пока нет доступных" type="people" />
            )}
        </section>
    );
}
