import React, { useEffect } from "react";

import MaskedInput from "react-input-mask";
import ShowError from "../ShowError/ShowError";

import { SetFieldsStatus } from "../../interface/SetFieldsStatus";

import "./Input.css";
export default function MaskedCustomInput({
    type = "",
    value = "",
    mode = "default",
    id = "",
    onBlur = () => {},
    onChange = () => {},
    text = "",
    required,
    showError = true,
    status = "default",
    formError,
    mask,
    placeholder,
    className,
    name,
    disabled = false,
    getInitialValues,
}) {
    // useEffect(() => {
    //   if (mode === "date") {
    //     getInitialValues(id);
    //   }
    // }, [id, mode]);

    return (
        <>
            {mode === "default" && (
                <div className={`${"input-wrapper"} ${SetFieldsStatus.setInputClassValid(status)} ${className}`}>
                    <label htmlFor="">
                        {text} <span className="required_star">{required ? "*" : ""}</span>
                    </label>
                    <MaskedInput
                        type={type}
                        value={value}
                        onBlur={onBlur}
                        onChange={onChange}
                        status="default"
                        id={id}
                        mask={mask}
                        name={name}
                        placeholder={placeholder}
                        disabled={disabled}
                    />
                    {SetFieldsStatus.setImage(status)}
                    {showError && <ShowError form={formError} name={id} />}
                </div>
            )}
            {mode === "date" && (
                <div className={`${"input-wrapper input-wrapper_question"}`}>
                    <MaskedInput
                        value={value}
                        onBlur={onBlur}
                        className="input-wrapper__input-question"
                        onChange={onChange}
                        status="default"
                        id={id}
                        mask={mask}
                        name={name}
                        disabled={disabled}
                        placeholder={placeholder}
                    />
                    {/* {showError && <ShowError form={formError} name={id} />} */}
                </div>
            )}
        </>
    );
}
