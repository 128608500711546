import React from "react";

import "./CheckboxGroup.css";

export const CheckboxGroup = ({ checkedBoxByGroup, id, nameGroup, label, onChange, type = "default", allValues = null }) => {
    
    const isChecked = (type === "default") ? checkedBoxByGroup[nameGroup].includes(label) : JSON.stringify(checkedBoxByGroup[nameGroup]) === JSON.stringify(allValues);

    const handleChange = event => {
        onChange(event, nameGroup);
    };

    return (
        <label htmlFor={id} className="checkbox-group__option">
            <span className="checkbox-group__input">
                <input checked={isChecked} id={id} name={label} type="checkbox" value={label} onChange={(type === "default") ? handleChange : () => handleChange(isChecked, nameGroup)} />
            </span>
            {label && <span className="checkbox-group__label">{label}</span>}
        </label>
    );
};
