import Header from "../../components/Header/Header";
import StaticticMember from "../StaticticMember/StaticticMember";

import "./AdminStatistic.css";

export default function AdminStatistic() {
    return (
        <>
            <Header />
            <div className="main">
                <div className="admin-table-statistics container-content">
                    <StaticticMember/>
                </div>
            </div>
        </>
    );
}
