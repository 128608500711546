import { useState, useEffect } from "react";

import CreateEventItem from "./CreateEventItem";

import AddImg from "../../images/create/add.svg";

import "./CreateEvent.css";

export default function CreateEvent({ setQuestionsList, eventRequestList }) {
    const [inputFields, setInputFields] = useState(
        !eventRequestList
            ? [
                  {
                      id: Date.now(),
                      question: "",
                      inner_id: Date.now(),
                      answer: [],
                      type: "text",
                      required: false,
                      select: "0",
                      answerlist: [
                          {
                              id: 1,
                              value: "Вариант ответа",
                              title: "Вариант ответа",
                              checked: null,
                              type: "text",
                          },
                          {
                              id: 2,
                              value: "Вариант ответа",
                              title: "Вариант ответа",
                              checked: null,
                              type: "text",
                          },
                      ],
                  },
              ]
            : eventRequestList
    );

    const handleAddFields = () => {
        setQuestionsList([
            ...eventRequestList,
            {
                id: Date.now(),
                question: "",
                inner_id: Date.now(),
                answer: [],
                type: "text",
                required: false,
                select: "0",
                answerlist: [],
            },
        ]);
    };

    const addQuestionForm = () => {
        handleAddFields();
    };

    useEffect(() => {
        setQuestionsList(inputFields);
    }, [inputFields]);

    return (
        <div className="create-ev">
            {eventRequestList.map(item => {
                return (
                    <CreateEventItem
                        inputFields={eventRequestList}
                        setQuestionsList={setQuestionsList}
                        setInputFields={setQuestionsList}
                        item={item}
                        key={item.id}
                    />
                );
            })}
            <div className="create-ev_question" onClick={addQuestionForm}>
                <div className="create-ev__btn create-ev__question">
                    <img src={AddImg} alt="добавить" />
                    Добавить новый вопрос
                </div>
            </div>
        </div>
    );
}
