export const email = value => ({ type: "email", payload: value });
export const password = value => ({ type: "password", payload: value });
export const logOut = value => ({ type: "logOut" });
export const first_name = value => ({ type: "first_name", payload: value });
export const last_name = value => ({ type: "last_name", payload: value });
export const middle_name = value => ({ type: "middle_name", payload: value });
export const vk = value => ({ type: "vk", payload: value });
export const telegram = value => ({ type: "telegram", payload: value });
export const first_name_translated = value => ({
    type: "first_name_translated",
    payload: value,
});
export const last_name_translated = value => ({
    type: "last_name_translated",
    payload: value,
});
export const event = value => ({
    type: "event",
    payload: value,
});
export const internship = value => ({
    type: "internship",
    payload: value,
});
export const msumembership = value => ({
    type: "msumembership",
    payload: value,
});
export const workexperience = value => ({
    type: "workexperience",
    payload: value,
});
export const education = value => ({
    type: "education",
    payload: value,
});
export const competency = value => ({
    type: "competency",
    payload: value,
});
export const social = value => ({
    type: "social",
    payload: value,
});
export const middle_name_translated = value => ({
    type: "middle_name_translated",
    payload: value,
});
export const requests = value => ({
    type: "requests",
    payload: value,
});
export const date_of_birth = value => ({
    type: "date_of_birth",
    payload: value,
});
export const sex = value => ({ type: "sex", payload: value });
export const faculty = value => ({ type: "faculty", payload: value });
export const faculty_id = value => ({ type: "faculty_id", payload: value });
export const type_of_learning = value => ({
    type: "type_of_learning",
    payload: value,
});
export const start_date = value => ({ type: "start_date", payload: value });
export const student_id = value => ({ type: "student_id", payload: value });
export const has_student_id = value => ({
    type: "has_student_id",
    payload: value,
});
export const phone_number = value => ({
    type: "phone_number",
    payload: value,
});
export const academic_level = value => ({
    type: "academic_level",
    payload: value,
});
export const grade_level = value => ({ type: "grade_level", payload: value });
export const end_date = value => ({ type: "end_date", payload: value });
export const image = value => ({ type: "image", payload: value });
export const access = value => ({ type: "access", payload: value });
export const role = value => ({ type: "role", payload: value });
export const card = value => ({ type: "card", payload: value });
export const status = value => ({ type: "status", payload: value });
export const entry_date = value => ({ type: "entry_date", payload: value });
export const id = value => ({ type: "id", payload: value });
export const notification_email = value => ({
    type: "notification_email",
    payload: value,
});
export const notification_sms = value => ({
    type: "notification_sms",
    payload: value,
});
export const status_gain_date = value => ({
    type: "status_gain_date",
    payload: value,
});
export const was_verificated = value => ({
    type: "was_verificated",
    payload: value,
});
export const was_verificated_twice = value => ({
    type: "was_verificated_twice",
    payload: value,
});
export const token = value => ({ type: "token", payload: value });

export const rzd_number = value => ({ type: "rzd_number", payload: value });
export const rzd_status = value => ({ type: "rzd_status", payload: value });
