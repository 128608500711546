const initialState = {
  usersListAll: [],
  usersListNew: [],
  usersListAPPL: [], //подал заявку
  usersListMember: [],
  usersListExclude: [],
};

const userListAdmin = (state = initialState, action) => {
  switch (action.type) {
    case "getUsersListAll":
      return {
        ...state,
        usersListAll: action.payload,
      };
    case "getUsersListNew":
      return {
        ...state,
        usersListNew: action.payload,
      };
    case "getUsersListAPPL":
      return {
        ...state,
        usersListAPPL: action.payload,
      };
    case "getUsersListMember":
      return {
        ...state,
        usersListMember: action.payload,
      };
    case "getUsersListExclude":
      return {
        ...state,
        usersListExclude: action.payload,
      };
    default:
      return state;
  }
};

export default userListAdmin;
