import { useState, useEffect } from "react";
import Cropper from "react-cropper";
import Button from "../../UI/Button/Button";
import { Slider } from "@mui/material";

import "cropperjs/dist/cropper.css";
import "./CropPhoto.css";

export default function CropPhoto({
    photo,
    cropRef,
    getCroppedData,
    setCanCropped,
    description = true,
    height,
    width,
    setModalStatus,
    mode,
}) {
    const [cropper, setCropper] = useState();
    const [cropData, setCropData] = useState("");
    const [zoomValue, setZoomValue] = useState(0.1);
    const cropperRef = cropRef;

    const setObjectSize = () => {
        const obj = {
            width: 200,
            height: 260,
        };
        if (mode === "create") {
            const obj = {
                width: 212,
                height: 557,
            };
            return obj;
        } else return obj;
    };

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setCropData(cropData => (cropData = cropper.getCroppedCanvas({ setObjectSize }).toDataURL()));
        }
    };

    const changeZoomValue = e => {
        setZoomValue(Number(e.target.value));
    };

    useEffect(() => {
        if (cropData !== "") {
            getCroppedData(cropData);
            setCanCropped(false);
        }
    }, [cropData]);

    return (
        <div className="crop__wrapper">
            <Cropper
                src={photo}
                style={{ height: height, width: width }}
                zoomTo={zoomValue}
                dragMode={"move"}
                initialAspectRatio={mode === "create" ? 100 / 35 : 10 / 13}
                scalable={false}
                guides={false}
                cropBoxResizable={false}
                checkOrientation={false}
                viewMode={2}
                ref={cropperRef}
                onInitialized={instance => {
                    setCropper(instance);
                }}
            />
            <Slider
                key={`slider-zoom`}
                size="small"
                value={zoomValue}
                min={0.1}
                max={2}
                step={0.01}
                onChange={e => changeZoomValue(e)}
            />
            {description && (
                <div className="crop__text">
                    Ваше лицо должно занимать от 60 до 80 процентов активной области. Фотография используется для
                    выпуска вашего профсоюзного билета, используйте максимально контрастные фотографии
                </div>
            )}
            <div className="crop__button">
                <Button
                    text="Сохранить"
                    color="green"
                    callback={() => {
                        getCropData();
                        setModalStatus("none");
                    }}
                />
            </div>
        </div>
    );
}
