import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";

import DownloadBoxWrapper from "../../components/DownloadBox/DownloadBoxWrapper";
import LoyoutModal from "../../components/Modals/LoyoutModal/LoyoutModal";
import Availability from "../../components/Availability/Availability";
import openNotification from "../../components/Modals/MessagePopup";
import CreateEvent from "../../components/CreateEvent/CreateEvent";
import { Overlay } from "../../components/Modals/Overlay/Overlay";
import CropPhoto from "../../components/CropPhoto/CropPhoto";
import TextArea from "../../components/Textarea/Textarea";
import SelectByGroup from "../../UI/Select/SelectByGroup";
import DatesFilter from "../СreateEventPage/DatesFilter";
import PhotoInput from "../../UI/PhotoInput/PhotoInput";
import Header from "../../components/Header/Header";
import Checkbox from "../../UI/Checkbox/Checkbox";
import Button from "../../UI/Button/Button";
import Input from "../../UI/Input/Input";
import Title from "../../UI/Title/Title";

import { ImageLoader } from "../../interface/ImageLoader";
import { Functions } from "../../interface/functions";
import { REQUEST_URL } from "../../interface/config";
import { SERVER_URL } from "../../interface/HTTP";
import { HTTP } from "../../interface/HTTP";

import "./СreateEventPage.css";

export default function EditEventPage({ type = "event" }) {
    const [modalStatus, setModalStatus] = useState("none");
    const [isChecked, setIsChecked] = useState(false);
    const [canCrop, setCanCrop] = useState(false);
    const [croppedData, setCroppedData] = useState("");
    const [croppedFile, setCroppedFile] = useState();
    const [filesList, setFilesList] = useState();
    const [emailList, setEmailList] = useState([]);
    const [spendingTimeRange, setSpendingTimeRange] = useState([Functions.setWeekAgo(), new Date()]);
    const [requestTimeRange, setRequestTimeRange] = useState([Functions.setWeekAgo(), new Date()]);
    const [accessValues, setAccessValues] = useState({
        type_of_learning: [],
        academic_level: [],
        sex: [],
        faculty: [],
    });
    const [slug, setSlug] = useState("");
    const [eventId, setEventId] = useState(null);

    const [questionsList, setQuestionsList] = useState([]);

    const pageParams = useParams();
    const navigate = useNavigate();

    const cropperRef = useRef(null);

    const formikСreateEvent = useFormik({
        initialValues: {
            title: "",
            description: "",
            address: "",
            address_description: "",
            photo: "",
        },
        validationSchema: Yup.object({
            // title: "",
            // description: "",
            // address: "",
        }),
        onSubmit: async values => {
            const formData = new FormData();
            for (let item of Object.entries(values)) {
                if (item[0] === "image") {
                    if (croppedFile !== undefined) {
                        formData.set("image", croppedFile);
                    }
                } else {
                    if (item[0] !== "photo") {
                        formData.append(item[0], item[1]);
                    }
                }
            }
            if (croppedFile !== undefined) {
                formData.set("image", croppedFile);
            }

            formData.append("id", eventId);
            formData.append("request_access", isChecked);
            formData.append("access_events_types", JSON.stringify(accessValues));
            formData.append("questions", JSON.stringify(questionsList));
            formData.append("type", "event");
            formData.append("slug", slug);

            const firstStartDate = new Date(spendingTimeRange[0]);
            const secondStartDate = new Date(spendingTimeRange[1]);
            const firstRequestDate = new Date(requestTimeRange[0]);
            const secondRequestDate = new Date(requestTimeRange[1]);

            formData.append(
                "start_time",
                [
                    `${firstStartDate.getDate()}.${Functions.getMonthWithPrefix(
                        firstStartDate.getMonth() + 1
                    )}.${firstStartDate.getFullYear()}`,
                    `${secondStartDate.getDate()}.${Functions.getMonthWithPrefix(
                        secondStartDate.getMonth() + 1
                    )}.${secondStartDate.getFullYear()}`,
                ].toString()
            );
            formData.append(
                "request_time",
                [
                    `${firstRequestDate.getDate()}.${Functions.getMonthWithPrefix(
                        firstRequestDate.getMonth() + 1
                    )}.${firstRequestDate.getFullYear()}`,
                    `${secondRequestDate.getDate()}.${Functions.getMonthWithPrefix(
                        secondRequestDate.getMonth() + 1
                    )}.${secondRequestDate.getFullYear()}`,
                ].toString()
            );

            const createEvent = await HTTP.updateEvent(
                type === "event" ? REQUEST_URL.editEvent(slug) : REQUEST_URL.editSocial(slug),
                formData
            );

            if (createEvent.id) {
                openNotification({
                    title: "Создать мероприятие",
                    text: "Задача успешно выполнена",
                    type: "success",
                });
                setTimeout(() => {
                    navigate(type === "event" ? "/events" : "/social_programs_admin");
                }, 3000);
            }
        },
    });

    const getEventData = async () => {
        const response = await HTTP.Get(
            `${type === "event" ? REQUEST_URL.getEventsList : REQUEST_URL.social}${pageParams.slug}/`
        );
        if (response) {
            setIsChecked(response.request_access);
            setAccessValues(JSON.parse(response.access_events_types));
            setQuestionsList(JSON.parse(response.questions));
            setEventId(response.id);
            setSlug(response.slug);

            formikСreateEvent.values.title = response.title;
            formikСreateEvent.values.description = response.description;
            formikСreateEvent.values.address = response.address;
            formikСreateEvent.values.address_description = response.address_description;
            formikСreateEvent.values.photo = response.image;
            setSpendingTimeRange([new Date(response.start_time), new Date(response.end_time)]);
            setRequestTimeRange([new Date(response.request_time), new Date(response.end_request_time)]);
        } else {
            navigate("/");
        }
    };

    useEffect(() => {
        if (croppedData !== "") {
            formikСreateEvent.values.photo = croppedData;
            setCroppedFile(ImageLoader.dataURLtoFile(croppedData, "user_photo.png"));
        }
    }, [croppedData]);

    useEffect(() => {
        if (canCrop) {
            setModalStatus("photocrop");
        }
    }, [canCrop]);

    useEffect(() => {
        getEventData();
    }, []);

    return (
        <>
            <Header />
            <div className="create-event">
                <Title text="Редактировать мероприятие" className={"create-event__title"} />
                <div className="create-event__container">
                    <div className="event-form">
                        <div className="event-form__left">
                            <form onSubmit={formikСreateEvent.handleSubmit} className="event-form__wrapper">
                                <div className={`${"event-form__general"} ${"box-white"}`}>
                                    <h3 className="event-form__title">Общая информация</h3>
                                    <div className="event-form__photo">
                                        <DownloadBoxWrapper
                                            className={"download__event"}
                                            setFilesList={e => ImageLoader.imageHandler(e, setFilesList, setCanCrop)}
                                        >
                                            <PhotoInput
                                                mode="create"
                                                id="photoInput"
                                                name="photoInput"
                                                multiple={false}
                                                setModalStatus={setModalStatus}
                                                form={formikСreateEvent}
                                                accept="image/jpeg, image/jpg, image/jfif, image/png"
                                                takeFile={e => ImageLoader.imageHandler(e, setFilesList, setCanCrop)}
                                                user_photo={SERVER_URL + formikСreateEvent.values.photo}
                                            />
                                        </DownloadBoxWrapper>
                                    </div>
                                    <Input
                                        text="Наименование мероприятия"
                                        placeholder={"Введите текст"}
                                        id="title"
                                        {...formikСreateEvent.getFieldProps("title")}
                                        formError={formikСreateEvent}
                                        value={formikСreateEvent.values.title}
                                        maxLength={"30"}
                                        className="event-form__label"
                                    />
                                    <TextArea
                                        title="Описание мероприятия"
                                        placeholder="Введите текст"
                                        mode="event"
                                        id="description"
                                        name="description"
                                        form={formikСreateEvent}
                                        error={true}
                                        inputChange={formikСreateEvent.handleChange}
                                        border={false}
                                        type="text"
                                        className="event-form__description"
                                    />
                                    <div className="event-form__dates">
                                        <DatesFilter
                                            spendingTimeRange={spendingTimeRange}
                                            setSpendingTimeRange={setSpendingTimeRange}
                                            requestTimeRange={requestTimeRange}
                                            setRequestTimeRange={setRequestTimeRange}
                                        />
                                    </div>
                                    <Input
                                        text="Место проведения"
                                        placeholder={"Адрес...."}
                                        id="address"
                                        {...formikСreateEvent.getFieldProps("address")}
                                        formError={formikСreateEvent}
                                        value={formikСreateEvent.values.address}
                                        className="event-form__label-place"
                                    />
                                    <TextArea
                                        mode="event"
                                        placeholder="Пояснение"
                                        id="address_description"
                                        name="address_description"
                                        form={formikСreateEvent}
                                        error={true}
                                        inputChange={formikСreateEvent.handleChange}
                                        border={false}
                                        type="text"
                                        className="event-form__explanation"
                                    />
                                    <Checkbox
                                        text="Одобрение заявок"
                                        checked={isChecked}
                                        inputChange={() => setIsChecked(!isChecked)}
                                        mode="switch"
                                        id="check"
                                        label="Автоматическое подтверждение участия"
                                    />
                                    <Button text="СОХРАНИТЬ" type="submit" color="violet" className="event-form__btn" />
                                </div>
                            </form>
                        </div>
                        <div className="event-form__right">
                            <div className={`${"event-form__availability"} ${"box-white"}`}>
                                <h3 className="event-form__title">Доступность мероприятия</h3>
                                <SelectByGroup
                                    className="event-form__select"
                                    setAccessValues={setAccessValues}
                                    accessValues={accessValues}
                                />
                                <Availability
                                    form={formikСreateEvent}
                                    emailList={emailList}
                                    setEmailList={setEmailList}
                                />
                            </div>
                            {type === "event" && (
                                <div className={`${"event-form__questions"} ${"box-white"}`}>
                                    <h3 className="event-form__title">Вопросы для отбора участников</h3>
                                    {/* <div className="questions-list">
                                    <div className="questions-list__items">
                                        {questionsList &&
                                            questionsList.length > 0 &&
                                            questionsList.map(item => {
                                                return <QuestionItem item={item} key={item.id} />;
                                            })}
                                    </div>
                                </div> */}
                                    <CreateEvent setQuestionsList={setQuestionsList} eventRequestList={questionsList} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {canCrop && (
                <Overlay setModalStatus={setModalStatus} modalStatus={modalStatus}>
                    <LoyoutModal
                        modalStatus={modalStatus}
                        setModalStatus={setModalStatus}
                        setCanCrop={setCanCrop}
                        title="Загрузка фотографии"
                    >
                        <CropPhoto
                            photo={filesList}
                            cropRef={cropperRef}
                            getCroppedData={setCroppedData}
                            setCanCropped={setCanCrop}
                            height={212}
                            width={680}
                            setModalStatus={setModalStatus}
                            mode="create"
                        />
                    </LoyoutModal>
                </Overlay>
            )}
        </>
    );
}
