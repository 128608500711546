export class ImageLoader {
  static imageHandler(event, setInputImage, setCanCrop) {
    if (event.target && event.target.files && event.target.files[0]) {
      const imgUrl = URL.createObjectURL(event.target.files[0]);
      setInputImage(imgUrl);
      setCanCrop(true);
    } 
    else {
      let files = Array.from(event);
      const imgUrl = URL.createObjectURL(files[0]);
      setInputImage(imgUrl);
      setCanCrop(true);
    }
  }

  static dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(",");
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
}

