import "./Button.css";

export default function Button({
    color,
    size,
    callback = () => { },
    type = "button",
    text,
    className
    
}) {
    const buttonClassName = () => {
        switch (color) {
            case "blue":
                return "button button_blue";
            case "green":
                return "button button_green";
            case "red":
                return "button button_red";
            case "gray":
                return "button button_gray";
            case "transparent":
                return "button button_transparent";
            case "violet":
                return "button button_violet";
            default:
                return "button button_blue";
        }
    };

    return (
        <button
         type={type} 
         className={`${buttonClassName()} ${className}`} 
         onClick={callback}
         size={size} 
         disabled={false}>
            {text}
        </button>
    );
}
