import React, { memo } from "react";

import "./Overlay.css";

const OverlayComponent = ({ children, modalStatus, setModalStatus }) => {
  return (
    <div
      className={modalStatus === "none" ? "overlay" : "overlay overlay_active"}
      onClick={() => setModalStatus("none")}
    >
      <div className={"container center overlay__container"} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export const Overlay = memo(OverlayComponent);
