import { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { ReactComponent as SortImage } from "../../images/other/sort.svg";

import TagButton from "../../UI/TagButton/TagButton";

import "./TableComponent.css";

export default function TableComponent({ header, data }) {
    const [sortKey, setSortKey] = useState(0);
    const [sortOrder, setSortOrder] = useState("ascn");

    const nav = useNavigate();

    const sortedData = useCallback(
        () => sortData({ tableData: data, sortKey, reverse: sortOrder === "desc" }),
        [data, sortKey, sortOrder]
    );

    const changeSort = (key, order) => {
        setSortOrder(order);
        setSortKey(key);
    };

    function sortData({ tableData, sortKey, reverse }) {
        if (!sortKey) return tableData;

        const sortedData = data.sort((a, b) => {
            return a[sortKey] > b[sortKey] ? 1 : -1;
        });

        if (reverse) {
            return sortedData.reverse();
        }
        return sortedData;
    }

    const setColorTab = item => {
        const status = item.toLowerCase();
        switch (status) {
            case "event_wait":
                return "dark_blue";
            case "завершено":
                return "gray";
            case "мероприятие отменено":
                return "dark_red";
            default:
                return "black";
        }
    };

	const convertStatus = (eventStatus) => {
		
		const status = eventStatus.toLowerCase();
        switch (status) {
            case "event_wait":
                return "идет регистрация";
            case "завершено":
                return "gray";
            case "мероприятие отменено":
                return "dark_red";
            default:
                return "black";
        }
	}

    const setSort = () => {};

    useEffect(() => {
        setSort();
    }, []);

    return (
        <div className="table__container">
            <table className="table__wrapper">
                <thead>
                    <tr className="table__tr">
                        {header.map((value, index) => (
                            <th className="table__header" key={value.key}>
                                <div className="table__header__name">
                                    <div>{value.label}</div>
                                    <div className="table__header__sort">
                                        <span
                                            onClick={() => {
                                                setSortOrder("ascn");
                                                setSortKey(value.key);
                                            }}
                                        >
                                            <SortImage className="table__header__sort-asc" />
                                        </span>
                                        <span onClick={() => changeSort(value.key, "desc")}>
                                            <SortImage className="table__header__sort-des" />
                                        </span>
                                    </div>
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="table__body">
                    {data &&
                        sortedData().map((row, row_idx) => (
                            <tr className="table__tr" key={row_idx}>
                                <td className="table__td"><span onClick={() => nav(`/events/${row.slug}`)} style={{cursor: "pointer", textDecoration: "underline"}}>{row.title}</span></td>
                                <td className="table__td">{row.request_time}</td>
                                <td className="table__td">{row.responsible}</td>
                                <td className="table__td">{row.address}</td>
                                <td className="table__td">{row.acceptance_of_applications}</td>
                                <td className="table__td">
                                    <TagButton text={convertStatus(row.status)} color={setColorTab(row.status)} type={"statistics"} />
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        </div>
    );
}
