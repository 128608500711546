const initialState = {
  cardsListNo: [],
  cardsListMake: [],
  cardsListUnload: [],
  cardsListMade: [],
  cardsListIssued: [],
};

const cardsListAdmin = (state = initialState, action) => {
  switch (action.type) {
    case "getCardsListNo":
      return {
        ...state,
        cardsListNo: action.payload,
      };
    case "getCardsListMake":
      return {
        ...state,
        cardsListMake: action.payload,
      };
    case "getCardsListUnload":
      return {
        ...state,
        cardsListUnload: action.payload,
      };
    case "getCardsListMade":
      return {
        ...state,
        cardsListMade: action.payload,
      };
    case "getCardsListIssued":
      return {
        ...state,
        cardsListIssued: action.payload,
      };
    default:
      return state;
  }
};

export default cardsListAdmin;
