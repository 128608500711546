import React, { useState } from "react";
import { useSelector } from "react-redux";

import Input from "../../UI/Input/Input";

import ConvertData from "../../interface/ConvertData";

export default function GeneralInfo({ detailUser, pageType, activeTableTab }) {
    const faculties = useSelector(({ facultiesList }) => [
        { id: "", title: "Выберите регион" },
        ...facultiesList.facultiesList,
    ]);

    return (
        <div className="general-info">
            <div className="card-profile__list">
                <Input className={"user"} mode="fake" text="Email" placeholder={detailUser && detailUser.email} />
                <Input className={"user"} mode="fake" text="Регион" placeholder={detailUser && detailUser.faculty} />
                <Input
                    className={"user"}
                    mode="fake"
                    text="Орган самоуправления"
                    placeholder={
                        detailUser &&
                        ConvertData.getData(
                            "type_of_learning",
                            detailUser.type_of_learning,
                            faculties.filter(item => Number(item.id) === Number(detailUser.faculty_id))[0]
                                ?.type_of_learning
                        )
                    }
                />
                <Input
                    className={"user"}
                    mode="fake"
                    text="Телефон"
                    placeholder={detailUser && detailUser.phone_number}
                />
                <div className="input-wrapper"></div>
            </div>
        </div>
    );
}
