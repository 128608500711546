import { useState, useRef } from "react";

import SelectFilter from "../../UI/Select/SelectFilter";
import Input from "../../UI/Input/Input";
import Checkbox from "../../UI/Checkbox/Checkbox";
import AnswerField from "./AnswerField/AnswerField";

import { selectList } from "../../assets/appData/selectCreateEvent";

import { ReactComponent as CopyImg } from "../../images/create/copy.svg";
import { ReactComponent as DeleteImg } from "../../images/create/delete.svg";

import PublishImg from "../../images/create/publish.svg";
import AddImg from "../../images/create/add.svg";
import { ref } from "yup";

export default function CreateEventItem({ inputFields, setQuestionsList, setInputFields, item }) {
    const [validForm, setValidForm] = useState(false);

    const formRef = useRef();

    const publishEvent = (e, id) => {
        e.preventDefault();

        const list = inputFields.filter(i => {
            return i.id === id;
        });
        list.map(i => {
            if (i.type !== "checkbox" && i.type !== "radio") {
                if (i.question !== "") {
                    setValidForm(true);
                    // handleRemoveFields(id);
                    // setQuestionsList(prevState => [...prevState, ...list]);
                    setValidForm(false);
                }
            } else {
                if ((i.answerlist.length > 2 || i.answerlist.length === 2) && i.question !== "") {
                    if (handleValidObj(i.answerlist) === true) {
                        setValidForm(true);
                        // handleRemoveFields(id);
                        // setQuestionsList(prevState => [...prevState, ...list]);
                        setValidForm(false);
                    }
                }
            }
        });
    };

    const handleValidObj = list => {
        let valid = false;
        list.filter(function (obj) {
            var values = Object.values(obj);
            var arr2 = ["", undefined];
            if (
                arr2.some(function (val) {
                    return values.indexOf(val) >= 0;
                })
            ) {
                valid = false;
                return false;
            } else {
                valid = true;
                return true;
            }
        });

        return valid;
    };

    const handleChangeAnswerInput = (valueTarget, id, valueInputId) => {
        const newInputFields = inputFields.map(i => {
            if (id === i.id) {
                // i.type = handleSwitchType(valueTarget);
                let newAnswerlist = i.answerlist.map(i =>
                    i.id === valueInputId
                        ? {
                              ...i,
                              id: i.id,
                              value: valueTarget,
                              title: valueTarget,
                              checked: null,
                              type: "text",
                          }
                        : i
                );
                i.answerlist = newAnswerlist;
            }
            return i;
        });

        setInputFields(newInputFields);
    };

    const handleSortAnswer = (id, idAnswer, dragItem, dragOverItem) => {
        const newInputFields = inputFields.map(i => {
            if (id === i.id) {
                let sortingItems = [...i.answerlist];
                // console.log("sortingItems77", sortingItems);
                const draggedItemContent = sortingItems.splice(dragItem, 1)[0];
                // console.log("draggedItemContent", draggedItemContent);
                sortingItems.splice(dragOverItem, 0, draggedItemContent);
                // i.answerlist = sortingItems;
                //  console.log("sortingItems", sortingItems);
                dragItem = null;
                dragOverItem = null;
            }
            return i;
        });
        setInputFields(newInputFields);
    };

    const handleCopyFields = id => {
        const values = [...inputFields];
        const currentItem = values.filter(i => {
            return i.id === id;
        });
        const copyObj = JSON.parse(JSON.stringify(currentItem));
        copyObj[0].id = Date.now();
        setInputFields([...inputFields, ...copyObj]);
    };

    function getActiveAnswer(index, id, name, value) {
        const newInputFields = inputFields.map(i => {
            if (String(id) === String(i.id)) {
                i[name] = i.id;
                i.type = handleSwitchType(value);
                i.select = Number(index);
            }
            return i;
        });

        setInputFields(newInputFields);
    }

    const handleSwitchType = text => {
        switch (text) {
            case "Длинный текст":
                return "text";
            case "Один из списка":
                return "radio";
            case "Несколько из списка":
                return "checkbox";
            case "Дата":
                return "date";
            case "Время":
                return "time";
            case "Загрузка файлов":
                return "file";
            default:
                return "text";
        }
    };

    const handleRemoveFields = id => {
        const values = inputFields.filter(i => {
            return i.id !== id;
        });

        setInputFields(values);
    };

    const removeAnswer = (e, id, idAnswer) => {
        const newInputFields = inputFields.map(i => {
            if (id === i.id) {
                const newInputFields = i.answerlist.filter(item => {
                    return item.id !== idAnswer;
                });
                i.answerlist = newInputFields;
            }
            return i;
        });
        setInputFields(newInputFields);
    };

    const addNextAnswer = (type, id) => {
        const newInputFields = inputFields.map(i => {
            if (id === i.id) {
                if (type === "checkbox" || type === "radio") {
                    const idAnswer = Date.now();
                    const answerObj = {
                        id: `answer-${idAnswer}`,
                        value: "Вариант ответа",
                        title: "Вариант ответа",
                        type: "text",
                        checked: null,
                    };
                    i.answerlist = [...i.answerlist, answerObj];
                }
            }
            return i;
        });
        setInputFields(newInputFields);
    };

    const handleChangeInput = (e, id) => {
        const { name } = e.target;
        const newInputFields = inputFields.map(i => {
            if (id === i.id) {
                if (name === "answer") {
                    let arr = [];
                    const el = (i[e.target.name] = e.target.value);
                    arr.push(el);
                    i.answer = arr;
                } else if (name === "required") {
                    i[e.target.name] = !i[e.target.name];
                } else if (name === "question") {
                    i[e.target.name] = e.target.value;
                }
            }
            return i;
        });

        setInputFields(newInputFields);
    };

    return (
        <form onSubmit={e => publishEvent(e, item.id)} onClick={() => formRef.current.click()}>
            <div className="create-ev__wrapper">
                <div className="create-ev__items">
                    <div className="create-ev_item">
                        <Input
                            name="question"
                            id={item.id}
                            type="text"
                            onChange={e => handleChangeInput(e, item.id)}
                            placeholder={"Вопрос"}
                            className="create-ev__input create-ev__input-question"
                            value={item.question}
                        />
                    </div>
                    <div className="create-ev_item">
                        <SelectFilter
                            items={selectList}
                            id={item.id}
                            name={"select"}
                            svgItem={true}
                            selectedItemKey={item.select}
                            error={true}
                            callback={getActiveAnswer}
                            mode="create"
                            className={"create"}
                        />
                    </div>

                    <AnswerField
                        activeAnswer={item.type}
                        item={item}
                        handleChangeAnswerInput={handleChangeAnswerInput}
                        handleSortAnswer={handleSortAnswer}
                        options={item.answerlist}
                        removeAnswer={removeAnswer}
                        name="answer"
                        mode="create"
                    />

                    <div className="create-ev_item create-ev_item-answer">
                        {(item.type === "checkbox" || item.type === "radio") && (
                            <div
                                className="create-ev__btn create-ev__btn-answer"
                                onClick={e => addNextAnswer(item.type, item.id)}
                            >
                                <img src={AddImg} alt="добавить" />
                                Добавить следующий ответ
                            </div>
                        )}
                        <div className="create-ev_checkbox">
                            <Checkbox
                                checked={item.required}
                                callback={e => handleChangeInput(e, item.id)}
                                mode="switch"
                                id={item.id}
                                label="Вопрос"
                                icon={true}
                                name="required"
                            />
                        </div>
                        <div className="create-ev__buttons">
                            <button
                                type="submit"
                                style={{ visibility: "hidden" }}
                                ref={formRef}
                                className="create-ev__publish"
                            >
                                <img src={PublishImg} alt="опубликовать" />
                                Сохранить
                            </button>
                            <div className="create-ev__icons">
                                <div onClick={() => handleCopyFields(item.id)}>
                                    <CopyImg className="create-ev__icon" />
                                </div>

                                <div onClick={() => handleRemoveFields(item.id)}>
                                    <DeleteImg className="create-ev__icon" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}
