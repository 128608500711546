import { combineReducers } from "redux";

import profile from "./profile";
import userListAdmin from "./userListAdmin";
import cardsListAdmin from "./cardsListAdmin";
import userListChairman from "./userListChairman";
import countUsers from "./countUsers";
import facultyContacts from "./facultyContacts";
import bookkeepingListAdmin from "./bookkeepingListsAdmin";
import facultiesList from "./faculties";

const rootReducer = combineReducers({
  profile,
  userListAdmin,
  cardsListAdmin,
  userListChairman,
  countUsers,
  facultyContacts,
  bookkeepingListAdmin,
  facultiesList,
});

export default rootReducer;
