import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import CardDescription from "../../components/CardDescription/CardDescription";
import Questionnaire from "../../components/Questionnaire/Questionnaire";
import Header from "../../components/Header/Header";
import Title from "../../UI/Title/Title";

import { Functions } from "../../interface/functions";
import { REQUEST_URL } from "../../interface/config";
import { HTTP } from "../../interface/HTTP";

import "./Event.css";

export default function Event() {

    const [eventData, setEventData] = useState({
        title: "",
        image: "http://apimgu.pskovhack-test2.ru:8000/media/user/images/None_R2Gg3mc.png",
        date: "12 – 25 февраля 2023",
        time: "08:00 - 20:00",
        place: "",
        description: "",
        text: "",
        questions: [],
    });

    const { event } = useParams();

    const getEventData = async () => {
        const requestList = await HTTP.Get(REQUEST_URL.detailEvent(event));
        if (requestList.hasOwnProperty("id")){
            const questionsList = JSON.parse(requestList.questions) ?? [];
            const startDate = new Date(requestList.start_time);
            const endDate = new Date(requestList.end_time);

            setEventData({...requestList, questions: questionsList, date: `${startDate.getDate()} ${Functions.getFullMonth(startDate.getMonth() + 1)} ${startDate.getFullYear()} - ${endDate.getDate()} ${Functions.getFullMonth(endDate.getMonth() + 1)} ${endDate.getFullYear()} г.`});
        }
    };

    useEffect(() => {
        getEventData();
    }, []);

    return (
        <>
            <Header />
            <div className="page-event">
                <Title text="О мероприятии" className={"page-title"} />
                <div className="page-event__container">
                    <CardDescription event={eventData} />
                    <Questionnaire questions={eventData.questions} setEventData={setEventData} title={"Подача заявки"} eventId={eventData.id}/>
                </div>
            </div>
        </>
    );
}
