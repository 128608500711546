
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useState } from 'react';
import Cookies from "js-cookie";
import * as Yup from 'yup';

import MaskedCustomInput from '../../UI/Input/MaskedCustomInput';
import Button from '../../UI/Button/Button';
import Input from '../../UI/Input/Input';

import * as regExpList from "../../interface/RegExpDictionary";
import { REQUEST_URL } from '../../interface/config';
import { HTTP } from '../../interface/HTTP';

import close_window from '../../images/other/close_window.png'

export default function CheckUserModal(props){

    const {modalStatus, setModalStatus, setFetchStatus, fetchStatus} = props;
    const [errorSearch, setErrorSearch] = useState(false);

    const [findedUser, setFindedUser] = useState({
        first_name: "",
        last_name: "",
        email: "",
        faculty: "",
        phone_number: "",
        student_id: "",
        grade_level: "",
    });

    const navigate = useNavigate();

    function getDateExpires(days) {
        let date = new Date();
        date.setDate(date.getDate() + days);
        return date;
    }

    const switchKeyValues = values => {
        return {
            "email": values.email_check,
            "phone-number": values.phone_check,
            "student-id": values.student_ticket_check,
        }
    }

    const formikCheckUser = useFormik({
        initialValues: {
            student_ticket_check: '',
            email_check: '',
            phone_check: '',
        },
        validationSchema: Yup.object({
            student_ticket_check: Yup.string()
            .matches(regExpList.STUDENT_TICKET_REG_EXP, "Студенческий билет должен содержать только цифры")
            .min(8, "Студенческий билет должен содержать не менее 8 цифр")
            .max(8, "Студенческий билет должен содержать не более 8 цифр"),
            phone_check: Yup.string()
            .matches(regExpList.PHONE_REG_EXP, "Введите правильный номер"),
            // .required("Укажите номер телефона"),
            email_check:
            Yup.string().email('Проверьте адрес электронной почты').required('Проверьте адрес электронной почты')
        }),
        validateOnChange: false, 
        validateOnBlur: false, 
        onSubmit: async (values) => {
            const response = await HTTP.searchUser(REQUEST_URL.searchUser, switchKeyValues(values));
            if (response.hasOwnProperty("id")){
                setFindedUser(response);
                setModalStatus('responseUser');
                setErrorSearch(false);
            }
            else{
                setErrorSearch(true);
            }
        },
    })

    return(
        <>
            <div className={modalStatus === 'checkUser'? "checkUser__modal checkUser__modal_active" : "checkUser__modal"}>
                <div className="modal__close" onClick={()=> {setModalStatus('none'); Cookies.set("modalCookie", false, { expires: getDateExpires(14) });}}>
                    <img src={close_window} alt="Close"/>
                </div>
                <div className="checkUser__modal-title">
                    Проверить пользователя
                </div>
                <form onSubmit={formikCheckUser.handleSubmit}>
                    <Input {...formikCheckUser.getFieldProps('student_ticket_check')} id='student_ticket_check' type="text" placeholder="" text='Введите номер студенческого билета' required={false} className={"input-wrapper-max"}/>
                    <MaskedCustomInput {...formikCheckUser.getFieldProps('phone_check')} id='phone_check' type="tel" placeholder="+7(111) 111-11-11" text='Телефон' required={false} mask={"+7(999) 999-99-99"} className={"input-wrapper-max"}/>
                    <Input {...formikCheckUser.getFieldProps('email_check')} id='email_check' type="text" placeholder="" text='Email' required={false} className={"input-wrapper-max"}/>
                    <Button type='submit' text='ПРОВЕРИТЬ' color='green' />
                </form>
                <div className="checkUser__modal-error">
                    {formikCheckUser.touched.student_ticket_check && formikCheckUser.errors.student_ticket_check ? (<span>{formikCheckUser.errors.student_ticket_check}</span>) : null}
                    {formikCheckUser.touched.phone_check && formikCheckUser.errors.phone_check ? (<span>{formikCheckUser.errors.phone_check}</span>) : null}
                    {formikCheckUser.touched.email_check && formikCheckUser.errors.email_check ? (<span>{formikCheckUser.errors.email_check}</span>) : null}
                    {errorSearch ? 'Пользователь не найден / The user is not found' : ''}
                </div>
            </div>

            <div className={modalStatus === 'responseUser'? "checkUser__modal-response checkUser__modal-response_active": "checkUser__modal-response"}>
                <div className="modal__close" onClick={()=>setModalStatus('none')}>
                    <img src={close_window} alt="Close"/>
                </div>
                <div className="checkUser__modal-title">
                    Проверить пользователя
                </div>
                <div className="inputLabel">По указанным данным найден пользователь</div>
                <div className="checkUser__modal-response-fakeForm">
                    <Input mode='fake' text='Фамилия' placeholder={findedUser.last_name}/>
                    <div className="empty-place"></div>
                    <Input mode='fake' text='Имя' placeholder={findedUser.first_name}/>
                    <Input mode='fake' text='Телефон' placeholder={findedUser.phone_number}/>
                    <Input mode='fake' text='Email' placeholder={findedUser.email}/>
                    <Input mode='fake' text='Студенческий билет' placeholder={findedUser.student_id}/>
                    <Input mode='fake' text='Регион' placeholder={findedUser.faculty}/>
                    <Input mode='fake' text='Текущий курс' placeholder={findedUser.grade_level}/>
                    <div className="empty-place"></div>
                    <div className="inputLabel">
                        Это не вы?
                    </div>
                    <div className="checkUser__modal-button">
                    <Button type='button' color='green' text='ВОЙТИ' callback={()=>navigate('/')} />
                    <Button type='button' color='green' text='ЗАРЕГИСТРИРОВАТЬСЯ' callback={()=>setModalStatus('none')} />
                    </div>
                </div>
            </div>

            <div className={(modalStatus === 'choice') ? "modal__container-choice modal__container-choice_active" : "modal__container-choice"}>
                <div className="checkUser__modal-title">
                    Регистрация
                </div>
                <div className="modal__container-choice-description">
                    Могли ли вы ранее или на другом Регионе МГУ уже вступать в Профсоюзную организацию?
                </div>
                <div className="modal__container-choice-block">
                    <Button text='Проверить членство' callback={() =>{setModalStatus('checkUser'); Cookies.set("modalCookie", false, { expires: getDateExpires(14) });}} color='green'/>
                    <Button text='Подаю заявление впервые' callback={() =>{setModalStatus('none'); Cookies.set("modalCookie", false, { expires: getDateExpires(14) });}} color='green'/>
                </div>
            </div>
        </>
    )
}