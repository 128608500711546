import { useSelector } from "react-redux";

export default function Tab({activeTab, tabName, currentTab, setTab, userRole = ""}){

    const countNewUsers = useSelector(({ countUsers }) => countUsers.countNewUsers);
    const countApplicationUsers = useSelector(({ countUsers }) => countUsers.countApplicationUsers);

    return(
        <div
            className={
            activeTab === currentTab
                ? "tables-switch-button tables-switch-button-active"
                : "tables-switch-button"
            }
            onClick={() => {
                setTab(currentTab);
            }}
        >
            {tabName}
            {(userRole === "Председатель профкома" && tabName === 'Новые' && countNewUsers > 0) &&  (
                <span className="new-users">+{countNewUsers}</span>
            )}
            {(userRole === "Председатель профкома" && tabName === 'Заявки' && countApplicationUsers > 0) && (
                <span className="new-requests">+{countApplicationUsers}</span>
            )}
        </div>
    )
}
