import useOnclickOutside from "react-cool-onclickoutside";
import React, { useState, useRef } from "react";

import { ReactComponent as DragImg } from "../../../images/create/drag.svg";
import { ReactComponent as CloseSvg } from "../../../images/other/close.svg";

const Option = props => {
    const {
        id,
        value,
        title,
        selected,
        type,
        onChange,
        removeAnswer,
        name,
        item,
        handleChangeAnswerInput,
        handleSortAnswer,
    } = props;

    const [visibleInput, setVisibleInput] = useState(false);
    const [valueInput, setValueInput] = useState(value);

    const isChecked = value === selected;

    const dragItem = useRef(null);
    const dragOverItem = useRef(null);

    const handleRemoveOption = (e, id, idAnswer) => {
        removeAnswer(e, id, idAnswer);
    };

    const changeVisibleInput = useOnclickOutside(() => {
        setVisibleInput(false);
    });

    const handleChange = () => onChange?.(value);

    return (
        <div
            key={item.id}
            data-checked={isChecked}
            ref={changeVisibleInput}
            className="checkboxInput answer__option"
            draggable
            onDragStart={e => (dragItem.current = id)}
            onDragEnter={e => (dragOverItem.current = id)}
            onDragEnd={e => handleSortAnswer(item.id, id, dragItem.current, dragOverItem.current)}
            onDragOver={e => e.preventDefault()}
        >
            <DragImg className="create-ev__icon-grab" />
            <label className="create-ev__label" htmlFor={id}>
                <input type={type} name={name} id={id} value={value} onChange={handleChange} />

                {visibleInput ? (
                    <div className="input-wrapper  create-ev__input create-ev__input-answer">
                        <input
                            id={id}
                            name={name}
                            type="text"
                            value={valueInput}
                            onChange={e => {
                                setValueInput(e.target.value);
                                handleChangeAnswerInput(e.target.value, item.id, id);
                            }}
                        />
                    </div>
                ) : (
                    <>
                        <p className="create-ev__input-answer__label" onClick={() => setVisibleInput(!visibleInput)}>
                            {valueInput}
                        </p>
                    </>
                )}
            </label>
            <div onClick={e => handleRemoveOption(e, item.id, id)}>
                <CloseSvg className="create-ev__icon" />
            </div>
        </div>
    );
};

const RadioGroup = props => {
    const {
        item,
        options,
        name,
        type,
        selected,
        onChange,
        answerTypeText,
        setAnswerTypeText,
        removeAnswer,
        handleChangeAnswerInput,
        handleSortAnswer,
    } = props;

    const handleChange = value => onChange?.(value);

    return (
        <>
            {options &&
                options.length > 0 &&
                options.map(({ value, title, id }) => (
                    <Option
                        item={item}
                        key={id}
                        id={id}
                        groupName={name}
                        name={name}
                        value={value}
                        title={title}
                        type={type}
                        selected={selected}
                        onChange={handleChange}
                        answerTypeText={answerTypeText}
                        setAnswerTypeText={setAnswerTypeText}
                        options={options}
                        removeAnswer={removeAnswer}
                        handleChangeAnswerInput={handleChangeAnswerInput}
                        handleSortAnswer={handleSortAnswer}
                    />
                ))}
        </>
    );
};

export default React.memo(RadioGroup);
