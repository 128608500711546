import React from "react";

import { Functions } from "../../interface/functions";

export default function SocialInfo({ detailUserSocials }) {
    return (
        <div className="social-info">
            <ul className="card-profile__list">
                {detailUserSocials.length > 0 &&
                    detailUserSocials?.map(item => {
                        const newDate = new Date(item.time_create);

                        const eventDate = `${Functions.getMonthWithPrefix(
                            newDate.getDate()
                        )}.${Functions.getMonthWithPrefix(newDate.getMonth() + 1)}.${newDate.getFullYear()} г.`;

                        return (
                            <>
                                <li key={item.id}>
                                    <p className="card-profile__label">{item.date}</p>
                                    <p className="card-profile__text">{item.title}</p>
                                </li>
                                <li className="input-wrapper"></li>
                            </>
                        );
                    })}
                {detailUserSocials <= 0 && <p>Социальные программы не найдены</p>}
            </ul>
        </div>
    );
}
