import React from "react";

import MaskedCustomInput from "../../UI/Input/MaskedCustomInput";
import ShowError from "../../UI/ShowError/ShowError";
import Checkbox from "../../UI/Checkbox/Checkbox";
import Radio from "../../UI/Radio/Radio";
import Input from "../../UI/Input/Input";

import { SetFieldsStatus } from "../../interface/SetFieldsStatus";

export default function AnswerUser({ item, mode, form, getInitialValues }) {
    const handleRadioButtons = e => (form.values.radio = e.target.value);
    const getOptions = () => {
        if ((Number(item.select) === 1 || Number(item.select) === 2) && item.answerlist) {
            const itemsOptions = item.answerlist.map((i, ind) => {
                return (
                    <>
                        <div className="questionnaire__option" key={ind}>
                            <Radio
                                type={Number(item.select) === 1 ? "radio" : "checkbox"}
                                id={item.id + ind}
                                text={i.title}
                                name={item.id}
                                {...form.getFieldProps(item.id)}
                                formError={form}
                                getInitialValues={getInitialValues}
                                value={i.value}
                                disabled={(mode === "view" ? true : false)}
                                checked={
                                    Number(item.select) === 1
                                        ? String(form.values[item.id]).toLowerCase() === String(i.value).toLowerCase()
                                        : form.values[item.id]?.includes(i.value)
                                }
                            />
                        </div>
                    </>
                );
            });
            return (
                <>
                    {itemsOptions}
                    <ShowError form={form} name={item.id} />
                </>
            );
        } else if (Number(item.select) === 3) {
            return (
                <>
                    <MaskedCustomInput
                        type={item.type}
                        placeholder={"дд.мм.гггг"}
                        id={"date"}
                        mask={"99.99.9999"}
                        mode={"date"}
                        disabled={(mode === "view" ? true : false)}
                        name={item.id}
                        {...form.getFieldProps(item.id)}
                        formError={form}
                        getInitialValues={getInitialValues}
                    />
                    <ShowError form={form} name={item.id} />
                </>
            );
        } else if (Number(item.select) === 4) {
            return (
                <>
                    <div className={`${"questionnaire__time"}`}>
                        <Input
                            mode={"time"}
                            type={item.type}
                            id={item.id}
                            disabled={(mode === "view" ? true : false)}
                            placeholder={item.placeholder}
                            {...form.getFieldProps(item.id)}
                            defaultValue={""}
                            formError={form}
                            getInitialValues={getInitialValues}
                        />
                        {/* <span className="questionnaire__time-span">:</span>
                        <Input
                            mode={"time"}
                            type={item.type}
                            id={item.id}
                            placeholder={item.placeholder}
                            {...form.getFieldProps(item.id)}
                            defaultValue={""}
                            formError={form}
                            getInitialValues={getInitialValues}
                        /> */}
                    </div>
                    <ShowError form={form} name={item.id} />
                </>
            );
        } else {
            return (
                <>
                    <Input
                        type={"text"}
                        id={item.id}
                        mode="question"
                        placeholder={item.placeholder}
                        {...form.getFieldProps(item.id)}
                        defaultValue={""}
                        disabled={(mode === "view" ? true : false)}
                        formError={form}
                        getInitialValues={getInitialValues}
                    />
                    <ShowError form={form} name={item.id} />
                </>
            );
        }
    };

    return <>{getOptions()}</>;
}
