import "./Loader.css";

export default function Loader() {
  return (
    <tbody>
      <tr className="spinner-container">
        <td className="loading-spinner"></td>
      </tr>
    </tbody>
  );
}
