import useOnclickOutside from "react-cool-onclickoutside";
import { useState, useEffect } from "react";

import "./Radio.css";

export default function Radio({
    text,
    value = "",
    onChange = () => {},
    checked = null,
    required,
    onBlur = () => {},
    type = "checkbox",
    name,
    id,
    mode = "default",
    getInitialValues,
    disabled = false,
}) {
    const [visibleInput, setVisibleInput] = useState(false);
    const [valueInput, setValueInput] = useState(text);

    const changeVisibleInput = useOnclickOutside(() => {
        setVisibleInput(false);
    });

    return (
        <>
            {mode === "default" && (
                <div className="checkboxInput">
                    {checked !== null && (
                        <input
                            type={type}
                            checked={checked}
                            id={id}
                            onChange={onChange}
                            onBlur={onBlur}
                            name={name}
                            value={value}
							disabled={disabled}
                        />
                    )}
                    {checked === null && (
                        <input
                            type={type}
                            id={id}
                            onChange={onChange}
                            onBlur={onBlur}
                            name={name}
                            value={value}
                            disabled={disabled}
                        />
                    )}
                    <label htmlFor={id}>
                        {text} <span>{required ? "*" : ""}</span>
                    </label>
                </div>
            )}
            {mode === "create" && (
                <>
                    <div className="checkboxInput" ref={changeVisibleInput}>
                        <label htmlFor={id} className="create-ev__label">
                            {checked !== null && (
                                <input
                                    type={type}
                                    checked={checked}
                                    id={id}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    name={name}
                                />
                            )}
                            {checked === null && (
                                <input type={type} id={id} onChange={onChange} onBlur={onBlur} name={name} />
                            )}

                            {visibleInput ? (
                                <div className="input-wrapper  create-ev__input create-ev__input-answer">
                                    <input
                                        id={id}
                                        name={valueInput}
                                        type="text"
                                        value={valueInput}
                                        onChange={e => setValueInput(e.target.value)}
                                    />
                                </div>
                            ) : (
                                <>
                                    <p
                                        className="create-ev__input-answer__label"
                                        onClick={() => setVisibleInput(!visibleInput)}
                                    >
                                        {valueInput}
                                    </p>
                                </>
                            )}
                        </label>
                    </div>
                </>
            )}
        </>
    );
}
