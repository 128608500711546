import React, { useEffect } from "react";

import PaginationBar from "./PaginationBar";

import useDasPagination from "../../hooks/useDasPagination";

import "./Pagination.css";

export default function Pagination({
    itemsList,
    maxItemsView = 10,
    style = {},
    type = "circle",
    customPaginationCount = 0,
    barPosition = "center",
    activeTab,
    renderFn,
}) {
    const pagination = useDasPagination(itemsList, maxItemsView);

    useEffect(() => {
        if (activeTab) {
            pagination.setActivePagination(1);
        }
    }, [activeTab]);

    useEffect(() => {
        pagination.setItemsListHandler(itemsList);
    }, [itemsList, activeTab]);

    return (
        <div className={"pagination__container"}>
            <div style={style} className={"pagination__list"}>
                {pagination.currentItems.length > 0 && pagination.currentItems.map(renderFn)}
                {pagination.currentItems.length === 0 && itemsList.map(renderFn)}
            </div>
            {pagination.itemsList.length > maxItemsView && (
                <div style={{ display: "flex", justifyContent: barPosition }}>
                    <PaginationBar
                        paginationState={[pagination.activePagination, pagination.setActivePagination]}
                        itemCount={pagination.itemsList.length}
                        maxItems={maxItemsView}
                        type={type}
                        customPaginationCount={customPaginationCount}
                    />
                </div>
            )}
        </div>
    );
}
