import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import { useSelector } from "react-redux";

import openNotification from "../../components/Modals/MessagePopup";
import LoyoutModal from "../Modals/LoyoutModal/LoyoutModal";
import ShowError from "../../UI/ShowError/ShowError";
import { Overlay } from "../Modals/Overlay/Overlay";
import AnswerUser from "../AnswerUser/AnswerUser";
import Button from "../../UI/Button/Button";

import { useDisableBodyScroll } from "../../interface/useDisableBodyScroll";
import { validateUserRequest } from "../../interface/validateUserRequest";
import { REQUEST_URL } from "../../interface/config";
import { HTTP } from "../../interface/HTTP";

import "./Questionnaire.css";

export default function Questionnaire({ questions, title, setEventData, eventId, type = "event" }) {
    // mode: edit, view
    const [modalStatus, setModalStatus] = useState("none");
    const [showError, setShowError] = useState(false);
    const [requestInfo, setRequestInfo] = useState({});
    const [initial, setInitial] = useState(false);
    const [validationSchema, setValidationSchema] = useState({});
    const [mode, setMode] = useState("edit");

    const userData = useSelector(({ profile }) => profile);

    const isRequest = useRef(false);

    const navigate = useNavigate();

    useDisableBodyScroll(modalStatus);

    const formikApplication = useFormik({
        initialValues: {},
        validationSchema: validationSchema,
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async values => {
            const response = await sendRequest(values);
            if (response.id) {
                openNotification({
                    title: "Отправка заявки",
                    text: "Задача успешно выполнена",
                    type: "success",
                });
                setTimeout(() => {
                    navigate(type === "event" ? "/events" : "/social_programs_student");
                }, 3000);
            }
        },
    });

    async function sendRequest(values) {
        const response = (isRequest.current && mode == "edit") ? 
        await HTTP.updateEvent(REQUEST_URL.setUserAnswer, JSON.stringify({
            event: eventId,
            answer: JSON.stringify(values),
        }))
         :  await HTTP.Post(REQUEST_URL.setUserAnswer, {
            event: eventId,
            answer: JSON.stringify(values),
        });
        return response;
    }

    const getInitialValues = value => {
        // console.log("value", value);
        // SetFieldsStatus.returnStatus(formikApplication, value)
        // console.log('status', SetFieldsStatus.returnStatus(formikApplication, value))
    };

    useEffect(() => {
        let isEmpty = Object.entries(formikApplication.errors).length === 0;
        if (!isEmpty) {
            setShowError(true);
        } else {
            setShowError(false);
        }
    }, [formikApplication.errors]);

    const setInitailValues = (reInit = false) => {
        const values = validateUserRequest.validate(questions);
        formikApplication.setValues(values.initialValues);
        setValidationSchema(values.validationSchema);
        if (reInit) {
            formikApplication.validateForm();
        }
        setInitial(true);
    };

    const getRequestInfo = async id => {
        const response = await HTTP.Post(REQUEST_URL.getRequestInfo, { id: Number(id) });
        if (response.hasOwnProperty("answer")) {
            setRequestInfo(response);
            const parsedAnswer = JSON.parse(response.answer);
            // console.log(parsedAnswer);
            formikApplication.setValues(parsedAnswer);
            setMode("view");
        }
    };

    const cancelRequest = async () => {
        const response = await HTTP.Post(REQUEST_URL.setUserAnswer, {
            id: eventId,
            answer_status: "request_denied",
        });

        setModalStatus(false);
    };

    const includesActivityId = (activityArray, eventId) => {
        return activityArray.some(item => {
            return Number(item.id) === Number(eventId);
        });
    };

    useEffect(() => setInitailValues(), [questions]);

    useEffect(() => {
        isRequest.current =
            (type === "event" && includesActivityId(userData.event, eventId)) ||
            (type === "social" && includesActivityId(userData.social, eventId));
    }, [eventId]);

    useEffect(() => {
        if (isRequest.current && eventId && initial === true) {
            getRequestInfo(eventId);
        }
    }, [isRequest, eventId, initial]);

    return (
        <>
            <form className="questionnaire" onSubmit={formikApplication.handleSubmit}>
                <div className="questionnaire__top">
                    <p className="questionnaire__title">{title}</p>
                    {isRequest.current === true && (
                        <span className="questionnaire__span questionnaire__span-btn">Заявка подана</span>
                    )}
                </div>

                {type === "event" &&
                    questions.map((item, index) => (
                        <div className={`questionnaire__box`} key={index}>
                            <p className="questionnaire__question">{item.question}</p>
                            <div className="questionnaire__wrapper">
                                <AnswerUser
                                    mode={mode}
                                    item={item}
                                    form={formikApplication}
                                    getInitialValues={getInitialValues}
                                />
                            </div>
                        </div>
                    ))}
                {showError && <ShowError form={formikApplication} mode={"application"} />}
                <div className="questionnaire__buttons">
                    {isRequest.current === true && (
                        <>
                            {mode !== "edit" && (
                                <>
                                    <Button
                                        text={"ОТМЕНИТЬ ЗАЯВКУ"}
                                        color={"transparent"}
                                        callback={() => setModalStatus(true)}
                                    />
                                    <Button text={"РЕДАКТИРОВАТЬ"} color={"green"} callback={() => setMode("edit")} />
                                </>
                            )}
                            {mode === "edit" && (
                                <Button
                                    type="submit"
                                    text={"СОХРАНИТЬ"}
                                    color={"green"}
                                    className={"questionnaire-button"}
                                />
                            )}
                        </>
                    )}
                    {isRequest.current === false && (
                        <>
                            {type === "event" && questions.length > 0 && (
                                <Button
                                    text={"ОЧИСТИТЬ ФОРМУ"}
                                    color={"transparent"}
                                    callback={() => setInitailValues(true)}
                                />
                            )}
                            <Button
                                type="submit"
                                text={"ПОДАТЬ ЗАЯВКУ"}
                                color={"green"}
                                className={"questionnaire-button"}
                            />
                        </>
                    )}
                </div>
            </form>

            {modalStatus && (
                <Overlay setModalStatus={setModalStatus} modalStatus={modalStatus}>
                    <LoyoutModal
                        modalStatus={modalStatus}
                        setModalStatus={setModalStatus}
                        title="Отменить заявку?"
                        classNameTitle="questionnaire-modal"
                        sizeModal="loyout-modal__warning"
                    >
                        <div className="button-modal questionnaire-modal__btn">
                            <Button
                                text={"Да"}
                                color={"green"}
                                className={"questionnaire-btn"}
                                callback={() => cancelRequest()}
                            />
                            <Button
                                text={"Нет"}
                                color={"transparent"}
                                className={"questionnaire-btn"}
                                callback={() => setModalStatus(false)}
                            />
                        </div>
                    </LoyoutModal>
                </Overlay>
            )}
        </>
    );
}
