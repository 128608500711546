import * as dispathObj from "../Redux/Actions/profile.jsx";
import { useDispatch, useSelector } from "react-redux";

export function useDispathProfile(defaultValue) {
    const userData = useSelector(({ profile }) => profile);
    const dispatch = useDispatch();

    const setDispatch = valueObj => {
        for (let item in valueObj) {
            if (valueObj[item] !== userData[item] && userData[item] !== undefined) {
                dispatch(dispathObj[item](valueObj[item]));
            }
        }
    };

    return [userData, setDispatch];
}
