import CardStatistics from "./CardStatistics";

import "./CardsStatistics.css";

export default function CardsStatistics({ statisticList, className }) {
    const statisticListNames = {
        all: "Одобрено заявок",
        rejected: "Непринятые заявки",
        accepted: "Посещенные мероприятия",
        waited: "Непосещенные мероприятия",
    };

    const setCards = () => {
        if (statisticList) {
            return Object.entries(statisticList).map((item, index) => {
                return <CardStatistics key={index} value={item[1]} title={statisticListNames[item[0]]} />;
            });
        }
    };

    return (
        <>
            <div className="stats-cards">
                <div className={`${"stats-cards__list"} ${className}`}>{setCards()}</div>
            </div>
        </>
    );
}
