import { useState, useEffect, useRef, useContext } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import InputTranslate from "../../UI/InputTranslate/InputTranslate";
import MaskedCustomInput from "../../UI/Input/MaskedCustomInput";
import PhotoInput from "../../UI/PhotoInput/PhotoInput";
import CropPhoto from "../CropPhoto/CropPhoto";
import Button from "../../UI/Button/Button";
import Select from "../../UI/Select/Select";
import Input from "../../UI/Input/Input";

import { useDispathProfile } from "../../hooks/useDispathProfile";
import { REQUEST_URL, userContext } from "../../interface/config";
import * as regExpList from "../../interface/RegExpDictionary";
import CustomValidationUser from "../../interface/Validation";
import { ImageLoader } from "../../interface/ImageLoader";
import { Functions } from "../../interface/functions";
import { HTTP } from "../../interface/HTTP";

import { academic_level, COURCES_LIST } from "../../assets/academic_level";
import { type_of_learning } from "../../assets/type_of_learning";

import close_window from "../../images/other/close_window.png";
import no_image from "../../images/other/no-photo.png";

export default function ChangeProfileModalByAdmin(props) {
    const { modalStatus, setModalStatus, detailUser, changeButtonStatus, activeTab } = props;
    const { studentData, setStudentData } = useContext(userContext);

    const [dispatchObject, setDispatchObject] = useState({});
    const [updateFetch, setUpdateFetch] = useState(false);
    const [canCrop, setCanCrop] = useState(false);
    const [croppedData, setCroppedData] = useState("");
    const [courcesCounter, setCourcesCounter] = useState(1);
    const [croppedFile, setCroppedFile] = useState(detailUser.image ? detailUser.image : null);
    const [inputImage, setInputImage] = useState();

    const userData = useSelector(({ profile }) => profile);
    const faculties = useSelector(({ facultiesList }) => [
        { id: "", title: "Выберите Регион" },
        ...facultiesList.facultiesList,
    ]);

    const cropperRef = useRef(null);
    const courceRef = useRef(null);
    const inputEmail = useRef(null);
    const inputFaculty = useRef(null);
    const inputPhoneNumber = useRef(null);
    const inputStartDate = useRef(null);
    const inputNumberRzd = useRef(null);

    const date = new Date().getFullYear();

    const customDateOfBirthValidation = value => {
        if (value.length === 10) {
            const newValue = value.split("-").reverse().join(".");
            return Number(date - 14) > Number(newValue.slice(6, 10));
        }
        return false;
    };

    const customDateOfBirthValidationDate = value => {
        const newValue = value.split("-").reverse().join(".");
        if (newValue !== undefined && String(newValue).slice(9, 10) !== "_") {
            const date = new Date(String(value).split(".").reverse().join("."));
            return (
                `${Functions.getMonthWithPrefix(date.getDate())}.${Functions.getMonthWithPrefix(
                    date.getMonth() + 1
                )}.${date.getFullYear()}` === String(newValue)
            );
        }
        return false;
    };

    useEffect(() => {
        if (croppedData !== "") {
            setCroppedFile(ImageLoader.dataURLtoFile(croppedData, "user_photo.png"));
        }
    }, [croppedData]);

    useEffect(() => {
        if (croppedFile) {
            formikChangeProfile.values.image = croppedFile;
        }
    }, [croppedFile]);

    useEffect(() => {
        if (detailUser.image) {
            Functions.setUserPhoto(detailUser.image);
        }
    }, [detailUser.image]);

    const customRzdValidate = value => {
        if (activeTab === "FILLED") {
            return value !== undefined;
        } else {
            return true;
        }
    };

    const formikChangeProfile = useFormik({
        initialValues: {
            last_name: detailUser.last_name !== "" ? detailUser.last_name : "",
            last_name_translated: detailUser.last_name_translated !== "" ? detailUser.last_name_translated : "",
            first_name: detailUser.first_name !== "" ? detailUser.first_name : "",
            first_name_translated: detailUser.first_name_translated !== "" ? detailUser.first_name_translated : "",
            middle_name: detailUser.middle_name !== "" ? detailUser.middle_name : "",
            middle_name_translated: detailUser.middle_name_translated !== "" ? detailUser.middle_name_translated : "",
            date_of_birth: detailUser.date_of_birth !== "" ? detailUser.date_of_birth : "",
            sex: detailUser.sex !== "" ? detailUser.sex : "",
            email: detailUser.email !== "" ? detailUser.email : "",
            faculty: detailUser.faculty !== "" ? detailUser.faculty : "",
            type_of_learning: detailUser.type_of_learning !== "" ? detailUser.type_of_learning : "",
            academic_level: detailUser.academic_level !== "" ? detailUser.academic_level : "",
            start_date: detailUser.start_date !== "" ? detailUser.start_date : "",
            student_id: detailUser.student_id !== "" ? detailUser.student_id : "",
            phone_number: detailUser.phone_number !== "" ? detailUser.phone_number : "",
            grade_level: detailUser.grade_level !== "" ? detailUser.grade_level : "",
            end_date: detailUser.end_date !== "" ? detailUser.end_date : "",
            image: detailUser.image !== "" ? detailUser.image : no_image,
            rzd_number: detailUser.rzd_number !== "" && detailUser.rzd_number !== null ? detailUser.rzd_number : "",
            number_general:
                detailUser.number_general !== "" && detailUser.number_general !== null ? detailUser.number_general : "",
        },
        validationSchema: Yup.object({
            last_name: Yup.string()
                .required("Некорректная фамилия")
                .test("name", `Введите корректную фамилию`, value => CustomValidationUser.customNameValidation(value))
                .matches(regExpList.CYRILLIC_REG_EXP, "Возможно использование только кириллицы"),
            first_name: Yup.string()
                .required("Некорректное имя")
                .test("name", `Введите корректное имя`, value => CustomValidationUser.customNameValidation(value))
                .matches(regExpList.CYRILLIC_REG_EXP, "Возможно использование только кириллицы"),
            middle_name: Yup.string()
                .test("name", `Введите корректное отчество`, value => CustomValidationUser.customNameValidation(value))
                .matches(regExpList.CYRILLIC_REG_EXP, "Возможно использование только кириллицы"),
            last_name_translated: Yup.string()
                .required("Укажите транслит фамилии")
                .matches(regExpList.LATIN_REG_EXP, "Введите корректный транслит фамилии"),
            first_name_translated: Yup.string()
                .required("Укажите транслит имени")
                .matches(regExpList.LATIN_REG_EXP, "Введите корректный транслит имени"),
            middle_name_translated: Yup.string().matches(
                regExpList.LATIN_REG_EXP,
                "Введите корректный транслит отчества"
            ),
            date_of_birth: Yup.string()
                .required("Укажите дату рождения")
                //.matches(regExpList.DATE_OF_BIRTH_REG_EXP, "Введите корректную дату")
                .test("name2", `Введите корректную дату`, value =>
                    CustomValidationUser.customDateOfBirthValidationReg(value)
                )
                .test("name", `Введите корректную дату`, value => customDateOfBirthValidation(value))
                .test("name1", `Введите корректную дату`, value => customDateOfBirthValidationDate(value)),
            sex: Yup.string().required("Некорректный пол"),
            faculty: Yup.string().required("Укажите Регион").notOneOf(["Выберите Регион"], "Укажите Регион"),
            type_of_learning: Yup.string()
                .required("Некорректный тип обучения")
                .notOneOf(["Выберите тип обучения"], "Выберите тип обучения"),
            start_date: Yup.string("Введите полную дату")
                .test("name2", `Введите корректную дату`, value =>
                    CustomValidationUser.customDateOfBirthValidationReg(value)
                )
                .required("Некорретная дата начала обучения"),
            academic_level: Yup.string()
                .required("Некорректный уровень обучения")
                .notOneOf(["Выберите уровень обучения"], "Выберите уровень обучения"),
            grade_level: Yup.string()
                // .matches(regExpList.STUDENT_TICKET_REG_EXP, "Некорректный текущий курс")
                .required("Некорректный текущий курс")
                .notOneOf([""], "Укажите курс"),
            end_date: Yup.string("Введите полную дату")
                .required("Некорректный плановый срок окончания обучения")
                .test("name2", `Введите корректную дату`, value =>
                    CustomValidationUser.customDateValidationReg(value, regExpList.DATE_REG_EXP)
                ),
            image: Yup.mixed(),
            student_id: Yup.string()
                .min(8, "Студенческий билет должен содержать не менее 8 цифр")
                .max(8, "Студенческий билет должен содержать не более 8 цифр")
                .matches(regExpList.STUDENT_TICKET_REG_EXP, "Некорректный студенческий билет"),
            number_general: Yup.string()
                .min(20, "Номер должен содержать не менее 20 цифр")
                .max(20, "Номер должен содержать не более 20 цифр")
                .matches(regExpList.STUDENT_TICKET_REG_EXP, "Некорректный номер"),
            phone_number: Yup.string().matches(regExpList.PHONE_REG_EXP, "Некорректный номер телефона"),
            email: Yup.string().email("Некорректный email").required("Некорректный email"),

            rzd_number: Yup.string()
                .test("rzd", `Некорректный номер`, value => customRzdValidate(value))
                .min(13, "Номер РЖД должен содержать не менее 13 цифр")
                .max(13, "Номер РЖД должен содержать не более 13 цифр")
                .matches(regExpList.STUDENT_TICKET_REG_EXP, "Номер РЖД должен содержать только цифры"),
        }),
        onSubmit: async values => {
            values.image = croppedFile;
            const updateStatus = await fetchUpdateProfile(values);
            if (updateStatus) {
                for (let item of Object.entries(updateStatus)) {
                    if (item[0] === "type_of_learning") {
                        type_of_learning.map(el => {
                            if (el.id === item[1]) {
                                updateStatus.type_of_learning = el.title;
                            }
                        });
                    } else if (item[0] === "academic_level") {
                        academic_level.map(el => {
                            if (el.id === item[1]) {
                                updateStatus.academic_level = el.title;
                            }
                        });
                    }
                }
                setStudentData(updateStatus);
                setUpdateFetch(true);
                setDispatchObject(updateStatus);
            }
        },
    });

    useEffect(() => {
        if (updateFetch && dispatchObject) {
            setModalStatus("none");
        }
    }, [updateFetch, dispatchObject]);

    async function fetchUpdateProfile(values) {
        let formData = new FormData();
        for (let item of Object.entries(values)) {
            if (item[0] === "date_of_birth" || item[0] === "start_date" || item[0] === "end_date") {
                const reverse = item[1].split(".").reverse().join("-");
                formData.append(item[0], reverse);
            } else if (item[0] === "image") {
                if (item[1] === detailUser.image) {
                    continue;
                } else {
                    formData.set(`${item[0]}`, item[1]);
                }
            } else {
                formData.append(`${item[0]}`, item[1]);
            }
        }
        if (detailUser.role === "Администратор" && userData.role === "Администратор") {
            return await HTTP.Update("PATCH", false, REQUEST_URL.updateCurrentUser, formData);
        } else {
            return await HTTP.Update("PATCH", false, REQUEST_URL.updateUser(detailUser.id), formData);
        }
    }

    const dispathProfile = useDispathProfile(dispatchObject, updateFetch);

    useEffect(() => {
        if (formikChangeProfile.values.academic_level !== "") {
            const countOfYears = Functions.getCountYear(formikChangeProfile.values.academic_level);
            setCourcesCounter(Number(countOfYears + 1));
            if (formikChangeProfile.values.grade_level > countOfYears || formikChangeProfile.values.grade_level <= 0) {
                formikChangeProfile.values.grade_level = "";
            }
        } else {
            courceRef.current.selectedIndex = 0;
            formikChangeProfile.values.grade_level = "";
            setCourcesCounter(1);
        }
    }, [formikChangeProfile.values.academic_level, formikChangeProfile.values.grade_level]);

    useEffect(() => {
        if (
            formikChangeProfile.values.academic_level !== "" &&
            formikChangeProfile.values.start_date !== "" &&
            formikChangeProfile.values.academic_level !== null
        ) {
            const getStringFromDate = date => {
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, "0");
                const day = date.getDate().toString().padStart(2, "0");
                return `${year}-${month}-${day}`;
            };
            const date = new Date(formikChangeProfile.values.start_date);
            const level_academic = Functions.getCountYear(formikChangeProfile.values.academic_level);
            const end_date_old = new Date(formikChangeProfile.values.end_date);
            const dateEnd_incrice = new Date(
                date.getFullYear() + level_academic,
                end_date_old.getMonth(),
                end_date_old.getDate()
            );
            const dateEnd = getStringFromDate(dateEnd_incrice);
            formikChangeProfile.values.end_date = dateEnd;
        }
    }, [formikChangeProfile.values.start_date, formikChangeProfile.values.academic_level]);

    useEffect(() => {
        if (userData.role === "Администратор" || userData.role === "Председатель профкома") {
            if (detailUser.status !== "Новый пользователь" && detailUser.status !== "Подал заявку") {
                inputFaculty.current.style.pointerEvents = "none";
                inputFaculty.current.style.backgroundColor = "#EEF4FF";
                inputEmail.current.style.pointerEvents = "none";
                inputEmail.current.style.backgroundColor = "#EEF4FF";
                //inputPhoneNumber.current.style.pointerEvents = "none";
                inputStartDate.current.style.pointerEvents = "none";
                inputStartDate.current.style.backgroundColor = "#EEF4FF";
            } else {
                inputEmail.current.style.pointerEvents = "none";
                inputEmail.current.style.backgroundColor = "#EEF4FF";
            }
        }
    }, [userData.role, inputEmail, inputPhoneNumber, inputFaculty, inputStartDate, detailUser.status]);

    useEffect(() => {
        if (activeTab !== "FILLED") {
            inputNumberRzd.current.style.pointerEvents = "none";
            inputNumberRzd.current.style.backgroundColor = "#EEF4FF";
        }
    }, [activeTab, inputNumberRzd]);

    return (
        <>
            <div className={modalStatus !== "none" ? "modalForms modalForms_active change-modal" : "modalForms"}>
                <div className="modal-content">
                    <div
                        className="modal-close"
                        onClick={() => {
                            setModalStatus("none");
                        }}
                    >
                        <img src={close_window} alt="Close" />
                    </div>
                    <form className="modalForms-mainEdit change-modal" onSubmit={formikChangeProfile.handleSubmit}>
                        <div className={canCrop ? "modalForms-header modalForms-header__column" : "modalForms-header"}>
                            <div className="modalForms-header-image">
                                <PhotoInput
                                    id="photoInput"
                                    user_photo={inputImage ? inputImage : Functions.setUserPhoto(detailUser.image)}
                                    name="photoInput"
                                    multiple={false}
                                    form={formikChangeProfile}
                                    // mode="change_profilebyadmin"
                                    accept="image/jpeg, image/jpg, image/jfif, image/png"
                                    takeFile={e => ImageLoader.imageHandler(e, setInputImage, setCanCrop)}
                                />
                                {canCrop && (
                                    <CropPhoto
                                        photo={inputImage}
                                        cropRef={cropperRef}
                                        getCroppedData={setCroppedData}
                                        setCanCropped={setCanCrop}
                                        height={400}
                                        width={"100%"}
                                    />
                                )}
                            </div>
                            <div className="modalForms-header-info">
                                <div className="modalForms-header-title">Редактировать</div>
                            </div>
                        </div>
                        <InputTranslate
                            id="last_name"
                            defaultValue={detailUser.last_name ? detailUser.last_name : ""}
                            defaultValueTranslit={
                                detailUser.last_name_translated ? detailUser.last_name_translated : ""
                            }
                            required={true}
                            {...formikChangeProfile.getFieldProps("last_name")}
                            translitOptions={formikChangeProfile.getFieldProps("last_name_translated")}
                            type="text"
                            text="Фамилия / Last name"
                            textTranslit="Транслит поля Фамилия"
                            formError={formikChangeProfile}
                        />
                        <InputTranslate
                            id="first_name"
                            defaultValue={detailUser.first_name ? detailUser.first_name : ""}
                            defaultValueTranslit={
                                detailUser.first_name_translated ? detailUser.first_name_translated : ""
                            }
                            required={true}
                            {...formikChangeProfile.getFieldProps("first_name")}
                            translitOptions={formikChangeProfile.getFieldProps("first_name_translated")}
                            type="text"
                            text="Имя / First name"
                            textTranslit="Транслит поля Имя"
                            formError={formikChangeProfile}
                        />
                        <InputTranslate
                            id="middle_name"
                            defaultValue={detailUser.middle_name ? detailUser.middle_name : ""}
                            defaultValueTranslit={
                                detailUser.middle_name_translated ? detailUser.middle_name_translated : ""
                            }
                            required={false}
                            {...formikChangeProfile.getFieldProps("middle_name")}
                            translitOptions={formikChangeProfile.getFieldProps("middle_name_translated")}
                            type="text"
                            text="Отчество / Middle name"
                            textTranslit="Транслит поля Отчество"
                            formError={formikChangeProfile}
                        />
                        <Input
                            id="date_of_birth"
                            {...formikChangeProfile.getFieldProps("date_of_birth")}
                            type="date"
                            text="День рождения"
                            defaultValue={detailUser.date_of_birth}
                            formError={formikChangeProfile}
                        />
                        <Select
                            id="sex"
                            mode="sex"
                            text="Пол"
                            defaultValue={detailUser.sex}
                            {...formikChangeProfile.getFieldProps("sex")}
                            formError={formikChangeProfile}
                        />
                        <Input
                            id="email"
                            refInput={inputEmail}
                            {...formikChangeProfile.getFieldProps("email")}
                            type="text"
                            text="Адрес электронной почты"
                            defaultValue={detailUser.email}
                            formError={formikChangeProfile}
                        />
                        <MaskedCustomInput
                            text="Номер телефона"
                            required={true}
                            id="phone_number"
                            {...formikChangeProfile.getFieldProps("phone_number")}
                            formError={formikChangeProfile}
                            mask={"+7(999) 999-99-99"}
                        />
                        <Select
                            mode="profileFaculty"
                            required={true}
                            id="faculty"
                            refInput={inputFaculty}
                            {...formikChangeProfile.getFieldProps("faculty")}
                            text="Регион"
                            defaultValue={detailUser.faculty}
                            list={faculties}
                            formError={formikChangeProfile}
                        />
                        <Select
                            list={
                                faculties.length <= 1
                                    ? academic_level
                                    : academic_level.concat(
                                          formikChangeProfile.values.faculty === ""
                                              ? academic_level
                                              : faculties.filter(
                                                    item =>
                                                        Number(item.id) === Number(detailUser.faculty_id)
                                                )[0]?.academic_level
                                      )
                            }
                            mode="editing"
                            required={true}
                            id="academic_level"
                            {...formikChangeProfile.getFieldProps("academic_level")}
                            text="Уровень обучения"
                            defaultValue={detailUser.academic_level}
                            formError={formikChangeProfile}
                        />
                        <Select
                            mode="editing"
                            required={true}
                            list={
                                formikChangeProfile.values.faculty === ""
                                    ? type_of_learning
                                    : type_of_learning.concat(
                                          formikChangeProfile.values.faculty === ""
                                              ? type_of_learning
                                              : faculties.filter(
                                                    item => Number(item.id) === Number(detailUser.faculty_id)
                                                )[0]?.type_of_learning
                                      )
                            }
                            text="Тип обучения"
                            id="type_of_learning"
                            {...formikChangeProfile.getFieldProps("type_of_learning")}
                            formError={formikChangeProfile}
                            defaultValue={detailUser.type_of_learning}
                        />
                        <Select
                            list={
                                formikChangeProfile.values.faculty === ""
                                    ? COURCES_LIST
                                    : COURCES_LIST.concat(
                                          formikChangeProfile.values.faculty === ""
                                              ? COURCES_LIST
                                              : faculties.filter(
                                                    item => Number(item.id) === Number(detailUser.faculty_id)
                                                )[0]?.course
                                      )
                            }
                            required={true}
                            defaultValue={detailUser.grade_level}
                            text="Текущий Курс"
                            id="grade_level"
                            {...formikChangeProfile.getFieldProps("grade_level")}
                            formError={formikChangeProfile}
                            refInput={courceRef}
                        />
                        <Input
                            id="start_date"
                            refInput={inputStartDate}
                            {...formikChangeProfile.getFieldProps("start_date")}
                            type="date"
                            text="Дата начала обучения"
                            defaultValue={detailUser.start_date}
                            formError={formikChangeProfile}
                        />
                        <Input
                            id="end_date"
                            {...formikChangeProfile.getFieldProps("end_date")}
                            type="date"
                            text="Плановый срок окончания обучения"
                            defaultValue={detailUser.end_date}
                            formError={formikChangeProfile}
                        />
                        <Input
                            id="student_id"
                            {...formikChangeProfile.getFieldProps("student_id")}
                            type="text"
                            text="Студенческий билет"
                            defaultValue={detailUser.student_id}
                            formError={formikChangeProfile}
                        />
                        <Input
                            id="number_general"
                            {...formikChangeProfile.getFieldProps("number_general")}
                            type="text"
                            text="Номер Профбилета ОПО"
                            defaultValue={detailUser.number_general}
                            formError={formikChangeProfile}
                        />
                        <Input
                            id="rzd_number"
                            refInput={inputNumberRzd}
                            {...formikChangeProfile.getFieldProps("rzd_number")}
                            type="text"
                            text="Номер РЖД"
                            defaultValue={detailUser.rzd_number}
                            formError={formikChangeProfile}
                        />
                        <div className="input-wrapper"></div>
                        <Button
                            type="submit"
                            text="СОХРАНИТЬ"
                            color="blue"
                            callback={e => changeButtonStatus("SAVE")}
                        />
                    </form>
                </div>
            </div>
        </>
    );
}
