import React, { useState, useEffect } from "react";

import Input from "../../UI/Input/Input";

export default function AccountingInfo({ detailUser }) {
    const [dataInput, setDataInput] = useState("");

    const setPaymentDay = date => {
        if (date === null || date === undefined) {
            setDataInput("");
        } else {
            const newpaymentDate = date.slice(0, 10);
            setDataInput(newpaymentDate);
        }
    };

    useEffect(() => {
        setPaymentDay(detailUser.payment_datetime);
    }, [detailUser.payment_datetime]);

    return (
        <div className="accounting-info">
            <div className="card-profile__list">
                <Input mode="fake" text="Дата уплаты взносов" placeholder={dataInput} className={"user"} />
                <div className="input-wrapper"></div>
            </div>
        </div>
    );
}
