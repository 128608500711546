import React from "react";
import ErrorBox from "../ErrorBox/ErrorBox";

import "./Checkbox.css";

export default function Checkbox({
  label = "",
  mode = "checkbox",
  checked = false,
  id,
  name,
  form,
  error = false,
  callback,
  inputChange,
  text,
  icon = false,
  type="checkbox"
}) {



  return (
    <div className="DAS-checkbox">
      {/* {(text !== "") && <p className="DAS-checkbox__label">{text}</p> } */}
      <div className="DAS-checkbox-container">
        <input
          type={type}
          name={name}
          id={id}
          className={`DAS-checkbox-${mode}`}
          checked={form ? form.values[name] : checked}
          onChange={form ? form.handleChange : inputChange ? (e) => inputChange(e.target.checked) : callback}
          value=""
        />
        <label className={"DAS-checkbox-label"}>{label}{icon && <p className="DAS-checkbox-icon">*</p>}</label>
      </div>
      <div className={"DAS-checkbox-error"}>
        <ErrorBox error={error} form={form} name={name} />
      </div>
    </div>
  );
}
