import { useCallback, useState, useRef, useEffect } from "react";
import { debounce } from "lodash";

import "./InputSearch.css";

export default function InputSearch({ value, handleInputChange, text, searchText, onSearch, mode = "default" }) {
    const inputRef = useRef(null);

    const updateSearchValue = useCallback(
        debounce(searchText => onSearch(searchText), 500),
        []
    );

    const handleTextChange = searchText => {
        updateSearchValue(searchText);
    };

    const handleKeyDown = e => {
        if (e.keyCode === 27) {
            e.target.blur();
        }
    };

    // useEffect(() => {
    //   inputRef.current?.focus();
    // }, [inputRef.current]);

    return (
        <>
            {mode === "default" && (
                <div className="wrapper_input">
                    <label className="wrapper_input_label" htmlFor="">
                        {text}{" "}
                    </label>
                    <input
                        type="text"
                        value={value}
                        onChange={e => handleInputChange(e.target.value)}
                        className="wrapper_input_input"
                    />
                </div>
            )}

            {mode === "search" && (
                <div className="root">
                    <svg
                        className="icon"
                        focusable="false"
                        aria-hidden="true"
                        viewBox="0 0 24 24"
                        data-testid="SearchIcon"
                    >
                        <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"></path>
                    </svg>
                    <input
                        type="text"
                        className="input"
                        value={searchText}
                        onKeyDown={e => handleKeyDown(e)}
                        onChange={e => handleTextChange(e.target.value)}
                        ref={inputRef}
                    />
                </div>
            )}
        </>
    );
}
