import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import React from "react";

import TagButton from "../../UI/TagButton/TagButton";
import Button from "../../UI/Button/Button";

import { Functions } from "../../interface/functions";
import { MAX_CHARS } from "../../interface/config";
import { SERVER_URL } from "../../interface/HTTP";

import "./CardEvent.css";

export default function CardEvent({ item, page }) {
    const userData = useSelector(({ profile }) => profile);

    const navigate = useNavigate();

    const setColorTab = () => {
        switch (item.tab) {
            case "Заявка отклонена":
                return "red";
            case "Заявка подана":
                return "orange";
            case "Записан":
                return "orange";
            case "Участвую":
                return "green";
            case "Нет доступа":
                return "green";
            case "Посетил":
                return "blue";
            case "Не пришел":
                return "violet";
            case "Событие завершилось":
                return "turquoise";
            case "Архив":
                return "violet";
            default:
                return "middle-blue";
        }
    };

    const handlerNavigate = () => {
        if (userData.role === "Администратор") {
            if (page === "social") {
                navigate(`/social_programs_admin/${item.slug}`);
            } else {
                navigate(`/events/${item.slug}`);
            }
        } else {
            if (page === "social") {
                navigate(`/about_social_program/${item.slug}`);
            } else {
                navigate(`/events/about_event/${item.slug}`);
            }
        }
    };

    return (
        <div className="card-event">
            <div className="card-event__image">
                <img src={SERVER_URL + item.image} alt={"Название мероприятия"} />{" "}
            </div>
            <div className="card-event__content">
                <TagButton text={Functions.getEventStatus(item.status)} color={setColorTab()} />
                <p className="card-event__title">{item.title}</p>
                <p className="card-event__descr">
                    {item.description.length > MAX_CHARS
                        ? Functions.getCroppedText(item.description, MAX_CHARS)
                        : item.description}
                </p>
                <p className="card-event__location">{item.location}</p>
                <Button
                    text="Подробнее"
                    type="button"
                    color="transparent"
                    className="card-event__button"
                    callback={e => handlerNavigate()}
                />
            </div>
        </div>
    );
}
