import React from 'react';

import "./Scrollbar.css";

export default function Scrollbar({children}){
  return (
    <div className="DAS-scrollbar">
        {children}
    </div>
  )
}
