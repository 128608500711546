import MaskedCustomInput from "../../UI/Input/MaskedCustomInput";
import Input from "../../UI/Input/Input.jsx";
import Select from "../../UI/Select/Select";

import { ReactComponent as ChangeBlock } from "../../images/profile/change_block.svg";
import { ReactComponent as DeleteBlock } from "../../images/profile/delete_block.svg";
import { ReactComponent as SaveChanges } from "../../images/profile/save_changes.svg";

export default function ChangeInfo({
    mode = "default",
    startDate = null,
    endDate = null,
    organisationName = "",
    organisationType = "",
    position = "",
    region = "",
    document,
    workType = "",
    isHead = false,
    logo,
    onDelete,
}
) {


    return (
        <>
            <div className="user__additionaInfo__block">
                <div className="user__additionaInfo__container">
                    <div className="user__additionaInfo-content">
                        <div className="user__additionaInfo__line-wrapper">
                            <div className="user__additionaInfo-date-container">
                                <div className="user__additionaInfo-date-name">Год</div>

                                <div className="user__additionaInfo-date-wrapper">
                                    <div className="user__additionaInfo-date">
                                        <MaskedCustomInput
                                            text="От"
                                            type="text"
                                            required={true}
                                            id="date_of_birth"
                                            value={startDate}
                                        />
                                    </div>
                                    <div className="user__additionaInfo-date user__additionaInfo-date__divider"> - </div>
                                    <div className="user__additionaInfo-date">
                                        <MaskedCustomInput
                                            text="До"
                                            type="text"
                                            value={endDate}
                                            required={true}
                                            id="date_of_birth"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="user__additionaInfo-text  user__additionaInfo-text-name">
                                <Input
                                    id={mode + "_organisation_name"}
                                    required={true}
                                    type="text"
                                    text="Наименование организации"
                                    value={organisationName}
                                />
                            </div>

                            <div className="user__additionaInfo-text user__additionaInfo-text-position">
                                <Input
                                    id={mode + "_position"}
                                    required={true}
                                    type="text"
                                    text="Должность"
                                    value={position}

                                />
                            </div>
                        </div>
                    </div>

                    {mode === "internships" && (
                        <div className="user__participations__section__organisationType">{isHead}</div>
                    )}
                </div>
                <div className="user__additionaInfo__buttons">
                    {/* <button  type="button"><ChangeBlock /></button> */}
                    <button type="button" onClick={onDelete}><DeleteBlock  /></button>
                </div>
            </div>
        </>
    );
}
