import { PAGINATION_COUNT, MAX_CARDS } from "../../interface/config";

import { ReactComponent as Arrow_next } from "../../images/pagination/arrow.svg";

import "./Pagination.css";

export default function PaginationBar({
    paginationState,
    itemCount,
    maxItems = MAX_CARDS,
    type = "circle",
    customPaginationCount = 0
}) {
    const mainPaginationCount = customPaginationCount !== 0 ? customPaginationCount : PAGINATION_COUNT;

    const [activePagination, setActivePagination] = paginationState;

    const pageCount = Math.ceil(itemCount / maxItems);
    const paginationOffset = (mainPaginationCount - (mainPaginationCount % 2)) / 2;

    const setPaginationItem = () => {
        let returnArr = [];
        for (let index = 1; index <= pageCount; index++) {
            returnArr.push(
                <div
                    key={index}
                    onClick={() => {

                        setActivePagination(index);
                    }}
                    className={
                        activePagination === index
                            ? `${`pagination-item_${type}`} ${"pagination-item_active"}`
                            : `pagination-item_${type}`
                    }
                >
                    {index}
                </div>
            );
        }

        if (pageCount > mainPaginationCount) {
            if (activePagination > paginationOffset && activePagination < pageCount - paginationOffset) {
                returnArr = returnArr.slice(
                    activePagination - paginationOffset - (mainPaginationCount % 2),
                    activePagination + paginationOffset
                );
            } else if (activePagination <= paginationOffset) {
                returnArr = returnArr.slice(0, mainPaginationCount);
            } else if (activePagination >= pageCount - paginationOffset) {
                returnArr = returnArr.slice(pageCount - mainPaginationCount, pageCount);
            }
        }

        return returnArr;
    };

    return (
        <div className="pagination">
            <div className="pagination-wrapper">
                <div
                    className={`${"pagination-arrow"} ${"pagination-arrow_prev"} ${
                        activePagination <= 1 ? "pagination-arrow_offset" : ""
                    }`}
                    onClick={() => (activePagination !== 1 ? setActivePagination(activePagination - 1) : null)}
                >
                    <Arrow_next />
                </div>
                {setPaginationItem()}
                <div
                    className={`${"pagination-arrow"} ${"pagination-arrow_next"} ${
                        activePagination >= pageCount ? "pagination-arrow_offset" : ""
                    }`}
                    onClick={() => (pageCount !== activePagination ? setActivePagination(activePagination + 1) : null)}
                >
                    <Arrow_next />
                </div>
            </div>
        </div>
    );
}
