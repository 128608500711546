import mgu_logo_reg from "../../images/other/mgu_logo_reg.webp";

import card_address from "../../images/card/card_address.png";
import card_phone from "../../images/card/card_phone.png";
import card_site from "../../images/card/card_site.png";
import card_mail from "../../images/card/card_mail.png";
import no_image from "../../images/other/no-image.png";
import card_vk from "../../images/card/card_vk.png";

import { SERVER_URL } from "../../interface/HTTP";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Header from "../../components/Header/Header";
import CardZachet from "../../components/CardZachet/CardZachet";
import ProfkomInfo from "../../components/ProfkomInfo/ProfkomInfo";
import Button from "../../UI/Button/Button";

import "./Card.css";

export default function Card() {
    const userData = useSelector(({ profile }) => profile);
    const faculties = useSelector(({ facultiesList }) => [
        { id: "", title: "Выберите Регион" },
        ...facultiesList.facultiesList,
    ]);

    const nav = useNavigate();

    const sponsorsHandler = () => {
        nav("/sponsors");
    };

    //   console.log("userData.card", userData.card);

    return (
        <>
            <Header />
            <div className="card container-content">
                <div className="main">
                    <div className="main__account">
                        <div className="main__account-card-back">
                            <div className="card-back-header">
                                <div className="card-back-header-card">ПРОФСОЮЗНЫЙ БИЛЕТ</div>
                                <div className="card-back-header-number">
                                    <span>
                                        {typeof userData.card === "object" && userData.card !== null
                                            ? `№ ${userData.card.number}`
                                            : ""}
                                    </span>
                                </div>
                            </div>
                            <div className="card-back-content card-back-content-center">
                                <div className="card-back-content-title">
                                    <div className="card-back-content-title-top">Zachёt!</div>
                                    <div className="card-back-content-title-bottom">Дисконтная система</div>
                                </div>
                                <div className="card-back-content-image">
                                    <img src={mgu_logo_reg} alt="" />
                                </div>
                            </div>
                            <div className="card-back-footer">
                                <div className="card-back-footer-block">
                                    <div className="card-back-footer-item">
                                        <span className="card-back-footer-item-description">
                                            <img src={card_address} alt="addres" className="card-page-icons" />
                                            <span>1018а, Главное здание МГУ</span>
                                        </span>
                                    </div>
                                    <div className="card-back-footer-item">
                                        <span className="card-back-footer-item-description">
                                            <a href="tel:+7(495)-939-14-58">
                                                <img src={card_phone} alt="phone" className="card-page-icons" />
                                                <span> +7 (495)-939-14-58</span>
                                            </a>
                                        </span>
                                    </div>
                                    <div className="card-back-footer-item">
                                        <span className="card-back-footer-item-description">
                                            <a href="mailto:begomvprofcom@mail.ru">
                                                <img src={card_mail} alt="mail" className="card-page-icons" />
                                                <span> begomvprofcom@mail.ru</span>
                                            </a>
                                        </span>
                                    </div>
                                </div>

                                <div className="card-back-footer-block">
                                    <div className="card-back-footer-item">
                                        <span className="card-back-footer-item-description">
                                            <a href="https://vk.com/msuprofcom" target="_blank">
                                                <img src={card_vk} alt="vk" className="card-page-icons" />
                                                <span> @msuprofcom</span>
                                            </a>
                                        </span>
                                    </div>
                                    <div className="card-back-footer-item">
                                        <span className="card-back-footer-item-description">
                                            <a href="https://molprav.ru" target="_blank">
                                                <img src={card_site} alt="site" className="card-page-icons" />
                                                <span> msuprof.com</span>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="main__account-card-front">
                            <div className="card-back-header">
                                <div className="card-back-header-card">ПРОФСОЮЗНЫЙ БИЛЕТ</div>
                                <div className="card-back-header-number">
                                    <span>
                                        {typeof userData.card === "object" && userData.card !== null
                                            ? `№ ${userData.card.number}`
                                            : ""}
                                    </span>
                                </div>
                            </div>
                            <div className="card-back-content">
                                <div className="card-back-content-photo">
                                    <img src={userData.image ? userData.image : no_image} alt="" />
                                    <div className="card-back-content-photo-title">СТУДЕНТ/ STUDENT</div>
                                </div>
                                <div className="card-back-content-cardInfo">
                                    <div className="cardInfo-name">
                                        {`${userData.first_name} `}
                                        {`${userData.last_name} `}
                                        {`${userData.middle_name} `}/<br />
                                        {`${userData.first_name_translated} `}
                                        {`${userData.last_name_translated} `}
                                        {`${userData.middle_name_translated} `}
                                    </div>
                                    <div className="cardInfo-birth">
                                        <div className="cardInfo-birth-title">дата рождения / DATE OF BIRTH</div>
                                        <div className="cardInfo-birth-data">{userData.date_of_birth}</div>
                                    </div>
                                    <div className="cardInfo-faculty">
                                        <div className="cardInfo-faculty-title">Регион / Department</div>
                                        <div className="cardInfo-faculty-data">{userData.faculty}</div>
                                    </div>
                                </div>
                            </div>

                            <div className="card-front-footer">LOMONOSOV MOSCOW STATE UNIVERSITY ID CARD</div>
                        </div>
                        {userData.card === "Нет карты" && (
                            <div className="main__account-card-request">
                                <div className="main__account-card-request-title">
                                    Карта может быть выпущена, если пользователь заполнил все поля.
                                </div>
                            </div>
                        )}
                        {(userData.type_of_learning === "DAY_CT" || userData.type_of_learning === "EVE_CT") && (
                            <div>
                                <img
                                    src={
                                        SERVER_URL +
                                            faculties.filter(item => Number(item.id) === Number(userData.faculty_id))[0]
                                                ?.qr_code ?? ""
                                    }
                                    alt="qr-code"
                                    style={{ width: "250px", height: "250px" }}
                                />
                            </div>
                        )}

                        <div style={{marginTop: "15px"}}>
                            <Button
                                color="blue"
                                text="Просмотреть спонсоров"
                                type="button"
                                callback={() => sponsorsHandler()}
                            />
                        </div>

                        {/* <Button color='blue' text='ВЫПУСТИТЬ КАРТУ ЗАЧЁТ' type='button' /> */}
                    </div>
                    <div className="main__info">
                        <CardZachet
                                cardStatus={userData.card?.status ?? null}
                                userStatus={userData.status}
                                imgStatus={(userData.image === true || (userData.image && !userData.image.endsWith("no-image.png"))) ? true : false}
                        />
                        <ProfkomInfo user_type={userData.status} />
                    </div>
                </div>
            </div>
        </>
    );
}
