import './NotFoundPage.css'

import Header from '../../components/Header/Header'

import { useNavigate } from 'react-router-dom'
import Button from '../../UI/Button/Button'

export default function NotFoundPage({header = true}){

    const navigate = useNavigate();

    return(
        <>
            {(header) && <Header/>}
            <div className="page-404">
                <div className="page-404__wrapper">
                    <h1>404</h1>
                    <h2>Страница не найдена</h2>
                    <Button type='button' callback={()=>navigate('/')} color='green' text='ВЕРНУТЬСЯ НА ГЛАВНУЮ'/>
                </div>
            </div>
        </>
    )
}