import MUIDataTable, { TableBody } from "mui-datatables";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";

import BookkepingModal from "../Modals/BookkepingModal/BookkepingModal";
import ShowRequest from "../Modals/ShowRequest/ShowRequest";
import Loader from "../../UI/Loader/Loader";
import Button from "../../UI/Button/Button";
import Tab from "../../UI/Tab/Tab";
import { Overlay } from "../../components/Modals/Overlay/Overlay";
import LoyoutModal from "../Modals/LoyoutModal/LoyoutModal";
import CardProfile from "../CardProfile/CardProfile";

import { getBudgetListAdmin, getContractListAdmin } from "../../Redux/Actions/bookkeepingListsAdmin";
import { tablesConfig, tableLangConfig } from "../../interface/tablesConfig";
import { useDisableBodyScroll } from "../../hooks/useDisableBodyScroll";
import { REQUEST_URL, routerPath } from "../../interface/config";
import { usersParams } from "../../interface/usersParams";
import { Table } from "../../interface/Table";
import { HTTP } from "../../interface/HTTP";

import detailInfo from "../../images/other/eye_detail.svg";

import "./BookkeepingTable.css";

export default function BookkeepingTable({ typeOfLearning }) {
    const dispatch = useDispatch();
    const [isLoadingDetailUser, setIsLoadingDetailUser] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const user_role = useSelector(({ profile }) => profile.role);
    const [activeTable, setActiveTable] = useState("ENTRY");
    const [modalStatus, setModalStatus] = useState("none");
    const [detailUser, setDetailUser] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [usersListArray, setUsersListArray] = useState([]);
    const [selected, setSelected] = useState([]);
    const [buttonStatus, setButtonStatus] = useState("");
    const [paymentDatetime, setPaymentDatetime] = useState("");
    const [selectedUserId, setSelectedUserId] = useState("");
    const [isVisibleShowRequest, setIsVisibleShowRequest] = useState("none");
    const [isVisibleDetailInfo, setIsVisibleDetailInfo] = useState(false);
    const [buttonText, setButtonText] = useState("");
    const [transferUsers, setTransferUsers] = useState([]);
    const [transferDetailUser, setTransferDetailUser] = useState();

    useDisableBodyScroll(modalStatus);
    const budgetListAdmin = useSelector(({ bookkeepingListAdmin }) => bookkeepingListAdmin.budgetListAdmin);

    const contractListAdmin = useSelector(({ bookkeepingListAdmin }) => bookkeepingListAdmin.contractListAdmin);

    const textObject = {
        PROL: "Продлить",
        TRANS: "Передать в ЦБ",
        CONF: "Подтвердить оплату",
    };

    const BodyComponent = {
        TableBody: function (props) {
            if (isLoading) return <Loader />;
            return <TableBody {...props} />;
        },
    };

    const transfersId = () => {
        if (transferUsers) {
            return [
                transferUsers.map(item => {
                    return item.id;
                }),
                // transferUsers[0].status,
            ];
        }
    };

    useEffect(() => {}, [selected]);

    async function getDetailUserFetch(selectedUserId) {
        setIsLoadingDetailUser(true);
        const userInfo = await HTTP.Get(REQUEST_URL.getUser(selectedUserId));
        if (userInfo) {
            setDetailUser(userInfo);
            setIsLoadingDetailUser(false);
        }
    }

    const setBudgetAdminList = async items => {
        dispatch(getBudgetListAdmin(items));
    };

    const setContractAdminList = async items => {
        dispatch(getContractListAdmin(items));
    };

    const displayDetailInfo = dataIndex => {
        const filteredUsersList = usersList.filter(filterItem => filterItem.payment_status === activeTable);
        filteredUsersList.filter(item => {
            if (item.id === dataIndex) {
                setSelectedUserId(item.id);
                return item;
            }
        });

        // setSelectedUserId(filteredUsersList[dataIndex].id);
        getDetailUserFetch(dataIndex);
        //setDetailUser(usersList[dataIndex]);
        setModalStatus(() => {
            switch (activeTable) {
                case "ENTRY":
                    return "ENTRY_users";
                case "PROL":
                    return "user_PROL";
                case "TRANS":
                    return "user_TRANS";
                case "CONF":
                    return "user_CONF";
                default:
                    return "user_application";
            }
        });
    };

    async function usersListRequest() {
        setIsLoading(true);
        const requestList = await HTTP.Get(
            `${REQUEST_URL.getUsersList}?type-learning=${typeOfLearning === "budget" ? "budget" : "contract"}`
        );
        if (requestList) {
            switch (typeOfLearning) {
                case "budget":
                    setBudgetAdminList(requestList);
                    break;
                case "contract":
                    setContractAdminList(requestList);
                    break;
                default:
                    break;
            }
            setIsLoading(false);
        }
    }

    useEffect(() => {
        switch (typeOfLearning) {
            case "budget":
                setUsersList(budgetListAdmin);
                break;
            case "contract":
                setUsersList(contractListAdmin);
                break;
            default:
                break;
        }
    }, [budgetListAdmin, contractListAdmin]);

    const updatePaymentDatetime = date => {
        setPaymentDatetime(date);
    };

    const getPaymentButtonStatus = text => {
        switch (text) {
            case textObject.PROL.toUpperCase():
                return "PROL";
            case textObject.TRANS.toUpperCase():
                return "TRANS";
            case textObject.CONF.toUpperCase():
                return "CONF";
            default:
                return "PROL";
        }
    };

    const showModalRequest = text => {
        if (selected.length > 0) {
            setButtonText(text);
            setButtonStatus(getPaymentButtonStatus(text));
            // setPaymentDatetime();
            setIsVisibleShowRequest(true);
        }
    };

    useEffect(() => {
        if (user_role === usersParams.role.admin) {
            setButtonStatus("TRANS");
            setSelected([]);
            // usersListRequest();
        }
    }, [activeTable, user_role]);

    useEffect(() => {
        usersListRequest();
    }, [activeTable]);

    useEffect(() => {
        if (usersList && usersList.length > 0) {
            const idList = transfersId();
            // Table.remove(usersList, setUsersList, transferUsers)
            let currentReduxList = typeOfLearning === "budget" ? [...budgetListAdmin] : [...contractListAdmin];
            currentReduxList.forEach((item, index) => {
                if (idList[0].includes(item.id)) {
                    currentReduxList[index].payment_status = idList[1];
                }
            });
            if (typeOfLearning === "budget") {
                setBudgetAdminList(currentReduxList);
            } else {
                setContractAdminList(currentReduxList);
            }
            setSelected([]);
        }
    }, [transferUsers]);

    useEffect(() => {
        if (usersList.length > 0) {
            if (!tablesConfig.noRemoveFromTable.includes(buttonStatus)) {
                if (transferDetailUser.length > 0) {
                    Table.remove(usersList, setUsersList, transferDetailUser, buttonStatus);
                    setSelected([]);
                    setTransferDetailUser([]);
                }
            }
        }
    }, [transferDetailUser]);

    useEffect(() => {
        if (usersList.length > 0) {
            if (!tablesConfig.noRemoveFromTable.includes(buttonStatus)) {
                if (transferUsers.length > 0) {
                    Table.remove(usersList, setUsersList, transferUsers, buttonStatus);
                    setSelected([]);
                    //setTransferUsers([]);
                }
            }
        }
    }, [transferUsers]);

    useEffect(() => {
        setUsersListArray([
            ...Table.setTableArray(usersList, routerPath.bookkeeping).filter(function (filterItem) {
                return filterItem[6] === activeTable;
            }),
        ]);
    }, [usersList, activeTable]);

    useEffect(() => {
        setSelected([]);
        setTransferDetailUser([]);
        //setTransferUsers([]);
        //usersListRequest();
    }, [activeTable]);

    const columns = [
        {
            name: "",
            options: {
                filter: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div
                            className="detail-img"
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                displayDetailInfo(tableMeta.rowData[5]);
                            }}
                        >
                            <img src={detailInfo} width="30" height="30" align="right" alt="" />
                        </div>
                    );
                },
            },
        },
        { name: "Дата уплаты взносов", options: { filter: false } },
        { name: "Фамилия Имя", options: { filter: false } },
        // { name: "Имя", options: { filter: false } },
        //"Отчество",
        "Уровень обучения",
        "Курс",
        { name: "ID студента", options: { filter: false } },
    ];

    const options = {
        ...tablesConfig.options,
        textLabels: tableLangConfig,
        onRowSelectionChange: rowsSelected => {
            Table.setSelectedRows(
                rowsSelected,
                selected,
                setSelected,
                usersList.filter(item => item.payment_status === activeTable),
                buttonStatus
            );
        },

        onTableChange: (event, tableState) => {
            const filteredUsersList = usersList.filter(filterItem => filterItem.payment_status === activeTable);
            if (filteredUsersList.length !== usersListArray.length) {
                tableState.selectedRows.lookup = {};
                tableState.selectedRows.data = [];
            }
        },
        onCellClick: (colData, cellMeta) => {
            cellMeta.event.isDefaultPrevented = e => {
                e.preventDefault();
            };
            // if (isVisibleDetailInfo === true) {
            // if (colData.type === "div") {
            //   //displayDetailInfo(cellMeta.dataIndex);
            // }
            // }
        },
        setRowProps: (row, dataIndex) => ({
            onDoubleClick: event => {
                event.preventDefault();
                //displayDetailInfo(dataIndex);
            },
        }),
    };

    return (
        <>
            <div className="tables-switch">
                {typeOfLearning === "budget" ? (
                    <>
                        <Tab activeTab={activeTable} tabName="Вступление" currentTab="ENTRY" setTab={setActiveTable} />
                        <Tab activeTab={activeTable} tabName="Продление" currentTab="PROL" setTab={setActiveTable} />
                        <Tab
                            activeTab={activeTable}
                            tabName="Передан в ЦБ"
                            currentTab="TRANS"
                            setTab={setActiveTable}
                        />
                        <Tab
                            activeTab={activeTable}
                            tabName="Подтверждена уплата взносов"
                            currentTab="CONF"
                            setTab={setActiveTable}
                        />
                    </>
                ) : (
                    <>
                        <>
                            <Tab
                                activeTab={activeTable}
                                tabName="Вступление"
                                currentTab="ENTRY"
                                setTab={setActiveTable}
                            />
                            <Tab
                                activeTab={activeTable}
                                tabName="Продление"
                                currentTab="PROL"
                                setTab={setActiveTable}
                            />
                            <Tab
                                activeTab={activeTable}
                                tabName="Подтверждена уплата взносов"
                                currentTab="CONF"
                                setTab={setActiveTable}
                            />
                        </>
                    </>
                )}
            </div>
            <MUIDataTable
                title=""
                data={usersListArray}
                columns={columns}
                options={options}
                components={BodyComponent}
            />
            <div className="main-content">
                {typeOfLearning === "budget" ? (
                    <div className="main__control__buttons">
                        {activeTable === "ENTRY" && (
                            <>
                                <Button
                                    text={textObject.TRANS}
                                    type="button"
                                    color="green"
                                    callback={e => showModalRequest(e.target.innerText)}
                                />
                            </>
                        )}
                        {activeTable === "PROL" && (
                            <>
                                <Button
                                    text={textObject.TRANS}
                                    type="button"
                                    color="green"
                                    callback={e => showModalRequest(e.target.innerText)}
                                />
                            </>
                        )}
                        {activeTable === "TRANS" && (
                            <>
                                <Button
                                    text={textObject.CONF}
                                    type="button"
                                    color="green"
                                    callback={e => showModalRequest(e.target.innerText)}
                                />
                            </>
                        )}
                    </div>
                ) : (
                    ""
                )}
            </div>
            <ShowRequest
                setSelected={setSelected}
                modalStatus={isVisibleShowRequest}
                setModalStatus={setIsVisibleShowRequest}
                setTransferUsers={setTransferUsers}
                buttonText={buttonText}
                selected={selected}
                buttonStatus={buttonStatus}
                paymentDatetime={paymentDatetime}
            ></ShowRequest>
            {modalStatus !== "none" && (
                <Overlay setModalStatus={setModalStatus} modalStatus={modalStatus}>
                    <LoyoutModal
                        modalStatus={modalStatus}
                        setModalStatus={setModalStatus}
                        sizeModal="loyout-modal__size-large"
                    >
                        <CardProfile
                            detailUser={detailUser}
                            pageType="table"
                            activeTableTab={activeTable}
                            isLoadingDetailUser={isLoadingDetailUser}
                            selected={selected}
                            modalStatus={modalStatus}
                            setModalStatus={setModalStatus}
                            activeState={1}
                            // selectedDetailUser={detailUser}
                            setTransferDetailUser={setTransferDetailUser}
                            setButtonStatus={setButtonStatus}
                            // changeButtonStatus={changeButtonStatus}
                            // changeFetchStatus={changeFetchStatus}
                            typeOfLearning={typeOfLearning}
                        />
                    </LoyoutModal>
                </Overlay>
            )}
            {/* // <BookkepingModal 
                //     page="bookkeping"
                //     isLoadingDetailUser={isLoadingDetailUser}
                //     detailUser={detailUser}
                //     setDetailUser={setDetailUser}
                //     modalStatus={modalStatus}
                //     isVisibleDetailInfo={isVisibleDetailInfo}
                //     setIsVisibleDetailInfo={setIsVisibleDetailInfo}
                //     setModalStatus={setModalStatus}
                //     selected={selected}
                //     selectedUserId={selectedUserId}
                //     paymentDatetime={paymentDatetime}
                //     updatePaymentDatetime={updatePaymentDatetime}
                //     activeTable={activeTable}
                //     setTransferDetailUser={setTransferDetailUser}
                //     typeOfLearning={typeOfLearning}
                {/* // /> */}
        </>
    );
}
