import { useNavigate } from "react-router-dom";
import "./Button.css";

export default function GoBack({ type = "button", text, className }) {
  const navigate = useNavigate();

  return (
    <button type={type} className={`back-button ${className}`} onClick={() => navigate(-1)}>
      {text}
    </button>
  );
}
