import { ReactComponent as ErrorImg } from "../../images/other/error.svg";

import "./ShowError.css";

export default function ShowError({ form, name, mode }) {
    const showErrors = (form, name, mode) => {
        if (form !== undefined) {
            return (
                <>
                    {mode === "application" ? (
                        form.errors && form.touched ? (
                            <div className="error__wrapper">
                                <ErrorImg className="error__image" />
                                <p className="error__text">Заполните форму</p>
                            </div>
                        ) : null
                    ) : form.errors[name] && form.touched[name] ? (
                        <div style={{ color: "#9C0B26", fontSize: "14px", marginTop: "6px", marginLeft: "40px", lineHeight: "18px" }}>
                            {form.errors[name]}
                        </div>
                    ) : null}
                </>
            );
        }
    };

    return showErrors(form, name, mode);
}
