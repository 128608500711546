import { useState } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import Header from "../../components/Header/Header";
import Button from "../../UI/Button/Button";
import Input from "../../UI/Input/Input";
import LogoGroup from "../../components/LogoGroup/LogoGroup";
import Footer from "../../components/Footer/Footer";

import { ReactComponent as Success } from "../../images/other/success_message.svg";

import { HTTP } from "../../interface/HTTP";
import { Functions } from "../../interface/functions";

import { REQUEST_URL } from "../../interface/config";

import "./PasswordRecoveryPage.css";

export default function PasswordRecoveryPage() {
    const [fetchStatusString, setFetchStatusString] = useState("Ждите");
    const [fetchStatus, setFetchStatus] = useState();
    const [passwordResetErrors, setPasswordResetErrors] = useState([]);
    const [fetchErrors, setFetchErrors] = useState([]);

    const location = useLocation();

    const navigate = useNavigate();

    const setPasswordResetConfirm = async () => {
        const locationsParts = location.pathname.replace("/password-recovery/", "").split("/");
        if (locationsParts.length === 2) {
            const data = {
                uid: locationsParts[0],
                token: locationsParts[1],
                new_password: formik.values.Password,
                re_new_password: formik.values.RepeatPassword
            };
            const response = await HTTP.passwordReset(REQUEST_URL.passwordResetConfirm, data);
            if (response.status === 204) {
                setFetchStatus(true);
                setFetchStatusString("Вы успешно изменили пароль. Теперь вы можете авторизоваться");
            }
            else {
                setFetchErrors([...Functions.getErrorsList(response.data)]);
                setFetchStatusString("Ошибка сброса пароля");
            }
        }
        else {
            navigate("/");
        }
    };

    const setFetchErrorsList = list => {
        return list.map(item => {
            return <span style={{ color: "#ff0000" }}>{item}</span>
        })
    }

    const passwordRegExp = /(?=.*[0-9])(?=.*[A-z])/g;

    const formik = useFormik({
        initialValues: {
            Password: "",
            RepeatPassword: "",
        },
        validationSchema: Yup.object({
            Password: Yup.string()
                .min(8, "Пароль должен содержать не менее 8 символов")
                .matches(passwordRegExp, "Пароль должен содержать буквы и цифры")
                .required("Введите новый пароль"),
            RepeatPassword: Yup.string()
                .required("Повторите пароль")
                .oneOf([Yup.ref("Password")], "Пароли должны совпадать"),
        }),
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async (values) => {

            // const formData = new FormData();
            // for (let item of Object.entries(values)) {
            //     formData.append(item[0], item[1]);
            // }
            // const data = {
            //   new_password: values.Password, 
            //   re_new_password: values.RepeatPassword 
            // }
            // const responce = await HTTP.PostPassword(
            //     // "POST",
            //     // false,
            //     REQUEST_URL.changePassword,
            //     data
            // );
            // if (responce.id) {
            //     navigate("/");
            // } else {
            //     setPasswordResetErrors(responce);
            // }
            await setPasswordResetConfirm();
        },
    });

    const showPasswordResetErrors = (errors) => {
        let errorsArray = [];
        for (let error in errors) {
            errorsArray.push(errors[error]);
        }
        return errorsArray.map((item, index) => {
            return <div key={index}>{item}</div>;
        });
    };

    // useEffect(() => {
    //     if (formik.values.Password !== "" && formik.values.RepeatPassword !== "") {
    //         if (!formik.errors.RepeatPassword && !formik.errors.Password) {
    //             setPasswordResetConfirm();
    //         }
    //     }
    // }, [formik]);

    return (
        <>
            <div className="reset">
                <div className="main-reset">
                    <div className="main-reset-container">
                        <LogoGroup />
                        {!fetchStatus && (
                            <>
                                <div className="main-reset-container-title">Создайте новый пароль</div>
                                {/* <div className="main-reset-container-description">Придумайте новый пароль</div> */}
                                <form onSubmit={formik.handleSubmit} className="main-reset-container-form">
                                    <Input
                                        mode="login_passwordToggle"
                                        placeholder="Новый пароль"
                                        type="password"
                                        id="Password"
                                        required={true}
                                        // text="Новый пароль / New password"
                                        {...formik.getFieldProps("Password")}
                                    />
                                    <Input
                                        mode="login_passwordToggle"
                                        placeholder="Подтвердите пароль" type="password"
                                        id="RepeatPassword"
                                        required={true}
                                        // text="Повторите пароль / New password confirmation"
                                        {...formik.getFieldProps("RepeatPassword")}
                                    />
                                    <div className="main-reset-container-requirements">
                                        {/* <span>Придумайте уникальный пароль</span> */}
                                        {formik.touched.Password && formik.errors.Password ? (
                                            <span style={{ color: "#ff0000" }}>{formik.errors.Password}</span>
                                        ) : null}
                                        {formik.touched.RepeatPassword && formik.errors.RepeatPassword ? (
                                            <span style={{ color: "#ff0000" }}>{formik.errors.RepeatPassword}</span>
                                        ) : null}
                                    </div>
                                    <Button className="main-reset-container__button" text="ОТПРАВИТЬ" type="submit" color="blue" />
                                    <div className="reg__content-error">
                                        {showPasswordResetErrors(passwordResetErrors)}
                                        {setFetchErrorsList(fetchErrors)}
                                    </div>
                                </form>
                            </>
                        )}
                        {fetchStatus && (
                            <>
                                <div className="notif-container">
                                    <Success></Success>
                                    {/* <div className="main-reset-container-description">{fetchStatusString}</div> */}
                                    <div className="notif-container__title">Вы успешно<br/>восстановили пароль</div>
                                    <NavLink to="/" className="main-reset-button-return">
                                        Вернуться к <span className="main-reset-button-return-highlight">Входу</span>
                                    </NavLink>
                                </div>

                            </>
                        )}
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}
