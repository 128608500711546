import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import RouterConfig from "./routing";

import { HTTP } from "./interface/HTTP";
import { useDispathProfile } from "./hooks/useDispathProfile";
import { useDocumentTitle } from "./interface/setDocumentTitle";
import { token } from "./Redux/Actions/profile.jsx";
import { Routing } from "./interface/router";

import { REQUEST_URL, routerPath, loginContext, userContext } from "./interface/config";
import { usersParams } from "./interface/usersParams";

export default function App() {
    const currentPage = useLocation();
    const DOC_TITLE_MAIN = "Ассоциация молодёжных правительств РФ";
    const [document_title, setDoucmentTitle] = useDocumentTitle(DOC_TITLE_MAIN);

    const userData = useSelector(({ profile }) => profile);
    const [loader, setloader] = useState(false);
    const [hasToken, setHasToken] = useState(false);
    const [userLoad, setUserLoad] = useState(false);
    const [studentData, setStudentData] = useState(false);

    const [fetchStatus, setFetchStatus] = useState(false);
    const [userObj, setUserObj] = useState(false);
    const [userToken, setUserToken] = useState(localStorage.getItem("token") ?? false);

    const [profileData, setDispatch] = useDispathProfile(userObj, fetchStatus);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const getFacultyInfo = async title => {
        const response = await HTTP.Get(REQUEST_URL.getFacultyContacts(title));
        if (response.faculty_email) {
            for (let item of Object.entries(response)) {
                dispatch({ type: item[0], payload: item[1] });
            }
        }
    };

    const getFacultiesInfo = async () => {
        const response = await HTTP.GetWithOutAuth(REQUEST_URL.getFaculties);
        dispatch({ type: "facultiesList", payload: response });
    };

    async function getUser() {
        const userInfo = await HTTP.Get(REQUEST_URL.getCurrentUser);
        if (userInfo.id) {
            await setDispatch(userInfo);
            setUserObj(userInfo);
            setFetchStatus(true);
            setHasToken(true);
            await getFacultyInfo(userInfo.faculty_id);
            if (currentPage.pathname === routerPath.main_page) {
                if (userInfo.role === usersParams.role.admin || userInfo.role === usersParams.role.chairman) {
                    navigate(routerPath.table);
                } else {
                    navigate(routerPath.profile);
                }
            }
        }
        if (userInfo.detail) {
            localStorage.removeItem("token");
            dispatch(token(""));
            setUserToken(false);
            setHasToken(false);
            setUserLoad(false);
        }
        setUserLoad(true);
        setloader(true);
    }

    function setDocTitle() {
        switch (currentPage.pathname) {
            case routerPath.table:
                setDoucmentTitle("Пользователи - " + DOC_TITLE_MAIN);
                break;
            case routerPath.new_users:
                setDoucmentTitle("Новые пользователи - " + DOC_TITLE_MAIN);
                break;
            case routerPath.members:
                setDoucmentTitle("Члены АМП - " + DOC_TITLE_MAIN);
                break;
            case routerPath.excluded:
                setDoucmentTitle("Исключенные - " + DOC_TITLE_MAIN);
                break;
            case routerPath.requests:
                setDoucmentTitle("Заявки - " + DOC_TITLE_MAIN);
                break;
            case routerPath.bookkeeping:
                setDoucmentTitle("Бухгалтерия - " + DOC_TITLE_MAIN);
                break;
            case routerPath.statistics:
                setDoucmentTitle("Статистика - " + DOC_TITLE_MAIN);
                break;
            case routerPath.card_admin:
                setDoucmentTitle("Карта Zachёt - " + DOC_TITLE_MAIN);
                break;
            case routerPath.profile:
                setDoucmentTitle("Личные данные - " + DOC_TITLE_MAIN);
                break;
            case routerPath.documents:
                setDoucmentTitle("Документы - " + DOC_TITLE_MAIN);
                break;
            case routerPath.card:
                setDoucmentTitle("Карта Zachёt - " + DOC_TITLE_MAIN);
                break;
            case routerPath.rzd_bonus:
                setDoucmentTitle("РЖД - " + DOC_TITLE_MAIN);
                break;
            case routerPath.rzd_bonus_profile:
                setDoucmentTitle("РЖД - " + DOC_TITLE_MAIN);
                break;
            case routerPath.events:
                setDoucmentTitle("Мероприятия - " + DOC_TITLE_MAIN);
                break;
            case routerPath.sponsors:
                setDoucmentTitle("Спонсоры - " + DOC_TITLE_MAIN);
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if (localStorage.getItem("token")) {
            dispatch(token(localStorage.getItem("token")));
        }
    }, []);

    useEffect(() => {
        if (userToken !== false) {
            getUser();
        } else {
            setloader(true);
        }
    }, [userToken]);

    useEffect(() => {
        if (userLoad) {
            Routing.routing(userData, location.pathname, navigate);
        }
    }, [userLoad]);

    useEffect(() => {
        getFacultiesInfo();
    }, []);

    useEffect(() => {
        setDocTitle(currentPage.pathname);
    }, [currentPage.pathname]);

    return (
        <loginContext.Provider value={{ setUserToken }}>
            <userContext.Provider value={{ studentData, setStudentData }}>
                {loader ? <RouterConfig hasToken={hasToken} /> : null}
            </userContext.Provider>
        </loginContext.Provider>
    );
}

/*
  Страница documents, card доступны только ученику
  table - админ
  card_admin - админ
  new_users, requests, members, excluded - только председатель
*/
