import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Button from "../../../UI/Button/Button";

import { UserActions } from "../../../interface/userActions";
import { Functions } from "../../../interface/functions";
import { REQUEST_URL } from "../../../interface/config";
import { HTTP } from "../../../interface/HTTP";

import close_window from "../../../images/other/close_window.png";

import "./ShowRequest.css";

export default function ShowRequest({
  modalStatus,
  setModalStatus,
  buttonText,
  selectedCountId,
  selected,
  setSelected,
  buttonStatus,
  secondStatus,
  setTransferUsers = false,
  paymentDatetime,
  rzdNumber,
  changeFetchStatus,
}) {
  const [textUser, setTextUser] = useState("");
  const userData = useSelector(({ profile }) => profile);

  const paymentStats = ["PROL", "TRANS", "CONF"];

  const setClose = () => {
    setModalStatus("none");
  };

  const updateUserClose = () => {
    setModalStatus("none");
  };

  const updateUser = async () => {

    if (
      buttonStatus === "NO" ||
      buttonStatus === "MAKE" ||
      buttonStatus === "MADE" ||
      buttonStatus === "UNLOAD" ||
      buttonStatus === "ISSUED"
    ) {
      const resp = await UserActions.updateCard(selected, buttonStatus);
      if (resp && setTransferUsers) {
        setTransferUsers(selected);
      }
    } else if (buttonStatus === "DELETE") {
      const resp = UserActions.delete(selected, changeFetchStatus);
      if (resp && setTransferUsers) {
        setTransferUsers(selected);
      }
    } else if (buttonStatus === "STATEMENT") {
      const userApplicationForm = await HTTP.PostFormForRequest(
        REQUEST_URL.getApplicationFormForAdmin,
        JSON.stringify({ ids: UserActions.getUsersIds(selected) })
      );
    } else if (buttonStatus === "DOWNLOAD_PHOTO") {
      const userApplicationForm = await HTTP.PostFormForRequest(
        REQUEST_URL.zipImageUsers,
        JSON.stringify({ ids: UserActions.getUsersIds(selected) })
      );
    } else if (paymentStats.includes(buttonStatus)) {
      if (paymentDatetime === "" || paymentDatetime === undefined) {
        let currentDateTime = new Date().toISOString();
        let newFormatDateTime = currentDateTime.slice(0, 10);
        const resp = UserActions.updateStatus(selected, buttonStatus, newFormatDateTime);
        if (resp && setTransferUsers) {
          setTransferUsers(selected);
        }
      } else {
        const resp = UserActions.updateStatus(selected, buttonStatus, paymentDatetime);
        if (resp && setTransferUsers) {
          setTransferUsers(selected);
        }
      }
    } else if (buttonStatus === "PASSED") {
      const resp = UserActions.updateStatus(selected, buttonStatus, paymentDatetime, rzdNumber);
      if (resp && setTransferUsers) {
        setTransferUsers(selected);
      }
    } else if (userData.role === "Администратор" || userData.role === "Председатель профкома") {
      const resp = UserActions.update(selected, buttonStatus, (secondStatus = false), changeFetchStatus);
      if (resp && setTransferUsers) {
        setTransferUsers([...selected]);
      }
    } else {
      const resp = UserActions.update(selected, buttonStatus, secondStatus);
    }
    setModalStatus("none");
    // if (!tablesConfig.noRemoveFromTable.includes(buttonStatus)) {
    //   setSelected([])
    // }
  };

  useEffect(() => {
    if (selected && selected.length) {
      if (selected.length > 1) {
        setTextUser("выбранных пользователей");
      } else if (selected.length === 1) {
        setTextUser("выбранного пользователя");
      }
    }
  }, [selected]);

  return (
    <div className="modals-wrapper">
      <div className="modal__container" style={{ display: modalStatus !== "none" ? "block" : "none" }}></div>
      <div className={modalStatus !== "none" ? "modalForms modalForms_active" : "modalForms"}>
        <div className="confirm-modal__close" onClick={setClose}>
          <img src={close_window} alt="" />
        </div>
        <div className="confirm-modal__container">
          <div className="confirm-modal__subtitle">
            Вы уверены, что хотите {buttonText === "заявление" ? "скачать заявления" : buttonText.toLowerCase()}{" "}
            {Functions.delimeterPrice(selected.length)} {textUser}?
          </div>
          <div className="confirm-modal__button">
            <div className="confirm-modal__button-size">
              <Button text="Да" type="button" color="green" callback={updateUser} />
            </div>
            <div className="confirm-modal__button-size">
              <Button text="НЕТ" type="button" color="red" callback={updateUserClose}></Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
