const initialState = {
  usersListChairmanNew: [],
  usersListChairmanAppL: [], //подал заявку
  usersListChairmanMember: [],
  usersListChairmanExclude: []
};

const userListChairman = (state = initialState, action) => {
  switch (action.type) {
    case "getUsersListChairmanNew":
      return {
        ...state,
        usersListChairmanNew: action.payload,
      };
    case "getUsersListChairmanAppL":
      return {
        ...state,
        usersListChairmanAppL: action.payload,
      };
    case "getUsersListChairmanMember":
      return {
        ...state,
        usersListChairmanMember: action.payload,
      };
    case "getUsersListChairmanExclude":
      return {
        ...state,
        usersListChairmanExclude: action.payload,
      };
    default:
      return state;
  }
};

export default userListChairman;
