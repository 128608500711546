import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import Calendar from "../../components/Calendar/Calendar";
import SelectFilter from "../../UI/Select/SelectFilter";
import InputSearch from "../../UI/Input/SearchInput";
import NotContent from "../NotContent/NotContent";
import Pagination from "../Pagination/Pagination";
import CardEvent from "../CardEvent/CardEvent";
import Title from "../../UI/Title/Title";
import Tabs from "../Tabs/Tabs";

import { Functions } from "../../interface/functions";
import { useFilter } from "../../hooks/useFilter";

import { type_of_social_admin } from "../../assets/appData/listSocialPrograms";
import { type_of_events_admin } from "../../assets/appData/eventList";
import { type_of_events } from "../../assets/type_of_events";
import { type_of_social } from "../../assets/type_of_social";

import "./CardsList.css";

export default function CardsList({ type, cardsList, filters, title, page, socialListUser }) {
    const user_role = useSelector(({ profile }) => profile.role);
    const userData = useSelector(({ profile }) => profile);

    const renderSelectList = () => {
        let listSelect = [];
        if (user_role === "Администратор") {
            if (page === "social") {
                listSelect = type_of_social_admin;
            } else {
                listSelect = type_of_events_admin;
            }
        } else {
            if (page === "social") {
                listSelect = type_of_social;
            } else {
                listSelect = type_of_events;
            }
        }
        return listSelect;
    };

    const [activeTab, setActiveTab] = useState(0);
    const [activeSelect, setActiveSelect] = useState(0);
    const [renderList, setRenderList] = useState([]);
    const [dateRange, setDateRange] = useState([Functions.setWeekAgo(), new Date()]);
    const [startDate, endDate] = dateRange;
    const [selectList, setSelectList] = useState(renderSelectList());

    const { enteredSearchValue, setEnteredSearchValue, availablesItems } = useFilter(cardsList, "");

    const getActiveTab = index => {
        setActiveTab(index);
    };

    const getActiveSelect = index => {
        setActiveSelect(index);
    };

    const searchCurrentEvent = (eventsList, eventId) => {
        return eventsList.some(item => item.id === eventId);
    };

    const getRenderList = (list, type, sortList) => {
        const filter = filters[activeTab].toLowerCase();
        const filterList = list.filter(item => item.type_events.toLowerCase() === filter);
        const sort = sortList[activeSelect];

        if (filters[activeTab].toLowerCase() === "мои события" || filters[activeTab].toLowerCase() === "мои заявки") {
            if (sort.toLowerCase() === "все") {
                setRenderList(list.filter(item => searchCurrentEvent(userData.event, item.id)));
            } else {
                setRenderList(
                    list.filter(item => {
                        if (sort.toLowerCase() === "регистрация") {
                            return (
                                searchCurrentEvent(userData.event, item.id) &&
                                Functions.getEventStatus(item.status).toLowerCase().includes("регистрация")
                            );
                        } else {
                            return (
                                searchCurrentEvent(userData.event, item.id) &&
                                Functions.getEventStatus(item.status).toLowerCase() === sort.toLowerCase()
                            );
                        }
                    })
                );
            }
        } else {
            if (sort.toLowerCase() === "все") {
                setRenderList(filterList);
            } else {
                setRenderList(
                    [...filterList].filter(i => {
                        if (sort.toLowerCase() === "регистрация") {
                            return Functions.getEventStatus(i.status).toLowerCase().includes("регистрация");
                        } else {
                            return Functions.getEventStatus(i.status).toLowerCase() === sort.toLowerCase();
                        }
                    })
                );
            }
        }
    };

    const getRenderListSocial = (list, type, sortList) => {
        let filterList = [];
        const sort = sortList[activeSelect];

        activeTab === 0 ? (filterList = list) : (filterList = Functions.filterArr(list, socialListUser));

        if (sort.toLowerCase() === "все") {
            setRenderList(filterList);
        } else {
            setRenderList(
                filterList.filter(i => {
                    return i.status === sort;
                })
            );
        }
    };

    const getRenderListAdmin = (list, sortList) => {
        const sort = sortList[activeSelect];
        if (sort.toLowerCase() === "все") {
            setRenderList(list);
        } else {
            setRenderList(
                [...list].filter(i => {
                    if (sort.toLowerCase() === "регистрация") {
                        return Functions.getEventStatus(i.status).toLowerCase().includes("регистрация");
                    } else {
                        return Functions.getEventStatus(i.status).toLowerCase() === sort.toLowerCase();
                    }
                })
            );
        }
    };

    useEffect(() => {
        if (userData.id !== null) {
            if (user_role !== "Администратор") {
                if (page === "social") {
                    getRenderListSocial(availablesItems, type, selectList);
                } else {
                    getRenderList(availablesItems, type, selectList);
                }
            } else {
                getRenderListAdmin(availablesItems, selectList);
            }
        }
    }, [activeTab, availablesItems, activeSelect, user_role, userData]);

    return (
        <section className="cards-list">
            <Title text={title} className="cards-list__title" />
            {user_role === "Администратор" && (
                <div className="cards-list__filter">
                    <InputSearch
                        type="search"
                        placeholder="Поиск"
                        value={enteredSearchValue}
                        onChange={e => {
                            setEnteredSearchValue(e.target.value);
                        }}
                    />
                    <SelectFilter
                        items={selectList}
                        id={"type_of_events_admin"}
                        name={"type_of_events_admin"}
                        except={true}
                        selectedItemKey={activeSelect}
                        error={true}
                        callback={getActiveSelect}
                        text={"Фильтровать по:"}
                    />
                    {type !== "social" ? (
                        <Calendar dateRangeState={[dateRange, setDateRange]} startDateState={[startDate, endDate]} />
                    ) : (
                        <></>
                    )}
                </div>
            )}
            {user_role !== "Администратор" && user_role !== "Председатель" && type === "events" && (
                <div className="cards-list__filter-student">
                    <Tabs tabsLabelList={filters} callback={getActiveTab} active={activeTab} type="white_box" />
                    <SelectFilter
                        items={selectList}
                        id={"type_of_events"}
                        name={"type_of_events"}
                        except={true}
                        selectedItemKey={activeSelect}
                        error={true}
                        callback={getActiveSelect}
                        text={"Фильтровать по:"}
                    />
                </div>
            )}
            {user_role !== "Администратор" && user_role !== "Председатель" && type === "social" && (
                <>
                    <Tabs tabsLabelList={filters} callback={getActiveTab} active={activeTab} />
                    <div className="cards-list__filter">
                        <InputSearch
                            type="search"
                            placeholder="Поиск"
                            value={enteredSearchValue}
                            onChange={e => setEnteredSearchValue(e.target.value)}
                        />
                        <SelectFilter
                            items={selectList}
                            id={"type_of_social"}
                            name={"type_of_social"}
                            except={true}
                            selectedItemKey={activeSelect}
                            error={true}
                            callback={getActiveSelect}
                            text={"Фильтровать по:"}
                        />
                    </div>
                </>
            )}
            {renderList && renderList.length > 0 ? (
                <Pagination
                    itemsList={renderList}
                    maxItemsView={8}
                    customPaginationCount={4}
                    activeTab={activeTab}
                    renderFn={(item, index) => {
                        return <CardEvent item={item} key={index} type={type} page={page} />;
                    }}
                />
            ) : (
                <NotContent text={"Здесь еще пока нет доступных"} type={type} />
            )}
        </section>
    );
}
