import { useState, useEffect, useRef } from "react";

import { HTTP } from "../interface/HTTP";

export default function useDasPagination(
    initialItems,
    offset,
    useDjangoPagination = false,
    link = null,
    setIsLoading = null
) {
    const [itemsList, setItemsList] = useState(initialItems);
    const [activePagination, setActivePagination] = useState(1);
    const [currentItems, setCurrentItems] = useState([]);
    const [paginationConfig, setPaginationConfig] = useState({
        count: 0,
        next: null,
        previous: null,
    });

    const previousState = useRef(1);

    const setPaginationConfigHandler = (count, next, previous) => {
        setPaginationConfig({
            count: count,
            next: next,
            previous: previous,
        });
    };

    const setItemsListHandler = items => {
        setItemsList(items);
    };

    const getPaginationRequest = async (link, setIsLoading, server = true) => {
        if (useDjangoPagination && link) {
            setIsLoading(true);
            const response = await HTTP.Get(link, server);
            if (response?.results?.length > 0) {
                setPaginationConfigHandler(response.count, response.next, response.previous);
                setCurrentItems(response.results);
            } else {
                setCurrentItems([]);
            }
            setIsLoading(false);
        }
    };

    const delimeterLink = str => {
        if (str) {
            const splitString = str.split("?");
            if (splitString.length > 0) {
                return splitString[0] + "?" + splitString[1] + `&page=${activePagination}`;
            } else {
                return splitString[0] + `?page=${activePagination}`;
            }
        } else {
            return "";
        }
    };

    useEffect(() => {
        setItemsList(initialItems);
    }, [initialItems]);

    useEffect(() => {
        if (useDjangoPagination && link) {
            getPaginationRequest(link, setIsLoading);
        }
    }, []);

    useEffect(() => {
        if (useDjangoPagination && setIsLoading) {
            if (previousState.current !== activePagination) {
                getPaginationRequest(
                    delimeterLink(paginationConfig.previous ?? paginationConfig.next ?? ""),
                    setIsLoading,
                    false
                );
            } else {
                setCurrentItems(itemsList);
            }
        } else {
            setCurrentItems([
                ...itemsList.slice((activePagination - 1) * offset, (activePagination - 1) * offset + offset),
            ]);
        }
        previousState.current = activePagination;
    }, [activePagination, itemsList]);

    return {
        itemsList,
        setItemsListHandler,
        activePagination,
        setActivePagination,
        currentItems,
        getPaginationRequest,
        paginationItemsCount: paginationConfig.count,
    };
}
