import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";

import Header from "../../components/Header/Header";
import Button from "../../UI/Button/Button";
import Input from "../../UI/Input/Input";
import LogoGroup from "../../components/LogoGroup/LogoGroup";
import Footer from "../../components/Footer/Footer";
import { ReactComponent as EmailLogo } from "../../images/other/email-send_icon.svg";
import { ReactComponent as FailLogo } from "../../images/other/fail_logo.svg";



import { SetFieldsStatus } from "../../interface/SetFieldsStatus";
import { token } from "../../Redux/Actions/profile.jsx";
import { REQUEST_URL } from "../../interface/config";
import { HTTP } from "../../interface/HTTP";

import "./PasswordResetPage.css";

export default function PasswordResetPage() {
    const [userEmailStatus, setUserEmailStatus] = useState(true);
    const [errors, setErrors] = useState([]);
    const [fetchStatus, setFetchStatus] = useState(false);
    const tokenDispatch = useDispatch();

    const navigate = useNavigate();
    const setToken = async tokenItem => {
        tokenDispatch(token(tokenItem));
    };
    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Неверный адрес электронной почты").required("Введите email"),
        }),
        validateOnChange: false,
        validateOnBlur: false,
        onSubmit: async values => {
            setFetchStatus(true);
            //   const formData = new FormData();
            //   for (let item of Object.entries(values)) {
            //     formData.append(item[0], item[1]);
            //   }
            const responce = await HTTP.PostPassword(REQUEST_URL.passwordReset, {
                email: values.email,
            });
            if (!responce.auth_token) {
                console.error("Token is not found");
                setUserEmailStatus(false);
            }
        },
    });

    return (
        <>
            <div className="reset">
                {!fetchStatus && (
                    <div className="main-reset">
                        <div className="main-reset-container">
                            <LogoGroup />
                            <div className="main-reset-container-title">Сброс пароля</div>
                            <div className="main-reset-container-description">
                                Укажите адрес электронной почты  и мы отправим вам ссылку для сброса пароля.
                            </div>
                            <form onSubmit={formik.handleSubmit} className="main-reset-container-form">
                                <Input
                                    id="email"
                                    name="email"
                                    type="text"
                                    placeholder="email"
                                    formError={formik}
                                    required={true}
                                    status={SetFieldsStatus.returnStatus(formik, "middle_name")}
                                    {...formik.getFieldProps("email")}
                                />

                                <div className="main-reset-button">
                                    <Button text="ОТПРАВИТЬ" type="submit" color="blue" />
                                    <NavLink to="/" className="main-reset-button-return">
                                        Вернуться к <span className="main-reset-button-return-highlight">Входу</span>
                                    </NavLink>
                                </div>
                            </form>
                        </div>
                    </div>
                )}
                {fetchStatus && (
                    <div className="main-reset">
                        <div className="main-reset-container">
                            {userEmailStatus && (
                                <>
                                    <LogoGroup/>
                                    <div className="notif-container">
                                        <EmailLogo />
                                        <div className="notif-container__title">Проверьте свою электронную почту</div>
                                        <div className="main-reset-container-description main-reset-container-description-sent">
                                            Мы отправили вам ссылку для сброса пароля.
                                        </div>
                                        <NavLink to="/" className="main-reset-button-return">
                                            Вернуться к <span className="main-reset-button-return-highlight">Входу</span>
                                        </NavLink>
                                    </div>
                                </>
                            )}
                            {!userEmailStatus && (
                                <>
                                    <LogoGroup/>
                                    <div className="notif-container">
                                        <FailLogo />
                                        <div className="notif-container__title">Ошибка сброса пароля</div>
                                        <div className="main-reset-container-description main-reset-container-description-sent">
                                            Не найдено пользователей с таким email
                                        </div>
                                        <NavLink to="/" className="main-reset-button-return">
                                            Вернуться к <span className="main-reset-button-return-highlight">Входу</span>
                                        </NavLink>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )}
                <Footer />
            </div>
        </>
    );
}
