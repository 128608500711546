import { useState } from "react";

import ChangeProfileModalByAdmin from "../ChangeProfileModalByAdmin";
import AddProfileModal from "../AddProfileModal/AddProfileModal";
import AddEducationModal from "../AddEducationModal/AddEducationModal";
import AddInternshipModal from "../AddInternshipModal/AddInternshipModal";
import AddWorkModal from "../AddWorkModal/AddWorkModal";
import AddMSUModal from "../AddMSUModal/AddMSUModal";
import ChangeProfileModal from "../ChangeProfileModal";
import CheckUserModal from "../CheckUserModal";
import OtherModals from "../OtherModals";

import "./Modals.css";

export default function Modals({
    page,
    selected,
    setDetailUser,
    selectedUserId,
    selectedUserStatus = false,
    selectedDetailUser,
    modalStatus,
    activeTab,
    setModalStatus,
    detailUser = false,
    setTransferDetailUser,
    isLoadingDetailUser,
    setButtonStatus,
    changeButtonStatus,
    changeFetchStatus,
}) {
    const [fetchStatus, setFetchStatus] = useState(false);

    return (
        <>
            <div className="modals-wrapper">
                {page === "registration" ? (
                    <>
                        {(modalStatus === "choice" ||
                            modalStatus === "checkUser" ||
                            modalStatus === "responseUser") && (
                            <>
                                <div className="modal__container">
                                    <CheckUserModal
                                        modalStatus={modalStatus}
                                        setModalStatus={setModalStatus}
                                        setFetchStatus={setFetchStatus}
                                        fetchStatus={fetchStatus}
                                    />
                                </div>
                            </>
                        )}
                    </>
                ) : (
                    ""
                )}
                {page == "add_education" && (
                    <>
                        {modalStatus !== "none" && <div className="modal__container"></div>}
                        {modalStatus === "add_education" ? (
                            <AddEducationModal modalStatus={modalStatus} setModalStatus={setModalStatus} />
                    
                        )  : (

                            ""
                        )}
                    </>
                )}
                {page == "add_work" && (
                    <>
                        {modalStatus !== "none" && <div className="modal__container"></div>}
                        {modalStatus === "add_work" ? (
                            <AddWorkModal modalStatus={modalStatus} setModalStatus={setModalStatus} />
                    
                        )  : (

                            ""
                        )}
                    </>
                )}
                {page == "add_msu" && (
                    <>
                        {modalStatus !== "none" && <div className="modal__container"></div>}
                        {modalStatus === "add_msu" ? (
                            <AddMSUModal modalStatus={modalStatus} setModalStatus={setModalStatus} />
                    
                        )  : (

                            ""
                        )}
                    </>
                )}
                {page == "add_internship" && (
                    <>
                        {modalStatus !== "none" && <div className="modal__container"></div>}
                        {modalStatus === "add_internship" ? (
                            <AddInternshipModal modalStatus={modalStatus} setModalStatus={setModalStatus} />
                    
                        )  : (

                            ""
                        )}
                    </>
                )}
                {(page !== "add_education" && page !== "add_work" && page !=="add_msu" && page !=="add_internship")   && (
                    <>
                        {modalStatus !== "none" && <div className="modal__container"></div>}
                        {modalStatus === "change_profile" ? (
                            <ChangeProfileModal
                                modalStatus={modalStatus}
                                setModalStatus={setModalStatus}
                                detailUser={detailUser}
                            />
                        ) : modalStatus === "add_education" ? (
                            <AddEducationModal modalStatus={modalStatus} setModalStatus={setModalStatus} />
                        ) : modalStatus === "add_profile" ? (
                            <AddProfileModal modalStatus={modalStatus} setModalStatus={setModalStatus} />
                        ) : modalStatus === "change_profilebyadmin" ? (
                            <ChangeProfileModalByAdmin
                                modalStatus={modalStatus}
                                setModalStatus={setModalStatus}
                                detailUser={detailUser}
                                changeButtonStatus={changeButtonStatus}
                                activeTab={activeTab}
                                changeFetchStatus={changeFetchStatus}
                            />
                        )  : (

                            <OtherModals
                                activeTab={activeTab}
                                setButtonStatus={setButtonStatus}
                                detailUser={detailUser}
                                setDetailUser={setDetailUser}
                                isLoadingDetailUser={isLoadingDetailUser}
                                modalStatus={modalStatus}
                                setModalStatus={setModalStatus}
                                setFetchStatus={setFetchStatus}
                                changeFetchStatus={changeFetchStatus}
                                selectedDetailUser={selectedDetailUser}
                                selected={selected}
                                selectedUserId={selectedUserId}
                                selectedUserStatus={selectedUserStatus}
                                setTransferDetailUser={setTransferDetailUser}
                            />
                        )}
                    </>
                )}
            </div>
        </>
    );
}
