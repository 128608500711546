import React from "react";
import ReactDOM from "react-dom/client";
import { createStore, compose, applyMiddleware } from "redux";
import rootReducer from "./Redux/Reducer/";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "normalize.css";
import App from "./App";
import "./css/fonts.css";
import "./styles/style.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware()));

root.render(
  //  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App/>
      </Router>
    </Provider>
  // </React.StrictMode>
);
