import useOnclickOutside from "react-cool-onclickoutside";
import React, { useState, useEffect } from "react";

import { Functions } from "../../interface/functions";

import ArrowDown from "../../images/other/arrow_down.svg";
import FilterImage from "../../images/other/filter_select.svg";

import "./Dropdown.css";

export default function Dropdown({
  children,
  label = "",
  value = "Выберите значение",
  type = "dropdown",
  className = "",
  icon = false,
  svgItem,
  style = {},
}) {
  const [open, setOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState(value);

  const closeDropdown = useOnclickOutside(() => {
    setOpen(false);
  });

  const closeInnerDropdown = () => {
    if (type === "select") {
      setOpen(false);
    }
  };

  const openHandler = () => {
    setOpen(!open);
  };

  useEffect(() => {
    setDropdownValue(value);
  }, [value]);

  return (
    <div className={`${"dropdown__container"} dropdown__container-${className}`} ref={closeDropdown}>
      {label !== "" && <span className={`${"dropdown__label"} dropdown__label-${className}`}>{label}</span>}
      <div className={`dropdown__container-header dropdown__container-header-${className}`} onClick={openHandler} style={style}>
        <div>
          {icon && <img src={FilterImage} alt="фильтер" />}
          {svgItem && <img src={Functions.setSvgItem(dropdownValue)} alt={dropdownValue} />}
          <span className={`${"dropdown__container-title"} dropdown__container-title-${className}`}>
            {dropdownValue}
          </span>
        </div>
        <div className={open ? "dropdown__container-arrow_open" : "dropdown__container-arrow"}>
          <img src={ArrowDown} alt="стрелка" />
        </div>
      </div>
      <div
        className={`${"dropdown__content"} dropdown__content-${className}`}
        style={{ display: open ? "flex" : "none" }}
        onClick={closeInnerDropdown}
      >
        {children}
        {className === "group" && (
          <div
            className="dropdown__content__button"
            onClick={() => {
              setOpen(false);
            }}
          >
            ЗАКРЫТЬ
          </div>
        )}
      </div>
    </div>
  );
}
