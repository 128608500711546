import { useState, useEffect, useContext, useRef } from "react";
import MUIDataTable, { TableBody } from "mui-datatables";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import ControlButtons from "../../components/ControlButtons/ControlButtons";
import ButtonsToolBar from "../../components/ButtonsToolBar/ButtonsToolBar";
import ShowRequest from "../../components/Modals/ShowRequest/ShowRequest";
import InputSearch from "../../UI/InputSearch/InputSearch";
import Modals from "../../components/Modals/Modals/Modals";
import Header from "../../components/Header/Header";
import CardProfile from "../../components/CardProfile/CardProfile";
import Loader from "../../UI/Loader/Loader";
import Scrollbar from "../../UI/Scroolbar/Scrollbar";
import LoyoutModal from "../../components/Modals/LoyoutModal/LoyoutModal";
import { Overlay } from "../../components/Modals/Overlay/Overlay";

import { getCountApplicationUsers, getCountNewUsers } from "../../Redux/Actions/countUsers";
import { useDisableBodyScroll } from "../../hooks/useDisableBodyScroll";
import { Table } from "../../interface/Table";
import { HTTP } from "../../interface/HTTP";

import { REQUEST_URL, routerPath, userContext } from "../../interface/config";
import { tablesConfig, tableLangConfig } from "../../interface/tablesConfig";

import detailInfo from "../../images/other/eye_detail.svg";

import "./AdminTable.css";

export default function AdminTable() {
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDetailUser, setIsLoadingDetailUser] = useState(false);
    const [inputStartValue, setInputStartValue] = useState("");
    const [inputEndValue, setInputEndValue] = useState("");
    const [activeTab, setActiveTab] = useState("all");
    const [modalStatus, setModalStatus] = useState("none");
    const [detailUser, setDetailUser] = useState(false);
    const [buttonStatus, setButtonStatus] = useState(" ");
    const [isFetch, setIsFetch] = useState(false);

    const [usersList, setUsersList] = useState([]);
    const [filteredUsersList, setFilteredUsersList] = useState([]);
    const [usersListArray, setUsersListArray] = useState([]);

    const [selected, setSelected] = useState([]);
    const [isVisibleShowRequest, setIsVisibleShowRequest] = useState("none");
    const [buttonText, setButtonText] = useState("");
    const [selectedCountId, setSelectedCountId] = useState([]); //длина массива выделенных пользователей
    const [transferUsers, setTransferUsers] = useState([]);
    const [transferDetailUser, setTransferDetailUser] = useState();
    const [selectedDetailUser, setSelectedDetailUser] = useState({});
    const [pathname, setPathname] = useState({});

    const { studentData, setStudentData } = useContext(userContext);

    const currentPage = useLocation();
    const lastRequest = useRef();

    const dispatch = useDispatch();

    const chairmanCounter = useSelector(state => state.countUsers);
    const userRole = useSelector(({ profile }) => profile.role);

    useDisableBodyScroll(modalStatus);

    const BodyComponent = props => {
        if (isLoading) {
            return <Loader />;
        } else {
            return <TableBody {...props} />;
        }
    };

    const tableButtonConfig = {
        current: [
            {
                text: "ОТКЛОНИТЬ",
                color: "red",
                access_type: "EXCLUDE_1",
                callback: e => showModalRequest(e.target.innerText),
            },
            {
                text: "ВЕРИФИЦИРОВАТЬ",
                color: "green",
                access_type: "APPL_1",
                callback: e => showModalRequest(e.target.innerText),
            },
        ],
        kick: [
            {
                text: "ПРИНЯТЬ ОБРАТНО",
                color: "green",
                access_type: "APPL_2",
                callback: e => showModalRequest(e.target.innerText),
            },
            {
                text: "АРХИВИРОВАТЬ",
                color: "green",
                access_type: "ARCH",
                callback: e => showModalRequest(e.target.innerText),
            },
            {
                text: "УДАЛИТЬ",
                color: "red",
                access_type: "DELETE",
                callback: e => showModalRequest(e.target.innerText),
            },
        ],
        applications: [
            {
                text: "ОТКЛОНИТЬ",
                color: "red",
                access_type: "EXCLUDE_1",
                callback: e => showModalRequest(e.target.innerText),
            },
            {
                text: "ПРИНЯТЬ",
                color: "green",
                access_type: "MEMBER",
                callback: e => showModalRequest(e.target.innerText),
            },
            {
                text: "ЗАЯВЛЕНИЕ",
                color: "green",
                access_type: "STATMENT",
                callback: e => showModalRequest(e.target.innerText),
            },
        ],
        users_prof: [
            {
                text: "ДОБАВИТЬ",
                color: "green",
                access_type: "ADD",
                callback: () => setModalStatus("add_profile"),
            },
            {
                text: "ПЕРЕВЫПУСТИТЬ КАРТУ",
                color: "green",
                access_type: "NO",
                callback: e => showModalRequest(e.target.innerText),
            },
            {
                text: "ИСКЛЮЧИТЬ",
                color: "red",
                access_type: "EXCLUDE_2",
                callback: e => showModalRequest(e.target.innerText),
            },
        ],
    };

    async function getDetailUserFetch(selectedUserId) {
        setIsLoadingDetailUser(true);
        const userInfo = await HTTP.Get(REQUEST_URL.getUser(selectedUserId));
        if (userInfo) {
            setIsLoadingDetailUser(false);
            setDetailUser(userInfo);
        }
    }

    const decreaseCounter = count => {
        if (activeTab === "current") {
            dispatch(getCountNewUsers(chairmanCounter.countNewUsers - count));
        } else if (activeTab === "applications") {
            dispatch(getCountApplicationUsers(chairmanCounter.countApplicationUsers - count));
        }
    };

    const getActiveTab = tab => {
        switch (tab) {
            case "all":
                return "user_all";
            case "current":
                return "new_users";
            case "applications":
                return "user_application";
            case "users_prof":
                return "user_prof";
            case "kick":
                return "kick";
            case "arch":
                return "user_application";
            default:
                return "user_application";
        }
    };

    const displayDetailInfo = dataIndex => {
        filteredUsersList.forEach(item => {
            if (item[14] === dataIndex) {
                setSelectedDetailUser(item);
                return item;
            }
        });

        getDetailUserFetch(dataIndex);
        setModalStatus(getActiveTab(activeTab));
    };

    function usersListId(inputStartValue, inputEndValue) {
        if (inputStartValue === "" && inputEndValue === "") {
            setFilteredUsersList(Table.setTableArray(usersList, routerPath.table));
            return;
        }

        const startPoint = inputStartValue === "" ? 0 : inputStartValue;
        const endPoint = inputEndValue === "" ? 100000 : inputEndValue;
        const filterArray = usersList.filter(item => item.id >= startPoint && item.id <= endPoint);

        setFilteredUsersList(Table.setTableArray(filterArray, routerPath.table));
    }

    async function usersListRequest() {
        let requestString = activeTab;
        const requestOptions = tablesConfig.table.requests;
        setIsLoading(true);
        let requestList = await HTTP.Get(`${REQUEST_URL.getUsersList}${requestOptions[requestString]}`);
        if (requestList && activeTab === lastRequest.current) {
            setUsersList(requestList);
        }
        setIsLoading(false);
    }

    const columns = [
        {
            name: "",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div
                            className="detail-img"
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                displayDetailInfo(tableMeta.rowData[14]);
                            }}
                        >
                            <img src={detailInfo} width="30" height="30" align="right" alt="" />
                        </div>
                    );
                },
                filter: false,
            },
        },
        { name: "Регион", options: { filter: true } },
        { name: "Фамилия Имя", options: { filter: false } },
        // { name: "Имя", options: { filter: false } },
        { name: "Дата рождения", options: { filter: false } },
        { name: "Email", options: { filter: false } },
        { name: "Телефон", options: { filter: false } },
        "Фото",
        {
            name: "Орган самоуправления",
            options: {
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                        <div className={`status-tab ${(value.toLowerCase().includes("бюджет")) ? "status-tab__color-blue" : "status-tab__color-green"}`} style={{color: "#000000"}}>
                            {value}
                        </div>
                    );
                },
                filter: false,
            },
        },
        { name: "Дата вступления", options: { filter: true } },
        "Статус",
        { name: "Дата присвоения статуса", options: { filter: false } },

        { name: "ID пользователя", options: { filter: false } },
    ];

    const options = {
        ...tablesConfig.options,
        textLabels: tableLangConfig,
        onRowSelectionChange: (rowsSelected, allRows, rowsSelectedTwo) => {
            Table.setSelectedRows(
                rowsSelected,
                selected,
                setSelected,
                usersList,
                buttonStatus,
                pathname,
                filteredUsersList
            );
        },
        onTableChange: (event, tableState) => {
            if (usersListArray.length !== usersList.length) {
                tableState.selectedRows.lookup = {};
                tableState.selectedRows.data = [];
            }
        },
        MUIDataTableBodyCell: { responsiveStacked: { whiteSpace: "pre" } },
        // onCellClick: (colData, cellMeta, tableMeta) => {
        //   if (colData.type === "div") {
        //     // displayDetailInfo(tableMeta.rowData[15]);
        //   }
        // },

        setRowProps: (row, dataIndex, rowIndex) => ({
            // onDoubleClick: (event) => {
            //   event.preventDefault();
            //   // displayDetailInfo(dataIndex);
            // },
            onMouseUp: () => {
                // event.preventDefault();
                // setIdSelected(row[15])
            },
        }),
    };

    const handleInputChangeStart = startValue => {
        setInputStartValue(startValue);
    };

    const handleInputChangeEnd = endValue => {
        setInputEndValue(endValue);
    };

    const showModalRequest = text => {
        if (selected.length !== 0) {
            const statusList = tablesConfig.table.buttonsStatus;
            for (let status in statusList) {
                if (Array.isArray(statusList[status])) {
                    statusList[status].forEach((item, index) => {
                        if (text.toUpperCase() === item) {
                            setButtonStatus(status);
                            setButtonText(item.toLowerCase());
                        }
                    });
                } else {
                    if (text.toUpperCase() === statusList[status]) {
                        setButtonStatus(status);
                        setButtonText(statusList[status].toLowerCase());
                    }
                }
            }
            setSelectedCountId(selected.length);
            setIsVisibleShowRequest(true);
        }
    };

    const changeButtonStatus = status => {
        setButtonStatus(status);
    };

    const changeFetchStatus = status => {
        setIsFetch(status);
    };

    useEffect(() => {
        if (usersList.length > 0) {
            if (!tablesConfig.noRemoveFromTable.includes(buttonStatus)) {
                if (transferDetailUser.length > 0 && isFetch === true) {
                    Table.remove(usersList, setUsersList, transferDetailUser, buttonStatus);
                    setSelected([]);
                    decreaseCounter(transferDetailUser.length);
                    setTransferDetailUser([]);
                    setIsFetch(false);
                }
            }
        }
    }, [transferDetailUser, isFetch]);

    useEffect(() => {
        if (usersList.length > 0) {
            if (buttonStatus === "SAVE" && studentData !== false) {
                Table.edit(usersList, setUsersList, studentData.id, studentData);
            }
            setStudentData(false);
        }
    }, [buttonStatus, studentData]);

    useEffect(() => {
        if (usersList.length > 0) {
            if (!tablesConfig.noRemoveFromTable.includes(buttonStatus)) {
                if (transferUsers.length > 0 && isFetch === true) {
                    Table.remove(usersList, setUsersList, transferUsers, buttonStatus);
                    setSelected([]);
                    decreaseCounter(transferUsers.length);
                    setTransferUsers([]);
                    setIsFetch(false);
                }
            }
        }
    }, [transferUsers.length, usersList, isFetch]);

    useEffect(() => {
        setSelected([]);
        setTransferDetailUser([]);
        setTransferUsers([]);
        lastRequest.current = activeTab;
        usersListRequest();
    }, [activeTab]);

    useEffect(() => {
        usersListId(inputStartValue, inputEndValue);
    }, [inputStartValue, inputEndValue, usersList]);

    useEffect(() => {
        setPathname(currentPage.pathname);
    }, [currentPage.pathname]);

    useEffect(() => {
        setUsersListArray(Table.setTableArray(usersList, routerPath.table));
    }, [usersList]);

    return (
        <>
            <Header selected={selected} activeTab={activeTab} />
            <div className="main">
                <div className="admin-table">
                    <div className="admin-table-filters">
                        <InputSearch
                            text={"Начало:"}
                            value={inputStartValue}
                            handleInputChange={handleInputChangeStart}
                            placeholder="начальный Id"
                        />
                        <InputSearch
                            text={"Конец:"}
                            value={inputEndValue}
                            handleInputChange={handleInputChangeEnd}
                            placeholder="конечный id"
                        />
                    </div>
                    <div className="tables-switch">
                        {Table.setTabs(activeTab, setActiveTab, tablesConfig.table.tabs, userRole)}
                    </div>

                    <MUIDataTable
                        title=""
                        data={filteredUsersList}
                        columns={columns}
                        options={options}
                        components={{
                            TableBody: BodyComponent,
                        }}
                    />

                    <div className="admin-table-container">
                        <div className="main__control__buttons">
                            <div className="main-content-accept">
                                <ButtonsToolBar config={tableButtonConfig} activeTab={activeTab} type={"table"} />
                            </div>
                            <ControlButtons type="2_buttons" usersListId={selected} />
                        </div>
                    </div>
                </div>
            </div>
            {/* <Modals
        page="adminTable"
        activeTab={activeTab}
        detailUser={detailUser}
        setDetailUser={setDetailUser}
        isLoadingDetailUser={isLoadingDetailUser}
        modalStatus={modalStatus}
        setModalStatus={setModalStatus}
        selected={selected}
        selectedDetailUser={selectedDetailUser}
        setTransferDetailUser={setTransferDetailUser}
        setButtonStatus={setButtonStatus}
        changeButtonStatus={changeButtonStatus}
        changeFetchStatus={changeFetchStatus}
      /> */}
            <ShowRequest
                setSelected={setSelected}
                modalStatus={isVisibleShowRequest}
                setModalStatus={setIsVisibleShowRequest}
                buttonText={buttonText}
                selectedCountId={selectedCountId}
                selected={selected}
                buttonStatus={buttonStatus}
                secondStatus={false}
                changeFetchStatus={changeFetchStatus}
                setTransferUsers={setTransferUsers}
            />
            {modalStatus !== "none" && (
                <Overlay setModalStatus={setModalStatus} modalStatus={modalStatus}>
                    <LoyoutModal
                        modalStatus={modalStatus}
                        setModalStatus={setModalStatus}
                        sizeModal="loyout-modal__size-large"
                    >
                        <CardProfile
                            detailUser={detailUser}
                            pageType="table"
                            activeTableTab={activeTab}
                            isLoadingDetailUser={isLoadingDetailUser}
                            selected={selected}
                            modalStatus={modalStatus}
                            setModalStatus={setModalStatus}
                            selectedDetailUser={selectedDetailUser}
                            setTransferDetailUser={setTransferDetailUser}
                            setButtonStatus={setButtonStatus}
                            changeButtonStatus={changeButtonStatus}
                            changeFetchStatus={changeFetchStatus}
                        />
                    </LoyoutModal>
                </Overlay>
            )}
        </>
    );
}
