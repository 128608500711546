import { useRef } from "react";

import ShowError from "../ShowError/ShowError";

import { SetFieldsStatus } from "../../interface/SetFieldsStatus";

import "./Input.css";

export default function InputTranslate({
  type = "",
  defaultValue = "",
  defaultValueTranslit = "",
  translitOptions,
  textTranslit,
  id = "",
  status = "default",
  onBlur = () => {},
  onChange = () => {},
  text = "",
  required,
  formError,
}) {
  const refTranslate = useRef();

  const wordsTranslit = {
    а: "a",
    б: "b",
    в: "v",
    г: "g",
    д: "d",
    е: "e",
    ё: "e",
    ж: "zh",
    з: "z",
    и: "i",
    й: "y",
    к: "k",
    л: "l",
    м: "m",
    н: "n",
    о: "o",
    п: "p",
    р: "r",
    с: "s",
    т: "t",
    у: "u",
    ф: "f",
    х: "h",
    ц: "c",
    ч: "ch",
    ш: "sh",
    щ: "sch",
    ь: "",
    ы: "y",
    ъ: "",
    э: "e",
    ю: "yu",
    я: "ya",

    А: "A",
    Б: "B",
    В: "V",
    Г: "G",
    Д: "D",
    Е: "E",
    Ё: "E",
    Ж: "Zh",
    З: "Z",
    И: "I",
    Й: "Y",
    К: "K",
    Л: "L",
    М: "M",
    Н: "N",
    О: "O",
    П: "P",
    Р: "R",
    С: "S",
    Т: "T",
    У: "U",
    Ф: "F",
    Х: "H",
    Ц: "C",
    Ч: "Ch",
    Ш: "Sh",
    Щ: "Sch",
    Ь: "",
    Ы: "Y",
    Ъ: "",
    Э: "E",
    Ю: "Yu",
    Я: "Ya",
  };

  const translitWord = (target, ref) => {
    const word = target.value;
    let result = "";

    for (let i = 0; i < word.length; ++i) {
      if (wordsTranslit[word[i]] === undefined) {
        result += word[i];
      } else {
        result += wordsTranslit[word[i]];
      }
    }
    ref.current.value = result;
    formError.values[translitOptions.name] = result;
  };

  return (
    <>
      <div className={`${"input-wrapper"} ${SetFieldsStatus.setInputClassValid(status)}`}>
        <label htmlFor="">
          {text} <span>{required ? "*" : ""}</span>
        </label>
        <input
          defaultValue={defaultValue}
          type={type}
          onBlur={onBlur}
          onChange={(e) => {
            translitWord(e.target, refTranslate);
            onChange(e);
          }}
          id={id}
        />
        {SetFieldsStatus.setImage(status)}
        <ShowError form={formError} name={id} />
      </div>
      <div className={`${"input-wrapper"} ${SetFieldsStatus.setInputClassValid(status)}`}>
        <label htmlFor="">
          {textTranslit} <span>{required ? "*" : ""}</span>
        </label>
        <input
          ref={refTranslate}
          defaultValue={defaultValueTranslit}
          onBlur={translitOptions.onBlur}
          onChange={(e) => {
            translitWord(e.target, refTranslate);
            translitOptions.onChange(e);
          }}
          type={type}
          id={translitOptions.name}
        />
        {SetFieldsStatus.setImage(status)}
        <ShowError form={formError} name={translitOptions.name} />
      </div>
    </>
  );
}
