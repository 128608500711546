export const sattisticsEventList = [
  {
    id: 1,
    title: "Благотворительная встреча",
    date: "20.08.2023",
    responsible: "Морсова Т.В",
    place: "Москва",
    acceptance_of_applications: "Автоматически",
    status: "Идет регистрация",
  },
  {
    id: 2,
    title: "Театр им. Лебедева",
    date: "24.06.2023",
    responsible: "Сидорова Т.В",
    place: "Владивосток",
    acceptance_of_applications: "Выборочно",
    status: "Завершено",
  },
  {
    id: 3,
    title: "Ботанический сад",
    date: "26.06.2023",
    responsible: "Иванов Т.В",
    place: "Казань",
    acceptance_of_applications: "Автоматически",
    status: "Мероприятие отменено",
  },
  {
    id: 4,
    title: "Благотворительная встреча",
    date: "20.08.2023",
    responsible: "Морсова Т.В",
    place: "Москва",
    acceptance_of_applications: "Автоматически",
    status: "Идет регистрация",
  },
  {
    id: 5,
    title: "Театр им. Лебедева",
    date: "24.06.2023",
    responsible: "Сидорова Т.В",
    place: "Владивосток",
    acceptance_of_applications: "Выборочно",
    status: "Завершено",
  },
  {
    id: 6,
    title: "Ботанический сад",
    date: "26.06.2023",
    responsible: "Иванов Т.В",
    place: "Казань",
    acceptance_of_applications: "Автоматически",
    status: "Мероприятие отменено",
  },
  {
    id: 7,
    title: "Благотворительная встреча",
    date: "20.08.2023",
    responsible: "Морсова Т.В",
    place: "Москва",
    acceptance_of_applications: "Автоматически",
    status: "Идет регистрация",
  },
  {
    id: 8,
    title: "Театр им. Лебедева",
    date: "24.06.2023",
    responsible: "Сидорова Т.В",
    place: "Владивосток",
    acceptance_of_applications: "Выборочно",
    status: "Завершено",
  },
  {
    id: 9,
    title: "Ботанический сад",
    date: "26.06.2023",
    responsible: "Иванов Т.В",
    place: "Казань",
    acceptance_of_applications: "Автоматически",
    status: "Мероприятие отменено",
  },
  {
    id: 10,
    title: "Благотворительная встреча",
    date: "20.08.2023",
    responsible: "Морсова Т.В",
    place: "Москва",
    acceptance_of_applications: "Автоматически",
    status: "Идет регистрация",
  },
  {
    id: 11,
    title: "Театр им. Лебедева",
    date: "24.06.2023",
    responsible: "Сидорова Т.В",
    place: "Владивосток",
    acceptance_of_applications: "Выборочно",
    status: "Завершено",
  },
  {
    id: 12,
    title: "Ботанический сад",
    date: "26.06.2023",
    responsible: "Иванов Т.В",
    place: "Казань",
    acceptance_of_applications: "Автоматически",
    status: "Мероприятие отменено",
  },
  {
    id: 13,
    title: "Благотворительная встреча",
    date: "20.08.2023",
    responsible: "Морсова Т.В",
    place: "Москва",
    acceptance_of_applications: "Автоматически",
    status: "Идет регистрация",
  },
  {
    id: 14,
    title: "Театр им. Лебедева",
    date: "24.06.2023",
    responsible: "Сидорова Т.В",
    place: "Владивосток",
    acceptance_of_applications: "Выборочно",
    status: "Завершено",
  },
  {
    id: 15,
    title: "Ботанический сад",
    date: "26.06.2023",
    responsible: "Иванов Т.В",
    place: "Казань",
    acceptance_of_applications: "Автоматически",
    status: "Мероприятие отменено",
  },
];

export const sttisticsPeopleList = [
  {
    id: 1,
    faculty: "Биологический",
    name: "Воскобойников М.М",
    date_of_birth: "23.06.1990",
    phone_number: "8 (888) 888 88 88",
    number_general: "0161020",
    type_of_learning: "Бюджет",
    status: "Заявка одобрена",
  },
  {
    id: 2,
    faculty: "ВШ Государственного Администрирования",
    name: "Воскобойников М.М",
    date_of_birth: "23.06.1990",
    phone_number: "8 (888) 888 88 88",
    number_general: "0110201",
    type_of_learning: "Контракт",
    status: "Заявка одобрена",
  },
  {
    id: 3,
    faculty: "Юридический",
    name: "Воскобойников М.М",
    date_of_birth: "23.06.1990",
    phone_number: "8 (888) 888 88 88",
    number_general: "0110201",
    type_of_learning: "Бюджет",
    status: "Заявка одобрена",
  },
  {
    id: 4,
    faculty: "Юридический",
    name: "Воскобойников М.М",
    date_of_birth: "23.06.1990",
    phone_number: "8 (888) 888 88 88",
    number_general: "0110201",
    type_of_learning: "Контракт",
    status: "Заявка одобрена",
  },
  {
    id: 5,
    faculty: "Юридический",
    name: "Воскобойников М.М",
    date_of_birth: "23.06.1990",
    phone_number: "8 (888) 888 88 88",
    number_general: "0110201",
    type_of_learning: "Контракт",
    status: "Заявка одобрена",
  },
  {
    id: 6,
    faculty: "Юридический",
    name: "Воскобойников М.М",
    date_of_birth: "23.06.1990",
    phone_number: "8 (888) 888 88 88",
    number_general: "0110201",
    type_of_learning: "Контракт",
    status: "Заявка не принята",
  },
];

export const header_of_sttisticsEventList = [
  { key: "title", label: "Название" },
  { key: "date", label: "Дата проведения" },
  { key: "responsible", label: "Ответственный" },
  { key: "place", label: "Город проведения" },
  { key: "acceptance_of_applications", label: "Прием заявок" },
  { key: "status", label: "Статус" },
];
