import Tab from "../UI/Tab/Tab";

import { usersParams } from "./usersParams";
import { routerPath } from "./config";
import { SERVER_URL } from "./HTTP";

export class Table {
    static remove(usersList, setUsersList, removeUsers, buttonStatus) {
        if (usersList.length > 0) {
            if (1) {
                if (usersList.length === removeUsers.length) {
                    setUsersList([]);
                } else {
                    const returnArr = usersList;
                    removeUsers.forEach(item => {
                        usersList.forEach((element, key) => {
                            if (element.id === item.id) {
                                returnArr.splice(key, 1);
                            }
                        });
                    });
                    setUsersList([...returnArr]);
                }
            }
        }
    }

    static edit(usersList, setUsersList, editUserIndex, studentData) {
        setUsersList(
            usersList.map(user => {
                if (editUserIndex === user.id) {
                    user = studentData;
                }
                return user;
            })
        );
    }

    static getUsers(arrayUsers, filteredUsersList) {
        const idOfFilteredUsersList = filteredUsersList.map(i => {
            return i[14];
        });

        const users = arrayUsers.filter(item => {
            return idOfFilteredUsersList.includes(item.id);
        });

        return users;
    }

    static setSelectedRows(rowsSelected, selected, setSelected, usersList, buttonStatus, pathname, filteredUsersList) {
        let users = [];
        if (rowsSelected.length !== 0) {
            let returnArr = selected;
            if (pathname && pathname.includes("table")) {
                users = this.getUsers(usersList, filteredUsersList);
            } else {
                users = usersList;
            }
            rowsSelected.forEach(target => {
                const id = users[target.dataIndex].id;
                const card = users[target.dataIndex].card;
                const status = users[target.dataIndex].status;
                const rzd_number = users[target.dataIndex].rzd_number;
                if (selected.length > 0) {
                    if (
                        selected.every(element => {
                            return element.id !== id;
                        })
                    ) {
                        this.pushRow(returnArr, id, buttonStatus, card, status, rzd_number);
                    } else {
                        returnArr = selected.filter(element => {
                            return element.id !== id;
                        });
                    }
                } else {
                    this.pushRow(returnArr, id, buttonStatus, card, status, rzd_number);
                }
            });
            setSelected([...returnArr]);
        } else {
            setSelected([]);
        }
    }

    static pushRow(returnArr, id, buttonStatus, card, status, rzd_number) {
        returnArr.push({
            id: id,
            status: this.setUserStatus(status),
            rzd_number: rzd_number,
            card: {
                id: card ? card.id : false,
                status: buttonStatus ? buttonStatus : false,
                number: card ? card.number : false,
                user: card ? card.user : false,
            },
        });
    }

    static setUserStatus(status) {
        let returnStatus;
        if (status !== "Членство приостановлено") {
            for (let item in usersParams.status) {
                if (status === usersParams.status[item]) {
                    returnStatus = item.toUpperCase();
                }
            }
        } else returnStatus = "EXCLUDE";
        return returnStatus;
    }

    static setOnlyId(selected, setSelectedIDArray) {
        if (selected.length !== 0) {
            let returnArr = selected.map((item, index) => {
                return item.id;
            });
            setSelectedIDArray(returnArr);
        }
    }

    static setTabs(activeTab, setActiveTab, config, userRole = "") {
        const tabArr = config;
        let returnArr = [];
        for (let tab in tabArr) {
            returnArr.push(
                <Tab
                    activeTab={activeTab}
                    tabName={tabArr[tab]}
                    currentTab={tab}
                    setTab={setActiveTab}
                    userRole={userRole}
                    key={tabArr[tab]}
                />
            );
        }
        return returnArr;
    }

    static setTableArray = (usersArray, path) => {
        if (usersArray.length > 0) {
            return usersArray.map((item, index) => {
                return this.setTableItem(item, path);
            });
        } else {
            return [];
        }
    };

    static setTableItem = (item, path) => {
        const faccultyRus_REG_Exp = /[а-яА-Я- ]/gi;
        const facculty_REG_Exp = /[a-zA-Z- ]/gi;

        switch (path) {
            case routerPath.card_admin:
                return [
                    item.img ? item.img : "",
                    item.faculty ? item.faculty.match(faccultyRus_REG_Exp).join("") : "",
                    item.faculty_translate ? item.faculty_translate.match(facculty_REG_Exp).join("") : "",
                    item.last_name && item.first_name ? item.last_name.concat(" ", item.first_name) : "",
                    item.last_name_translated && item.first_name_translated
                        ? item.last_name_translated.concat(" ", item.first_name_translated)
                        : "",
                    // item.first_name ? item.first_name : "",
                    //item.first_name_translated ? item.first_name_translated : "",
                    item.date_of_birth ? item.date_of_birth : "",
                    item.status ? item.status : "",
                    item.card ? item.card.number : "",
                    item.card.date ? item.card.date : "",
                    item.id ? item.id : "",
                ];
            case routerPath.rzd_bonus:
                return [
                    item.img ? item.img : "",
                    item.faculty ? item.faculty.match(faccultyRus_REG_Exp).join("") : "",
                    item.last_name && item.first_name ? item.last_name.concat(" ", item.first_name) : "",
                    // item.first_name ? item.first_name : "",
                    item.middle_name ? item.middle_name : "",
                    item.rzd_number ? item.rzd_number : "",
                    item.rzd_datetime ? item.rzd_datetime.slice(0, 10) : "",
                    item.rzd_status ? item.rzd_status : "",
                    item.id ? item.id : "",
                ];
            case routerPath.table:
                return [
                    item.img ? item.img : "",
                    item.faculty ? item.faculty.match(faccultyRus_REG_Exp).join("") : "",
                    item.last_name && item.first_name ? item.last_name.concat(" ", item.first_name) : "",
                    // item.first_name && item.last_name ? item.first_name : "",
                    item.date_of_birth ? item.date_of_birth : "",
                    item.email ? item.email : "",
                    item.phone_number ? item.phone_number : "",
                    item.image !== `${SERVER_URL}/media/user/images/no-image.png` ? "Да" : "Нет",
                    item.academic_level ? item.academic_level : "",
                    item.grade_level ? item.grade_level : "",
                    item.card ? item.card.number : "",
                    item.type_of_learning ? item.type_of_learning : "",
                    item.entry_date ? item.entry_date : "",
                    item.status ? item.status : "",
                    item.status_gain_date ? item.status_gain_date : "",
                    item.id ? item.id : "",
                    item.number_general ? item.number_general : "",
                ];
            case routerPath.bookkeeping:
                return [
                    item.img ? item.img : "",
                    item.payment_datetime === null || item.payment_datetime === undefined
                        ? ""
                        : item.payment_datetime.slice(0, 10),
                    item.last_name && item.first_name ? item.last_name.concat(" ", item.first_name) : "",
                    // item.first_name ? item.first_name : "",
                    // item.middle_name === null || item.middle_name === undefined || item.middle_name === ""
                    //     ? "Нет информации"
                    //     : item.middle_name,
                    //item.middle_name ? item.middle_name : "Нет данных",
                    item.academic_level ? item.academic_level : "",
                    item.grade_level ? item.grade_level : "",
                    item.id,
                    item.payment_status,
                ];

            case routerPath.members:
                return [
                    item.img ? item.img : "",
                    item.faculty ? item.faculty : "",
                    item.last_name ? item.last_name : "",
                    item.first_name ? item.first_name : "",
                    item.date_of_birth ? item.date_of_birth : "",
                    item.email ? item.email : "",
                    item.phone_number ? item.phone_number : "",
                    item.image ? "Да" : "Нет",
                    item.grade_level ? item.grade_level : "",
                    item.academic_level ? item.academic_level : "",
                    item.card ? item.card.number : "",
                    item.type_of_learning ? item.type_of_learning : "",
                    item.entry_date ? item.entry_date : "",
                    item.status ? item.status : "",
                    item.status_gain_date ? item.status_gain_date : "",
                    item.id ? item.id : "",
                ];

        case routerPath.peoples_responses:
      
        return [
          item.img ? item.img : "",
          item.name ? item.name : "",
          item.time_stamp ? item.time_stamp : "",
          item.about_person ? item.about_person : "",
          item.slogan ? item.slogan : "",
          item.selection_from_list ? item.selection_from_list : "",
          item.few_from_list ? item.few_from_list : "",
          item.drop_down_list ? item.drop_down_list : "",
          item.uploading_files ? item.uploading_files : "",
          item.date ? item.date : "",
          item.time ? item.time : "",
        ];
            default:
                break;
        }
    };
}
