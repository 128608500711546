const initialState = {
    facultiesList: [],
};

const facultiesList = (state = initialState, action) => {
    switch (action.type) {
        case "facultiesList":
            return {
                ...state,
                facultiesList: action.payload,
            };
        default:
            return state;
    }
};

export default facultiesList;