import ErrorBox from "../../UI/ErrorBox/ErrorBox";

import { Functions } from "../../interface/functions";

// import styles from "./FileInput.module.scss";

export default function FileInput({
  id,
  accept,
  name,
  form,
  disabled = false,
  error = true,
  multiple = false,
  text = "Выберите файл",
  className,
  titleBox,
  subTitleBox,
  setFilesList,
  inputRef,
  mode
}) {


  const changeFiles = () => {
    form.values[name] = Functions.filesValidation(inputRef.current.files, accept, multiple, setFilesList, mode);
    form.validateForm();
  };

  return (
    <>
      <div className={"file-input"}>
        <button type="button" onClick={() => inputRef.current.click()} className={"download__button-file"}>
          {titleBox}
        </button>
        <input
          ref={inputRef}
          type="file"
          multiple={multiple}
          id={id}
          datatype={"doc,pdf,jpeg"}
          accept={accept}
          name={name}
          className={className}
          onChange={changeFiles}
        />

        <ErrorBox error={error} form={form} id={id} />
      </div>
      <p className={"download__text"}>{subTitleBox}</p>
    </>
  );
}
