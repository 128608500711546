import React from "react";

import StatusTab from "../../UI/StatusTab/StatusTab";

import { ReactComponent as EyeImage } from "../../images/event/eye.svg";
import { ReactComponent as AppealImage } from "../../images/other/appeal_icon.svg";

import "./PeopleEvent.css";

function PeopleEvent({ item, type = "default", activeTab, setSelectedUser }) {
    return (
        <tr className="people-event">
            {type !== "appeal" && (
                <td className={"people-event__text"}>
                    <EyeImage className={"people-event__icon"} onClick={() => setSelectedUser(item.id)} />
                </td>
            )}
            <td className="people-event__text" title={item.name}>
                {item.name}{" "}
            </td>{" "}
            {/*{item.first_name.slice(0, 1)}. {item.middle_name.slice(0, 1)}. */}
            <td className="people-event__text" title={item.faculty}>
                {item.faculty}
            </td>
            <td className={"people-event__text"} title={item.phone}>
                {item.phone}
            </td>
            <td className={"people-event__text"} title={item.number_card}>
                {item.number_card}
            </td>
            <td className={"people-event__text"} title={item.type_of_learning}>
                <StatusTab text={item.type_of_learning} type="type_of_learning" />
            </td>
            <td className={"people-event__text"} title={item.status}>
                <StatusTab text={item.status} type="status" />
            </td>
            {type === "appeal" && (
                <td className={"people-event__text"}>
                    <a href={item.link ?? ""} target={"_blank"} rel="noreferrer">
                        <AppealImage />
                    </a>
                </td>
            )}
        </tr>
    );
}

export default PeopleEvent;
