import { HTTP } from "./HTTP";
import { REQUEST_URL } from "./config";

export class UserActions {
  
  static async update(usersArr, buttonStatus, secondStatus = false, changeFetchStatus){
    const requestUpdate = await HTTP.Update(
      "PATCH",
      true,
      REQUEST_URL.updateMultipleUsers,
      this.setUsersStatus(usersArr, buttonStatus, secondStatus)
    );
    if (requestUpdate) {
      changeFetchStatus(true)
    }
    else {
      changeFetchStatus(false)
    }
  }

  static setUsersStatus(users, buttonStatus, secondStatus) {
    return users.map((item) => {
      const userObject = { id: item.id, status: buttonStatus };
      if (item.status === 'EXCLUDE' && buttonStatus === 'MEMBER') {
        userObject.status = 'MEMBER';
      }
      if (userObject){
        // openNotification({
        //   title: "Задача",
        //   text: "Задача успешно выполнена",
        //   type: "success",
        // })
      }
      return userObject;
    });
  }

  static setCardsStatus(users, buttonStatus) {
    return users.map((item) => {
      return { 
        id: item.card.id,
        status: buttonStatus, 
        // number: `${item.card.number}`,
        // user: item.card.user
      };
    });
  }

  static async updateCard(usersArr, buttonStatus) {
    const requestUpdate = await HTTP.Update(
      "PATCH",
      true,
      REQUEST_URL.updateMultipleCards,
      this.setCardsStatus(usersArr, buttonStatus)
    );
    if (requestUpdate) {
      // openNotification({
      //   title: "Задача",
      //   text: "Задача успешно выполнена",
      //   type: "success",
      // });
      return requestUpdate
    }
    // else {
    //   openNotification({
    //     title: "Задача",
    //     text: "Операция не выполнена, обратитесь к разработчикам",
    //     type: "error",
    //   });
    // }
  }

  static setPaymantStatus(users, buttonStatus, paymentDatetime) {
    return users.map((item) => {
      return {
        id: item.id,
        payment_status: buttonStatus,
        payment_datetime: paymentDatetime,
      };
    });
  }


  static setRzdStatus(users, buttonStatus, rzd_number) {
    return users.map((item) => {
      return {
        id: item.id,
        rzd_status: buttonStatus,
        rzd_number: item.rzd_number ? item.rzd_number : rzd_number,
      };
    });
  }

  static async updateStatus(usersArr, buttonStatus, paymentDatetime, rzdNumber) {
  
    if (buttonStatus !== "TRANS" && buttonStatus !== "PASSED"){
      const requestUpdate = await HTTP.Update(
        "PATCH",
        true,
        REQUEST_URL.paymentsUsers,
        this.setPaymantStatus(usersArr, buttonStatus, paymentDatetime)
      );
    }
    else if (buttonStatus === "PASSED"){
      const requestUpdate = await HTTP.getDownloadFiles(
        REQUEST_URL.updateRzdStatus,
        this.setRzdStatus(usersArr, buttonStatus, rzdNumber),
        "filename=",
        "PATCH"
      );
    }
    else{
      const requestUpdate = await HTTP.getDownloadFiles(
        REQUEST_URL.paymentsUsers,
        this.setPaymantStatus(usersArr, buttonStatus, paymentDatetime),
        "filename=",
        "PATCH"
      );
      // if (requestUpdate) {
       
      // }
      // else {
      //   console.log('2', 2)
      //   changeFetchStatus(false)
      // }
    }
  }

  static setUsersDelete(users) {
    const usersList = [];
    users.forEach((item) => {
      usersList.push(item.id);
    });
    return { ids: usersList };
  }

  static getUsersIds(users) {
    const usersList = [];
    users.forEach((item) => {
      usersList.push(Number(item.id));
    });
    return usersList;
  }

  static async delete(usersArr, changeFetchStatus) {
    const requestUpdate = await HTTP.Update(
      "DELETE",
      true,
      REQUEST_URL.deleteMultipleUsers,
      this.setUsersDelete(usersArr)
    );
    if (requestUpdate) {
      changeFetchStatus(true)
      // openNotification({
      //   title: "Задача",
      //   text: "Задача успешно выполнена",
      //   type: "success",
      // });
    }
    else {
      changeFetchStatus(false)
    }
    // else{
    //   openNotification({
    //     title: "Задача",
    //     text: "Операция не выполнена, обратитесь к разработчикам",
    //     type: "error",
    //   });
    // }
  }


}
