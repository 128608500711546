import { NavLink, useLocation } from "react-router-dom";
import useOnclickOutside from "react-cool-onclickoutside";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, memo } from "react";

import HeaderItem from "./HeaderItem";

import { getCountApplicationUsers, getCountNewUsers } from "../../Redux/Actions/countUsers";
import { HTTP } from "../../interface/HTTP";
import { logOut } from "../../Redux/Actions/profile.jsx";

import { REQUEST_URL } from "../../interface/config";
import { routerPath, routerPathSuMenu, routerPathTables, routerPathSocials } from "../../interface/config";
import { usersParams } from "../../interface/usersParams";
import { Functions } from "../../interface/functions";

import mgu_logo from "../../images/other/mgu_logo_sm.png";
import mgi_logo_mobile from "../../images/other/mgu_logo_reg.webp";

import "./Header.css";

export default memo(function Header({ selectedCountId = 0, activeTab }) {
  //admin, user, user_anonymous, anonymous, user_prof, director, user_kick
  //для director добавить число новых заявок с бека
  const dispatch = useDispatch();
  const user_status = useSelector(({ profile }) => profile.status);
  const user_role = useSelector(({ profile }) => profile.role);
  const user_photo = useSelector(({ profile }) => profile.image);

  const exit = useDispatch();
  const currentPage = useLocation();

  const countApplicationUsers = useSelector(({ countUsers }) => countUsers.countApplicationUsers);
  const countNewUsers = useSelector(({ countUsers }) => countUsers.countNewUsers);

  // const user_card = useSelector((store) => store.card);
  const [menuItemsToggle, setMenuItemsToggle] = useState(false);

  const handleBtnClick = () => setMenuItemsToggle(!menuItemsToggle);
  const closeActiveBlock = () => setMenuItemsToggle(false);
  const ref = useOnclickOutside(() => closeActiveBlock());

  const setCountApplicationUsers = async (users) => {
    dispatch(getCountApplicationUsers(users));
  };

  const setCountNewUsers = async (users) => {
    dispatch(getCountNewUsers(users));
  };

  async function usersListRequest() {
    if (user_role === usersParams.role.chairman) {
      let requestList = await HTTP.getUser(REQUEST_URL.getUserCount);
      if (requestList) {
        setCountApplicationUsers(requestList.count_application_users);
        setCountNewUsers(requestList.count_new_users);
      }
    }
  }

  useEffect(() => {
    if (user_role === usersParams.role.chairman) {
      usersListRequest();
    }
  }, []);

  useEffect(() => {
    if (user_role === usersParams.role.chairman && (activeTab === "current" || activeTab === "applications")) {
      usersListRequest();
    }
  }, [activeTab]);

  useEffect(() => {
    if (user_photo) {
      Functions.setUserPhoto(user_photo);
    }
  }, [user_photo]);

  return (
    <div className="container">
      <header className="header">
        <div className="header__container">
          {currentPage.pathname.includes("password_reset") ||
          currentPage.pathname.includes("password-recovery") ||
          currentPage.pathname.includes("password_recovery") ? (
            <></>
          ) : (
            <div className="header__container-burger" onClick={handleBtnClick}>
              <div className="header__container-burger-item burger-item">
                <span className="burger-item-line burger-item-line-first"></span>
                <span className="burger-item-line burger-item-line-second"></span>
                <span className="burger-item-line burger-item-line-third"></span>
              </div>
            </div>
          )}

          <div className="header__container-mobileLogo">
            <a href="https://molprav.ru" rel="noreferrer" target="_blank">
              <img src={mgi_logo_mobile} alt="ОПК МГУ" />
            </a>
          </div>
          <div ref={ref} className={`header__container-inner ${!menuItemsToggle ? "burger-non-active" : ""}`}>
            {user_status !== "" && (
              <div className="header__logo">
                <a href="https://molprav.ru" rel="noreferrer" target="_blank" style={{ textDecoration: "none" }}>
                  <img src={mgu_logo} alt="ОПК МГУ" />
                </a>
                <a href="https://molprav.ru" rel="noreferrer" target="_blank" style={{ textDecoration: "none" }}>
                  <div className="header__title">АМП РФ</div>
                </a>
              </div>
            )}
            {user_status === "" && (
              <div className="header__logo">
                <a href="https://molprav.ru" rel="noreferrer" target="_blank">
                  <img src={mgu_logo} alt="ОПК МГУ" />
                </a>
                <a href="https://molprav.ru" rel="noreferrer" target="_blank">
                  <div className="header__title">АМП РФ</div>
                </a>
              </div>
            )}
            {user_status !== "" && (
              <nav className="header__menu" onClick={closeActiveBlock}>
                {(user_role === usersParams.role.admin || user_role === usersParams.role.chairman) && (
                  <>
                    {/* <HeaderItem path={routerPath.table} title="Пользователи" />
                    <HeaderItem path={routerPath.statistics} title="Статистика" />
                    <HeaderItem path={routerPath.card_admin} title="Карта Zachёt" />
                    <HeaderItem path={routerPath.bookkeeping} title="Бухгалтерия" />
                    <HeaderItem path={routerPath.rzd_bonus} title="РЖД" /> */}
                    <HeaderItem mode='select' subMenuList={routerPathTables} title="Таблицы" />
                    <HeaderItem mode='select' subMenuList={routerPathSuMenu} title="Мероприятия" />
                    <HeaderItem mode='select' subMenuList={routerPathSocials}  title="Соц. программы" />
                    {/* <HeaderItem path={routerPath.documents_program} title="Документы" /> */}
                    <HeaderItem path={routerPath.appeals} title="Обращения" />
                  </>
                )}
                {user_role === usersParams.role.user &&
                  (user_status === usersParams.status.new || user_status === usersParams.status.verified) && (
                    <>
                      <HeaderItem path={routerPath.profile} title="Личные данные" />
                    </>
                  )}

                {(user_role === usersParams.role.member || user_role === usersParams.role.user) &&
                  user_status === usersParams.status.member && (
                    <>
                      <HeaderItem path={routerPath.profile} title="Личные данные" />
                      <HeaderItem path={routerPath.sponsors} title="Спонсоры" className="header__menu-item-sponsors" />
                      {/* <HeaderItem path={routerPath.card} title="Карта Zachёt" />
                      <HeaderItem path={routerPath.rzd_bonus_profile} title="РЖД" />
                      <HeaderItem path={routerPath.events} title="Мероприятия" />
                      <HeaderItem path={routerPath.social_programs_student} title="Соц. программы" /> */}
                      
                    </>
                  )}
                {user_status === usersParams.status.apply &&
                  user_role !== usersParams.role.admin &&
                  user_role !== usersParams.role.chairman && (
                    <>
                      <HeaderItem path={routerPath.profile} title="Личные данные" />
                    </>
                  )}
                {user_status === usersParams.status.exclude &&
                  user_role !== usersParams.role.admin &&
                  user_role !== usersParams.role.chairman && (
                    <HeaderItem path={routerPath.profile} title="Личные данные" />
                  )}
                <div className="header__lk">
                  <div className="header__lk-photo">
                    {/* <NavLink to="/profile"> */}
                    <img src={Functions.setUserPhoto(user_photo)} alt="user" />
                    {/* </NavLink> */}
                  </div>
                  <div className="header__lk-exit">
                    <NavLink
                      to={routerPath.main_page}
                      onClick={async () => {
                        exit(logOut());
                        const logOutRequest = await HTTP.logOut();
                        if (logOutRequest) {
                          localStorage.removeItem("token");
                        }
                      }}
                    >
                      Выйти
                    </NavLink>
                  </div>
                </div>
              </nav>
            )}
          </div>
        </div>
      </header>
    </div>
  );
});