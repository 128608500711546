import MaskedCustomInput from "../../UI/Input/MaskedCustomInput";
import Input from "../../UI/Input/Input.jsx";
import { useState} from "react";
import Select from "../../UI/Select/Select";
import ChangeInfo from "./ChangeInfo.jsx";
import { ReactComponent as ChangeBlock } from "../../images/profile/change_block.svg";
import { ReactComponent as DeleteBlock } from "../../images/profile/delete_block.svg";
import { ReactComponent as SaveChanges } from "../../images/profile/save_changes.svg";
import Modals from "../../components/Modals/Modals/Modals";
import Cookies from "js-cookie";
export default function ChangeSections({
    educations,
    mode,
    text_section,
}
) {

    const [education, setEducation] = useState(educations);
    const [modalStatus, setModalStatus] = useState("none");
    const [modalStatusWork, setModalStatusWork] = useState("none");
    const [modalStatusMSU, setModalStatusMSU] = useState("none");
    const [modalStatusInternship, setModalStatusInternship] = useState("none");
    const removeItem = (index) => {
        const newEducation = education.filter((_, i) => i !== index);
        setEducation(newEducation);
    };
    const handleClick = () => {
        setModalStatus("add_education");
        console.log(modalStatus);
      };
    return (
        <>  
            {mode === "education" && (
                <div className="user__additionaInfo-education" id={mode}>
                    <div className="user__additionaInfo-nameGroup">
                        <div className="user__additionaInfo-title">{text_section}</div>
                    </div>
                    {education.map((item, index) => (
                        <ChangeInfo
                            mode={mode}
                            startDate={item.start_date}
                            endDate={item.end_date}
                            organisationName={item.institution_name}
                            organisationType={item.organisationType}
                            region={item.region}
                            document={item.document}
                            logo=''
                            position={item.specialization}
                            workType={item.workType}
                            isHead={item.isHead}
                            onDelete={() => removeItem(index)}
                        />
                    ))}
                    <div className="buttonwrapper">
                        <button type="button" onClick={async () => {
                            await setModalStatus("add_education"); console.log(modalStatus);
                        }}>+</button>
                    </div>
                    
                    <Modals page="add_education" setModalStatus={setModalStatus} modalStatus={modalStatus} />   
                </div>
            )}
            {mode === "workExp" && (
                <div className="user__additionaInfo-education" id={mode}>
                    <div className="user__additionaInfo-nameGroup">
                        <div className="user__additionaInfo-title">{text_section}</div>
                    </div>
                    {education.map((item, index) => (
                        <ChangeInfo
                            mode={mode}
                            startDate={item.start_date}
                            endDate={item.end_date}
                            organisationName={item.company_name}
                            organisationType={item.organisationType}
                            region={item.region}
                            document={item.document}
                            logo=''
                            position={item.position}
                            workType={item.workType}
                            isHead={item.isHead}
                            onDelete={() => removeItem(index)}
                        />
                    ))}
                    <div className="buttonwrapper">
                        <button type="button" onClick={async () => {
                            await setModalStatusWork("add_work"); console.log(modalStatus);
                        }}>+</button>
                    </div>
                    
                    <Modals page="add_work" setModalStatus={setModalStatusWork} modalStatus={modalStatusWork} />   
                </div>
            )}
            {mode === "msu" && (
                <div className="user__additionaInfo-education" id={mode}>
                    <div className="user__additionaInfo-nameGroup">
                        <div className="user__additionaInfo-title">{text_section}</div>
                    </div>
                    {education.map((item, index) => (
                        <ChangeInfo
                            mode={mode}
                            startDate={item.start_date}
                            endDate={item.end_date}
                            organisationName={item.organization_name}
                            organisationType={item.organisationType}
                            region={item.region}
                            document={item.document}
                            logo=''
                            position={item.position}
                            workType={item.workType}
                            isHead={item.isHead}
                            onDelete={() => removeItem(index)}
                        />
                    ))}
                    <div className="buttonwrapper">
                        <button type="button" onClick={async () => {
                            await setModalStatusMSU("add_msu"); console.log(modalStatus);
                        }}>+</button>
                    </div>
                    
                    <Modals page="add_msu" setModalStatus={setModalStatusMSU} modalStatus={modalStatusMSU} />  
                </div>
            )}
            {mode === "internships" && (
                <div className="user__additionaInfo-education" id={mode}>
                    <div className="user__additionaInfo-nameGroup">
                        <div className="user__additionaInfo-title">{text_section}</div>
                    </div>
                    {education.map((item, index) => (
                        <ChangeInfo
                            mode={mode}
                            startDate={item.start_date}
                            endDate={item.end_date}
                            organisationName={item.organization_name}
                            organisationType={item.organisationType}
                            region={item.region}
                            document={item.document}
                            logo=''
                            position={item.position}
                            workType={item.workType}
                            isHead={item.isHead}
                            onDelete={() => removeItem(index)}
                        />
                    ))}
                    <div className="buttonwrapper">
                        <button type="button" onClick={async () => {
                            await setModalStatusInternship("add_internship"); console.log(modalStatus);
                        }}>+</button>
                    </div>
                    
                    <Modals page="add_internship" setModalStatus={setModalStatusInternship} modalStatus={modalStatusInternship} />
                </div>
            )}
        </>
    );
}
