import React from "react";

import NotFoundPage from "../../pages/NotFoundPage/NotFoundPage";
import Header from "../../components/Header/Header";

export default function LoadingLayout({ loading, successLoading, children }) {
    return(
        <>
            <Header/>
            {(loading && !successLoading) && <div>Загрузка</div>}
            {(!loading && !successLoading) && <NotFoundPage header={false}/>}
            {(!loading && successLoading) && <>{children}</>}
        </>
    );
}
