import React from "react";

import "./Title.css";

export default function Title({ text, className }) {
  return (
    <div
      className={`
    title 
    ${className}
`}
    >
      <div>{text}</div>
    </div>
  );
}
