import no_user_img from "../../images/other/no-image.png";

const initialState = {
  first_name: "",
  first_name_translated: "",
  last_name: "",
  last_name_translated: "",
  middle_name: "",
  middle_name_translated: "",
  date_of_birth: "",
  requests: {
    all: 0,
    accepted: 0,
    waited: 0,
    rejected: 0
  },
  sex: "",
  email: "",
  faculty: "",
  faculty_id: "",
  type_of_learning: "",
  start_date: "",
  student_id: "",
  vk: "",
  telegram:"",
  has_student_id: false,
  phone_number: "",
  academic_level: "",
  grade_level: 3,
  end_date: "",
  event: [],
  education: [],
  competency: [],
  internship: [],
  msumembership: [],
  workexperience:[],
  social: [],
  password: "",
  image: no_user_img,
  access: {},
  role: "Администратор",
  status: "",
  card: {},
  //1-Нет карты 2-На изготовлении 3-Изготовлена 4-Выдана
  entry_date: "",
  id: -1,
  notification_email: false,
  notification_sms: false,
  status_gain_date: false,
  was_verificated: false,
  was_verificated_twice: false,
  token: null,
  isLoaded: false,
  users: [],
  usersCard: [],
  inputStartValue: 1,
  inputEndValue: 10,
  rzd_number: "",

};

const logOut = {
  first_name: "",
  first_name_translated: "",
  last_name: "",
  last_name_translated: "",
  middle_name: "",
  middle_name_translated: "",
  date_of_birth: "",
  requests: {
    all: 0,
    accepted: 0,
    waited: 0,
    rejected: 0
  },
  sex: "",
  email: "",
  faculty: "",
  faculty_id: "",
  type_of_learning: "",
  start_date: "",
  student_id: "",
  has_student_id: "",
  phone_number: "",
  academic_level: "",
  grade_level: "",
  end_date: "",
  password: "",
  image: "",
  access: {},
  role: "",
  status: "",
  card: "",
  entry_date: "",
  id: "",
  notification_email: "",
  notification_sms: "",
  status_gain_date: "",
  was_verificated: "",
  was_verificated_twice: "",
  token: "",
  rzd_number: "",
  rzd_status: ""
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case "token":
      return {
        ...state,
        token: action.payload,
      };
    case "email":
      return {
        ...state,
        email: action.payload,
      };
    case "password":
      return {
        ...state,
        password: action.payload,
      };
    case "first_name":
      return {
        ...state,
        first_name: action.payload,
      };
    case "vk":
      return {
        ...state,
        vk: action.payload,
      };
    case "telegram":
      return {
        ...state,
        telegram: action.payload,
      };
    case "first_name_translated":
      return {
        ...state,
        first_name_translated: action.payload,
      };
    case "last_name":
      return {
        ...state,
        last_name: action.payload,
      };
    case "last_name_translated":
      return {
        ...state,
        last_name_translated: action.payload,
      };
    case "middle_name":
      return {
        ...state,
        middle_name: action.payload,
      };
    case "middle_name_translated":
      return {
        ...state,
        middle_name_translated: action.payload,
      };
    case "date_of_birth":
      return {
        ...state,
        date_of_birth: action.payload,
      };
      case "requests":
        return {
          ...state,
          requests: action.payload,
        };
    case "sex":
      return {
        ...state,
        sex: action.payload,
      };
    case "access":
      return {
        ...state,
        access: action.payload,
      };
    case "event":
      return {
        ...state,
        event: action.payload,
      };
    case "internship":
      return {
        ...state,
        internship: action.payload,
      };
    case "msumembership":
      return {
        ...state,
        msumembership: action.payload,
      };
    case "workexperience":
      return {
        ...state,
        workexperience: action.payload,
      };
    case "education":
      return {
        ...state,
        education: action.payload,
      };
    case "competency":
      return {
        ...state,
        competency: action.payload,
      };
    case "social":
      return {
        ...state,
        social: action.payload,
      };
    case "faculty":
      return {
        ...state,
        faculty: action.payload,
      };
    case "faculty_id":
      return {
        ...state,
        faculty_id: action.payload,
      };
    case "type_of_learning":
      return {
        ...state,
        type_of_learning: action.payload,
      };
    case "start_date":
      return {
        ...state,
        start_date: action.payload,
      };
    case "student_id":
      return {
        ...state,
        student_id: action.payload,
      };
    case "has_student_id":
      return {
        ...state,
        has_student_id: action.payload,
      };
    case "phone_number":
      return {
        ...state,
        phone_number: action.payload,
      };
    case "academic_level":
      return {
        ...state,
        academic_level: action.payload,
      };
    case "grade_level":
      return {
        ...state,
        grade_level: action.payload,
      };
    case "end_date":
      return {
        ...state,
        end_date: action.payload,
      };
    case "image":
      return {
        ...state,
        image: action.payload,
      };
    case "role":
      return {
        ...state,
        role: action.payload,
      };
    case "card":
      // const getStatusCard = (obj) => {
      //   for (let key in obj) {
      //     if (key === "status") {
      //       let resStatusCard = obj[key];
      //       return resStatusCard;
      //     }
      //   }
      // };
      // const cardStatus = getStatusCard(action.payload);
      return {
        ...state,
        card: action.payload,
      };
    case "status":
      return {
        ...state,
        status: action.payload,
      };
    case "entry_date":
      return {
        ...state,
        entry_date: action.payload,
      };
    case "id":
      return {
        ...state,
        id: action.payload,
      };
    case "notification_email":
      return {
        ...state,
        notification_email: action.payload,
      };
    case "notification_sms":
      return {
        ...state,
        notification_sms: action.payload,
      };
    case "status_gain_date":
      return {
        ...state,
        status_gain_date: action.payload,
      };
    case "was_verificated":
      return {
        ...state,
        was_verificated: action.payload,
      };
    case "was_verificated_twice":
      return {
        ...state,
        was_verificated_twice: action.payload,
      };
    case "logOut":
      return {
        ...logOut,
      };
    case "setUsersList":
      return {
        ...state,
        users: action.payload,
        isLoaded: true,
      };
    case "setUsersListCard":
      return {
        ...state,
        usersCard: action.payload,
        isLoaded: true,
      };
    case "set_loaded":
      return {
        ...state,
        isLoaded: action.payload,
      };
    case "setFilterUserList":
      return {
        ...state,
        inputStartValue: action.payload.inputStartValue,
        inputEndValue: action.payload.inputEndValue,
      };
    case "rzd_number":
      return {
        ...state,
        rzd_number: action.payload,
      };

    default:
      return state;
  }
};

export default profile;
