import { useState, useEffect } from "react";

import { Functions } from "../../interface/functions";

import Calendar from "../../components/Calendar/Calendar";

export default function DatesFilter({
    spendingTimeRange,
    setSpendingTimeRange,
    requestTimeRange,
    setRequestTimeRange,
}) {
    return (
        <>
            <Calendar
                dateRangeState={[spendingTimeRange, setSpendingTimeRange]}
                label={"Время проведения"}
                startDateState={[spendingTimeRange[0], spendingTimeRange[1]]}
            />
            <Calendar
                dateRangeState={[requestTimeRange, setRequestTimeRange]}
                label={"Период подачи заявки"}
                startDateState={[requestTimeRange[0], requestTimeRange[1]]}
            />
        </>
    );
}
