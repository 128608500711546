import { useState, useEffect } from "react";

import SponsorsList from "../../components/SponsorsList/SponsorsList";
import Header from "../../components/Header/Header";
import GoBack from "../../UI/Button/GoBack";

import { REQUEST_URL } from "../../interface/config";
import { HTTP } from "../../interface/HTTP";

// import "./Sponsors.css";

export default function Sponsors() {
    const [sponsorsList, setSponsorsList] = useState([]);

    const eventsListRequest = async () => {
        // setIsLoading(true);
        let requesrList = await HTTP.Get(REQUEST_URL.getSponsorsList);
        if (requesrList) {
            setSponsorsList(requesrList.results ?? []);
        }
        // setIsLoading(false);
    };

    useEffect(() => {
        eventsListRequest();
    }, []);

    return (
        <>
            <Header />
            <div className="sponsors">
                <div className="main">
                    <div className="container-content center">
                        <GoBack text={"Назад"} type="button" />
                        <SponsorsList cardsList={sponsorsList} title={"Спонсоры"} />
                    </div>
                </div>
            </div>
        </>
    );
}
