export default class ConvertData{
    static getData(type, key, listItems){

        let returnData = undefined;
        // if (type === "academic_level"){
        //     listItems.forEach(item => {
        //         if (item.id === key){
        //             returnData =  item.title;
        //         }
        //     })
        // }
        // if (type === "type_of_learning"){
        //     listItems.forEach(item => {
        //         if (item.id === key){
        //             returnData = item.title;
        //         }
        //     })
        // }
        // else{
        //     console.error("Type not found");
        // }

        return returnData;
    }
}