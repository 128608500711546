import DatePicker, { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import { forwardRef } from "react";

import button_calendar from "../../images/calendar/button_calendar.svg";

import "./Calendar.css";
import "./CustomCalendar.css";

export default function Calendar({ dateRangeState, startDateState, label }) {
    const [dateRange, setDateRange] = dateRangeState;
    const [startDate, endDate] = startDateState;

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className="calendar-input">
            <div className="calendar-input__date" ref={ref}>
                {value}
            </div>
            <img src={button_calendar} className="calendar-input__button" onClick={onClick}></img>
        </div>
    ));

    registerLocale("ru", ru);

    return (
        <div className="calendar">
            {label !== undefined && <p className="calendar__label">{label}</p>}
            <DatePicker
                customInput={<ExampleCustomInput />}
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={update => {
                    setDateRange(update);
                }}
                locale="ru"
                // isClearable={true}
                dateFormat="dd MMM yyyy"
                showMonthDropdown
            />
        </div>
    );
}
