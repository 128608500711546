import ImgEvent from "../../images/event/event.png";

export const listEvents = [
  // {
  //   id: 1,
  //   img: ImgEvent,
  //   title: "Акция “Помоги приюту”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Регистрация с 12.05-31.07",
  //   type_events: "Мои события",
  // },
  // {
  //   id: 2,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Заявка отклонена",
  //   type_events: "Мои события",
  // },
  // {
  //   id: 3,
  //   img: ImgEvent,
  //   title: "Акция “Помощь театру” им. Великого князя Язя",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Заявка подана",
  //   type_events: "Мои события",
  // },
  // {
  //   id: 4,
  //   img: ImgEvent,
  //   title: "Акция “Помоги приюту”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Не пришел",
  //   type_events: "Мои события",
  // },
  // {
  //   id: 5,
  //   img: ImgEvent,
  //   title: "Акция “Помоги приюту”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Участвую",
  //   type_events: "Мои события",
  // },
  // {
  //   id: 6,
  //   img: ImgEvent,
  //   title: "Акция “Помоги приюту”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Посетил",
  //   type_events: "Мои события",
  // },
  // {
  //   id: 7,
  //   img: ImgEvent,
  //   title: "Акция “Помоги приюту”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Событие завершилось",
  //   type_events: "Мои события",
  // },
  // {
  //   id: 8,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Не пришел",
  //   type_events: "Мои события",
  // },
  // {
  //   id: 9,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Участвую",
  //   type_events: "Мои события",
  // },
  // {
  //   id: 10,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Посетил",
  //   type_events: "Мои события",
  // },
  // {
  //   id: 11,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Событие свершилось",
  //   type_events: "Мои события",
  // },
  // {
  //   id: 12,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Заявка отклонена",
  //   type_events: "Мои события",
  // },
  // {
  //   id: 13,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Заявка отклонена",
  //   type_events: "Мои события",
  // },
  // {
  //   id: 14,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Заявка отклонена",
  //   type_events: "Мои события",
  // },
  // {
  //   id: 15,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Заявка отклонена",
  //   type_events: "Актуальные события",
  // },
  // {
  //   id: 16,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Не пришел",
  //   type_events: "Актуальные события",
  // },
  // {
  //   id: 17,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Заявка отклонена",
  //   type_events: "Актуальные события",
  // },
  // {
  //   id: 18,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Событие завершилось",
  //   type_events: "Актуальные события",
  // },
  // {
  //   id: 19,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Участвую",
  //   type_events: "Актуальные события",
  // },
  // {
  //   id: 20,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Регистрация с 12.05-31.07",
  //   type_events: "Актуальные события",
  // },
  // {
  //   id: 21,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Посетил",
  //   type_events: "Мои события",
  // },
  // {
  //   id: 22,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Посетил",
  //   type_events: "Мои события",
  // },
  // {
  //   id: 23,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Посетил",
  //   type_events: "Мои события",
  // },
  // {
  //   id: 24,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Посетил",
  //   type_events: "Мои события",
  // },
  // {
  //   id: 25,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Посетил",
  //   type_events: "Мои события",
  // },
  // {
  //   id: 26,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Посетил",
  //   type_events: "Мои события",
  // },
  // {
  //   id: 27,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Посетил",
  //   type_events: "Мои события",
  // },
  // {
  //   id: 28,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Посетил",
  //   type_events: "Мои события",
  // },
];

export const type_of_Events = [
  "Регистрация",
  "Заявка отклонена",
  "Заявка подана",
  "Не пришел",
  "Участвую",
  "Посетил",
  "Событие завершилось",
];

export const typeOfEvents = {
  registration : "Регистрация",
  request_denied: "Заявка отклонена",
  request_success: "Заявка подана",
  not_going: "Не пришел",
  participate: "Участвую",
  visited: "Посетил",
  event_end: "Событие завершилось",
};

export const listEventsAdmin = [
  // {
  //   id: 1,
  //   img: ImgEvent,
  //   title: "Акция “Помоги приюту”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Регистрация с 12.05-31.07",
  // },
  // {
  //   id: 2,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Заявка отклонена",
  // },
  // {
  //   id: 3,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Заявка подана",
  // },
  // {
  //   id: 4,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Не пришел",
  // },
  // {
  //   id: 5,
  //   img: ImgEvent,
  //   title: "Акция “Помоги приюту”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Регистрация с 12.05-31.07",
  // },
  // {
  //   id: 6,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Заявка отклонена",
  // },
  // {
  //   id: 7,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Заявка подана",
  // },
  // {
  //   id: 8,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Не пришел",
  // },
  // {
  //   id: 9,
  //   img: ImgEvent,
  //   title: "Акция “Акция “Помощь театру” им. Великого князя Язя”",
  //   description: "12 – 25 февраля 2023, 08:00 - 20:00 пппппппп",
  //   location: "Россия, Москва",
  //   status: "Не пришел",
  // },
];


export const type_of_events_admin = [
    "Все",
    "Регистрация",
    "Заявка отклонена",
    "Заявка подана",
    "Не пришел",
  ];