import { NavLink } from "react-router-dom";
import { useState } from "react";

import "./LogoGroup.css";

import mguLogo from "../../images/other/mgu_logo_reg.webp";
import ampLogo from "../../images/other/header_logo.webp";


export default function LogoGroup() {

    return (
        <>
            <div className="mgu__logo-group">
                <div className="mgu__logo-group-container">
                <a href="https://molprav.ru" target="_blank">
                    <img src={ampLogo} alt="АМП" className="mgu__logo-group__logo" />
                </a>
                </div>
                {/* <div className="mgu__text">
                    <div className="mgu__text-lg">АССОЦИАЦИЯ МОЛОДЁЖНЫХ ПРАВИТЕЛЬСТВ</div>
                    <div className="mgu__text-md">РОССИЙСКОЙ ФЕДЕРАЦИИ</div>
                </div> */}
            </div>
        </>
    );
}

