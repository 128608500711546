import { debounceSearchRender } from "mui-datatables";
import InputSearch from "../UI/InputSearch/InputSearch";

export const tablesConfig = {
  card_admin: {
    tabs: {
      no_map: "Нет карты",
      make: "На изготовлении",
      unload: "Выгруженные",
      made: "Изготовлены",
      issued: "Выданы",
    },
    requests: {
      no_map: "?card-status=NO",
      make: "?card-status=MAKE",
      unload: "?card-status=UNLOAD",
      made: "?card-status=MADE",
      issued: "?card-status=ISSUED",
    },
    buttonsStatus: {
      MAKE: ["НАЧАТЬ ФОРМИРОВАНИЕ КАРТЫ", "ЗАБРАТЬ КАРТУ", "ВЕРНУТЬ 'НА ИЗГОТОВЛЕНИИ'"],
      UNLOAD: ["ИЗГОТОВИТЬ КАРТУ", "ВЕРНУТЬ 'ВЫГРУЖЕННЫЕ'"],
      NO: "ВЕРНУТЬ 'НЕТ КАРТЫ'",
      MADE: ["ВЕРНУТЬ 'ИЗГОТОВЛЕНЫ'", "ВЫДАТЬ КАРТУ"],
      ISSUED: "ЗАБРАТЬ КАРТУ",
      DOWNLOAD_PHOTO: "СКАЧАТЬ ФОТО",
      ARCH: "АРХИВИРОВАТЬ"
      
    },
    detailInfo: false,
  },
  table: {
    tabs: {
      all: "Все",
      current: "Новые",
      applications: "Заявки",
      users_prof: "АМП",
      kick: "Исключённые",
    },
    requests: {
      all: "",
      current: "?status=NEW",
      applications: "?status=APPL",
      users_prof: "?status=MEMBER",
      kick: "?status=EXCLUDE",
    },
    buttonsStatus: {
      EXCLUDE: ["ОТКЛОНИТЬ", "ИСКЛЮЧИТЬ"],
      MEMBER: "ПРИНЯТЬ",
      APPL: ["ВЕРИФИЦИРОВАТЬ", "ПРИНЯТЬ ОБРАТНО"],
      NO: "ПЕРЕВЫПУСТИТЬ КАРТУ",
      DELETE: "УДАЛИТЬ",
      STATEMENT: "ЗАЯВЛЕНИЕ",
      SAVE: "СОХРАНИТЬ",
      ARCH: "АРХИВИРОВАТЬ"
    },
    detailInfo: true,
  },
  rzd_bonus: {
    tabs: {
      FILLED: "Номер РЖД",
      PASSED: "Передан в РЖД",
    },
    requests: {
      FILLED: "",
      PASSED: "",
    },
    buttonsStatus: {
      PASSED: "ПЕРЕДАТЬ В РЖД",
    },
    detailInfo: true,
  },
  peoples_responses: {
    buttonsStatus: {
      REJECT: "ОТКЛОНИТЬ",
      ACCEPT: "ОДОБРИТЬ",
    },
  },

  state: {
    isRowClicked: false,
    idUser: "",
    rowsSelected: [],
  },

  options: {
    textLabels: {
      body: {
        noMatch: "Таблица пуста",
      },
    },
    caseSensitive: false,
    download: "disabled",
    filterType: "multiselect",
    print: false,
    rowsPerPageOptions: [],
    searchAlwaysOpen: true,
    selectableRows: "multiple",
    selectableRowsOnClick: true,
    searchOpen: true,
    customSearchRender: (searchText, handleSearch) => {
      return <InputSearch handleInputChange={handleSearch} value={searchText} onSearch={handleSearch} mode = "search" />;
    },
    selectToolbarPlacement: "none",
    responsive: "standard",
    customToolbarSelect: () => {},
    customTableBodyFooterRender: (options) => {
      return (
        <tfoot style={{ height: "50px", width: "100%" }}>
          <tr>
            <td>
              <div style={{ position: "absolute", left: "20px", bottom: "15px" }}>
                Выбранно пользователей: {options.selectedRows.data.length}
              </div>
            </td>
          </tr>
        </tfoot>
      );
    },
  },
  noRemoveFromTable: ["NO", "STATEMENT"],
};

export const tableLangConfig = {
  body: {
    noMatch: "Пусто",
    toolTip: "Сортировка",
    columnHeaderTooltip: (column) => `Сортировка для ${column.label}`,
  },
  pagination: {
    next: "Следующая страница",
    previous: "Предыдущая страница",
    rowsPerPage: "Строк на странице:",
    displayRows: "из",
  },
  toolbar: {
    search: "Поиск",
    downloadCsv: "Скачать CSV",
    print: "Печать",
    viewColumns: "Вид колонок",
    filterTable: "Фильтровать таблицу",
  },
  filter: {
    all: "Все",
    title: "Фильтры",
    reset: "Сбросить",
  },
  viewColumns: {
    title: "Колонки",
    titleAria: "Скрыть/показать колонки",
  },
  selectedRows: {
    text: "пользователей выбрано",
    delete: "Удалить",
    deleteAria: "Удалить выбранных пользователей",
  },
};
