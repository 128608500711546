import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Header from "../../components/Header/Header";
import Title from "../../UI/Title/Title";
import { HTTP } from "../../interface/HTTP";
import { REQUEST_URL, routerPath } from "../../interface/config";
import CardDescription from "../../components/CardDescription/CardDescription";
import Questionnaire from "../../components/Questionnaire/Questionnaire";

import "./SocialProgram.css";

export default function SocialProgram() {
    const { social } = useParams();

    const event_data = {
        title: "Помощь студентам совмещающим работу и учебу",
        description: "wercyercyercywercyercyercywercyercyercy",
        image: "https://i.pinimg.com/originals/ad/a2/70/ada2702c50a2c3dd2697774fd0c39bee.jpg",
        text: "Наша социальная программа направлена на улучшение всех возможных качеств, которые помогут студентам.. Всех благ и котиков Очень интересное событие, которое ОБЯЗАН посетить любой уважающий себя человечек. Всех благ и котиков",
    };
    const [eventData, setEventData] = useState(event_data);

    useEffect(() => {
        const getEventData = async () => {
            let requesrList = await HTTP.Get(REQUEST_URL.detailSocial(social));
            if (requesrList) {
                setEventData(requesrList);
            }
        };

        getEventData();
    }, []);
    const questions = [
        {
            name: "question_1",
            questions: "Если ли у вас фотоаппарат?",
            type: "text",
            placeholder: "Мой ответ",
        },
        {
            name: "question_2",
            questions: "Если ли у вас фотоаппарат?",
            type: "radio",
            options: [
                {
                    name: "options-1",
                    value: "",
                    text: "Да",
                },
                {
                    name: "options-1",
                    value: "",
                    text: "Нет",
                },
            ],
        },
        {
            name: "question_3",
            questions: "Если ли у вас фотоаппарат?",
            type: "checkbox",
            options: [
                {
                    name: "checkbox-1",
                    value: "",
                    text: "Вариант 1",
                },
                {
                    name: "checkbox-2",
                    value: "",
                    text: "Вариант 2",
                },
            ],
        },
        {
            name: "question_4",
            questions: "Когда вы купили фотоаппарат?",
            type: "text",
            placeholder: "дд.мм.гггг",
            mask: "00.00.0000",
        },
        {
            name: "question_5",
            questions: "Во сколько вы ложитесь спать?",
            type: "number",
            placeholder: "00",
        },
    ];

    return (
        <>
            <Header />
            <div className="about-event">
                <Title text="О социальной программе" className={"page-title"} />
                <div className="about-event__container">
                    <CardDescription event={eventData} />
                    <Questionnaire questions={questions} title={"Подача заявки"} type="social" />
                </div>
            </div>
        </>
    );
}
