import DownloadFile from "../DownloadFile/DownloadFile";

import { Functions } from "../../interface/functions";

import {ReactComponent as CloseImage} from "../../images/documents/close.svg";

import "./DocumentItem.css";

export default function DocumentItem({ item, deleteFile, mode, deleteItem,  }) {
  // console.log('item', item)

  return (
    <>
      {mode === "admin_doc" && (
        <div className="document">
          <div className="document__wrapper document__wrapper-admin">
            <p className="document__title">{item}</p>
            <div className="download-file-close" onClick={() => deleteItem(item)}>
              <CloseImage  />
            </div>
          </div>
        </div>
      )}

      {mode === "student_doc" && (
        <div className="document">
          <div className="document__wrapper">
 
            {/* <p className="document__time">{"07.06.23 в 10:33"}</p>
            <DownloadFile
              progress={100}
              name={item.name}
              size={Functions.fileSizeConverter(item.size)}
              deleteFile={deleteFile}
              type={item.type.replace("application/", ".")}
              mode={"list"}
            /> */}
          </div>
        </div>
      )}
    </>
  );
}
