import React from "react";

import "./TagButton.css";

export default function TagButton({ text, color, type }) {
    return (
        <div
            className={`
    tag-button 
    tag-button__${color}
    ${type === "statistics" ? "tag-button__statistics" : ""} 
    `}
        >
            <div>{text}</div>
        </div>
    );
}