import { useEffect } from "react";

const useDisableBodyScroll = modalStatus => {
    useEffect(() => {
        if (modalStatus === "none") {
            document.body.style.overflow = "auto";
        } else {
            document.body.style.overflow = "hidden";
        }
    }, [modalStatus]);
};

export { useDisableBodyScroll };
