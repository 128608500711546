import { SERVER_URL } from "../../interface/HTTP";

import "./CardSponsor.css";

export default function CardSponsor({ item }) {
    return (
        <div className="card-sponsor">
            <div className="card-sponsor__image">
                <img src={SERVER_URL + item.image} alt={"спонсор"} />{" "}
            </div>
            <div className="card-sponsor__content">
                <p className="card-sponsor__title">{item.title}</p>
                <p className="card-sponsor__subname">{item.short_description}</p>
                <p className="card-sponsor__descr">{item.long_description}</p>
                <a href={item.link} target="_blank">
                    <div className="card-sponsor__button">Узнать больше</div>
                </a>
            </div>
        </div>
    );
}
