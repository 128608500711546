import React from "react";

import Input from "../../UI/Input/Input";

export default function CardZachetInfo({ detailUser }) {
    return (
        <div className="accounting-info">
            <div className="card-profile__list">
                <Input
                    className={"user"}
                    mode="fake"
                    text="Студенческий билет"
                    placeholder={detailUser && detailUser.student_id}
                />
            </div>
        </div>
    );
}
