// export const type_of_events = [
//   {
//     id: "",
//     title: "",
//   },
//   {
//     id: "ALL",
//     title: "Все",
//   },
//   {
//     id: "SUBMITTED",
//     title: "Заявка подана",
//   },
//   {
//     id: "ACTIVE",
//     title: "Активные",
//   },
//   {
//     id: "PAST",
//     title: "Прошедшие",
//   },
//   {
//     id: "COMPLETED",
//     title: "Мои завершенные мероприятия",
//   },
// ];


export const type_of_events = ["Все", "Регистрация", "Заявка отклонена", "Заявка подана", "Участвую", "Посетил", "Событие завершилось"];