import ControlButtonsItem from './ControlButtonsItem';
import openNotification from '../Modals/MessagePopup';
import Button from '../../UI/Button/Button';

import { HTTP } from '../../interface/HTTP';
import { UserActions } from '../../interface/userActions';

import { REQUEST_URL } from '../../interface/config';

import './ControlButtons.css';

export default function ControlButtons({type, usersListId, statistics = []}){

    const controlList = ['НОВЫХ ЧЛЕНОВ ПРОФСОЮЗА','ЗАНОВО ВЕРИФИЦИРОВАННЫХ ПОЛЬЗОВАТЕЛЕЙ','ТЕХ, КТО НЕ РАСПЕЧАТАЛ ПРОФБИЛЕТ','ВЫБРАННЫХ ПОЛЬЗОВАТЕЛЕЙ','БЮДЖЕТНИКОВ','КОНТРАКТНИКОВ',]

    const getFiles = async fileType => {
        if (usersListId.length > 0){
            openNotification({
                title: "Задача",
                text: "Идёт формирование данных, ожидайте",
                type: "success",
            });
            await HTTP.getDownloadFiles(REQUEST_URL.excelFiles(fileType), {ids: UserActions.getUsersIds(usersListId)}, "filename=", "POST");
        }
    }
    const getStatisticsFiles = async (fileType, statsList) => {
        openNotification({
            title: "Задача",
            text: "Идёт формирование данных, ожидайте",
            type: "success",
        });
        await HTTP.getDownloadFiles(REQUEST_URL.getDownloadStatistics(fileType), statsList, "filename=", "POST");
    }
    
    const setItems = () =>{
        let returnArr = []
        controlList.map((item, index) => {
            returnArr.push(<ControlButtonsItem key={index} title={item} />)
        })
        return returnArr;
    }

    return(
        <div className={type === '2_buttons'? "main-statistic-buttons" : "main-statistic-buttons main-statistic-buttons_more"}>
            <div className="main-statistic-buttons__title">Сохранить списком</div>
            {type === '2_buttons' && (
                <div className="main-statistic-buttons__wrapper">
                    <Button type='button' text='Сохранить Excel' color='blue' callback={() => (statistics.length > 0) ? getStatisticsFiles("excel", statistics): getFiles("excel")}/>
                    <Button type='button' text='Сохранить CSV' color='blue' callback={() => (statistics.length > 0) ? getStatisticsFiles("csv", statistics): getFiles("csv")}/>
                </div>
            )}
            {type === 'more_buttons' && (
                <div className="main-statistic-buttons__wrapper main-statistic-buttons__wrapper_more">
                    {setItems()}
                </div>
            )}
        </div>
    )
}