import { useSelector } from "react-redux";

import Button from "../../UI/Button/Button";

import "./ChangeFaculty.css";

export default function ChangeFaculty({ setModalStatus, mode }) {
    const userData = useSelector(({ profile }) => profile);

    return (
        <div className="faculty-change">
            <div className="faculty-change-title">
                {mode === "change_faculty" && <>Смена региона</>}
                {mode === "material_support" && <>Заявление на оказание материальной поддержки</>}
                {mode === "join_faculty" && <>Заявление на вступление</>}
            </div>
            <div className="faculty-change-description">
                {mode === "change_faculty" && (
                    <>
                        Для смены Региона вы также можете обратиться к председателю. Обратите
                        внимание, что рассмотрение заявки может занять от 2 до 7 дней.
                    </>
                )}
                {mode === "material_support" && (
                    <>После формирования файла вы должны его подписать и отнести в профком.</>
                )}
                {mode === "join_faculty" && (
                    <>
                        Заявки можете скидывать лично Андрею Мищенкову.
                    </>
                )}
            </div>
            {mode === "change_faculty" && (
                <>
                    <Button
                        text="СМЕНИТЬ Регион"
                        color="green"
                        className={"faculty-change-btn"}
                        callback={() => setModalStatus("change_faculty")}
                    />
                    {userData.status === "Членство приостановлено" && (
                        <div className="faculty-change-additional-button">
                            <Button
                                text="ВОССТАНОВИТЬ ЧЛЕНСТВО"
                                color="green"
                                className={"faculty-change-btn"}
                                callback={() => setModalStatus("restore_membership")}
                            />
                        </div>
                    )}
                    {/* {userData.status !== "Членство приостановлено" && (
                        <div className="faculty-change-additional-button">
                            <Button
                                text="ЗАКОНЧИЛ МГУ"
                                color="green"
                                className={"faculty-change-btn"}
                                callback={() => setModalStatus("finished_msu")}
                            />
                        </div>
                    )} */}
                </>
            )}
            {/* {mode === "material_support" && (
                <Button text="СФОРМИРОВАТЬ ДОКУМЕНТ" color="blue" callback={() => setModalStatus("material_support")} />
            )}
            {mode === "join_faculty" && (
                <>
                    <div className="faculty-change-button">
                        <Button
                            text="СФОРМИРОВАТЬ ДОКУМЕНТ"
                            color="blue"
                            callback={() => setModalStatus("join_faculty")}
                        />
                    </div>
                </>
            )} */}
        </div>
    );
}
