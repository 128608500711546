import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import CardsStatistics from "../../components/CardStatistics/CardsStatistics";
import ChangeFaculty from "../../components/ChangeFaculty/ChangeFaculty";
import ChangeProfile from "../../components/ChangeProfile/ChangeProfile";
import ProfkomInfo from "../../components/ProfkomInfo/ProfkomInfo";
import CardZachet from "../../components/CardZachet/CardZachet";
import Modals from "../../components/Modals/Modals/Modals";
import Header from "../../components/Header/Header.jsx";
import Button from "../../UI/Button/Button.jsx";
import Input from "../../UI/Input/Input.jsx";
import Radio from "../../UI/Radio/Radio";
import UserAdditionalInfo from "../../components/UserAdditionalInfo/UserAdditionalInfo";

import { ReactComponent as Success } from "../../images/other/success_message.svg";

import { ReactComponent as Birthday } from "../../images/profile/birthday.svg";
import { ReactComponent as Location } from "../../images/profile/location.svg";
import { ReactComponent as Mail } from "../../images/profile/mail.svg";
import { ReactComponent as Phone } from "../../images/profile/phone.svg";
import { ReactComponent as Vk_logo } from "../../images/other/vk_icon.svg";
import { ReactComponent as Tg_logo } from "../../images/other/tg_icon.svg";


import { useDisableBodyScroll } from "../../hooks/useDisableBodyScroll";
import { Functions } from "../../interface/functions";
import ConvertData from "../../interface/ConvertData";

import "./Profile.css";

export default function Profile() {

    let fakeData = {
        id: null,
        // это сейчас faculty_id ?
        telegram: "",
        vk: "dwwedewddedew",
        education: [
            {
                startDate: "05.2021",
                endDate: "03.2023",
                organisationName: "Наименование организации",
                position: "Специальность",
            },
        ],

        workExperience: [
            {
                startDate: "05.2021",
                endDate: "03.2023",
                organisationName: "Наименование организации",
                position: "Должность",
            },
        ],

        msu: [
            {
                startDate: "05.2021",
                endDate: "03.2023",
                region: "Регион",
                organisationType: "",
                document: undefined,
                organisationName: "Наименование организации",
                position: "Должность",
                logo: undefined,
            },
        ],

        internships: [
            {
                startDate: "05.2021",
                endDate: "03.2023",
                workType: "Форма деятельности",
                organisationType: "Форма деятельности",
                document: undefined,
                organisationName: "Наименование организации",
                position: "Должность",
                isHead: false,
                logo: undefined,
            },
        ],

        skills: ["Копирайтинг", "IT"],
    };


    const userData = useSelector(({ profile }) => profile);
    console.log(userData)
    const [modalStatus, setModalStatus] = useState("none");
    const [hideProfile, setHideProfile] = useState(false);

    const faculties = useSelector(({ facultiesList }) => [
        { id: "", title: "Выберите Регион" },
        ...facultiesList.facultiesList,
    ]);

    const setUserInfoTitle = useMemo(() => {
        if (userData.role === "Администратор") {
            return "Администратор";
        } else if (userData.role === "Председатель профкома") {
            return "Председатель профкома";
        } else {
            switch (userData.status) {
                case "Новый пользователь":
                    return "Новый пользователь";
                case "Подал заявку":
                    return "Подал заявку";
                case "Верефицированный пользователь":
                    return "Профиль подтверждён";
                case "Член профсоюза":
                    return "Член профсоюза";
                case "Членство приостановлено":
                    return "Членство приостановлено";
                case "Архивированный пользователь":
                    return "Архивированный пользователь";
                default:
                    return "";
            }
        }
    }, [userData.role]);

    const statisticList = [
        {
            name: "Одобрено заявок",
            quantity: "182",
        },
        {
            name: "Непринятые заявки",
            quantity: "0",
        },
        {
            name: "Посещенные мероприятия",
            quantity: "0",
        },
        {
            name: "Непосещенные мероприятия",
            quantity: "7",
        },
    ];

    useEffect(() => {
        if (userData.image) {
            Functions.setUserPhoto(userData.image);
        }
    }, [userData.image]);

    return (
        <>
            <Header />
            <div className="profile container-content">
                <div className="main">
                    <div
                        className={
                            userData.status === "Членство приостановлено"
                                ? "main__account main__account-kick"
                                : "main__account"
                        }
                    >
                        {hideProfile ? (
                            <ChangeProfile setHideProfile={setHideProfile} />
                        ) : (
                            <>
                                <div className="main__account-user">
                                    {!hideProfile && (
                                        <Button
                                            className="account-user__settings"
                                            type="button"
                                            text="Редактировать"
                                            callback={() => {
                                                setModalStatus("change_profile");
                                                setHideProfile(true);
                                            }}
                                            color={"transparent"}
                                        />
                                    )}
                                    <div className="account-user-photo">
                                        <img src={Functions.setUserPhoto(userData.image)} alt="user" />
                                    </div>
                                    <div className="account-user__container">
                                        <div className="account-user__name">
                                            <Input
                                                mode="fake"
                                                placeholder={`${userData.last_name}`}
                                            />
                                            <Input
                                                mode="fake"
                                                placeholder={`${userData.first_name}`}
                                            />
                                            <Input
                                                mode="fake"
                                                placeholder={
                                                    userData.middle_name || userData.middle_name_translated
                                                        ? `${userData.middle_name}`
                                                        : ""
                                                }
                                            />
                                        </div>
                                        <div className="account-user__wrapper account-user__wrapper-maininfo">
                                            <div className="account-user__group">
                                                <div className="account-user__icon">
                                                    ID
                                                </div>
                                                <div className="account-user__id">
                                                    <Input
                                                        mode="fake"
                                                        placeholder={`${userData.faculty_id}`}
                                                    />
                                                </div>
                                            </div>
                                            <div className="account-user__group">
                                                <div className="account-user__icon">
                                                    <Birthday />
                                                </div>
                                                <Input className="account-user__date" mode="fake" placeholder={userData.date_of_birth} />
                                            </div> <div className="account-user__group">
                                                <div className="account-user__icon">
                                                    <Location />
                                                </div>
                                                <Input className="account-user__place" id="faculty" mode="fake" placeholder={userData.faculty} />
                                            </div><div className="account-user__group">
                                                <div className="account-user__icon">
                                                    <Phone />
                                                </div>
                                                <Input className="account-user__number" id="phone_number" mode="fake" placeholder={userData.phone_number} />
                                            </div>
                                            <div className="account-user__group">
                                                <div className="account-user__icon">
                                                    <Mail />
                                                </div>
                                                <Input className="account-user__email" id="email" mode="fake" placeholder={userData.email} />
                                            </div>
                                            <div className="account-user__links">
                                                <a href={userData.vk} className="account-user__sns-link" id="vk">
                                                    <Vk_logo className="account-user__icon" />
                                                </a>
                                                <a href={userData.telegram} className="account-user__sns-link" id="telegram">
                                                    <Tg_logo className="account-user__icon" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="user__additionaInfo">
                                    <div className="user__additionaInfo__wrapper" id="education">
                                        <div className="user__additionaInfo-nameGroup">
                                            <div className="user__additionaInfo-title">Образование</div>
                                        </div>
                                        {userData?.education?.map((item) => (
                                            <UserAdditionalInfo
                                                startDate={item.start_date}
                                                endDate={item.end_date}
                                                organisationName={item.institution_name}
                                                organisationType={item.specialization}
                                                region={item.region}
                                                document={item.document}
                                                logo=''
                                                position={item.specialization}
                                                workType={item.workType}
                                                isHead={item.isHead}
                                            />
                                        ))}
                                        {userData?.education.length === 0 ? (
                                            <>
                                                <Button
                                                    className="edit_button__settings"
                                                    type="button"
                                                    text="Добавить информацию"
                                                    callback={() => {
                                                        setModalStatus("change_profile");
                                                        setHideProfile(true);
                                                    }}
                                                    color={"transparent"}
                                                />
                                            </>
                                        ) : (
                                            <>
                                            </>
                                        ) }

                                    </div>

                                    <div className="user__additionaInfo__wrapper" id="workExp">
                                        <div className="user__additionaInfo-nameGroup">
                                            <div className="user__additionaInfo-title">Опыт работы</div>
                                        </div>
                                        {userData?.workexperience?.map((item) => (
                                            <UserAdditionalInfo
                                                startDate={item.start_date}
                                                endDate={item.end_date}
                                                organisationName={item.company_name}
                                                organisationType={item.organisationType}
                                                region={item.region}
                                                document={item.document}
                                                logo=''
                                                position={item.position}
                                                workType={item.workType}
                                                isHead={item.isHead}
                                            />
                                        ))}
                                        {userData?.workexperience.length === 0 ? (
                                            <>
                                                <Button
                                                    className="edit_button__settings"
                                                    type="button"
                                                    text="Добавить информацию"
                                                    callback={() => {
                                                        setModalStatus("change_profile");
                                                        setHideProfile(true);
                                                    }}
                                                    color={"transparent"}
                                                />
                                            </>
                                        ) : (
                                            <>
                                            </>
                                        ) }
                                    </div>
                                </div>

                                <div className="user__additionaInfo-skills user__additionaInfo__wrapper">
                                    <div className="user__additionaInfo-nameGroup">
                                        <div className="user__additionaInfo-title">Компетенции</div>
                                    </div>
                                    <div className="user__additionaInfo-content">
                                        {userData.competency.map((item) => (
                                            <div className="user__additionaInfo-content__skillItem">{item.name}</div>
                                        ))}
                                        {userData?.competency.length === 0 ? (
                                                <>
                                                    <Button
                                                        className="edit_button__settings"
                                                        type="button"
                                                        text="Добавить информацию"
                                                        callback={() => {
                                                            setModalStatus("change_profile");
                                                            setHideProfile(true);
                                                        }}
                                                        color={"transparent"}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                </>
                                            ) }
                                    </div>
                                </div>
                                <div className="user__additionaInfo">
                                    <div className="user__additionaInfo__wrapper" id="msu">
                                    <div className="user__additionaInfo-nameGroup">
                                            <div className="user__additionaInfo-title">Членство в МСУ</div>
                                        </div>
                                            {userData?.msumembership?.map((item) => (
                                                <UserAdditionalInfo
                                                    startDate={item.start_date}
                                                    endDate={item.end_date}
                                                    organisationName={item.organization_name}
                                                    organisationType={item.organisationType}
                                                    region={item.region}
                                                    document={item.document}
                                                    logo=''
                                                    position={item.position}
                                                    workType={item.workType}
                                                    isHead={item.isHead}
                                                />
                                            ))}
                                            {userData?.msumembership.length === 0 ? (
                                                <>
                                                    <Button
                                                        className="edit_button__settings"
                                                        type="button"
                                                        text="Добавить информацию"
                                                        callback={() => {
                                                            setModalStatus("change_profile");
                                                            setHideProfile(true);
                                                        }}
                                                        color={"transparent"}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                </>
                                            ) }
                                    </div>
                                    <div className="user__additionaInfo__wrapper" id="internships">
                                    <div className="user__additionaInfo-nameGroup">
                                            <div className="user__additionaInfo-title">Стажировка / Практика / Трудоустройсво</div>
                                        </div>
                                            {userData?.internship?.map((item) => (
                                                <UserAdditionalInfo
                                                    mode="internships"
                                                    startDate={item.start_date}
                                                    endDate={item.end_date}
                                                    organisationName={item.organization_name}
                                                    organisationType={item.organisationType}
                                                    region={item.region}
                                                    document={item.document}
                                                    logo=''
                                                    position={item.position}
                                                    workType={item.workType}
                                                    isHead={item.isHead}
                                                />
                                            ))}
                                            {userData?.internship.length === 0 ? (
                                                <>
                                                    <Button
                                                        className="edit_button__settings"
                                                        type="button"
                                                        text="Добавить информацию"
                                                        callback={() => {
                                                            setModalStatus("change_profile");
                                                            setHideProfile(true);
                                                        }}
                                                        color={"transparent"}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                </>
                                            ) }
                                    </div>
                                </div>

                                {/* <div className="user__participations">
                                <div className="user__participations__section" id="internships">
                                        <div className="user__participations__section-titleGroup">
                                            <div className="user__participations__section-title">Стажировка / Практика / Трудоустройсво</div>
                                            <div className="user__participations__section-showAll"><button >Смотреть все</button></div>
                                        </div>
                                        <div className="user__participations__section__wrapper ">
                                            {fakeData.internships.map((item) => (
                                                <UserAdditionalInfo
                                                    mode="internships"
                                                    startDate={item.startDate}
                                                    endDate={item.endDate}
                                                    organisationName={item.organisationName}
                                                    organisationType={item.organisationType}
                                                    region={item.region}
                                                    document={item.document}
                                                    logo=''
                                                    position={item.position}
                                                    workType={item.workType}
                                                    isHead={item.isHead}
                                                />
                                            ))}
                                        </div>
                                    </div>
                                </div> */}


                                {/* <div className="main__account-fakeForm">

                                    <Input mode="fake" text="Пол" placeholder={Functions.setUserSex(userData)} />
                                    <Input
                                        mode="fake"
                                        text="Орган самоуправления"
                                        placeholder={ConvertData.getData(
                                            "type_of_learning",
                                            userData.type_of_learning,
                                            faculties.filter(item => Number(item.id) === Number(userData.faculty_id))[0]
                                                ?.type_of_learning ?? []
                                        )}
                                    />

                                </div> */}
                            </>
                        )}
                        {(userData.status === "Новый пользователь" ||
                            userData.status === "Подал заявку" ||
                            userData.status === "Член профсоюза" ||
                            userData.status === "Членство приостановлено") && (
                                <div className="main__account-editLoad">

                                    {/* <Button
                  type="button"
                  text="СОХРАНИТЬ"
                  // callback={() => setModalStatus("change_profile")}
                  color={"violet"}
                /> */}
                                </div>
                            )}
                    </div>

                    {/* <div className="main__info">
                        {userData.status === "USER_KICK" && <ChangeFaculty setModalStatus={setModalStatus} />}
                        {userData.status !== "USER_KICK" && (
                            <>
                                {(userData.status === "Член профсоюза" ||
                                    userData.status === "Членство приостановлено") && (
                                    <>
                                        {(userData.role === "Член профсоюза" || userData.role === "Пользователь") && (
                                            <ChangeFaculty mode="change_faculty" setModalStatus={setModalStatus} />
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        {userData.status === "Член профсоюза" && (
                            <CardZachet
                                cardStatus={userData.card?.status ?? null}
                                userStatus={userData.status}
                                imgStatus={
                                    userData.image === true ||
                                    (userData.image && !userData.image.endsWith("no-image.png"))
                                        ? true
                                        : false
                                }
                            />
                        )}
                        <ProfkomInfo user_type={userData.status} />
                        {userData.requests && (
                            <div className="main__account-stats">
                                <div className={`${"title"} ${"main__account-stats__title"}`}>
                                    <div>Статистика по мероприятиям</div>
                                </div>
                                <CardsStatistics statisticList={userData.requests} className={"main__account-stat"} />
                            </div>
                        )}
                    </div> */}
                </div>
            </div>
            {/* <Modals page="profile" modalStatus={modalStatus} setModalStatus={setModalStatus} /> */}
        </>
    );
}
