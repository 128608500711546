const initialState = {
    budgetListAdmin: [],
    contractListAdmin: [],
};

const bookkeepingListAdmin = (state = initialState, action) => {
    switch (action.type) {
      case "getBudgetListAdmin":
        return {
          ...state,
          budgetListAdmin: action.payload,
        };
      case "getContractListAdmin":
        return {
          ...state,
          contractListAdmin: action.payload,
        };
      default:
        return state;
    }
};

export default bookkeepingListAdmin;