import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import InputTranslate from "../../UI/InputTranslate/InputTranslate";
import MaskedCustomInput from "../../UI/Input/MaskedCustomInput";
import CropPhoto from "../../components/CropPhoto/CropPhoto";
import PhotoInput from "../../UI/PhotoInput/PhotoInput";
import Button from "../../UI/Button/Button";
import Select from "../../UI/Select/Select";
import Input from "../../UI/Input/Input";

import { useDispathProfile } from "../../hooks/useDispathProfile";
import * as regExpList from "../../interface/RegExpDictionary";
import CustomValidationUser from "../../interface/Validation";
import { ImageLoader } from "../../interface/ImageLoader";
import { Functions } from "../../interface/functions";
import { REQUEST_URL } from "../../interface/config";
import { HTTP } from "../../interface/HTTP";

import { academic_level, COURCES_LIST } from "../../assets/academic_level";
import { type_of_learning } from "../../assets/type_of_learning";

import close_window from "../../images/other/close_window.png";
import no_user_img from "../../images/other/no-photo.png";

export default function ChangeProfileModal(props) {
    const { modalStatus, setModalStatus } = props;

    const userData = useSelector(({ profile }) => profile);

    const [dispatchObject, setDispatchObject] = useState({});
    const [updateFetch, setUpdateFetch] = useState(false);
    const [canCrop, setCanCrop] = useState(false);
    const [croppedData, setCroppedData] = useState("");
    const [courcesCounter, setCourcesCounter] = useState(1);
    const [croppedFile, setCroppedFile] = useState(userData.image ? userData.image : null);
    const [inputImage, setInputImage] = useState();

    const faculties = useSelector(({ facultiesList }) => [
        { id: "", title: "Выберите Регион" },
        ...facultiesList.facultiesList,
    ]);

    const [dispathProfile, setDispatchProfile] = useDispathProfile(dispatchObject, updateFetch);

    const inputEmail = useRef(null);
    const inputFaculty = useRef(null);
    const inputTypeOfLearning = useRef(null);
    const inputAcademicevel = useRef(null);
    //const inputGradeLevel = useRef(null);
    const inputStartDate = useRef(null);
    const inputEndDate = useRef(null);
    const cropperRef = useRef(null);
    const courceRef = useRef(null);

    const date = new Date().getFullYear();

    const deleteFields = [
        "first_name",
        "last_name",
        "middle_name",
        "email",
        "faculty",
        "type_of_learning",
        "academic_level",
        "grade_level",
        "start_date",
        "end_date",
    ];

    const customDateOfBirthValidation = value => {
        if (value.length === 10) {
            const newValue = value.split("-").reverse().join(".");
            return Number(date - 14) > Number(newValue.slice(6, 10));
        }
        return false;
    };

    const customDateOfBirthValidationDate = value => {
        const newValue = value.split("-").reverse().join(".");
        if (newValue !== undefined && String(newValue).slice(9, 10) !== "_") {
            const date = new Date(String(value).split(".").reverse().join("."));
            return (
                `${Functions.getMonthWithPrefix(date.getDate())}.${Functions.getMonthWithPrefix(
                    date.getMonth() + 1
                )}.${date.getFullYear()}` === String(newValue)
            );
        }
        return false;
    };

    const formikChangeProfile = useFormik({
        initialValues: {
            first_name: userData.first_name !== "" ? userData.first_name : "",
            first_name_translated: userData.first_name_translated !== "" ? userData.first_name_translated : "",
            last_name: userData.last_name !== "" ? userData.last_name : "",
            last_name_translated: userData.last_name_translated !== "" ? userData.last_name_translated : "",
            middle_name: userData.middle_name !== "" ? userData.middle_name : "",
            middle_name_translated: userData.middle_name_translated !== "" ? userData.middle_name_translated : "",
            date_of_birth: userData.date_of_birth !== "" ? userData.date_of_birth : "",
            sex: userData.sex !== "" ? userData.sex : "",
            email: userData.email !== "" ? userData.email : "",
            faculty: userData.faculty !== "" ? userData.faculty : "",
            type_of_learning: userData.type_of_learning !== "" ? userData.type_of_learning : "",
            academic_level: userData.academic_level !== "" ? userData.academic_level : "",
            start_date: userData.start_date !== "" ? userData.start_date : "",
            student_id: userData.student_id !== "" ? userData.student_id : "",
            phone_number: userData.phone_number !== "" ? userData.phone_number : "",
            grade_level: userData.grade_level !== "" ? userData.grade_level : "",
            end_date: userData.end_date !== "" ? userData.end_date : "",
            image: userData.image !== "" ? userData.image : no_user_img,
        },
        validationSchema: Yup.object({
            last_name: Yup.string()
                .test("name", `Введите корректную фамилию`, value => CustomValidationUser.customNameValidation(value))
                .matches(regExpList.CYRILLIC_REG_EXP, "Возможно использование только кириллицы")
                .required("Некорректная фамилия"),
            first_name: Yup.string()
                .test("name", `Введите корректное имя`, value => CustomValidationUser.customNameValidation(value))
                .required("Некорректное имя")
                .matches(regExpList.CYRILLIC_REG_EXP, "Возможно использование только кириллицы"),
            middle_name: Yup.string()
                .test("name", `Введите корректное отчество`, value => CustomValidationUser.customNameValidation(value))
                .matches(regExpList.CYRILLIC_REG_EXP, "Возможно использование только кириллицы"),
            last_name_translated: Yup.string()
                .required("Укажите транслит фамилии")
                .matches(regExpList.LATIN_REG_EXP, "Введите корректный транслит фамилии"),
            first_name_translated: Yup.string()
                .required("Укажите транслит имени")
                .matches(regExpList.LATIN_REG_EXP, "Введите корректный транслит имени"),
            middle_name_translated: Yup.string().matches(
                regExpList.LATIN_REG_EXP,
                "Введите корректный транслит отчества"
            ),
            date_of_birth: Yup.string()
                .required("Укажите дату рождения")
                //.matches(regExpList.DATE_OF_BIRTH_REG_EXP, "Введите корректную дату")
                .test("name2", `Введите корректную дату`, value =>
                    CustomValidationUser.customDateOfBirthValidationReg(value)
                )
                .test("name", `Введите корректную дату`, value => customDateOfBirthValidation(value))
                .test("name1", `Введите корректную дату`, value => customDateOfBirthValidationDate(value)),
            sex: Yup.string().required("Некорректный пол"),
            faculty: Yup.string().required("Укажите Регион").notOneOf(["Выберите Регион"], "Укажите Регион"),
            type_of_learning: Yup.string()
                .required("Некорректный тип обучения")
                .notOneOf(["Выберите тип обучения"], "Выберите тип обучения"),
            start_date: Yup.string()
                .required("Некорретная дата начала обучения")
                .test("name2", `Введите корректную дату`, value =>
                    CustomValidationUser.customDateOfBirthValidationReg(value)
                ),
            academic_level: Yup.string()
                .required("Некорректный уровень обучения")
                .notOneOf(["Выберите уровень обучения"], "Выберите уровень обучения"),
            grade_level: Yup.string()
                .required("Некорректный текущий курс")
                .matches(/^[1-9]+$/, "Некорректный текущий курс")
                //.matches(regExpList.STUDENT_TICKET_REG_EXP, "Некорректный текущий курс")
                .notOneOf([""], "Укажите курс"),
            end_date: Yup.string("Введите полную дату")
                .required("Некорректный плановый срок окончания обучения")
                .test("name2", `Введите корректную дату`, value =>
                    CustomValidationUser.customDateValidationReg(value, regExpList.DATE_REG_EXP)
                ),
            image: Yup.mixed(),
            student_id: Yup.string()
                .min(8, "Студенческий билет должен содержать не менее 8 цифр")
                .max(8, "Студенческий билет должен содержать не более 8 цифр")
                .matches(regExpList.STUDENT_TICKET_REG_EXP, "Студенческий билет должен содержать только цифры"),
            phone_number: Yup.string().matches(regExpList.PHONE_REG_EXP, "Некорректный номер телефона"),
            email: Yup.string().email("Некорректный email").required("Некорректный email"),
        }),
        onSubmit: async values => {
            values.image = croppedFile;
            const updateStatus = await fetchUpdateProfile(values);
            if (updateStatus.id) {
                setUpdateFetch(true);
                setDispatchObject(updateStatus);
                setDispatchProfile(updateStatus);
            }
        },
    });

    useEffect(() => {
        if (userData.image) {
            Functions.setUserPhoto(userData.image);
        }
    }, [userData.image]);

    useEffect(() => {
        if (updateFetch && dispatchObject) {
            setModalStatus("none");
        }
    }, [updateFetch, dispatchObject]);

    async function fetchUpdateProfile(values) {
        let formData = new FormData();
        for (let item of Object.entries(values)) {
            if (item[0] === "date_of_birth" || item[0] === "start_date" || item[0] === "end_date") {
                const reverse = item[1].split(".").reverse().join("-");
                formData.append(item[0], reverse);
            } else if (item[0] === "image") {
                if (item[1] === userData.image) {
                    continue;
                } else {
                    formData.set(`${item[0]}`, item[1]);
                }
            } else {
                formData.append(`${item[0]}`, item[1]);
            }
        }
        if (userData.status !== "Новый пользователь" && userData.status !== "Подал заявку") {
            for (let item of deleteFields) {
                formData.delete(item);
            }
        }
        return await HTTP.UpdateUser("PATCH", false, REQUEST_URL.updateCurrentUser, formData);
    }

    useEffect(() => {
        if (
            formikChangeProfile.values.academic_level !== "" &&
            formikChangeProfile.values.start_date !== "" &&
            formikChangeProfile.values.academic_level !== null
        ) {
            const getStringFromDate = date => {
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, "0");
                const day = date.getDate().toString().padStart(2, "0");
                return `${year}-${month}-${day}`;
            };
            const date = new Date(formikChangeProfile.values.start_date);
            //const level_academic = Number(formikChangeProfile.values.academic_level.match(year_of_academic_level_REG_EXP));
            //const level_academic = formikChangeProfile.values.academic_level;
            //.match(year_of_academic_level_REG_EXP);
            const level_academic = Functions.getCountYear(formikChangeProfile.values.academic_level);
            const end_date_old = new Date(formikChangeProfile.values.end_date);
            const dateEnd_incrice = new Date(
                date.getFullYear() + level_academic,
                end_date_old.getMonth(),
                end_date_old.getDate()
            );
            const dateEnd = getStringFromDate(dateEnd_incrice);
            formikChangeProfile.values.end_date = dateEnd;
        }
    }, [formikChangeProfile.values.start_date, formikChangeProfile.values.academic_level]);

    useEffect(() => {
        if (croppedData !== "") {
            setInputImage(croppedData);
            setCroppedFile(ImageLoader.dataURLtoFile(croppedData, "user_photo.png"));
        }
    }, [croppedData]);

    useEffect(() => {
        if (croppedFile) {
            formikChangeProfile.values.image = croppedFile;
        }
    }, [croppedFile]);

    useEffect(() => {
        if (formikChangeProfile.values.academic_level !== "") {
            const countOfYears = Functions.getCountYear(formikChangeProfile.values.academic_level);
            setCourcesCounter(Number(countOfYears + 1));
            if (formikChangeProfile.values.grade_level > countOfYears || formikChangeProfile.values.grade_level <= 0) {
                formikChangeProfile.values.grade_level = "";
            }
        } else {
            courceRef.current.selectedIndex = 0;
            formikChangeProfile.values.grade_level = "";
            setCourcesCounter(1);
        }
    }, [formikChangeProfile.values.academic_level, formikChangeProfile.values.grade_level]);

    useEffect(() => {
        if (
            userData.status === "Новый пользователь" ||
            userData.status === "Подал заявку" ||
            userData.status === "Членство приостановлено" ||
            userData.status === "Архивированный пользователь"
        ) {
            inputEmail.current.style.pointerEvents = "none";
            inputEmail.current.style.backgroundColor = "#EEF4FF";
        }
    }, [userData.status, inputEmail]);

    // useEffect(() => {
    //     if (userData.status !== "Новый пользователь" && userData.status !== "Подал заявку") {
    //         inputFaculty.current.style.pointerEvents = "none";
    //         inputTypeOfLearning.current.style.pointerEvents = "none";
    //         inputAcademicevel.current.style.pointerEvents = "none";
    //         //inputGradeLevel.current.style.pointerEvents = "none";
    //         courceRef.current.style.pointerEvents = "none";
    //         inputStartDate.current.style.pointerEvents = "none";
    //         inputEndDate.current.style.pointerEvents = "none";
    //         inputEmail.current.style.pointerEvents = "none";
    //     }
    // }, [
    //     userData.status,
    //     inputEmail,
    //     inputAcademicevel,
    //     inputEndDate,
    //     inputFaculty,
    //     inputTypeOfLearning,
    //     //inputGradeLevel,
    //     courceRef,
    //     inputStartDate,
    // ]);

    return (
        <>
            <div className={modalStatus !== "none" ? "modalForms modalForms_active change-modal" : "modalForms"}>
                <div className="modal-content">
                    <div className="modal-color">
                        <div
                            className="modal-close"
                            onClick={() => {
                                setModalStatus("none");
                            }}
                        >
                            <img src={close_window} alt="Close" />
                        </div>

                        <form
                            className={
                                modalStatus !== "material_support" && modalStatus !== "change_faculty"
                                    ? "modalForms-mainEdit change-modal"
                                    : "modalForms-mainEdit"
                            }
                            onSubmit={formikChangeProfile.handleSubmit}
                        >
                            <div
                                className={
                                    canCrop ? "modalForms-header modalForms-header__column" : "modalForms-header"
                                }
                            >
                                <div className="modalForms-header-image">
                                    <PhotoInput
                                        id="photoInput"
                                        accept="image/jpeg, image/jpg, image/jfif, image/png"
                                        name="photoInput"
                                        multiple={false}
                                        user_photo={inputImage ? inputImage : Functions.setUserPhoto(userData.image)}
                                        mode="change_profile"
                                        form={formikChangeProfile}
                                        takeFile={e => ImageLoader.imageHandler(e, setInputImage, setCanCrop)}
                                    />
                                    {canCrop && (
                                        <CropPhoto
                                            photo={inputImage}
                                            cropRef={cropperRef}
                                            getCroppedData={setCroppedData}
                                            setCanCropped={setCanCrop}
                                            height={400}
                                            width={"100%"}
                                        />
                                    )}
                                </div>
                                <div className="modalForms-header-info">
                                    <div className="modalForms-header-title">Редактировать</div>
                                </div>
                            </div>
                            {userData.status !== "Новый пользователь" &&
                            userData.status !== "Подал заявку" &&
                            userData.status !== "Членство приостановлено" ? (
                                <>
                                    <Input
                                        id="last_name_translated"
                                        required={true}
                                        {...formikChangeProfile.getFieldProps("last_name_translated")}
                                        type="text"
                                        text="Транслит поля Фамилия"
                                        defaultValue={
                                            userData.last_name_translated ? userData.last_name_translated : ""
                                        }
                                        formError={formikChangeProfile}
                                    />
                                    <Input
                                        id="first_name_translated"
                                        required={false}
                                        {...formikChangeProfile.getFieldProps("first_name_translated")}
                                        type="text"
                                        text="Транслит поля Имя"
                                        defaultValue={
                                            userData.first_name_translated ? userData.first_name_translated : ""
                                        }
                                        formError={formikChangeProfile}
                                    />
                                    <Input
                                        id="middle_name_translated"
                                        required={false}
                                        {...formikChangeProfile.getFieldProps("middle_name_translated")}
                                        type="text"
                                        text="Транслит поля Отчество"
                                        defaultValue={
                                            userData.middle_name_translated ? userData.middle_name_translated : ""
                                        }
                                        formError={formikChangeProfile}
                                    />
                                    <Input
                                        id="date_of_birth"
                                        {...formikChangeProfile.getFieldProps("date_of_birth")}
                                        type="date"
                                        text="День рождения"
                                        defaultValue={userData.date_of_birth}
                                        formError={formikChangeProfile}
                                    />
                                    <Select
                                        id="sex"
                                        mode="sex"
                                        text="Пол"
                                        defaultValue={userData.sex}
                                        {...formikChangeProfile.getFieldProps("sex")}
                                        formError={formikChangeProfile}
                                    />
                                    <MaskedCustomInput
                                        text="Номер телефона"
                                        required={true}
                                        id="phone_number"
                                        {...formikChangeProfile.getFieldProps("phone_number")}
                                        formError={formikChangeProfile}
                                        mask={"+7(999) 999-99-99"}
                                    />
                                    <Input
                                        id="student_id"
                                        {...formikChangeProfile.getFieldProps("student_id")}
                                        type="text"
                                        text="Студенческий билет"
                                        defaultValue={userData.student_id}
                                        formError={formikChangeProfile}
                                    />
                                </>
                            ) : (
                                <>
                                    <InputTranslate
                                        id="last_name"
                                        defaultValue={userData.last_name ? userData.last_name : ""}
                                        defaultValueTranslit={
                                            userData.last_name_translated ? userData.last_name_translated : ""
                                        }
                                        required={true}
                                        {...formikChangeProfile.getFieldProps("last_name")}
                                        translitOptions={formikChangeProfile.getFieldProps("last_name_translated")}
                                        type="text"
                                        text="Фамилия / Last name"
                                        textTranslit="Транслит поля Фамилия"
                                        formError={formikChangeProfile}
                                    />
                                    <InputTranslate
                                        id="first_name"
                                        defaultValue={userData.first_name ? userData.first_name : ""}
                                        defaultValueTranslit={
                                            userData.first_name_translated ? userData.first_name_translated : ""
                                        }
                                        required={true}
                                        {...formikChangeProfile.getFieldProps("first_name")}
                                        translitOptions={formikChangeProfile.getFieldProps("first_name_translated")}
                                        type="text"
                                        text="Имя / First name"
                                        textTranslit="Транслит поля Имя"
                                        formError={formikChangeProfile}
                                    />
                                    <InputTranslate
                                        id="middle_name"
                                        defaultValue={userData.middle_name ? userData.middle_name : ""}
                                        defaultValueTranslit={
                                            userData.middle_name_translated ? userData.middle_name_translated : ""
                                        }
                                        required={false}
                                        {...formikChangeProfile.getFieldProps("middle_name")}
                                        translitOptions={formikChangeProfile.getFieldProps("middle_name_translated")}
                                        type="text"
                                        text="Отчество / Middle name"
                                        textTranslit="Транслит поля Отчество"
                                        formError={formikChangeProfile}
                                    />
                                    <Input
                                        id="date_of_birth"
                                        {...formikChangeProfile.getFieldProps("date_of_birth")}
                                        type="date"
                                        text="День рождения"
                                        defaultValue={userData.date_of_birth}
                                        formError={formikChangeProfile}
                                    />
                                    <Select
                                        id="sex"
                                        mode="sex"
                                        text="Пол"
                                        defaultValue={userData.sex}
                                        {...formikChangeProfile.getFieldProps("sex")}
                                        formError={formikChangeProfile}
                                    />

                                    <Input
                                        id="email"
                                        {...formikChangeProfile.getFieldProps("email")}
                                        type="text"
                                        text="Адрес электронной почты"
                                        defaultValue={userData.email}
                                        refInput={inputEmail}
                                        formError={formikChangeProfile}
                                    />
                                    <MaskedCustomInput
                                        text="Номер телефона"
                                        required={true}
                                        id="phone_number"
                                        {...formikChangeProfile.getFieldProps("phone_number")}
                                        formError={formikChangeProfile}
                                        mask={"+7(999) 999-99-99"}
                                    />
                                    <Select
                                        mode="profileFaculty"
                                        required={true}
                                        id="faculty"
                                        refInput={inputFaculty}
                                        {...formikChangeProfile.getFieldProps("faculty")}
                                        text="Регион"
                                        defaultValue={userData.faculty}
                                        list={faculties}
                                        formError={formikChangeProfile}
                                    />
                                    <Select
                                        mode="editing"
                                        required={true}
                                        id="academic_level"
                                        refInput={inputAcademicevel}
                                        {...formikChangeProfile.getFieldProps("academic_level")}
                                        text="Уровень обучения"
                                        defaultValue={userData.academic_level}
                                        list={
                                            faculties.length <= 1
                                                ? academic_level
                                                : academic_level.concat(
                                                      formikChangeProfile.values.faculty === ""
                                                          ? academic_level
                                                          : faculties.filter(
                                                                item => Number(item.id) === Number(userData.faculty_id)
                                                            )[0]?.academic_level
                                                  )
                                        }
                                        formError={formikChangeProfile}
                                    />
                                    <Select
                                        mode="editing"
                                        required={true}
                                        list={
                                            formikChangeProfile.values.faculty === ""
                                                ? type_of_learning
                                                : type_of_learning.concat(
                                                      formikChangeProfile.values.faculty === ""
                                                          ? type_of_learning
                                                          : faculties.filter(
                                                                item => Number(item.id) === Number(userData.faculty_id)
                                                            )[0]?.type_of_learning
                                                  )
                                        }
                                        text="Тип обучения"
                                        refInput={inputTypeOfLearning}
                                        id="type_of_learning"
                                        {...formikChangeProfile.getFieldProps("type_of_learning")}
                                        formError={formikChangeProfile}
                                        defaultValue={userData.type_of_learning}
                                    />
                                    {/* <Input
                                id="grade_level"
                                {...formikChangeProfile.getFieldProps("grade_level")}
                                type="number"
                                text="Текущий Курс"
                                refInput={inputGradeLevel}
                                defaultValue={userData.grade_level}
                                formError={formikChangeProfile}
                            /> */}
                                    <Select
                                        list={
                                            formikChangeProfile.values.faculty === ""
                                                ? COURCES_LIST
                                                : COURCES_LIST.concat(
                                                      formikChangeProfile.values.faculty === ""
                                                          ? COURCES_LIST
                                                          : faculties.filter(
                                                                item => Number(item.id) === Number(userData.faculty_id)
                                                            )[0]?.course
                                                  )
                                        }
                                        required={true}
                                        defaultValue={userData.grade_level}
                                        text="Текущий Курс"
                                        id="grade_level"
                                        {...formikChangeProfile.getFieldProps("grade_level")}
                                        formError={formikChangeProfile}
                                        refInput={courceRef}
                                    />
                                    <Input
                                        id="start_date"
                                        refInput={inputStartDate}
                                        {...formikChangeProfile.getFieldProps("start_date")}
                                        type="date"
                                        text="Дата начала обучения"
                                        defaultValue={userData.start_date}
                                        formError={formikChangeProfile}
                                    />
                                    <Input
                                        id="end_date"
                                        refInput={inputEndDate}
                                        {...formikChangeProfile.getFieldProps("end_date")}
                                        type="date"
                                        text="Плановый срок окончания обучения"
                                        defaultValue={userData.end_date}
                                        formError={formikChangeProfile}
                                    />
                                    <Input
                                        id="student_id"
                                        {...formikChangeProfile.getFieldProps("student_id")}
                                        type="text"
                                        text="Студенческий билет"
                                        defaultValue={userData.student_id}
                                        formError={formikChangeProfile}
                                    />
                                </>
                            )}

                            <div className="input-wrapper"></div>
                            <Button type="submit" text="СОХРАНИТЬ" color="blue" />
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
