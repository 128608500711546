import * as Yup from "yup";

import * as regExpList from "./RegExpDictionary";
import CustomValidationUser from "./Validation";

export class validateUserRequest {
    static validate = list => {
        let initialValues = {};
        let validationSchema = {};
        list.forEach(element => {
            switch (element.type) {
                case "text":
                    (() => {
                        initialValues[element.id] = "";
                        if (element.required === true) {
                            validationSchema[element.id] = Yup.string().required("Введите текст ответа");
                        }
                    })();
                    break;
                case "radio":
                    (() => {
                        initialValues[element.id] = "";
                        if (element.required === true) {
                            validationSchema[element.id] = Yup.string().required("Выберите ответ");
                        }
                    })();
                    break;
                case "checkbox":
                    (() => {
                        initialValues[element.id] = [];
                        if (element.required === true) {
                            validationSchema[element.id] = Yup.array()
                                .test("checked", `Выберите ответ`, value =>
                                    CustomValidationUser.customCheckedCheckboxValidationReg(value)
                                )
                                .required("Выберите ответ");
                        }
                    })();
                    break;
                case "date":
                    (() => {
                        initialValues[element.id] = "";
                        if (element.required === true) {
                            validationSchema[element.id] = Yup.string()
                                .matches(regExpList.DATE_EVENT_REG_EXP, "Некорректная дата")
                                .test("date", `Введите корректную дату`, value =>
                                    CustomValidationUser.customDateOfBirthValidationDate(value)
                                )
                                .required("Введите дату");
                        }
                    })();
                    break;
                case "time":
                    (() => {
                        initialValues[element.id] = "";
                        if (element.required === true) {
                            validationSchema[element.id] = Yup.string()
                                // .matches(regExpList.HOURS_REG_EXP, "Некорректное время")
                                .required("Введите время");
                        }
                    })();
                    break;
                case "file":
                    (() => {
                        initialValues[element.id] = "";
                        // if (element.required === true){
                        //     validationSchema[element.id] = Yup.string()
                        //         .matches(regExpList.MINUTE_REG_EXP, "Некорректное время")
                        //         .required("Введите текст ответа");
                        // }
                    })();
                    break;
                default:
                    (() => {
                        initialValues[element.id] = "";
                        if (element.required === true) {
                            validationSchema[element.id] = Yup.string().required("Введите текст ответа");
                        }
                    })();
                    break;
            }
        });

        return {
            initialValues: initialValues,
            validationSchema: Yup.object(validationSchema),
        };
    };
}
