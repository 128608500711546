import { useState } from "react";

export default function useLoading() {
    const [loading, setLoading] = useState(true);
    const [successLoading, setSuccessLoading] = useState(false);

    const successLoadingHandler = (value) => {
        setLoading(false);
        if (value){
            setSuccessLoading(true);
        }
    }

    return { loading, setLoading, successLoading, setSuccessLoading, successLoadingHandler };
}