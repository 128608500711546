const PHONE_REG_EXP = /^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/;
const DATE_REG_EXP = /^\d{2}.\d{2}.\d{4}$/;
const CYRILLIC_REG_EXP = /^(?! )([а-яА-ЯёЁ\-]+)((\s)?)([а-яА-ЯёЁ\-]+)$/g;
const LATIN_REG_EXP = /^(?! )([A-Za-z\-]+)((\s)?)([A-Za-z\-]+)$/g;
const STUDENT_TICKET_REG_EXP = /^\d+$/;
const PASSWORD_REG_EXP = /(?=.*[0-9])(?=.*[A-z])/g;
const date = new Date().getFullYear();
const currentYear = String(date).slice(3, 4);
const DATE_OF_BIRTH_REG_EXP = new RegExp(
  `^(0?[1-9]|[12][0-9]|3[01])[-\.](0?[1-9]|1[012])[-\.](19[5-9][0-9]|20([01][0-9]|2[0-${currentYear}]))$`
);
const HOURS_REG_EXP = new RegExp(`^([01]?[0-9]|2[0-3])$`);
const MINUTE_REG_EXP = new RegExp(`^[0-5][0-9]$`);
const DATE_EVENT_REG_EXP = /\b(0?[1-9]|1\d|2[0-9]|3[0-1])\.(0?[1-9]|1[012])\.\d{4}\b/

export {
  PHONE_REG_EXP,
  DATE_REG_EXP,
  CYRILLIC_REG_EXP,
  LATIN_REG_EXP,
  STUDENT_TICKET_REG_EXP,
  PASSWORD_REG_EXP,
  DATE_OF_BIRTH_REG_EXP,
  HOURS_REG_EXP,
  MINUTE_REG_EXP,
  DATE_EVENT_REG_EXP,
};
