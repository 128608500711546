import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";

import CardDescription from "../../components/CardDescription/CardDescription";
import CardsStatistics from "../../components/CardStatistics/CardsStatistics";
import LoadingLayout from "../../layouts/LoadingLayout/LoadingLayout";
import Header from "../../components/Header/Header";
import Button from "../../UI/Button/Button";
import Title from "../../UI/Title/Title";

import { Functions } from "../../interface/functions";
import { REQUEST_URL } from "../../interface/config";
import useLoading from "../../hooks/useLoading";
import { HTTP } from "../../interface/HTTP";

import "./AboutSocialProgram.css";

export default function AboutSocialProgram() {
    const [detailDate, setDetailDate] = useState({
        title: "",
        address: "",
        description: "",
        image: "",
        date: "",
    });

    const { loading, successLoading, successLoadingHandler } = useLoading();

    const pageId = useParams();

    const navigate = useNavigate();

    const requestDetail = async () => {
        const response = await HTTP.Get(`${REQUEST_URL.social}${pageId.id}/`);
        if (response.hasOwnProperty("id")) {
            const startDate = new Date(response.start_time);
            const endDate = new Date(response.end_time);
            setDetailDate({
                ...response,
                date: `${startDate.getDate()} ${Functions.getFullMonth(
                    startDate.getMonth() + 1
                )} ${startDate.getFullYear()} - ${endDate.getDate()} ${Functions.getFullMonth(
                    endDate.getMonth() + 1
                )} ${endDate.getFullYear()} г.`,
            });
            successLoadingHandler(true);
        } else {
            successLoadingHandler(false);
        }
    };

    const fakeStats = {
        all: 0,
        rejected: 0,
        accepted: 0,
        waited: 0,
    };

    useEffect(() => {
        requestDetail();
    }, []);

    return (
        <LoadingLayout
            loading={loading}
            successLoading={successLoading}
            children={
                <div className="about-program">
                    <Title text="О социальной программе" className={"page-title"} />
                    <div className="about-program__container">
                        <CardDescription event={detailDate} />
                        <div className="about-program__right">
                            <div className="about-program__buttons">
                                <Button
                                    text={"редактировать"}
                                    type="button"
                                    color="green"
                                    className="about-program__btn"
                                    callback={() => navigate(`/edit-social-page/${pageId.id}`)}
                                />
                                <Button
                                    text={"участники социальной программы"}
                                    type="button"
                                    color="transparent"
                                    className="about-program__btn about-program__btn-border"
                                    callback={() => navigate(`/peoples_social/${detailDate.id}`)}
                                />
                            </div>
                            <CardsStatistics statisticList={fakeStats} />
                        </div>
                    </div>
                </div>
            }
        />
    );
}
