import { useSelector } from "react-redux";

import Button from "../../UI/Button/Button";

export default function ButtonsToolBar({config, activeTab, type}){

    const userData = useSelector(({ profile }) => profile);

    return(
        <>
            {config.hasOwnProperty(activeTab) && config[activeTab].map((item, index) => {
                if (userData.hasOwnProperty("access") && item.hasOwnProperty("access_type") && userData.access && userData.access[type].includes(item.access_type)){
                    return <Button
                        text={item.text}
                        type="button"
                        color={item.color}
                        callback={item.callback}
                        key={index}
                    />
                }
            })}
        </>
    )
}
