import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import PasswordRecoveryPage from "./pages/PasswordRecoveryPage/PasswordRecoveryPage";
import SocialProgramsAdmin from "./pages/SocialProgramsAdmin/SocialProgramsAdmin";
import AccountActivatePage from "./pages/AccountActivatePage/AccountActivatePage";
import SocialProgramsUser from "./pages/SocialProgramsUser/SocialProgramsUser";
import AboutSocialProgram from "./pages/AboutSocialProgram/AboutSocialProgram";
import CreateAccountPage from "./pages/CreateAccountPage/CreateAccountPage";
import PasswordResetPage from "./pages/PasswordResetPage/PasswordResetPage";
import DocumentsProgram from "./pages/DocumentsProgram/DocumentsProgram";
import PeoplesResponses from "./pages/PeoplesResponses/PeoplesResponses";
import СreateSocialPage from "./pages/СreateSocialPage/СreateSocialPage";
import RzdBonusProfile from "./pages/RzdBonusProfile/RzdBonusProfile";
import StatisticsEvent from "./pages/StatisticsEvent/StatisticsEvent";
import СreateEventPage from "./pages/СreateEventPage/СreateEventPage";
import AdminStatistic from "./pages/AdminStatistic/AdminStatistic";
import AboutEventPage from "./pages/AboutEventPage/AboutEventPage";
import SocialProgram from "./pages/SocialProgram/SocialProgram";
import EditEventPage from "./pages/EditEventPage/EditEventPage";
import PeoplesSocial from "./pages/PeoplesSocial/PeoplesSocial";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import PeoplesEvent from "./pages/PeoplesEvent/PeoplesEvent";
import AppealsPages from "./pages/AppealsPage/AppealsPages";
import Bookkeeping from "./pages/Bookkeeping/Bookkeeping";
import AdminTable from "./pages/AdminTable/AdminTable";
import CardAdmin from "./pages/CardAdmin/CardAdmin";
import LoginPage from "./pages/LoginPage/loginPage";
import Documents from "./pages/Documents/Documents";
import RzdBonus from "./pages/RzdBonus/RzdBonus";
import Sponsors from "./pages/Sponsors/Sponsors";
import Profile from "./pages/Profile/Profile";
import Events from "./pages/Events/Events";
import Event from "./pages/Event/Event";
import Card from "./pages/Card/Card";

import { routerPath, routesCommon } from "./interface/config";


export default function RouterConfig({ hasToken }) {
  const userData = useSelector(({ profile }) => profile);
  const navigate = useNavigate();

  const routesConfig = [
    {
      path: routerPath.main_page,
      element: userData.token ? <Profile /> : <LoginPage />,
    },
    { path: `${routerPath.password_reset}/*`, element: <PasswordResetPage /> },
    { path: `${routerPath.password_recovery}/*`, element: <PasswordRecoveryPage /> },
    { path: routerPath.registration, element: <CreateAccountPage /> },
    { path: routerPath.activateProfile, element: <AccountActivatePage /> },
    { path: routerPath.error, element: <NotFoundPage /> },
    { path: routerPath.profile, element: <Profile /> },
    { path: routerPath.documents, element: <Documents /> },
    { path: routerPath.card, element: <Card /> },
    { path: routerPath.card_admin, element: <CardAdmin /> },
    { path: routerPath.table, element: <AdminTable /> },
    { path: routerPath.statistics, element: <AdminStatistic /> },
    { path: routerPath.bookkeeping, element: <Bookkeeping /> },
    { path: routerPath.rzd_bonus, element: <RzdBonus /> },
    { path: routerPath.rzd_bonus_profile, element: <RzdBonusProfile /> },
    { path: routerPath.events, element: <Events /> },
    { path: routerPath.event, element: <AboutEventPage /> },
    { path: routerPath.peoples_event, element: <PeoplesEvent /> },
    { path: routerPath.peoples_social, element: <PeoplesSocial /> },
    { path: routerPath.peoples_responses, element: <PeoplesResponses /> },
    { path: routerPath.sponsors, element: <Sponsors /> },
    { path: routerPath.social_programs_admin, element: <SocialProgramsAdmin /> },
    { path: routerPath.social_program_admin, element: <AboutSocialProgram /> },
    { path: routerPath.social_programs_student, element: <SocialProgramsUser /> },
    { path: routerPath.about_social_program, element: <SocialProgram /> },
    { path: routerPath.create_social, element: <СreateSocialPage /> },
    { path: routerPath.editEventPage, element: <EditEventPage /> },
    { path: routerPath.editSocialPage, element: <EditEventPage type="social"/> },

    {path: routerPath.statistics_event, element: <StatisticsEvent /> },
    {path: routerPath.documents_program, element: <DocumentsProgram /> },
    {path: routerPath.appeals, element: <AppealsPages /> },
    {path: routerPath.about_event, element: <Event /> },
    {path: routerPath.about_social_program, element: <AboutSocialProgram /> },
    {path: routerPath.create_event, element: <СreateEventPage /> },
  ];

  useEffect(() => {
    if (routesCommon.includes(window.location.href)) {
      navigate(window.location.href);
      if (!hasToken) {
        navigate("/");
      }
    }

    // if((routesCommon.includes(currentPage.pathname))) {
    //    navigate(currentPage.pathname);
    //     if (!hasToken) {
    //         navigate("/");
    //     }
    // }

    // else if (userData.role === "Председатель АМП") {
    //   navigate("/new_users");
    // }
  }, [window.location.href]);

  return (
    <Routes>
      {hasToken &&
        routesConfig.map((route, index) => {
          return <Route key={index} path={route.path} element={route.element} />;
        })}
      {!hasToken &&
        routesConfig.map((route, index) => {
          // if (index < 6) {
            return <Route key={index} path={route.path} element={route.element} />;
          // }
        })}
    </Routes>
  );
}
