import { useState, useEffect } from "react";

import CardsList from "../../components/CardsList/CardsList";
import Header from "../../components/Header/Header";

import { REQUEST_URL } from "../../interface/config";
import { HTTP } from "../../interface/HTTP";

//import './SocialProgramsAdmin.css'

export default function SocialProgramsAdmin() {
    const [sociaList, setSociaList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const socialListRequest = async () => {
        setIsLoading(true);
        let requesrList = await HTTP.Get(`${REQUEST_URL.social}`);
        if (requesrList) {
            setSociaList(requesrList);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        socialListRequest();
    }, []);

    return (
        <>
            <Header />
            <div className="events container-content">
                <div className="main">
                    <CardsList title="Социальные программы" type="social" page="social" cardsList={sociaList} />
                </div>
            </div>
        </>
    );
}
