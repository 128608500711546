import { useMemo } from "react";

import DocumentItem from "./DocumentItem";

import "./DocumentsList.css";

export default function DocumentsList({ documents, mode, deleteItem }) {
  const renderItems = useMemo(() => {
    if (documents.length > 0) {
      return documents.map((item, index) => {
        return <DocumentItem item={item} key={index} mode={mode} deleteItem={deleteItem} />;
      });
    } else {
      return <></>;
    }
  }, [documents, mode, deleteItem]);

  return (
    <>
      {mode === "student_doc" ? (
        <div>{renderItems}</div>
      ) : (
        <section className="documents-list">
          <div className="documents-list__wrapper">{renderItems}</div>
        </section>
      )}
    </>
  );
}
