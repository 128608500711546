import "./CardZachet.css";

export default function CardZachet({ cardStatus, userStatus, card, imgStatus = false }) {

    return (
        <div className="card-zachet">
            <div className="card-zachet-title">Изготовление карты Зачёт</div>
            {(cardStatus === "Нет карты" || cardStatus === null) && (
                <>
                    {userStatus === "Член профсоюза"  && (
                        <>
                            <div className="card-zachet-status card-zachet-status-red">
                                Статус: Карта изготавливается
                            </div>
                            <div className="card-zachet-message card-zachet-message-error">
                                Пожалуйста, дождитесь окончания изготовления карты
                            </div>

                            <div className="card-zachet-ticket">ПРОФСОЮЗНЫЙ БИЛЕТ  № 0161020</div>
                        </>
                    )}
                    {userStatus !== "Член профсоюза" && (
                        <>
                            <div className="card-zachet-status card-zachet-status-red">
                                Статус: ожидает верификации
                            </div>
                            <div className="card-zachet-message card-zachet-message-error">
                                Пожалуйста, дождитесь подтверждения профиля члена профсоюза
                            </div>
                        </>
                    )}
                    {!imgStatus && (
                        <div className="card-zachet-message card-zachet-message-error" style={{marginTop: "15px"}}>
                            Пожалуйста, добавьте изображение в профиль
                        </div>
                    )}
                </>
            )}
            {cardStatus === "На изготовление" && (
                <>
                    <div className="card-zachet-status card-zachet-status-purple">Статус: Карта изготавливается</div>
                    <div className="card-zachet-message">Пожалуйста, дождитесь окончания изготовления карты</div>
                    {!imgStatus && (
                        <div className="card-zachet-message card-zachet-message-error" style={{marginTop: "15px"}}>
                            Пожалуйста, добавьте изображение в профиль
                        </div>
                    )}
                </>
            )}
            {cardStatus === "Выгружена" && (
                <>
                    <div className="card-zachet-status card-zachet-status-purple">Статус: Карта изготавливается</div>
                    <div className="card-zachet-message">Пожалуйста, дождитесь окончания изготовления карты</div>
                </>
            )}
            {cardStatus === "Изготовлена" && (
                <>
                    <div className="card-zachet-status card-zachet-status-green">Статус: Карта изготовлена</div>
                    <div className="card-zachet-message">Карта готова. Вы можете забрать её в профкоме.</div>
                </>
            )}
            {cardStatus === "Выдана" && (
                <>
                    <div className="card-zachet-status card-zachet-status-green">Статус: Выдана</div>
                    <div className="card-zachet-message">Карта готова.</div>
                </>
            )}
        </div>
    );
}
