import { useState, useEffect } from "react";

import StaticticMemberRow from "../../components/StaticticMemberRow/StaticticMemberRow";
import Calendar from "../../components/Calendar/Calendar";
import Scrollbar from "../../UI/Scroolbar/Scrollbar";
import Select from "../../UI/Select/Select";

import { vocabularyStaticticMembers } from "../../assets/staticticMembers";
import { Functions } from "../../interface/functions";
import { REQUEST_URL } from "../../interface/config";
import { HTTP } from "../../interface/HTTP";

import "./StaticticMember.css";

export default function StaticticMember() {

    const [dates, setDates] = useState([Functions.setWeekAgo(), new Date()]);
    const [isLoading, setIsLoading] = useState(false);

    const [profkomStatistics, setProfkomStatistics] = useState({
        BUDGET: [],
        CONTRACT: [],
    });

    const convertCurrentDate = (date) => {
        const innerDate = new Date(date);
        return `${innerDate.getFullYear()}-${Functions.getMonthWithPrefix(innerDate.getMonth() + 1)}-${Functions.getMonthWithPrefix(innerDate.getDate())}`;
    }

    const getProfkomStatisticsHendler = async () => {
        try {
            setIsLoading(true);
            const resp = await HTTP.Get(`${REQUEST_URL.getProfkomStatistics}?start_date=${convertCurrentDate(dates[0])}&end_date=${convertCurrentDate(dates[1])}`);
            if (resp !== undefined) {
                setProfkomStatistics((resp.length > 0) ? resp : {
                    BUDGET: [],
                    CONTRACT: [],
                });
                setIsLoading(false);
            }
        } catch (e) {
            setIsLoading(false);
            console.error(e);
        }
    };

    const setCourse = () => {
        let returnArr = [];
        vocabularyStaticticMembers.forEach(item =>
            returnArr.push(
                <div className="statictic-memeber-table-td statictic-memeber-table-head-th" key={item.id}>
                    {item.course}
                </div>
            )
        );
        return returnArr;
    };

    const setAcademicLevel = academicList => {
        if (academicList){
            const academicListKeys = Object.keys(academicList);
    
            let returnArr = [];
            academicListKeys.forEach((item, index) =>
                returnArr.push(
                    <div className="statictic-memeber-table-tr statictic-memeber-table-rt-head" key={index}>
                        <div className="statictic-memeber-table-td">{item}</div>
                    </div>
                )
            );
            return returnArr;
        }

        return [];
    };

    useEffect(() => {
        getProfkomStatisticsHendler();
    }, []);

    useEffect(() => {
        getProfkomStatisticsHendler();
    }, [dates]);

    return (
        <>
            <div style={{ marginBottom: "15px" }}>
                <Calendar dateRangeState={[dates, setDates]} startDateState={dates} label={"Выберите даты:"} />
            </div>
            <div className="statictic-memeber">
                <Scrollbar>
                    <div className="statictic-memeber-table">
                        <div className="statictic-memeber-table-head">
                            <div className="statictic-memeber-table-head-top">Бакалавриат (4 года)</div>
                            <div className="statictic-memeber-table-head-bottom">{setCourse()}</div>
                        </div>
                        <div>
                            {isLoading ? (
                                <div className="statictic-memeber-table-loader">Загрузка...</div>
                            ) : (
                                <>
                                    <div className="statictic-memeber-table-title">
                                        <div className="statictic-memeber-table-tr-head">
                                            <strong>Бюджет</strong>
                                        </div>

                                        <div className="statictic-memeber-table-body">
                                            <div>{setAcademicLevel(profkomStatistics?.BUDGET)}</div>

                                            <div className="statictic-memeber-table-body-item">
                                                <StaticticMemberRow listOfRender={profkomStatistics?.BUDGET} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="statictic-memeber-table-title">
                                        <div className="statictic-memeber-table-tr-head">
                                            <strong>Контракт</strong>
                                        </div>

                                        <div className="statictic-memeber-table-body">
                                            <div>{setAcademicLevel(profkomStatistics?.CONTRACT)}</div>
                                            <div className="statictic-memeber-table-body-item">
                                                <StaticticMemberRow listOfRender={profkomStatistics?.CONTRACT} />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </Scrollbar>
            </div>
        </>
    );
}
