import { useState } from "react";
import RadioGroup from "../../../UI/Checkbox/RadioGroup/RadioGroup";

import "./Answer.css";

export default function Answer({ item, type, options, removeAnswer, name, handleChangeAnswerInput, handleSortAnswer }) {
    const [text, setText] = useState("");

    const handleSelectedChange = val => {
        setText(val);
    };

    return (
        <div className="answer">
            <RadioGroup
                selected={text}
                name={name}
                onChange={handleSelectedChange}
                handleChangeAnswerInput={handleChangeAnswerInput}
                handleSortAnswer={handleSortAnswer}
                options={options}
                type={type}
                item={item}
                removeAnswer={removeAnswer}
            />
        </div>
    );
}
