import { useState, useEffect } from "react";

import PeoplesEventTable from "../../components/PeoplesEventTable/PeoplesEventTable";
import LoyoutModal from "../../components/Modals/LoyoutModal/LoyoutModal";
import CardProfile from "../../components/CardProfile/CardProfile";
import { Overlay } from "../../components/Modals/Overlay/Overlay";
import Header from "../../components/Header/Header";
import GoBack from "../../UI/Button/GoBack";

import { REQUEST_URL } from "../../interface/config";
import { HTTP } from "../../interface/HTTP";

// import "./AppealPage.css";

export default function AppealsPages() {
    const [appealList, setAppealList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [modalStatus, setModalStatus] = useState("none");
    const [isLoadingDetailUser, setIsLoadingDetailUser] = useState(false);
    const [detailUser, setDetailUser] = useState(false);
    const [activeTab, setActiveTab] = useState("all");
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedDetailUser, setSelectedDetailUser] = useState({});

    const filtersList = ["Все заявки", "Открытые", "Закрытые"];

    const eventsListRequest = async () => {
        setIsLoading(true);
        // setAppealList(peopleAppealList);
        let response = await HTTP.Get(`${REQUEST_URL.usersRequests}`);
        if (response) {
            setAppealList(response.results);
        }
        setIsLoading(false);
    };

    async function getDetailUserFetch(selectedUserId) {
        setIsLoadingDetailUser(true);
        const userInfo = await HTTP.Get(REQUEST_URL.getUser(selectedUserId));
        if (userInfo) {
            setIsLoadingDetailUser(false);
            setDetailUser(userInfo);
        }
    }

    useEffect(() => {
        eventsListRequest();
    }, []);
    
    useEffect(() => {
        if (selectedUser){
            getDetailUserFetch(selectedUser);
            setModalStatus("open");
        }
    }, [selectedUser]);

    useEffect(() => {
        if (modalStatus === "none"){
            setSelectedUser(null);
        }
    }, [modalStatus]);

    return (
        <>
            <Header />
            <div className="people-events">
                <div className="main">
                    <div className="container-content center">
                        <GoBack text={"Назад"} type="button" />
                        <PeoplesEventTable
                            title="Обращения"
                            type="appeal"
                            setSelectedUser={setSelectedUser}
                            cardsList={appealList}
                            filters={filtersList}
                            djangoPagination={true}
                            setIsLoading={setIsLoading}
                        />
                    </div>
                </div>
            </div>
            {modalStatus !== "none" && (
                <Overlay setModalStatus={setModalStatus} modalStatus={modalStatus}>
                    <LoyoutModal
                        modalStatus={modalStatus}
                        setModalStatus={setModalStatus}
                        sizeModal="loyout-modal__size-large"
                    >
                        <CardProfile
                            detailUser={detailUser}
                            pageType="table"
                            activeTableTab={activeTab}
                            isLoadingDetailUser={isLoadingDetailUser}
                            modalStatus={modalStatus}
                            setModalStatus={setModalStatus}
                            selectedDetailUser={selectedDetailUser}
                        />
                    </LoyoutModal>
                </Overlay>
            )}
        </>
    );
}
