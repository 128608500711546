import { useState, useEffect } from "react";

import Scrollbar from "../../UI/Scroolbar/Scrollbar";
import Title from "../../UI/Title/Title";
import TableComponent from "../TableComponent/TableComponent";

import "./StatisticsEventList.css";

export default function StatisticsEventList({ data, header }) {
    return (
        <section className="statistics-event">
            <Title text={"Список мероприятий"} className={"statistics-event"} />
            <div className="statistics-event__table">
                <Scrollbar>
                    <TableComponent header={header} data={data} />
                </Scrollbar>
            </div>
        </section>
    );
}
