import { useFormik } from "formik";
import * as yup from "yup";
import { useState, useEffect, useRef, useMemo } from "react";

import Button from "../../UI/Button/Button";

import LoyoutModal from "../Modals/LoyoutModal/LoyoutModal";
import { Overlay } from "../Modals/Overlay/Overlay";
import DownloadBoxWrapper from "../DownloadBox/DownloadBoxWrapper";
import DocumentsList from "../DocumentsList/DocumentsList";
import FileInput from "../FileInput/FileInput";
import FileInputList from "../FileInput/FileInputList";

import { HTTP } from "../../interface/HTTP";
import { REQUEST_URL } from "../../interface/config";

import "./DocumentsStudent.css";

export default function DocumentsStudent({ filesNameList }) {
  const [downloadFiles, setDownloadFiles] = useState([]);
  const [fileList, setFileList] = useState([]);

  const [modalStatus, setModalStatus] = useState(false);
  const [isError, setIsError] = useState(false); // стейт для ошибок загружаемых файлов
  const [error, setError] = useState([]); // ошибки загрузки
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [progressInfos, setProgressInfos] = useState(23);
  const [isVisibleProgressBar, setisVisibleProgressBar] = useState(false);

  const inputRef = useRef(null);

  const deleteFile = (name) => {
    setFileList(fileList.filter((item) => item.name !== name));
  };

  async function sendFormData(file) {

    setModalStatus("none")
    let percentage = 0;
    setisVisibleProgressBar(true);
    if (isError === false && fileList.length > 0) {
      try {
        const formData = new FormData();
        formData.append("file", file[0]);

        // const resp = await HTTP.PostFormForRequest(REQUEST_URL.jjj, formData, (event) => {
        //    percentage = Math.round(
        //     (100 * event.loaded) / event.total
        //   );
        //   setProgressInfos(percentage);
        // });
        // if (resp.id) {
        //
        // } else {
        //   setSubmitError("Произошла ошибка при отправке формы. Попробуйте еще раз.");
        // }
      } catch (error) {
        console.error(error);
        percentage = 0;
        setProgressInfos(percentage);
        // setSubmitError("Произошла ошибка при отправке формы. Попробуйте еще раз.");
      }
    }
  }

  const formikAddFile = useFormik({
    initialValues: {
      file: [],
    },
    validationSchema: yup.object().shape({
      file: yup.array().nullable().required("Файл обязателен"),
    }),
    onSubmit: (values, { resetForm }) => {
      sendFormData(values);
      resetForm();
    },
  });

  const renderTitleFiles = useMemo(() => {
    if (filesNameList.length > 0) {
      return filesNameList.map((item, index) => {
        return (
          <div key={index} className="documents-student__list-item">
            <div className="documents-student__list-content">
              <p className="documents-student__list-title">{item}</p>
              {downloadFiles && downloadFiles.length > 0 && (
                <DocumentsList documents={downloadFiles} mode={"student_doc"} />
              )}
              {
                <Button
                  type="button"
                  text={"ЗАГРУЗИТЬ ДОКУМЕНТ"}
                  color="green"
                  className="documents-student__btn"
                  callback={() => setModalStatus("loading")}
                />
              }
            </div>
          </div>
        );
      });
    } else {
      return <></>;
    }
  }, [filesNameList]);

  useEffect(() => {
    formikAddFile.values["file"] = fileList;
  }, [fileList, formikAddFile.values["file"]]);

  // useEffect(() => {
  //   if (isError === false && fileList.length > 0) {
  //     sendFormData(formikAddFile.values["file"]);
  //   }
  // }, [isError, formikAddFile.values["file"]]);

  return (
    <>
      <section className="documents-student">
        <div className="documents-student__list">
          <div className="documents-student__list-wrapper">{renderTitleFiles}</div>
        </div>
      </section>
      {modalStatus && (
        <Overlay setModalStatus={setModalStatus} modalStatus={modalStatus}>
          <LoyoutModal modalStatus={modalStatus} setModalStatus={setModalStatus} title="Загрузка документа">
            <>
              {fileList && fileList.length > 0 ? (
                <form onSubmit={formikAddFile.handleSubmit} className="documents-student__form" id="check_foto">
                  <FileInputList
                    filesList={fileList}
                    persent={"persent"}
                    deleteFile={deleteFile}
                    setIsError={setIsError}
                    titleFile={formikAddFile.values.name}
                    progressInfos={progressInfos}
                    isVisibleProgressBar={isVisibleProgressBar}
                  />
                  <div className="documents-student__button">
                    <Button text="ГОТОВО" size="middle" color="green" type={"submit"} />
                  </div>
                </form>
              ) : (
                <DownloadBoxWrapper setFilesList={setFileList}>
                  <FileInput
                    titleBox="Нажмите чтобы выбрать или перетащите ваш файл"
                    accept="application/doc, application/pdf, application/jpeg"
                    subTitleBox={"Поддержка форматов: jpeg, doc, PDF"}
                    id="file"
                    name="file"
                    form={formikAddFile}
                    error={true}
                    setFilesList={setFileList}
                    multiple={false}
                    className={"download__input"}
                    inputRef={inputRef}
                    mode='file_list'
                  />
                </DownloadBoxWrapper>
              )}

              {isError && fileList.length > 0 && (
                <p className="documents-student__error">Недопустимый формат или размер файла</p>
              )}
            </>
          </LoyoutModal>
        </Overlay>
      )}
    </>
  );
}
