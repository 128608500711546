import { useState } from 'react';
import Button from '../../UI/Button/Button';

import { Functions } from '../../interface/functions';

import { SERVER_URL } from '../../interface/HTTP';
import { MAX_CHARS } from '../../interface/config';

import './CardDescription.css'

export default function CardDescription({ event }) {
    const [isShowText, setIsShowText] = useState(false);

    function toggleFullText() {
        if(isShowText) {
            setIsShowText(false) 
        } else {
            setIsShowText(true) 
        }
    }

    return (
        <div className='card-description'>
            <div className='card-description__header'>
                <h3 className='card-description__title'>{event.title}</h3>
            </div>
            <div className='card-description__body'>
                <div className='card-description__img'>
                    <img src={SERVER_URL + event.image} alt={`Изображение: ${event.title ?? "мероприятие"}`}/>
                </div>

                <div className='card-description__box'>
                    <p className='card-description__info'>{event.date}</p>
                    <p className='card-description__info'>{event.address}</p>
                </div>

                <div className='card-description__wrapper'>
                    <p className='card-description__description'>Описание</p>
                    <p className='card-description__text' dangerouslySetInnerHTML={{__html: (isShowText) ? event.description : (event.description.length > MAX_CHARS) ? Functions.getCroppedText(event.description, MAX_CHARS) : event.description}}></p>
                </div>
                {(event.description.length > MAX_CHARS) && (
                    <Button text={isShowText ? 'скрыть' : 'показать полностью'} color='transparent' callback={toggleFullText}/>
                )} 
            </div>
        </div>
    )
}