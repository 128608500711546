import { useState, useEffect } from "react";

import { Functions } from "../../interface/functions";
import { useFilter } from "../../hooks/useFilter";

import StatisticsEventList from "../../components/StatisticsEventList/StatisticsEventList";
import Calendar from "../../components/Calendar/Calendar";
import SelectFilter from "../../UI/Select/SelectFilter";
import InputSearch from "../../UI/Input/SearchInput";
import Header from "../../components/Header/Header";

import { REQUEST_URL, routerPath } from "../../interface/config";
import { HTTP } from "../../interface/HTTP";

import {
    sattisticsEventList,
    sttisticsPeopleList,
    header_of_sttisticsEventList,
} from "../../assets/appData/sttisticsEventList";

import "./StatisticsEvent.css";

export default function StatisticsEvent() {
    const [activeSelect, setActiveSelect] = useState(0);
	const [eventsList, setEventsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("");

    const [dateRange, setDateRange] = useState([Functions.setWeekAgo(), new Date()]);
    const [startDate, endDate] = dateRange;

    const filtersList = ["Все", "Идет регистрация", "Мероприятие отменено"];

    const eventsListRequest = async () => {
        setIsLoading(true);
        let requesrList = await HTTP.Get(REQUEST_URL.getEventsList);
        if (requesrList) {
            setEventsList(requesrList);
        }
        setIsLoading(false);
    };

    const getActiveSelect = index => {
        setActiveSelect(index);
    };

    const searchHandlerRender = (list, searchValueState, select) => {
        if (searchValueState !== ""){
            const filteredValues = list.filter(item => item.title.toLowerCase().includes(searchValueState.toLowerCase()))
            if (Number(select) === 0){
                return [...filteredValues];
            }
            else{
                if (Number(select) === 1){
                    return [...filteredValues.filter(item => item.status === "event_wait")];
                }
                if (Number(select) === 2){
                    return [];
                }
            }
        }
        else{
            if (Number(select) === 1){
                return [...list.filter(item => item.status === "event_wait")];
            }
            if (Number(select) === 2){
                return [];
            }
        }
        return list;
    }

	useEffect(() => {
		eventsListRequest();
	}, []);

    return (
        <>
            <Header />
            <div className="statistics-event">
                <div className="main">
                    <div className="container-content center">
                        {/* <GoBack text={"Назад"}  type="button" /> */}
                        <h1 className="peoples-list__title">Статистика</h1>
                        <div className="statistics-event__filter">
                            <div className="peoples-list__filter-sort">
                                <InputSearch
                                    placeholder="Поиск"
                                    value={searchValue}
                                    onChange={setSearchValue}
                                />
                                <SelectFilter
                                    items={filtersList}
                                    id={"type_of_events"}
                                    name={"type_of_events"}
                                    except={false}
                                    selectedItemKey={activeSelect}
                                    error={true}
                                    callback={getActiveSelect}
                                    text={"Фильтровать по:"}
                                />
                                {/* <Calendar
                                    dateRangeState={[dateRange, setDateRange]}
                                    startDateState={[startDate, endDate]}
                                /> */}
                            </div>
                        </div>

                        <secion className="statistics-event__wrapper">
                            <StatisticsEventList header={header_of_sttisticsEventList} data={searchHandlerRender(eventsList, searchValue, activeSelect)} />
                        </secion>
                    </div>
                </div>
            </div>
        </>
    );
}
