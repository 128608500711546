import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import CardsList from "../../components/CardsList/CardsList";
import Header from "../../components/Header/Header";

import { REQUEST_URL } from "../../interface/config";
import { HTTP } from "../../interface/HTTP";

import "./Events.css";

export default function Events() {
    const user_role = useSelector(({ profile }) => profile.role);

    const [eventsList, setEventsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const filtersList = ["Мои события", "Актуальные события", "Завершенные события"];

    const eventsListRequest = async () => {
        setIsLoading(true);
        let requesrList = await HTTP.Get(REQUEST_URL.getEventsList);
        if (requesrList) {
            setEventsList(requesrList);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        eventsListRequest();
    }, []);

    return (
        <>
            <Header />
            <div className="events container-content">
                <div className="main">
                    <CardsList title="Мероприятия" type="events" cardsList={eventsList} filters={filtersList} />
                </div>
            </div>
        </>
    );
}
