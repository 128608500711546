import React from "react";

import { Functions } from "../../interface/functions";

export default function EventInfo({ detailUserEvents }) {

    return (
        <div className="event-info">
            <ul className="card-profile__list">
                {detailUserEvents.length > 0 && detailUserEvents?.map(item => {
                    const newDate = new Date(item.time_create);

                    const eventDate = `${Functions.getMonthWithPrefix(
                        newDate.getDate()
                    )}.${Functions.getMonthWithPrefix(newDate.getMonth() + 1)}.${newDate.getFullYear()} г.`;

                    return (
                        <>
                            <li key={item.id}>
                                <p className="card-profile__label">{eventDate}</p>
                                <p className="card-profile__text">{item.title}</p>
                            </li>
                            <li className="input-wrapper"></li>
                        </>
                    );
                })}
                {detailUserEvents.length <= 0 && <p>Мероприятия не найдены</p>}
            </ul>
        </div>
    );
}
