import React from "react";

import "./ErrorBox.css";

export default function ErrorBox({ error, name, form }) {
  return (
    <>
      {error && form && form.errors[name] && form.touched[name] ? (
        <div className={"DAS-error-box"}>{form.errors[name]}</div>
      ) : null}
    </>
  );
}
