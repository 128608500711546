export const academic_level = [
    {
        id: "", 
        title: "Выберите уровень обучения"
    },
];

export const COURCES_LIST = [
    {
        id: "",
        title: "Выберите курс"
    },
];