import { useState, useEffect} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { HTTP } from "../../interface/HTTP";
import { REQUEST_URL } from "../../interface/config";
import Button from "../../UI/Button/Button";

export default function AccountActivatePage(){
  const [fetchStatusTitle, setFetchStatusTitle] = useState("Активация аккаунта");
  const [fetchStatusString, setFetchStatusString] = useState("Ждите");
  const navigate = useNavigate();

  const location = useLocation();

  const setActivateProfile = async () => {
    const locationsParts = location.pathname.replace("/activate-profile/", "").split("/");
    if (locationsParts.length === 2){
      const data = {
        uid: locationsParts[0],
        token: locationsParts[1],
      }
      const response = await HTTP.activateAccount(REQUEST_URL.activateProfile, data);

      if (response){
        setFetchStatusTitle("Аккаунт успешно активирован")
        setFetchStatusString("Теперь вы можете войти в свой аккаунт");
      }
      else{
        setFetchStatusString("Ошибка активации");
      }
    }
    else{
      navigate("/");
    }
  }

  const goToMainPage = () => {
    navigate("/");
  }

  useEffect(() => {
    setActivateProfile();
  }, []);

  return(
    <>
      <div className="reset">
          <div className="main-reset">
            <div className="main-reset-container">
              <div className="main-reset-container-title">{fetchStatusTitle}</div>
              <div className="main-reset-container-description">
                {fetchStatusString}
              </div>
              <Button
                color={"blue"}
                text={"Выйти на главную"}
                callback={goToMainPage}
              />
            </div>
          </div>
      </div>
    </>
  );
}
