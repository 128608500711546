import { NavLink, useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import Input from "../../UI/Input/Input";
import Button from "../../UI/Button/Button";
import LogoGroup from "../../components/LogoGroup/LogoGroup";
import Footer from "../../components/Footer/Footer";


import { token } from "../../Redux/Actions/profile.jsx";
import { HTTP } from "../../interface/HTTP";

import { REQUEST_URL, loginContext } from "../../interface/config";

import mguLogo from "../../images/other/mgu_logo_reg.webp";
import vk_logo from "../../images/other/vk_icon.png";
import tg_logo from "../../images/other/tg_icon.png";

import "./LoginPage.css";

export default function LoginPage() {
    const navigate = useNavigate();
    const [errors, setErrors] = useState([]);
    const tokenDispatch = useDispatch();
    const { setUserToken } = useContext(loginContext);

    const setToken = async (tokenItem) => {
        tokenDispatch(token(tokenItem));
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Неверный адрес электронной почты").required("Неверный адрес электронной почты"),
            password: Yup.string().required("Введите пароль"),
        }),
        validateOnChange: false, 
        validateOnBlur: false, 
        onSubmit: async (values) => {
            const responce = await HTTP.getLogin(REQUEST_URL.login, values);
            if (responce.auth_token){
                setUserToken(responce.auth_token);
            }
            else {
                setErrors([...responce.non_field_errors]);
            }
        },
    });

    return (
        <>
            <div className="login">
                <div className="container">
                    <div className="login-top">
                        <LogoGroup></LogoGroup>
                        <div className="login__form">
                            <div className="login__form-entryText">Вход</div>
                            <form onSubmit={formik.handleSubmit} className="login__form-data">
                                <Input
                                    mode="login"
                                    type="text"
                                    placeholder="email"
                                    id="email"
                                    {...formik.getFieldProps("email")}
                                />
                                <Input
                                    mode="login_passwordToggle"
                                    placeholder="пароль"
                                    id="password"
                                    {...formik.getFieldProps("password")}
                                />
                                <NavLink to="/password_reset" className="container__additional-forgotPassword container__additional-forgotPassword-undelined">
                                    Забыли пароль?
                                </NavLink>
                                <div className="login__form-errors">
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className="login__form-error">{formik.errors.email}</div>
                                    ) : null}
                                    {formik.touched.password && formik.errors.password ? (
                                        <div className="login__form-error">{formik.errors.password}</div>
                                    ) : null}
                                    <div className="login__form-error">
                                        {errors}
                                    </div>
                                </div>
                                <Button type="submit" color="white" text="Вход" />

                            </form>
                            <div className="container__additional">
                                {/* <NavLink to="/password_reset" className="container__additional-forgotPassword">
                                    Забыли пароль
                                </NavLink> */}
                                <NavLink to="/registration" className="container__additional-regSign">
                                Нет аккаунта? <span className="container__additional-regSign-highlight">Зарегистрироваться</span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer></Footer>
            </div>
        </>
    );
}
