import "./UserAdditionalInfo.css";

import Input from "../../UI/Input/Input.jsx";
import Select from "../../UI/Select/Select";

export default function UserAdditionalInfo({
    mode = "default",
    startDate = null,
    endDate = null,
    organisationName = "",
    organisationType = "",
    position = "",
    region = "",
    document,
    workType = "",
    isHead = false,
    logo,
}
) {

    return (
        <>
            {mode === "default" && (
                <div className="user__additionaInfo__container">
                    <div className="user__additionaInfo-content">
                        <div className="user__additionaInfo-date-wrapper">
                            <div className="user__additionaInfo-date">
                                <Input

                                    mode="fake"
                                    placeholder={startDate}
                                />
                            </div>
                            <div className="user__additionaInfo-date user__additionaInfo-date__divider"> - </div>
                            <div className="user__additionaInfo-date">

                                <Input
                                    mode="fake"
                                    placeholder={endDate}
                                />
                            </div>
                        </div>
                        <div className="user__additionaInfo-text  user__additionaInfo-text-name">
                            <Input
                                mode="fake"
                                placeholder={organisationName}
                            />
                        </div>
                    </div>
                    <div className="user__additionaInfo-text user__additionaInfo-text-position">
                        <Input
                            mode="fake"
                            placeholder={position}
                        />
                    </div>
                </div>
            )}

            {mode === "internships" && (
                <div className="user__additionaInfo__container">
                    <div className="user__additionaInfo-content">
                        <div className="user__additionaInfo-date-wrapper">
                            <div className="user__additionaInfo-date">
                                <Input

                                    mode="fake"
                                    placeholder={startDate}
                                />
                            </div>
                            <div className="user__additionaInfo-date user__additionaInfo-date__divider"> - </div>
                            <div className="user__additionaInfo-date">

                                <Input
                                    mode="fake"
                                    placeholder={endDate}
                                />
                            </div>
                        </div>
                        <div className="user__participations__section-item-name user__participations__section-workType">
                            <Input
                                mode="fake"
                                placeholder={workType}
                            />
                        </div>

                    </div>

                        <div className="user__additionaInfo-text  user__additionaInfo-text-name">
                            <Input
                                mode="fake"
                                placeholder={organisationName}
                            />
                        </div>
                    <div className="user__additionaInfo-text user__additionaInfo-text-position">
                        <Input
                            mode="fake"
                            placeholder={position}
                        />
                    </div>


                </div>
            )
            } 
            
            
            {/* {mode === "msu" && (
                <div>
                    <div className="user__participations__section-data hidden">
                        <div className="user__participations__section-data__organisationType">
                            <Input
                                mode="fake"
                                placeholder={organisationType}
                            />
                        </div>
                        <div className="user__participations__section-data__region">
                            <Input
                                mode="fake"
                                placeholder={region}
                            /></div>
                        <div className="user__participations__section-data__document">{document}</div>
                    </div>

                    <div className="user__participations__section__wrapper">
                        <div className="user__participations__section-item">
                            <div className="user__participations__section-item-image">{logo}</div>
                            <div className="user__participations__section-item-name">
                                <Input
                                    mode="fake"
                                    placeholder={organisationName}
                                /></div>
                            <div className="user__participations__section-item-position">
                                <Input
                                    mode="fake"
                                    placeholder={position}
                                /></div>
                            <div className="user__additionaInfo-date-wrapper">
                                <div className="user__participations__section-date">
                                    <Input

                                        mode="fake"
                                        placeholder={startDate}
                                    />
                                </div>
                                <div className="user__participations__section"> - </div>
                                <div className="user__participations__section-date">

                                    <Input
                                        mode="fake"
                                        placeholder={endDate}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )} */}

        </>
    );
}
